<template>
  <Indicator name="выполнение" :progress="loading">
    <template v-slot:button>
      <GoToButton v-if="false" text="в показатели" to="home" />
    </template>
    <template v-slot:content>
      <div class="mt-6">
        <div v-for="(el, idx) in [shop, user]" :key="idx" class="d-flex mt-3">
          <ShopCheap :shop="idx == 1 ? 'Я' : shop_id" style="bottom: -4px;" />
          <v-row class="ml-1">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div class="ml-5">
                  <v-badge
                    left
                    bordered
                    color="grey5"
                    :content="
                      (el.planmoney
                        ? ((el.moneyI || 0) / el.planmoney) * 100
                        : 0
                      ).toFixed() + '%'
                    "
                    class="grey2--text"
                  >
                    {{ el.moneyI || 0 | money }}
                  </v-badge>
                  <span class="grey5--text"> ₽ </span>
                </div>
                <div class="mr-5">
                  <v-badge
                    bordered
                    label
                    :color="
                      el.prognoz >= 95 ? 'green lighten-4' : 'red lighten-4'
                    "
                    :content="(el.prognoz || 0).toFixed() + '%'"
                    class="grey2--text"
                  >
                    {{ el.planmoney || 0 | money }}
                  </v-badge>
                  <span class="grey5--text"> ₽ </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="mt-n7">
              <v-progress-linear
                class="rounded-xl"
                :value="((el.moneyI || 0) / el.planmoney) * 100 || 0"
                :buffer-value="el.prognoz || 0"
                stream
                color="grey4"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </Indicator>
</template>

<script>
// если el.date | timedelta_ndays == 'сегодня'  class="orangeMain--text"
// <ShopCheap shop="371" :color="grey4" /> если shop != основной магазин

import ShopCheap from "./ShopCheapComponent";
import Indicator from "./IndicatorComponent";
import GoToButton from "./GoToButtonComponent";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "IndicatorWorkDays",
  components: {
    GoToButton,
    Indicator,
    ShopCheap
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    },
    shop_id: {
      type: String,
      required: false,
      default: undefined,
      validator: v => typeof v === "string"
    }
  },
  data: () => ({
    loading: false,
    shop: {}
  }),
  computed: {
    ...mapState(["back_domen", "year", "month"]),
    user() {
      if (!this.shop.sellers || !this.user_id) return {};
      for (let el of this.shop.sellers) {
        if (el.id == this.user_id) return el;
      }
      return {};
    }
  },
  watch: {
    shop_id() {
      this.add_data();
    },
    month() {
      this.add_data();
      console.log(this.month);
    },
    year() {
      this.add_data();
    }
  },
  methods: {
    async get_store_users(id, year, month) {
      return axios
        .post(this.back_domen + "/service/shop_users", {
          shop_id: id,
          month: month,
          year: year
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []))
        .catch(() => (this.loading = false));
    },
    add_data() {
      this.loading = true;
      this.get_store_users(this.shop_id, this.year, this.month)
        .then(data => (this.shop = data))
        .then(() => (this.loading = false));
    }
  },
  created() {
    if (this.shop_id) this.add_data();
  }
};
</script>

<style scoped>
$badge-color: black;
</style>
