<template>
  <v-btn fab text small class="grey6">
    <div class="d-flex text-18 grey3--text">
      <div>{{ $props.daynumber }}</div>
      <sup
        class="text-10 orangeMain--text mt-3 bold"
        v-text="$props.weekday"
      ></sup>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "DaySaleComponent",
  props: {
    daynumber: {
      type: String,
      required: false,
      default: "00",
      validator: v => typeof v === "string"
    },

    weekday: {
      type: String,
      required: false,
      default: "ПН",
      validator: v => typeof v === "string"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.Div {
  width: 74px;
  height: 29px;
  background: #ffffff;
  border-radius: 5px;
  float: left;
  text-align: center;
}
.DayNumber {
  font-size: 24px;
  line-height: 28px;
  color: rgba(242, 153, 74, 0.4);
}

.WeekDay {
  font-size: 14px;
  line-height: 16px;
  vertical-align: top;
}
</style>
