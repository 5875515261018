<template>
  <v-card  flat >
     <v-row justify="end" class="Arrow">
            <v-btn icon to="blog" >
              <v-icon color="grey">fas fa-arrow-left</v-icon>
            </v-btn>
          </v-row>
      <FillForBlog v-for="el in card" :key="el + Math.random()" :title="el.name" :content="el.content"/>
    </v-card>
</template>

<script>
import FillForBlog from "./FillForBlogComponent"

export default {
name: 'Page',
components: {
  FillForBlog,
},

data: () => ({
  open: true,
  card: [
        {name:'Перемещения товаров между магазинами 1С ',
   content: 'Перемещение влияет на движение по организации, а расходный ордер отражает движение по складу. Т.е. если Вы не внесли правки по Расходному ордеру, товар так и будет числится за Вами.Есть две ситуации, когда Вы отправляете товар и когда Вы получаете товар. Вы отправляете товар в другой магазин.'}
  ]
}),
}
</script>

<style scoped>
.Arrow {
 
  margin-bottom: 15px;
  margin-right: 15px;
}
</style>
