<template>
<v-main
  class="pb-0 pt-0"
  :style="style.background">
  <v-card
    color="grey6"
    max-width="800"
    height="100%"
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-6 pt-md-12 px-6 px-md-12 pb-16">
    <!-- открытие смены -->
    <v-btn
      class="my-2 my-md-6"
      height="55"
      :style="style.neobtn"
      width="100%"
      v-if="access.any != 'admin' && user.workNow && user.workNow != 'open'"
      @click="user.workNow == 'close' ? open_shift() :
              user.workNow == 'no' ? openshift = true : ''">
      открыть смену
      <v-icon class="pl-6"
              color="orangeMain">fas fa-key</v-icon>
      <v-icon v-if="user.workNow == 'no'" class="pl-1"
              color="orangeMain">fas fa-key</v-icon></v-btn>

    <!-- <div class="text-right mr-16 mb-n16 mt-6 orangeMain--text red" -->
    <!-- строка поиска -->
    <div v-if="Object.keys(active_product).length > 0"
         class="d-flex flex-rows">
      <div class=" ml-auto mr-16 mb-n16 mt-10 mb-n2 orangeMain--text"
           :style="style.foreground">
        <span class="pr-md-10">
          <span v-if="!count">{{active_product.Цена | money}}</span>
          <span v-else>{{count}} x {{active_product.Цена | money}} = {{active_product.Цена * count | money}}</span>
          <v-icon x-small class="orangeMain--text ml-1">fas fa-ruble-sign</v-icon>
        </span>
      </div>
    </div>

    <v-text-field outlined hide-details
                  :autofocus="copy_products.lenth > 0 ? true : false"
                  id="search"
                  class="py-0 mb-4 mb-md-6 mt-6"
                  :style="style.neocard"
                  :label="headers.join(' - ').toLowerCase()"
                  maxlength="20"
                  v-model.trim="search"
                  color="orangeMain"
                  @input="active_product={}">
      <v-icon v-show="search"
              v-if="search"
              color="orangeMain"
              slot="append"
              @click="search='' ;count=''">fas fa-backspace</v-icon>
    </v-text-field>

    <!-- таблица с результатами -->
    <v-simple-table
      class="px-md-12 py-4 neocard"
      color="grey6"
      v-if="Object.keys(this.active_product).length == 0 &&
            search_products.length > 0 &&
            full_products.length > 0">
      <template>
        <thead>
          <tr>
            <th
              class="text-center grey4--text"
              v-for="el in [...headers, ...headers_add]"
              :key="el">
              {{good_title[el] || el.toLowerCase()}}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr v-for="el in full_products" :key="el.Артикул"
              @click="active_product=el">
            <td
              v-for="el1 in [...headers, ...headers_add]"
              :key="el1"
              :width="el == 'Коллекция' ? '40%' : ''">
              <a class="black--text">
                {{el[el1] ? el[el1]: ''}}
              </a>
            </td>
            <td>
              <v-img v-if="el.Бонус"
                     contain
                     width="20px"
                     :src="$root.coin"
                     ></v-img>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- количество -->
    <v-row v-if="Object.keys(active_product).length > 0
                 && $route.name == 'Sale'
                 && user.workNow == 'open'
                 && user.seller">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="5">
            <v-row>
              <v-col cols="12" >
                <v-text-field outlined hide-details
                              color="orangeMain"
                              :style="style.neocard"
                              label="количество"
                              maxlength="3"
                              v-model="count"
                              @keydown="check_number($event)">
                  <v-icon v-show="count"
                          v-if="count"
                          color="orangeMain"
                          slot="append"
                          @click="count=''">fas fa-backspace</v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="count > 1 ? count-- : count = '' "
                  class="mt-n4"
                  height="40"
                  :style="{...style.neobtn, ...style.redborder}"
                  >-</v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  @click="count++"
                  class="ml-n2 ml-md-n0 mt-n4"
                  height="40"
                  :style="{...style.neobtn, ...style.greenborder}"
                  >+</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row class="ml-md-2">
              <v-col cols="4"
                     v-for="i in 6"
                     :key="'row1' + i"
                     class="px-0">
                <v-btn
                  height="40"
                  @click="count = i"
                  :style="style.neobtn"
                  >{{i}}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="6" md="12" class="pb-md-1">
            <v-btn large
                   :style="count == 0 ?
                           {...style.neocard, ...style.redborder} :
                           {...style.neobtn, ...style.redborder}"
                   width="100%"
                   v-if="$route.name == 'Sale'"
                   :disabled = "count == 0 || false"
                   @click="count=-count; add_sale()">возврат
            </v-btn>
          </v-col>
          <v-col cols="6" md="12">
            <v-btn large
                   :style="count == 0 ?
                           {...style.neocard, ...style.greenborder} :
                           {...style.neobtn, ...style.greenborder}"
                   width="100%"
                   :disabled = "count == 0 || false"
                   @click="add_sale()">продажа
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- инфа о товаре -->
    <div v-if="Object.keys(active_product).length > 0">
      <v-card
        class="pa-1 mt-6"
        :style="style.neobtn">
        <v-row>
          <v-col class="py-0" cols="6">
            <div class="text-24  orangeMain--text">
              {{active_product.Артикул}}</div>
            <div class="text-16 mt-n3 grey4--text">
              {{active_product.Коллекция}}</div>
          </v-col>
          <v-col  cols="6">
            <div
              class="mt-n1 mb-n3 text-end"
              v-for="el in ['ОстатокНаСкладе', 'СвободныйОстаток']"
              :key="el">
              <span class="text-12 grey4--text">{{good_title[el] || el.toLowerCase()}}</span>
              <span class="text-12"> {{active_product[el] | money}}</span>
            </div>
          </v-col>
        </v-row>
        <v-parallax dark
                    class="mt-3"
                    height="300"
                    :src="api_data[active_product.Артикул] &&
                          api_data[active_product.Артикул].imgFrontal.s600x400 ?
                          api_data[active_product.Артикул].imgFrontal.s600x400 :
                          (active_product.pic600x400 ||
                          active_product.pic100x100 || not_image)">
        </v-parallax>
      </v-card>
    </div>

    <!-- добавление продажи уведомление-->
    <v-snackbar top right
                :timeout="1500"
                v-model="addsale"
                :color="~message.indexOf('ошибка') ? 'error' :
                        ~message.indexOf('возврат') ? 'warning' : 'success'">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon
               v-bind="attrs"
               @click="addsale = false">
          <v-icon small>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- открытие смены-->
    <v-dialog
      overlay-color="black"
      overlay-opacity="0.8"
      width="440"
      v-model="openshift">
      <v-card flat
              class="pa-4"
              :style="style.neodialog">
        <v-card-title>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-alert
            outlined
            type="warning"
            prominent
            >
            <div class="text-12">
              магазин {{shop}} обеспокоен
            </div>
            <div>
              нет смены в графике работы
            </div>
          </v-alert>
          <div class="mt-6 mb-n3 blueGrey--text text-18 text-center">
            открыть смену?
          </div>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-space-between pb-5">
          <v-btn text
                 width="40%"
                 :style="{...style.neobtn}"
                 @click="openshift = false">
            <span class="blueGrey--text">
            Нет
            </span>
          </v-btn>

          <v-btn text
                 width="40%"
                 :style="{...style.neobtn}"
                 @click="openshift = false; open_shift()">
            <span class="blueGrey--text">
            Да
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     
  </v-card>

</v-main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import axios from 'axios'

export default {
name: 'Sale',
data: () => ({
    headers: ['ТорговаяМарка', 'Коллекция', 'Артикул'],
    headers_add: ['Цена'],
    search: '',
    active_product: {},
    openshift: false,
    products: [],
    full_products: [],
    count: '',
    copy_products: [],
    good_title: {
        'ОстатокНаСкладе': 'у поставщика',
        'СвободныйОстаток': 'свободно у поставщика',
        'ТорговаяМарка': 'ТМ'
    },
    message: '',
    addsale: false,
    editing: true
}),

computed: {

  ...mapState(['user', 'shop', 'access', 'not_image', 'back_domen',
               'api_data', 'style']),

  search_products: function () {
      if (!this.search) return [];
      let check = ['Артикул', 'ТорговаяМарка', 'Коллекция'];
      let result = this.products.filter(el => ~check.map(el1 =>
                                                         el[el1]).join(' ').toLowerCase().indexOf(this.search.toLowerCase()));
      if (result.length < 13) return result;
      return [];
  }

},

methods: {

  ...mapActions(['add_api_data']),

  async get_products() { //получаем список продуктов
      if (!this.shop) return;
      return axios.post(this.back_domen + '/service/productlist')
          .then(res => res.data)
          .then(js => this.products = js['response']);
  },

  async get_full_products() { // получаем расширенные продукты
      return axios.post(this.back_domen + '/service/product', {
          'products': this.search_products,
          'price': true
      }).then(res => res.data)
          .then(json => this.full_products = json['response']);
  },

  async add_sale() { // регистрируем продажу
    let article = this.active_product.Артикул;
    return axios.post(this.back_domen + '/service/addsale', 
      Object.assign(this.active_product,
                    this.user,
                    {'count': this.count,
                     'shopID': this.shop})
    ).then(res => res.data)
    .then(json => {
       if (this.count > 0)
           this.message = json.response.article + ' продажа оk';
       else
           this.message = json.response.article + ' возврат ok';
      this.count = 0;
      this.addsale = true;
      this.search = '';
      if (this.active_product.Артикул == article) this.active_product = {};
    })
    .catch(() => {
      this.message = 'произошла ошибка, ' + article + ' не был добавлен';
      this.addsale = true;
    });
  },

  async open_shift() { // открываем смену
    return axios.post(this.back_domen + '/service/openshift', this.user)
    .then(res => res.data)
    .then(json => {
      if (json.response.status == 'open') this.user.workNow = 'open';
    });
  },

  find_product_full: function (el) {
    for (let prod of this.full_products){
      let result = Object.values(prod).filter(
        val => val && ~Object.values(el).indexOf(val));
      // console.log(el, prod, result);
      if (result.length == Object.values(el).filter(el => el).length)
        return prod;
    }
    return {};
  },

  check_number: function (event) {
    if (event.keyCode != 13 && event.keyCode != 8 && event.keyCode != 9) {
      if (isNaN(event.key) || +event.key == 0) event.preventDefault();
    }
  },

  focus(id, focus) { // снимаем фокус с элемента
    let el = document.getElementById(id);
    focus ? el.click() : el.blur();
  }


},

watch: {

  search_products () {
    // запрашиваем новые продукты при изменении результатов поиска
    if (this.search_products.length > 0) {
      let set1 = this.search_products.map(el => el.Артикул);
      let set2 = new Set(this.copy_products.map(el => el.Артикул));
      if ([...set1].filter(el => !set2.has(el)).length > 0) {
        this.full_products = [];
        this.get_full_products();
        this.copy_products = [...this.search_products];
      }
    }
    // автоматическое открытие продукта, если найден только один
    // console.log('!!!', this.search_products);
    if (this.search_products.length == 1 &&
        Object.keys(this.active_product).length == 0) 
      this.active_product = this.find_product_full(this.search_products[0]);
  },

  active_product () {
    // заполняем строку поиска при выборе продукта  и снимаем фокус
    if (Object.keys(this.active_product).length > 0) {
      this.search = Object.values(this.active_product).filter(
        el => typeof(el) == 'string' && ~el.toLowerCase().indexOf(
          this.search.toLowerCase()))[0].toLowerCase();
      this.focus('search', false);
    }
    // запрашиваем данные из апи
    if (this.active_product.Артикул)
      this.add_api_data([this.active_product.Артикул,]);
    
  },

  search () {
    // при удалении поискового запроса чистим результат
    if (!this.search) this.active_product = {};
    // console.log('search', this.search_products);
    // console.log('full', this.full_products);
  },

  full_products () {
    // ищем продукт
    if (this.full_products.length > 0 && this.search_products.length == 1)
      this.active_product = this.find_product_full(this.search_products[0]);
  },

  shop () {
    if (this.shop) {
      this.active_product = {};
      this.get_products().then(() => {
        if (this.search_products.length > 0) {
          let set1 = this.search_products.map(el => el.Артикул);
          let set2 = new Set(this.copy_products.map(el => el.Артикул));
          if ([...set1].filter(el => !set2.has(el)).length > 0) {
            this.full_products = [];
            this.get_full_products();
            this.copy_products = [...this.search_products];
          }
        }
      });
    }
  }
},


created: function() {
  this.get_products();
}

}
</script>
