<template>
  <div class="my-16">
    <!-- Лимит -->
    <div v-if="loading && !cards.length">
      <div class="text-h4 text-center grey3--text">
        не найдены
      </div>
      <div class="text-center grey4--text text-16 mt-3 mx-10">
        обычно их добавляют в начале месяца, загляните сюда попозже
      </div>
    </div>
    <div
      v-if="limit"
      name="Лимит"
      style="position: absolute; top: 4px; right: 10px; width: 50%"
      class="text-right"
    >
      <!-- v-model="(limit[1] / 100) * limit[0]" -->
      <v-progress-linear
        class="rounded-lg grey2--text"
        color="orangeMain"
        v-model="complete"
        height="25"
      >
        <span>{{ `${complete} из ${limit}` }}</span>
      </v-progress-linear>
      <div class="d-flex justify-space-between grey3--text px-2 mb-3">
        <div>
          продано
        </div>
        <div>
          лимит
        </div>
      </div>
    </div>

    <!-- Про закончившийся лимит -->
    <div v-if="limit && complete >= limit" class="white--text red px-3">
      <span>
        Лимит бонус товаров в этом месяце для этого магазина закончился
      </span>
    </div>

    <!-- Про заработок -->
    <div v-if="money && complete < limit" class="mt-16">
      <span class="text-18 orangeMain white--text rounded-xl pa-1 mx-1"
        >{{ money }} руб
      </span>
      <span class="grey3--text"> за штуку</span>
    </div>

    <!-- Список товаров -->
    <div v-for="(collections, vendor) in chapters" :key="vendor">
      <div v-for="col in collections" :key="col" class="pb-6">
        <div class="grey7 pr-3 text-18 grey3--text text-right">
          <span class="grey1--text mr-2">{{ col }}</span>
          <span>{{ vendor }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div
            style="width: 33%; height: 100px"
            v-for="(el, i) in cards.filter(
              el => el.ТорговаяМарка == vendor && el.Коллекция == col
            )"
            :key="i"
          >
            <v-img
              style="opacity: 0.8"
              lazy-src="https://picsum.photos/id/237/600/400"
              :src="
                api_data[el.Артикул]
                  ? api_data[el.Артикул].imgFrontal.s600x400
                  : el.pic600x400 || not_image
              "
              aspect-ratio="1"
              height="90"
              class="rounded-lg ma-1 d-flex align-end text-right"
            >
              <div style="opacity: 0.9" class="orangeMain px-1">
                {{ el.Артикул }}
              </div>
            </v-img>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Bonus",
  data: () => ({
    loading: false,
    limit: 0,
    complete: 0,
    settings: {},
    cards: []
  }),

  computed: {
    ...mapState(["shop", "api_data", "not_image", "user"]),

    chapters() {
      let vendors = [...new Set(this.cards.map(el => el.ТорговаяМарка))].sort(
        (a, b) => a - b
      );
      vendors = vendors.reduce(
        (acc, el) =>
          el
            ? {
                ...acc,
                [el]: [
                  ...new Set(
                    this.cards
                      .filter(el1 => el1.ТорговаяМарка == el)
                      .map(el => el.Коллекция)
                  )
                ].sort((a, b) => a - b)
              }
            : acc,
        {}
      );
      return vendors;
    },

    money() {
      if (!this.user.status || !this.settings.data) return 0;
      return this.settings.data
        .filter(el => el.status == this.user.status)
        .map(el => el.money)[0];
    }
  },

  methods: {
    ...mapActions(["add_api_data"]),

    async art() {
      if (!this.shop) return;
      this.loading = true;
      return axios
        .post("/service/bonusroll", {})
        .then(res => res.data)
        .then(json => {
          this.cards = json["response"].goods || [];
          this.limit = json["response"].limit || 0;
          this.complete = json.response.selling || 0;
          this.settings = json.response.settings || {};
        })
        .then(() =>
          this.cards.length
            ? this.add_api_data(this.cards.map(el => el.Артикул))
            : ""
        )
        .then(() => (this.loading = true));
    }
  },

  watch: {
    shop() {
      this.art();
    }
  },

  created: function() {
    this.art();
  }
};
</script>

<style scoped></style>
