import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_show: false,
    ready: false, // предварительные обязательные данные загружены
    loading: false, // бегунок закгрузки
    monitor: undefined, // размер монитора
    pic_art: {}, // артикул из апи с картинками для пока в карусели
    menu: {
      default: { main: [], extra: [] },
      shop: {
        main: [
          {
            name: "главная",
            icon: "mdi-badge-account-outline",
            key: "home",
            func: "go_to"
          },
          {
            name: "зарплата",
            icon: "mdi-wallet-outline",
            key: "salary",
            func: "go_to"
          },
          {
            name: "продажи",
            icon: "mdi-point-of-sale",
            key: "sale",
            func: "go_to"
          },
          {
            name: "показатели",
            icon: "mdi-chart-arc",
            key: "indicators",
            func: "go_to"
          }
        ],
        extra: [
          {
            name: "карьера",
            icon: "mdi-rocket-launch-outline",
            key: "career",
            func: "go_to"
          },
          { name: "знания", icon: "mdi-newspaper", key: "blog", func: "go_to" },
          {
            name: "рейтинги",
            icon: "mdi-chart-box-outline",
            key: "top",
            func: "go_to"
          },
          {
            name: "бонус тов.",
            icon: "mdi-fruit-cherries",
            key: "bonus",
            func: "go_to"
          },
          {
            name: "Заказ",
            icon: "mdi-truck-outline",
            key: "orders",
            func: "go_to"
          }
        ]
      },
      seller: {
        main: [
          {
            name: "главная",
            icon: "mdi-badge-account-outline",
            key: "home",
            func: "go_to"
          },
          {
            name: "зарплата",
            icon: "mdi-wallet-outline",
            key: "salary",
            func: "go_to"
          },
          {
            name: "продажи",
            icon: "mdi-point-of-sale",
            key: "sale",
            func: "go_to"
          },
          {
            name: "показатели",
            icon: "mdi-chart-arc",
            key: "indicators",
            func: "go_to"
          }
        ],
        extra: [
          {
            name: "карьера",
            icon: "mdi-rocket-launch-outline",
            key: "career",
            func: "go_to"
          },
          { name: "знания", icon: "mdi-newspaper", key: "blog", func: "go_to" },
          {
            name: "рейтинги",
            icon: "mdi-chart-box-outline",
            key: "top",
            func: "go_to"
          },
          {
            name: "бонус тов.",
            icon: "mdi-fruit-cherries",
            key: "bonus",
            func: "go_to"
          },
          {
            name: "Заказ",
            icon: "mdi-truck-outline",
            key: "orders",
            func: "go_to"
          }
        ]
      },
      admin: {
        main: [
          {
            name: "главная",
            icon: "mdi-badge-account-outline",
            key: "home",
            func: "go_to"
          },
          {
            name: "зарплата",
            icon: "mdi-wallet-outline",
            key: "salary",
            func: "go_to"
          },
          {
            name: "продажи",
            icon: "mdi-point-of-sale",
            key: "sale",
            func: "go_to"
          },
          {
            name: "показатели",
            icon: "mdi-chart-arc",
            key: "indicators",
            func: "go_to"
          }
        ],
        extra: [
          {
            name: "карьера",
            icon: "mdi-rocket-launch-outline",
            key: "career",
            func: "go_to"
          },
          { name: "знания", icon: "mdi-newspaper", key: "blog", func: "go_to" },
          {
            name: "рейтинги",
            icon: "mdi-chart-box-outline",
            key: "top",
            func: "go_to"
          },
          {
            name: "бонус тов.",
            icon: "mdi-fruit-cherries",
            key: "bonus",
            func: "go_to"
          },
          {
            name: "Заказ",
            icon: "mdi-truck-outline",
            key: "orders",
            func: "go_to"
          }
        ]
      },
      producer: {
        main: [
          {
            name: "дашборд",
            icon: "mdi-radar",
            key: "producer",
            func: "go_to"
          },
          {
            name: "обор-ть",
            icon: "mdi-restart",
            key: "turnover2022",
            func: "go_to"
          },
          {
            name: "abc",
            icon: "mdi-file-table-outline",
            key: "abc2022",
            func: "go_to"
          }
        ],
        extra: []
      }
    },
    username: localStorage.getItem("access_token")
      ? VueJwtDecode.decode(localStorage.getItem("access_token")).sub
      : "", // имя пользователя
    fullname: localStorage.getItem("access_token")
      ? VueJwtDecode.decode(localStorage.getItem("access_token")).user_claims
          ?.name
      : "",
    access: localStorage.getItem("access_token")
      ? {
          ...(VueJwtDecode.decode(localStorage.getItem("access_token"))
            ?.user_claims?.[process.env.VUE_APP_JWT_DOMEN || document.domain] ||
            {}),
          root: VueJwtDecode.decode(localStorage.getItem("access_token"))
            .user_claims?.root
        }
      : {}, // права пользователя
    year: new Date().getFullYear(), // глобальный год
    month: new Date().getMonth() + 1, // глобальный месяц
    months: [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь"
    ],
    days: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    access_shop: [],
    statuses: ["стажер", "юниор", "мастер", "эксперт"],
    data_tree: {}, // дерево данных
    style: {
      ordneo: {
        background: "linear-gradient(to top left, #FFFFFF, #EAEAEA",
        "box-shadow":
          "inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(225, 225, 225, 0.5), -4px 4px 8px rgba(225, 225, 225, 0.2), 4px -4px 8px rgba(225, 225, 225, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(225, 225, 225, 0.9)",
        "border-radius": "15px",
        border: "1px solid rgba(255, 212, 128, 0.2)"
      },
      neobtn: {
        background: "#F2F2F2",
        "box-shadow":
          "-7px 7px 14px rgba(224, 224, 224, 0.2), 7px -7px 14px rgba(224, 224, 224, 0.2), -7px -7px 14px rgba(255, 255, 255, 0.9), 7px 7px 18px rgba(224, 224, 224, 0.9), inset -1px -1px 2px rgba(224, 224, 224, 0.5)",
        "border-radius": "15px"
      },
      neocard: {
        background: "#F2F2F2",
        "box-shadow":
          "1px 1px 2px rgba(255, 255, 255, 0.6), -1px -1px 2px rgba(201, 201, 201, 0.5), inset -4px 4px 8px rgba(201, 201, 201, 0.2), inset 4px -4px 8px rgba(201, 201, 201, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.9), inset 4px 4px 10px rgba(201, 201, 201, 0.9)",
        "border-radius": "15px"
      },

      neodialog: {
        background: "#FCFCFC",
        border: "1px solid #FFAA00",
        "box-sizing": "border-box",
        "border-radius": "15px"
      },
      neomenu: {
        border: "0.5px solid #FFFFFF",
        "box-shadow":
          "-4px -3px 15px rgba(189, 189, 189, 1), 2px 4px 10px rgba(255, 255, 255, 1)",
        "border-radius": "20px",
        background: "linear-gradient(to  top left, #FFFFFF, #E1E1E1",
        height: "27px",
        width: "39px"
      },
      neoexp: {
        background: "#F2F2F2",
        border: "0.5px solid rgba(255, 255, 255, 0.5)"
      },
      pressbtn: {
        border: "0.5px solid rgba(111, 222, 0, 0.2)",
        background: "#F2F2F2",
        "box-shadow":
          "inset -3px -3px 1.5px rgba(255, 255, 255, 1), inset 3px 3px 1px rgba(0, 0, 0, 0.15), -2px -2px 3px rgba(255, 255, 255, 1), 2px 3px 4px rgba(0, 0, 0, 0.4), inset 10px 10px 20px rgba(0, 0, 0, 0.11), inset -31px -31px 43px rgba(255, 255, 255, 0.64)",
        "border-radius": "20px",
        height: "27px",
        width: "39px"
      },

      neostock: {
        background: "#E6E9F4",
        "box-shadow":
          "1px 1px 2px rgba(255, 255, 255, 0.6), -1px -1px 2px rgba(201, 201, 201, 0.5), inset -4px 4px 8px rgba(201, 201, 201, 0.2), inset 4px -4px 8px rgba(201, 201, 201, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.9), inset 4px 4px 10px rgba(201, 201, 201, 0.9)",
        "border-radius": "5px",
        width: "110px",
        height: "40px",
        "text-color": "#383F61"
      },

      toggle: {
        border: "1px solid rgba(134, 142, 170, 0.1)",
        background: "#383F61",
        "box-shadow":
          "inset -3px -3px 3px rgba(255, 255, 255, 0.1), inset 4px 4px 2px rgba(0, 0, 0, 0.25)"
      },

      btntop: {
        background: "#FFAA00",
        "box-shadow":
          "inset -3px -3px 2px rgba(255, 255, 255, 0.35), inset 3px 3px 5px rgba(0, 0, 0, 0.45)",
        border: "0.5px solid rgba(255, 170, 0, 0.62)"
      },

      neologin: {
        background: "#F2F2F2",
        border: "1px solid #BEBEBE",
        "box-shadow":
          "inset 0px -10px 10px rgba(0, 0, 0, 0.3), inset 10px 10px 10px rgba(54, 54, 54, 0.5), inset -15px 0px 10px rgba(255, 255, 255, 1)",
        "border-radius": "10px"
      },
      textlog: {
        background: "#FFFFFF",
        "box-shadow":
          " inset 3px 5px 10px rgba(0, 0, 0, 0.4), inset 5px 0px 10px rgba(255, 255, 255, 0.9), inset -5px -5px 10px rgba(255, 255, 255, 1)",
        "border-radius": "5px"
      },
      greenborder: { border: "1px solid rgba(111, 222, 0, 0.2)" },
      redborder: { border: "1px solid rgba(255, 130, 130, 0.2)" },
      whiteborder: { border: "1px solid rgba(255, 255, 255, 0.2)" },
      btnagree: {
        border: "solid 4px rgba(255, 130, 130, 0.15)",
        "border-radius": "5px",
        "box-shadow": "0 0 2px #FF6767",
        background: "#F2F2F2"
      },
      background: {
        background: "url(https://251317.selcdn.ru/oboi.ru/background/fon5.png)",
        "background-size": "400px"
      },
      // background: {},
      transparent: { "background-color": "transparate" },
      foreground: { "z-index": "1", position: "relative" }
    },
    user: {}, // данные активного пользователя
    shop: "", // ид активного магазина
    userinfo: {}, // инфа по пользователям магазина (для переключения)
    all_shop_info: {}, // инфа по всем магазинам для карточки с подсказкой
    shopID_show: "", // ид магазина для показа
    api_data: {}, // данные из апи по артикулам
    back_domen: process.env.VUE_APP_BACK_DOMEN || "",
    jwt_domen: process.env.VUE_APP_JWT_DOMEN || document.domain,
    positions: {
      seller: "продавец",
      cashier: "кассир",
      storekeeper: "кладовщик"
    },
    icons: {
      article: "",
      email: "fas fa-store",
      category: "",
      vendor: "fas fa-trademark",
      collection: "",
      price: "fas fa-tag",
      costprice: "",
      sale: "fas fa-shopping-cart",
      kpd: "fas fa-pepper-hot",
      marjin: "маржа",
      profit_balance_m: "fas fa-coins",
      balance: "fas fa-cubes",
      current_balance_m: "текущий ТовЗап ₽",
      average_balance: "cрдн ТовЗап шт",
      average_balance_m: "cрдн ТовЗап ₽",
      turnover_ratio: "обор-ть",
      turnover_ratio_m: "обор-ть ₽",
      turnover_ratio_day: "fas fa-undo",
      stock_level_day: "far fa-calendar-alt"
    },
    version: "",
    not_image: "https://251317.selcdn.ru/goods/noimage.jpg", // заглушка для пустой картинки
    coin: "https://251317.selcdn.ru/oboi.ru/sup/coinbig.gif", // монетка для бонуса
    not_found: "https://251317.selcdn.ru/oboi.ru/no_results_found.png", // заглушка на отсутствие данных
    logoWhite:
      "https://251317.selcdn.ru/oboi.ru/logo/%D0%BB%D0%BE%D0%B3%D0%BE%20%D0%B1%D0%B5%D0%BB%D1%8B%D0%B9.png",
    logo:
      "https://251317.selcdn.ru/oboi.ru/logo/%D0%BB%D0%BE%D0%B3%D0%BE%20%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D0%BE%D0%B9.png",
    help: {
      // текст для подсказок по
      tbu:
        "Точка безубыточности - минимальная сумма месячной прибыли, необходимая для покрытия всех расходов",
      plan:
        "План выручки - сколько мы планируем получить денег в данном периоде в этом магазине.<br>На основании плана будут расчитаны ежедневные планы продаж, индивидуальные планы для продавцов.<br>Важно выставлять план обоснованно, т.е. завышенная сумма приведет к демотивации сотрудников, а заниженная сумма еще и к чрезмерным затратам на заработную плату"
    },
    show_help: "", // ключ подсказки для показа
    no_logo: "https://251317.selcdn.ru/oboi.ru/sup/wtf.gif" // закглушка для невыбранного пользователя или магазина
  },

  mutations: {
    login_on(state) {
      // console.log("login_on()");
      state.login_show = true;
    },

    login_off(state) {
      state.login_show = false;
    },
    change_data_tree(state, value) {
      state.data_tree = value;
      state.data_tree.time = Math.floor(Date.now() / 1000) + 3600 / 2;
    },
    change_pic_art(state, value) {
      state.pic_art = value;
    },
    change_ready(state, value) {
      state.ready = value;
    },
    change_shopID_show(state, value) {
      state.shopID_show = value;
    },
    change_monitor(state, value) {
      state.monitor = value;
    },
    change_username(state, value) {
      state.username = value;
    },
    change_footer_date(state, value) {
      if (value) state.footer_date = true;
      else state.footer_date = false;
    },
    change_access(state, value) {
      if (value) {
        state.access =
          VueJwtDecode.decode(value).user_claims[state.jwt_domen] || {};
        if (VueJwtDecode.decode(value).user_claims.root)
          state.access.root = true;
      } else state.access = {};
    },
    change_version(state, value) {
      state.version = value.replace("-", ".");
    },
    change_back_domen(state, value) {
      state.back_domen = value;
    },
    change_shop(state, value) {
      if (value) localStorage.setItem("shop", value);
      state.shop = value;
    },
    change_user(state, user) {
      // console.log('user', user);
      if (Object.keys(user).length > 1)
        localStorage.setItem("supuser", JSON.stringify(user));
      if (Object.keys(state.user).length == 1 && Object.keys(user).length == 0)
        return;
      state.user = user;
      // console.log("user", user);
    },
    change_access_shop(state, value) {
      if (!Array.isArray(value)) value = [value];
      // console.log('new_shop', value);
      state.access_shop = value.filter(el => !isNaN(el.slice(0, 3)));
    },
    change_month(state, month) {
      if (month && month <= 12) state.month = month;
    },
    change_year(state, year) {
      if (year) state.year = year;
    },
    change_show_help(state, value) {
      state.show_help = value;
    },
    change_api_data(state, value) {
      for (let el of value) state.api_data[el.article] = el;
      if (Object.keys(state.api_data).length > 1000) {
        state.api_data = Object.values(state.api_data).reduce(
          (acc, el) =>
            ~value.map(el => el.article).indexOf(el.article)
              ? { ...acc, [el.article]: el }
              : acc,
          {}
        );
      } else state.api_data = { ...state.api_data };
    },
    set_userinfo(state, info) {
      let userinfo = {};
      for (let el of info) {
        if ((state.shop && state.shop != el.shop) || !el.workNow) continue;
        userinfo[el.id] = el;
      }
      state.userinfo = userinfo;
    },
    set_all_shop_info(state, info) {
      if (info.length == 0) return;
      state.all_shop_info = info.reduce(
        (acc, el) => (el.ID ? { ...acc, [el.ID]: el } : acc),
        {}
      );
    }
  },

  actions: {
    init_userinfo: ({ commit, state }) => {
      // инфа по продавцам магазина
      let date = new Date();
      // if (state.access.any == "admin") return;
      return axios
        .post(state.back_domen + "/service/userinfo", {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          shopID: state.shop
        })
        .then(res => res.data)
        .then(json => {
          commit("set_userinfo", json["response"]);
        });
    },

    init_all_shop_info: ({ commit, state }) => {
      // инфа по всем магазинам для справки
      return axios
        .post(state.back_domen + "/service/shopinfo", {
          attributes: [
            "ID",
            "planmoney",
            "users.name",
            "format",
            "address",
            ".name",
            "name",
            "email"
          ]
        })
        .then(res => res.data)
        .then(json => {
          commit("set_all_shop_info", json["response"]);
        });
    },

    init_data_tree: ({ commit, state }) => {
      // получение дерева данных
      let admin = Object.entries(state.access).reduce(
        (acc, [k, v]) =>
          v == "admin" || v == "producer" ? { ...acc, [k]: v } : acc,
        {}
      );
      if (
        state.access.any != "admin" &&
        state.access.any != "producer" &&
        Object.keys(admin).length == 0
      )
        return;
      return axios
        .post(state.back_domen + "/service/datasheff", {
          month: state.month,
          year: state.year,
          balance: true,
          shopID: state.access.any ? "all" : Object.keys(admin),
          attributes: {
            tree: ["plan", "money", "prognoz"],
            region: ["name", "money", "pic", "money", "prognozP", "plan"],
            shop: [
              "name",
              "address",
              "format",
              "tochkabu",
              "planmoney",
              "plan_ss",
              "art_ss",
              "sales_itog",
              "ad",
              "phone",
              "graph",
              "equipment",
              "money",
              "freemoney",
              "sellingBonus",
              "moneypercent",
              "ID",
              "correct",
              "moneyI",
              "prognoz",
              "wdayscount",
              "balanceDate",
              "space",
              "adM",
              "peopleCount",
              "marjin",
              "buyingCount",
              "conversion",
              "averCheck",
              "storekeepers",
              "cashiers"
            ],
            user: [
              "name",
              "mininame",
              "pic",
              "phone",
              "allShop",
              "addon",
              "grade",
              "leader",
              "shopD",
              "workNow",
              "sellingMoney",
              "correction",
              "sellingBonus",
              "shiftNum",
              "shiftOpen",
              "shiftOpenFul",
              "planmoney",
              "prognoz",
              "planpercent",
              "personaleffectiv",
              "moneyI",
              "disabled",
              "position"
            ]
          }
        })
        .then(res => res.data)
        .then(json => {
          commit("change_data_tree", json["response"]);
        });
    },

    init_access_shop: ({ commit, state }) => {
      // список доступных магазинов
      if (state.access.any == "admin" || Object.keys(state.access).length == 0)
        axios
          .post(state.back_domen + "/service/access_shop", {})
          .then(res => res.data)
          .then(json => {
            commit("change_access_shop", json["response"]);
          });
      else
        commit(
          "change_access_shop",
          Object.keys(state.access).filter(el => el != "any" && el != "root")
        );
    },

    init_settings: ({ commit }) => {
      fetch(`/config.json?v=${Math.random()}`, { method: "GET" })
        .then(res => res.json())
        .then(json => {
          // console.log(json);
          if (json["Version"]) commit("change_version", json["Version"]);
          if (json["Domain"]) commit("change_back_domen", json["Domain"]);
          // console.log(state.version, state.back_domen)
        });
    },

    add_api_data: ({ commit, state }, articles) => {
      articles = articles.filter(el => !state.api_data[el]);
      if (articles.length == 0) return;
      axios
        .post("https://api.oboi.ru/application/product", {
          filter: { article: articles }
        })
        .then(res => res.data)
        .then(json => {
          commit("change_api_data", json["response"]);
        });
    }
  },

  modules: {}
});
