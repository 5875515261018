<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pt-3 pb-12 px-md-6"
      width="1060"
      height="100%"
    >
      <v-tabs
        center-active
        height="100"
        background-color="grey6"
        v-model="tabs"
        slider-size="0"
        slider-color="grey6"
        color="black"
      >
        <v-tab class="mx-2 my-8" :style="style.neobtn" @click="tabs = 'blog'">
          Моя база знаний
        </v-tab>
        <v-tab
          class="mx-2 my-8"
          :style="style.neobtn"
          @click="tabs = 'blog_oboiru'"
        >
          Статьи oboi.ru
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-4 pt-md-3 grey6" v-model="tabs">
        <v-tab-item key="blog">
          <BlogLocal />
        </v-tab-item>
        <v-tab-item key="blog_oboiru">
          <Blog />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import Blog from "./blog.vue";
import BlogLocal from "./blog_local.vue";
export default {
  name: "BlogGroup",
  components: {
    Blog,
    BlogLocal
  },
  data: () => ({
    tabs: "blog"
  }),
  computed: {
    ...mapState([
      "shop",
      "user",
      "back_domen",
      "access",
      "style",
      "not_image",
      "jwt_domen"
    ])
  },

  methods: {
    load_section(key) {
      let data = this.tabsName.filter(el => el.key == key);
      if (data.length && data[0].load) {
        for (let el of data[0].load) {
          this[el]();
        }
      }
    }
  }
};
</script>
