<template>
  <v-card height="100%" elevation="0" flat class="grey3 rounded-0">
    <div class="d-flex justify-space-between">
      <v-date-picker
        class="rounded-xl ml-auto"
        color="orangeDark"
        v-model="picker"
        no-title
        type="month"
        locale="ru"
      ></v-date-picker>
    </div>
    <div class="mt-3 text-14 font-weight-thin white--text"></div>
    <div
      class="d-flex flex-wrap justify-end ml-6"
      v-if="shops_list && (access_shop.length > 1 || !shop)"
    >
      <v-btn
        fab
        text
        v-for="i in access_shop"
        :key="i"
        small
        @click="change_shop(i)"
      >
        <ShopCheap
          :color="i === shop ? 'orangeMain' : 'white'"
          :text_color="i !== shop ? 'orangeMain' : 'white'"
          size="38"
          :shop="i"
        />
      </v-btn>
    </div>
    <div
      class="ml-4 mt-8"
      v-if="workers_list && (Object.values(userinfo).length > 1 || !user.id)"
    >
      <div>
        <v-card
          color="background9"
          flat
          v-for="l in Object.values(userinfo)"
          :key="l.id"
          class="rounded-lg white ma-1 pl-1 text-18 pl-6"
          :class="!user.id ? 'jitter' : ''"
          @click="change_user(l)"
        >
          <Manager
            class="ml-4 py-1"
            size="18"
            text_size="text-16"
            :color="l.id == user.id ? 'white' : 'grey6'"
            :text_color="l.id == user.id ? 'grey3' : 'grey1'"
            :id="l.id"
            :user_name="l.name"
            :user_status="l.status"
            :work_now="l.workNow == 'no' ? false : true"
          />
        </v-card>
      </div>
    </div>
    <div class="d-flex" style="position: absolute; bottom: 16px; right: 10px">
      <div class="background7--text mt-3 mr-3 ml-auto text-14">
        {{ fullname || username }}
      </div>
      <v-btn text class="orangeDark rounded-lg" color="white" @click="logout()">
        <span class="text-14"> Выход </span>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ShopCheap from "./ShopCheapComponent";
import Manager from "./ManagerComponent";

export default {
  name: "AppAvatarDesktop",

  components: {
    ShopCheap,
    Manager,
  },
  emits: ["change_theme"],
  data: () => ({
    opend: false,
    workers_list: false,
    shops_list: false,
    rainbow: [
      "violet",
      "indigo",
      "blue",
      "green",
      "orange",
      "red",
      "violet",
      "indigo",
      "blue",
      "green",
      "orange",
      "red",
    ],
    picker: new Date().toISOString().substr(0, 7),
  }),
  computed: {
    ...mapState([
      "user",
      "logoWhite",
      "shop",
      "loading",
      "access",
      "access_shop",
      "no_logo",
      "username",
      "year",
      "month",
      "months",
      "all_shop_info",
      "userinfo",
      "login_show",
      "fullname",
      "ready",
    ]),
  },

  methods: {
    ...mapMutations([
      "login_on",
      "change_user",
      "change_footer_date",
      "change_shop",
      "change_username",
      "change_month",
      "change_year",
    ]),
    logout() {
      //выход из системы
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.login_on();
      // this.$router.push({ name: "Login" });
    },
    check_access() {
      if (this.access.any == "admin") {
        this.workers_list = false;
        this.shops_list = true;
      } else if (Object.values(this.access).includes("shop")) {
        this.workers_list = true;
        this.shops_list = false;
      } else if (Object.values(this.access).includes("seller")) {
        this.workers_list = false;
        this.shops_list = true;
      }
    },
  },
  watch: {
    picker() {
      if (this.month != +this.picker.split("-")[1]) {
        this.change_month(+this.picker.split("-")[1]);
      }
      if (this.year != +this.picker.split("-")[0]) {
        this.change_year(+this.picker.split("-")[0]);
      }
    },
    access() {
      this.check_access();
    },
    ready() {
      if (!this.user.id && this.ready) {
        this.opend = true;
      }
    },
  },
  created() {
    this.check_access();
    if (!this.user.id && this.ready) {
      this.opend = true;
    }
  },
};
</script>
<style scoped>
.AppAvatarButton {
  /* filter: drop-shadow(0px 0px 3px white); */
  z-index: 9;
  position: fixed;
  left: 10px;
  top: 8px;
}
.v-menu__content {
  position: fixed !important;
  left: 0px !important;
  top: 0px !important;
}
.AppAvatarUserName {
  font-family: Pattaya, Roboto;
}
.v-picker {
  background: #bdbdbd !important;
}

/***** Animation CSS Code *****/
/* Copy this code with the specified HTML to get your animation effect up and running! */
.jitter {
  animation: move 0.1s 1s 6;
  -webkit-animation: move 0.1s 1s 6;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}

@keyframes move {
  50% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
