<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pt-5 pb-12 px-md-9"
      width="1000"
      height="100%"
    >
      <div class="pb-8">
        <v-chip-group
          multiple
          column
          v-model="activeTag"
          color="blueGrey"
          active-class="orangeDark white--text"
        >
          <v-chip
            label
            v-if="access.any == 'admin'"
            class="mx-2 elevation-1"
            key="create"
            @click="$router.push({ name: 'Article', params: { id: 'new' } })"
          >
            <v-icon>create</v-icon>
          </v-chip>
          <v-chip
            label
            class="mx-2 elevation-1"
            v-for="tag in tags"
            :key="tag"
            :value="tag"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>
      <div fluid class="mb-12">
        <v-layout row>
          <v-flex
            xs12
            sm12
            md6
            v-for="post in showPost"
            :key="post.title"
            :cols="postFlex"
          >
            <v-card
              dark
              class="ma-md-1"
              elevation="6"
              v-if="post.title"
              @click="
                $router.push({ name: 'Article', params: { id: post.id } })
              "
            >
              <v-img :src="post.src" height="300px" max-weight="mx-auto">
                <v-card-title
                  class="text-md-h5"
                  v-bind:style="{ 'text-shadow': '1px 1px 10px black' }"
                >
                  <div v-text="post.title"></div>
                </v-card-title>
              </v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Blog",
  data: () => ({
    posts: [],
    tags: [
      "1С",
      "возврат",
      "зарплата",
      "карьера",
      "команда",
      "конверсия",
      "лояльность",
      "обучение",
      "оплата",
      "остатки",
      "продажа",
      "сервис",
      "средний чек",
      "стандарты",
      "трафик"
    ],
    show: true,
    postFlex: 12,
    activeTag: [],
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  }),

  computed: {
    ...mapState(["shop", "user", "back_domen", "access", "style"]),

    showPost() {
      if (this.posts.length == 0) return [];
      if (this.activeTag.length == 0) return this.posts;
      return this.posts.filter(
        el => el.tag.filter(el1 => ~this.activeTag.indexOf(el1)).length > 0
      );
    }
  },

  methods: {
    async getArticles() {
      return axios
        .post(this.back_domen + "/service/blog/getarticles")
        .then(res => res.data)
        .then(json => (this.posts = json["response"]))
        .catch(err => console.warn(err));
    }

    // goArtcile(id) {
    //   localStorage.setItem('oboi.ru_blog_article', id);
    //   document.location.href = 'blog/article';
    // }
  },

  watch: {},

  created: function() {
    this.getArticles();
  }
};
</script>
