<template>
  <div>
    <v-footer padless fixed dark color="blueMain">
      <div class="font-weight-thin mr-3 ml-3 blueLight--text text--lighten-5">
        v {{ version }}
      </div>
      <v-spacer></v-spacer>
      <div class="font-weight-thin mr-3 ml-3 blueLight--text text--lighten-5">
        &copy; {{ new Date().getFullYear() }}
      </div>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {
    ...mapState(["version"])
  },
  methods: {}
};
</script>
