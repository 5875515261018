<template>
<v-main class="pb-0 pt-0"
        :style="style.background">
  <v-card
    color="grey6"
    max-width="1100"
    height="100%"
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-6 pt-md-8 pb-12">
    <v-row >
      <v-col cols=12 md=6 align="center" >
        <v-btn outlined
              :disabled="access.any != 'admin'"
              color="blueGrey" 
              @click="total_set=true;private_set=false">
            Общие настройки
        </v-btn>
        <v-row justify="center" class="mt-n1 mb-2">
          <v-col cols=11 md=10>
            <div class="grey4--text">{{hint_description}}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 md=6 align="center">
        <v-btn outlined
              :disabled="active_shop ? false : true"
              color="blueGrey" 
              @click="private_set=true; discribe_set=false">
            Частные настройки
        </v-btn>
         <v-row justify="center" class="mt-n1 mb-2">
          <v-col cols=11 md=10>
            <div class="grey4--text">если нужны уникальные настройки для магазина</div>
          </v-col>
        </v-row>
        <v-row class="btn_shop">
          <v-btn small 
          v-for="shop in Object.values(all_shop_info).filter(el => access.any == 'admin' || access[el.ID] == 'admin')" 
          :key="shop.ID"
                  v-show="private_set"
                  class="ma-2 blueGrey--text"
                  @click="active_shop = shop.ID"
                  :style="active_shop == shop.ID ? 
                          {...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)', 'background-color':'#d5d5d5'} :
                          {...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}">
            {{shop.ID}}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-md-8 ml-2 mb-md-n8"
          :class="!private_set ? 'justify-center': 'jastify-start'">
      <v-col cols=11 md=8>
        <v-row>    
          <v-col cols=12 md=6 >
            <v-dialog
              v-model="startMenu"
              :close-on-content-click="false"
          
              max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startData"
                label="применить с месяца"
                v-bind="attrs"
                v-on="on"
                color="orangeMain"
                append-icon="mdi-calendar"
                outlined
                dense
                :style="{...style.neocard, 'border-radius':'10px'}"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startData"
              @input="startMenu = false"
               no-title
              locale="ru"
              color="orangeMain"
              type="month"
            ></v-date-picker>
          </v-dialog>
        </v-col> 
        <v-col cols=12 md=6>
          <v-btn small
                  v-for="el in ['seller', 'cashier']"
                  :key="el"
                  @click="edit_position=el"
                  class="ma-2 blueGrey--text"
                  :style="edit_position == el ? 
                          {...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)', 'background-color':'#d5d5d5'} :
                          {...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}">
            {{positions[el]}}
          </v-btn>
        </v-col>
<!--   кнопка сохранения изменений  -->
          <v-col cols=12 md=8 v-show="startData">
            <v-btn small outlined color="blueLight"
              @click="(data=check_all()) && data.length ? save_all(data) : ''">
              <v-icon color="green lighten-3">
                  done</v-icon>
                сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-col>  
    </v-row> 
<!--    уведомление об отправке/ошибке -->
    <v-snackbar top right
                :timeout="type_message ? 2000 : -1"
                v-model="save_set"
                :color="type_message ? 'green' : 'error'"> <!-- для ошибок цвет -  error -->
                <pre>{{save_message}}</pre>
      <template v-slot:action="{ attrs }">
        <v-btn icon
               v-bind="attrs"
               @click="save_message = ''">
          <v-icon small>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

   <!--  дата конца, применить к магазинам -->
   <!--            <v-col md="3">
                <v-dialog 
                  v-model="endMenu"
                  max-width="500">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      label="дата конца"
                      v-model="endData"
                      v-bind="attrs"
                      v-on="on"
                      color="orangeMain"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      :style="{...style.neocard, 'border-radius':'10px'}"
                  hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endData"
                    no-title
                    locale="ru"
                    color="orangeMain"
                  ></v-date-picker>
                </v-dialog>
                </v-col> -->
       <!--          <v-col cols=12 md=5 >
                <v-combobox outlined dense 
                  label="применить к магазинам" 
                  :items="shops" 
                  multiple 
                  clearable
                  append-icon="store"
                  item-color="orangeMain"
                  color="orangeMain"
                  v-model="chooseShop"
                  
                  :style="{...style.neocard, 'border-radius':'10px'}"
                  hide-details
                  v-show="private_set">
                </v-combobox>
              </v-col> --> 
    <v-row class="mx-2" justify="center">               
      <v-col cols=12 md=8 >

         <!-- ---------------------------------------------------    ОКЛАД -->

        <v-card class="pa-5 ma-md-6 mb-5 main" 
                hide-details 
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="stable == '' || error_module.includes('stable') ? 'shadow_card' : ''">
      <!--     <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between">
            <div class="text-24 blueMain--text">{{module_name.stable}}</div>
              <div class="grey4--text text-12">
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="stable.length > 0 && !private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              @click="delete_stable()">    
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>очистить настройки</span>
                </v-tooltip>
            <!--     <v-btn icon v-show="stable.length > 0 && !private_set"> 
                  <v-icon 
                  @click="delete_stable()"
                  color="grey5">
                  mdi-trash-can-outline
                  </v-icon>
                </v-btn> -->
                <v-tooltip bottom v-if="({...active_set.stable, ...active_set.hours} || {}).email">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              size="30"
                              @click="private_to_common('stable')">    
                        mdi-cog-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>установить общие настройки</span>
                </v-tooltip>
              </div>
          </div>
          <div class="grey4--text mt-n3">ежемесячные выплаты</div>

        <!-- стоимость часа/ коэффициент смены -->
          <v-row class="align-end mb-3" v-show="stable.length > 0">
            <v-col cols=12 md=6 >
              <v-switch 
                  label="стоимость часа" 
                  v-model="hours_stable"
                  color="orangeMain">
              </v-switch>
            </v-col>
            <v-col  md=6>
              <v-text-field
                hide-details
                outlined
                placeholder="1000"
                :label="`базовая стоимость ${hours_stable ? 'часа' : 'смены'}`"
                dense 
                class="mb-2"
                color="orangeMain"
                v-model="basic"
                :style="{...style.neocard, 'border-radius':'10px'}"
              ></v-text-field>
            </v-col>
          </v-row>
          <!--таблица стоимость смены/часа -->

          <v-simple-table dense style="background:#F2F2F2" v-show="stable.length > 0" class="mb-8">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th v-for="sal in stable" 
                      :key="sal.id" 
                      class="text-left subtitle-1 blueGrey--text">
                    {{sal.name}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-show="!hours_stable">
                  <td class="blueGrey--text">коэффициент смены</td>
                    <td v-for="sal in stable" 
                        :key="sal.id + sal.name">
                        <input size="7" 
                                lass="text-left"
                                v-model="sal.coef"/>
                    </td>
                </tr>
                <tr v-show="hours_stable">
                  <td class="blueGrey--text">стоимость часа</td>
                  <td v-for="sal in stable" 
                      :key="sal.id + sal.name">
                      <input size="7" 
                            class="text-left"
                            v-model="sal.hour"/>
                  </td>  
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="value"></v-divider> 

      <!-- коэфф-т смены -->

          <!-- <v-row v-show="stable.length > 0">
            <v-col cols=12 md=5 >
              <v-checkbox v-show="!hours_stable"
                label="коэф-т увеличения оклада" 
                v-model="raising" 
                dense  
                color="orangeMain">
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col md=4 class="mr-3" v-show="raising && !hours_stable">  
              <v-dialog
                v-model="raisMenu"
                :close-on-content-click="false"
                max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="raisData"
                    label="дата увеличения"
                    v-bind="attrs"
                    v-on="on"
                    color="orangeMain"
                    append-icon="mdi-calendar"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="raisData"
                  @input="raisMenu = false"
                   no-title
                  locale="ru"
                  color="orangeMain"
                ></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row> -->

         <!--  коэф-т увеличения оклада -->

<!--           <v-simple-table style="background:#F2F2F2" v-show="stable.length > 0">
            <template v-slot:default>
              <tbody class="blueGrey--text" v-show="raising && !hours_stable">
                <tr dense>
                  <td>коэф-т увеличения</td>
                  <td v-for="sal in longday" 
                      :key="sal.id + sal.name">
                    <v-text-field size="6" 
                                dense 
                                v-model="sal.coef">
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
        <template v-if="edit_position=='seller'">
          <v-row class="px-3 mt-n4" v-show="stable.length > 0">
            <v-checkbox 
                label="старший продавец" 
                v-model="selected" 
                dense 
                color="orangeMain">
            </v-checkbox>
            <v-spacer></v-spacer>
            <v-checkbox 
                label="смена в другом городе" 
                v-model="smena" 
                dense 
                color="orangeMain">
            </v-checkbox>
          </v-row>

      <!--  доп коэффициенты -->
          <v-simple-table style="background:#F2F2F2" v-show="stable.length > 0">
            <template v-slot:default>
              <tbody class="blueGrey--text">
                <tr dense v-show="selected">
                  <td>старший продавец</td>
                  <td v-for="sal in leader" 
                      :key="sal.id + sal.name">
                    <v-text-field size="6" 
                                dense 
                                v-model="sal.coef">
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
              <tbody class="blueGrey--text">
                <tr v-show="smena">
                  <td>смена в другом городе</td>
                  <td v-for="sal in othercity" 
                      :key="sal.id + sal.name" >
                      <v-text-field 
                              size="6" 
                               dense
                              v-model="sal.coef">
                      </v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4" 
                      class="mt-4" 
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(stable)"
                      v-show="stable.length == ''"
                      size="50">
                    add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>


      <!-- --------------------------------------------------- ЛИЧНЫЙ ПЛАН -->

        <v-card class="pa-5 ma-md-6 mb-5" v-if="edit_position=='seller'"
                hide-details 
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="personal == '' || error_module.includes('personal') ? 'shadow_card' : ''">
 <!--          <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between">
            <div class="text-24 blueMain--text">{{module_name.personal}}</div>
              <div class="grey4--text text-12">
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="personal.length > 0 && !private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              @click="personal=[]">    
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>очистить настройки</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(active_set.personal || {}).email">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              size="30"
                              @click="private_to_common('personal')">    
                        mdi-cog-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>установить общие настройки</span>
                </v-tooltip>
              </div>
          </div>
          <div class="grey4--text mt-n3">ежемесячные выплаты</div>
             
          <v-simple-table dense class="mt-3" style="background:#F2F2F2" v-show="personal.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="150" class="text-left subtitle-1 blueGrey--text">
                    сумма продаж</th>
                  <th v-for="(head,k) in heads_plan" :key="k" class="text-left subtitle-1 blueGrey--text">
                    {{head}}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in personal" :key="index">
                  <td>
                    <input size="6"
                          dense 
                          color="blueGrey"
                          v-model="i.plan"/></td>
                  <td>
                    <input size="6"
                          dense
                          v-model="i.st"/></td>
                  <td>
                    <input size="6"
                          dense
                          v-model="i.un"/></td>
                  <td>
                    <input size="6"
                          v-model="i.ma"
                          dense/></td>
                  <td>
                    <input dense
                          size="6"
                          v-model="i.ex"/></td>
                  <td>
                    <v-icon color="grey4"
                            @click="personal.splice(index, 1)"
                    >mdi-minus-circle-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="personal.length > 0"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4" 
                      class="mt-4" 
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(personal)"
                      v-if="personal.length <= max_len"
                      :size="personal.length > 0 ? '' : 50">
                    add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>

      <!----------------------------------------------------- ЭКСТРА-БОНУС -->

        <v-card class="pa-5 ma-md-6 mb-5" v-if="edit_position=='seller'"
                hide-details 
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="extra == '' || error_module.includes('extra') ? 'shadow_card' : ''">
   <!--        <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between mb-4">
            <div class="text-24 blueMain--text">{{module_name.extra}}</div>
            <div class="grey4--text text-12">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn icon v-show="extra.length> 0 && !private_set"
                      v-on="on"
                      v-bind="attrs"> 
                    <v-icon color="grey5" 
                            @click="extra=[]">    
                        mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>очистить настройки</span>
              </v-tooltip>
              <v-tooltip bottom v-if="(active_set.extra || {}).email">
                <template v-slot:activator="{on, attrs}">
                  <v-btn icon v-show="private_set"
                        v-on="on"
                        v-bind="attrs"> 
                    <v-icon color="grey5" 
                            size="30"
                            @click="private_to_common('extra')">    
                      mdi-cog-sync
                    </v-icon>
                  </v-btn>
                </template>
                <span>установить общие настройки</span>
              </v-tooltip>
            </div>
          </div>
          <div class="grey4--text mt-n7 mb-3">ежемесячные выплаты</div>
             
          <v-simple-table dense style="background:#F2F2F2" v-show="extra.length> 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left subtitle-1 blueGrey--text px-0">
                          % вып-я плана м-на</th>
                  <th  class="text-left subtitle-1 blueGrey--text"
                      v-for="(bonus, b) in heads_bonus" 
                      :key="b">
                    {{bonus}}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>        
                <tr v-for="(el, index) in extra" 
                    :key="index">
                  <td >
                    <input size="6" 
                          dense 
                          v-model="el.plan"/>
                  </td>
                  <td>
                    <input class="text-left"
                          size="6"
                          dense
                          placeholder="0"
                          v-model="el.st"/></td>
                  <td>
                    <input size="6"
                          class="text-left"
                          dense
                          placeholder="0"
                          v-model="el.un"/></td>
                  <td>
                    <input size="6"
                          class="text-left"
                          v-model="el.ma"
                          dense/></td>
                  <td>
                    <input dense size="6"
                          class="text-left"
                          v-model="el.ex"/></td>
                  <td><v-icon color="grey4"
                    @click="extra.splice(index, 1)"
                    >mdi-minus-circle-outline</v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="extra.length > 0"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4" v-if="extra.length <= max_len"
                      class="mt-4" 
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(extra)"
                      :size="extra.length > 0 ? '' : 50">
                  add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>

      <!-- --------------------------------------------------БОНУСНЫЙ ТОВАР-->

        <v-card class="pa-5 ma-md-6 mb-8" v-if="edit_position=='seller'"
                hide-details 
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="bonusroll == '' || error_module.includes('bonusroll') ? 'shadow_card' : ''">
<!--           <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between mb-4">
            <div class="text-24 blueMain--text">{{module_name.bonusroll}}</div>
              <div class="grey4--text text-12">
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="bonusroll.length > 0 && !private_set"
                        v-on="on"
                        v-bind="attrs"> 
                      <v-icon color="grey5" 
                              @click="bonusroll=[]">    
                          mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>очистить настройки</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(active_set.bonusroll || {}).email">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              size="30"
                              @click="private_to_common('bonusroll')">    
                        mdi-cog-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>установить общие настройки</span>
                </v-tooltip>
              </div>
          </div>
          <div class="grey4--text mt-n7 mb-3">ежемесячные выплаты</div>

          <v-simple-table dense style="background:#F2F2F2" v-show="bonusroll.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th v-for="good in bonusroll" 
                      :key="good.id" 
                      class="text-left subtitle-1 blueLight--text">
                    {{good.name}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="blueLight--text">вознаграждение за шт.</td>
                  <td v-for="good in bonusroll" 
                      :key="good.name">
                    <input size="6"
                          v-model="good.coef"/>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="bonusroll.length > 0"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4"  
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(bonusroll)"
                      v-show="bonusroll == ''"
                      size="50">
                    add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>

               <!-- ----------------------------------------- ДНЕВНОЙ ПЛАН -->
        <v-card class="pa-5 ma-md-6 mb-5" v-if="edit_position=='seller'"
                hide-details
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="dailyplan == '' || error_module.includes('dailyplan') ? 'shadow_card' : ''">
    <!--       <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between mb-4">
            <div class="text-24 blueMain--text">{{module_name.dailyplan}}</div>
              <div class="grey4--text text-12">
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="dailyplan.length > 0 && !private_set"
                        v-on="on"
                        v-bind="attrs"> 
                      <v-icon color="grey5" 
                               @click="dailyplan=[]">    
                          mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>очистить настройки</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(active_set.dailyplan || {}).email">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              size="30"
                              @click="private_to_common('dailyplan')">    
                        mdi-cog-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>установить общие настройки</span>
                </v-tooltip>
              </div>
          </div>
          <div class="grey4--text mt-n7 mb-3">ежедневные выплаты</div>

          <v-simple-table dense style="background:#F2F2F2" v-show="dailyplan.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="190" 
                      class="subtitle-1 blueLight--text px-0">
                    % вып-я плана м-на</th>
                  <th  v-for="(head,s) in heads_plan" 
                      :key="s" class="subtitle-1 blueLight--text">
                    {{head}}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(day, index) in dailyplan" 
                    :key="day.id">
                  <td>
                    <input size="6" 
                          dense
                          v-model="day.plan"/>
                  </td>
                  <td>
                    <input size="6"
                          dense
                          placeholder="0"
                          v-model="day.st"/></td>
                  <td>
                    <input size="6"
                          placeholder="0"
                          dense
                          v-model="day.un"/></td>
                  <td>
                    <input size="6"
                          v-model="day.ma"
                          dense/></td>
                  <td>
                    <input dense size="6"
                          v-model="day.ex"/></td>
                  <td>
                    <v-icon color="grey4"
                    @click="dailyplan.splice(index, 1)"
                    >
                      mdi-minus-circle-outline
                    </v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="dailyplan.length > 0"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4" v-if="dailyplan.length <= max_len"
                      class="mt-4" 
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(dailyplan)"
                      :size="dailyplan.length > 0 ? '' : 50">
                    add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>


      <!-- --------------------------------------------------  СРЕДНИЙ ЧЕК -->


        <v-card class="pa-5 ma-md-6 mb-5" v-if="edit_position=='seller'"
                hide-details
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}"
                :class="avercheck == '' || error_module.includes('avercheck') ? 'shadow_card' : ''">
<!--          <template v-if="!private_set">
            <span class="grey5--text">кроме:</span>
            <span v-for="shop in all_shop_info" 
                  :key="shop.ID"
                  class="grey5--text"> 
                {{shop.ID}}, 
            </span>
          </template> -->
          <div class="d-flex flex-row justify-space-between mb-4">
            <div class="text-24 blueMain--text">{{module_name.avercheck}}</div>
              <div class="grey4--text text-12"> 
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="avercheck.length > 0 && !private_set"
                        v-on="on"
                        v-bind="attrs"> 
                      <v-icon color="grey5" 
                              @click="avercheck=[];aver_daily=false">    
                          mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>очистить настройки</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(active_set.avercheck || {}).email">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon v-show="private_set"
                      v-on="on"
                      v-bind="attrs"> 
                      <v-icon color="grey5" 
                              size="30"
                              @click="private_to_common('avercheck')">    
                        mdi-cog-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>установить общие настройки</span>
                </v-tooltip>
              </div>
          </div>
          <div class="grey4--text mt-n7 mb-3">ежедневные выплаты</div>
          <v-simple-table dense style="background:#F2F2F2" v-show="avercheck.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="190" class="text-left subtitle-1 blueLight--text">
                      величина чека</th>
                  <th  v-for="(head,r) in heads_plan" 
                      :key="r" 
                      class="text-left subtitle-1 blueLight--text">
                    {{head}}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(aver, index) in avercheck" 
                    :key="aver.id">
                  <td>
                    <input dense size="6"
                          v-model="aver.plan"/></td>
                  <td>
                    <input size="6"
                          dense
                          placeholder="0"
                          v-model="aver.st"/></td>
                  <td>
                    <input size="6"
                          dense
                          placeholder="0"
                          v-model="aver.un"/></td>
                  <td>
                    <input size="6"
                          v-model="aver.ma"
                          dense/></td>
                  <td>
                    <input dense size="6"
                          v-model="aver.ex"/></td>
                  <td>
                    <v-icon color="grey4"
                    @click="avercheck.splice(index, 1)">
                      mdi-minus-circle-outline
                    </v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="avercheck.length > 0"></v-divider>
          <v-checkbox label="выплачивать при выполнении 100% ежедневного плана" 
                      v-model="aver_daily"
                      color="orangeMain"
                      v-show="avercheck.length > 0">
          </v-checkbox>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon color="grey4" v-if="avercheck.length <= max_len"  
                      v-bind="attrs" 
                      v-on="on" 
                      @click="save(avercheck)"
                      :size="avercheck.length > 0 ? '' : 50">
                    add_box
              </v-icon>
            </template>
            <span>добавить условие</span>
          </v-tooltip>
        </v-card>
      </v-col>

      <!-- -------------------------------------------------------ПРИМЕР ЗП-->
      <v-col md="4" v-show="private_set" class="mt-9 " >
        <v-row>
          <v-btn text plain
            @click="(data=check_all().filter(el=>!el.deletedate))
                     data.length ? pay(data) : ''">
            <v-icon size="30" color="green lighten-2">autorenew</v-icon>
             <span class="blueGrey--text mt-1 ml-1 text-12">пересчитать</span>
          </v-btn>
        </v-row>
        <v-card  flat
                class="px-4 py-2 mt-6 d-flex flex-column"
                color="#blueGrey"
                :style="style.neoexp">
          <v-row class="my-1">
            <v-col cols=4 class="ml-n4">
              <v-btn text @click="startCalendar = true">
                <v-icon @click="startCalendar = true" 
                        class="mr-1 blueGrey--text">
                      mdi-calendar
                </v-icon>
                <div class="blueMai--text">{{ startCal }}</div>
              </v-btn>
              <v-dialog offset-y 
                        v-model="startCalendar" 
                        width="500">
                <v-card class="text-center pt-3">
                  <v-date-picker
                        v-model="startCal"
                        no-title
                        locale="ru"
                        color="orangeMain"
                        type="month"
                        @input="startCalendar = false"
                  ></v-date-picker>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols=8 class="d-flex justify-end mx-2">
              <v-row>
                <v-col cols=2>
                  <v-btn small icon
                    :disabled="idx_user<=0"
                    @click="idx_user--">
                    <v-icon>
                      keyboard_arrow_left
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols=8 class="px-0 blueMain--text text-center">
                    {{user.name}}
                </v-col>
                <v-col cols=2  class="px-0">
                  <v-btn small icon
                  :disabled="idx_user+1 >= users_data.length"
                  @click="idx_user++"
                  >
                    <v-icon>
                      keyboard_arrow_right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="active_pay && active_pay[user.id]">
          <div class="mt-n2 mb-3 grey4--text">
            статус - {{user.grade}}</div>
            <div>
              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text">оклад</div>
                <div class="orangeMain--text">
                    {{stable_money=(upd_shop_pays[active_shop] ||
                      active_pay)[user.id].stable | money}}
                </div>
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center ">
                    {{active_pay[user.id].shop}}
                </v-col>
                <v-col cols="6" class="text-left">
                    {{active_pay[user.id].SData.count}} смен {{active_pay[user.id].SData.hours}}ч.
                  <div class="grey4--text ml-n12 mt-n1 text-14" v-if="edit_position=='seller'">
                      за старшего продавца  {{(upd_shop_pays[active_shop] || active_pay)[user.id].SData.leader | money}}
                      <div class="mt-n2 text-decoration-line-through grey4--text text-end"
                      v-if="(upd_shop_pays[active_shop] || active_pay)[user.id].SData.leader
                             != active_pay[user.id].SData.leader">
                      {{active_pay[user.id].SData.leader | money}}
                    </div></div>
                </v-col>
                <v-col class="text-end">
                    {{stable_money | money}}
                    <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                    v-if="stable_money != active_pay[user.id].stable">
                    {{active_pay[user.id].stable | money}}
                    </div>
                </v-col>
              </v-row>
              <template v-if="edit_position == 'seller'">
              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text mt-3">личные продажи</div>
                <div class="orangeMain--text mt-3">
                    {{personal_money=upd_shop_pays[active_shop] ?
                      upd_shop_pays[active_shop][user.id].personal :
                      active_pay[user.id].personal | money}}
                </div>
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center ">
                    {{(upd_shop_pays[active_shop] ||
                       active_pay)[user.id].PData.percent || 0}}<span class="text-14">%</span>
                </v-col>
                <v-col cols="6" class="text-left">
                  <span>от {{active_pay[user.id].PData.selling | money}}</span>
                  <div class="grey4--text ml-n12 mt-n1 text-14">
                      корректировка {{active_pay[user.id].PData.correction | money}}</div>
                </v-col>
                <v-col class="text-end">
                    {{personal_money | money}}
                    <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                    v-if="personal_money != active_pay[user.id].personal">
                    {{active_pay[user.id].personal | money}}
                    </div>
                </v-col>
              </v-row>

              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text mt-3">бонус рулоны</div>
                <div class="orangeMain--text mt-3">
                  {{bonusroll_money=upd_shop_pays[active_shop] ?
                      upd_shop_pays[active_shop][user.id].bonusroll :
                      active_pay[user.id].bonusroll | money}}
                </div>
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center">
                  {{active_pay[user.id].shop}}
                </v-col>
                <v-col cols="6" class="text-left">
                  {{active_pay[user.id].BRData.quantity || 0}} шт.
                </v-col>
                <v-col class="text-end">
                  {{bonusroll_money}}
                  <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                  v-if="bonusroll_money != active_pay[user.id].bonusroll">
                  {{active_pay[user.id].bonusroll | money}}
                  </div>
                </v-col>
              </v-row>

              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text mt-3">план магазина</div>
                <div class="orangeMain--text mt-3">
                    {{extra_money=upd_shop_pays[active_shop] ?
                      upd_shop_pays[active_shop][user.id].extra :
                      active_pay[user.id].extra | money}}
                </div>
              </div>
              <div class="text-14 grey4--text mt-n2">
                от личных продаж и бонус рулонов
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center">
                    {{active_pay[user.id].shop}}
                </v-col>
                <v-col cols="6" class="text-left">
                    {{(upd_shop_pays[active_shop] || active_pay)[user.id].extraC ?
                      (((upd_shop_pays[active_shop] || active_pay)[user.id].extraC - 1) * 100).toFixed() : 0
                    }}<span class="text-14">%</span>
                </v-col>
                <v-col class="text-end">
                    {{extra_money | money}}
                  <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                  v-if="extra_money != active_pay[user.id].extra">
                    {{active_pay[user.id].extra | money}}
                  </div>
                </v-col>
              </v-row>

              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text mt-3">дневной план</div>
                <div class="orangeMain--text mt-3">
                  {{dailyplan_money=upd_shop_pays[active_shop] ?
                      upd_shop_pays[active_shop][user.id].dailyplan :
                      active_pay[user.id].dailyplan | money}}
                </div>
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center">
                    {{active_pay[user.id].shop}}
                </v-col>
                <v-col cols="6" class="text-left">
                    {{(upd_shop_pays[active_shop] || active_pay)[user.id].DPData.quantity || 0}} смен
                </v-col>
                <v-col class="text-end">
                    {{dailyplan_money | money}}
                    <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                    v-if="dailyplan_money != active_pay[user.id].dailyplan">
                    {{active_pay[user.id].dailyplan | money}}
                    </div>
                </v-col>
              </v-row>

              <div class="d-flex justify-space-between text-18">
                <div class="grey4--text mt-3">средний чек</div>
                <div class="orangeMain--text mt-3">
                  {{avercheck_money=upd_shop_pays[active_shop] ?
                      upd_shop_pays[active_shop][user.id].avercheck :
                      active_pay[user.id].avercheck | money}}
                </div>
              </div>
              <v-row class="blueGrey--text text-18">
                <v-col cols="3" class="text-center">
                    {{active_pay[user.id].shop}}
                </v-col>
                <v-col cols="6" class="text-left">
                    {{(upd_shop_pays[active_shop] || active_pay)[user.id].ACData.quantity || 0}} шт.
                </v-col>
                <v-col class="text-end">
                    {{avercheck_money | money}}
                    <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                    v-if="avercheck_money != active_pay[user.id].avercheck">
                    {{active_pay[user.id].avercheck | money}}
                    </div>
                </v-col>
              </v-row>
              </template>
            </div>

            <div class="mt-4">
              <v-card width="110"
                      height="50"
                      class="ml-auto d-flex align-center justify-center"
                      :style="style.neobtn">
                <div class="text-18 blueLight--text">
                    {{(upd_shop_pays[active_shop] || active_pay)[user.id].pay | money}}  
                <div class="mt-n2 text-decoration-line-through blueMiddle--text"
                v-if="(upd_shop_pays[active_shop] || active_pay)[user.id].pay != active_pay[user.id].pay">
                     {{active_pay[user.id].pay | money}}
                </div>
                </div>
              
              </v-card>
            </div>
          </template>
          </v-card>
        </v-col>
      </v-row>
  </v-card>
</v-main>
</template>




<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import axios from 'axios'


export default {
name: 'Wages',
data: () => ({
  datatree: {}, // дерево с данными целое
  addon: {"offence": "штрафные баллы",
          "efficiency": "низкая эффективность",
          "noleader": "↓ старший продавец"}, // для пометок об эффективности
  averageData: {}, // средине показатели пользователя
  hint_description: 'для всех магазинов, у которых нет дополнительных(частных) настроек',
  save_set: false,
  total_set: false,
  private_set: false,
  discribe_set: false,
  coef_zoom: false,
  tab : null,
  chooseShop: '',
  actShop: null,
  startMenu: false,
  startCalendar: false,
  startData: undefined,
  local_year: new Date().getFullYear(),
  local_month: new Date().getMonth() + 1,
  startCal:`${new Date().getFullYear()}-${
      ('0' + (new Date().getMonth() + 1)).slice(-2)}`,
  endData: null,
  endMenu: false,
  raisMenu: false,
  raisData: undefined,
  all_set_data: {},
  shop_set_data: {},
  shop_pays: {},
  upd_shop_pays: {},
  active_pay: {},
  idx_user: 0,
  active_shop: undefined,
  active_set: {},
  shops: ['331', '371', '372', '373','374','375'],
  heads_plan: {
    st: 'стажер',
    un: 'юниор',
    ma: 'мастер',
    ex: 'эксперт'
  },
  heads_bonus: ['стажер','юниор','мастер','эксперт'],
  heads_days: ['стажер','юниор','мастер','эксперт'],
  personal: [],
  stable: [],
  bonusroll: [],
  dailyplan: [],
  avercheck: [],
  aver_daily: false,
  hours_stable: false,
  basic: undefined,
  value: false,
  selected: false,
  leader: [],
  othercity: [],
  longday: [],
  smena: false,
  raising: false,
  extra: [],
  date: new Date().toISOString().substr(0, 10),
  editedIndex: -1,
  editedItem: {
        plan: 0,
        st: 0,
        un: 0,
        ma: 0,
        ex: 0,
  },
  max_len: 10,
  save_message: '',
  type_message: 0,
  error_module: [],
  edit_position: 'seller',
  module_name: {
    personal: 'Личный план',
    extra: 'Экстра-бонус',
    bonusroll: 'Бонусный товар',
    dailyplan: 'Дневной план',
    avercheck: 'Средний чек',
    stable: 'Оклад'}
}),

computed: {

  ...mapState(['user', // данные о пользователе
               'not_found', // сслыка на заглушку если нет данных
               'shop', // ид магазина
               'coin', // ссылка на монетку для бонусов
               'api_data', // данные из апи по артикулам
               'not_image', // ссылка на заглушку если нет картинки
               'month', // сквозной месяц
               'year', // сквозной год
               'back_domen',
               'style',
               'all_shop_info',
               'positions',
               'data_tree',
               'access']),

  users_data () {
    // справочные данные по продавцам
    if (this.active_shop && this.data_tree.regions) {
      for (let el of this.data_tree.regions) {
        for (let shop of el.shops){
          if (shop.ID == this.active_shop) return shop[this.edit_position + 's'];
        }
      }
    } 
    return [];
  },

  user () {
    // активный пользователь
    if (this.idx_user < this.users_data.length) 
      return this.users_data[this.idx_user];
    return {};
  },
},

methods: {

  ...mapMutations([]),

  ...mapActions([]),

  check_all() {

    const check_empty = list => {
      let list2 = list.length ? list.filter(el =>
        Object.values(el)
        .reduce((a, b) => a  + (+b || 0), 0) > +el.plan) : list;
      return list.length != list2.length ? true : false;
    }

    const check_empty2 = list => {
      return list.reduce(
          (acc, el) => acc + (+el.coef || +el.hour || 0), 0) == 0 ? true : false;
    }

    const check_duplicate = list => {
      if (list.length
          != [...new Set(Object.values(list)
              .map(el => +el.plan))].length) return true;
      return false;
    }

    const check_string = list => {
      let result = list.filter(el => Object.values(el).filter(
        el1 => !Object.values(this.heads_plan).includes(el1) && isNaN(el1)).length);
      if (result.length) return true;
      return false
    }

    let error = [];
    let data = [];
    let createdate;
    let empty = false;
    let duplicate = false;
    let string = false;
    let id = this.active_shop;

    if (!this.startData) {
      createdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 3);
    } else createdate = new Date(this.startData + 'T03:00:00');

    let stage = 'personal';
    if (this[stage].length && (empty = check_empty(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`); 
    if (this[stage].length && (duplicate = check_duplicate(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} дублируются значения`);
    if (this[stage].length && (string = check_string(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && !duplicate && !string) {
      data.push({
        stage: stage,
        position: this.edit_position,
        createdate: createdate,
        data: this[stage].map(
          el => Object.keys(this.heads_plan).map(
            el1 => {return {
              money: +el.plan,
              status: this.heads_plan[el1],
              percent: +el[el1]
            }})).flat()
      });
      this.error_module = this.error_module.filter(el => el != stage)
    } else this[stage].length ? this.error_module.push(stage) : '';
    

    stage = 'extra';
    if (this[stage].length && (empty = check_empty(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`); 
    if (this[stage].length && (duplicate = check_duplicate(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} дублируются значения`);
    if (this[stage].length && (string = check_string(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && !duplicate && !string) {
      data.push({
        stage: stage,
        position: this.edit_position,
        createdate: createdate,
        data: this[stage].map(
          el => Object.keys(this.heads_plan).map(
            el1 => {return {
              percent: +el.plan,
              status: this.heads_plan[el1],
              coeff: +el[el1]
            }})).flat()
      });
      this.error_module = this.error_module.filter(el => el != stage)
    } else this[stage].length ? this.error_module.push(stage) : '';

    stage = 'bonusroll';
    if (this[stage].length &&
        (empty = check_empty2(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} все значения нулевые`);
    if (this[stage].length && (string = check_string(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && !string) {
        data.push({
          stage: stage,
          position: this.edit_position,
          createdate: createdate,
          data: this[stage].map(
            el => {return {
              status: el.name,
              money: +el.coef
            };
          })});
        this.error_module = this.error_module.filter(el => el != stage)
    } else this[stage].length ? this.error_module.push(stage) : '';


    stage = 'dailyplan';
    if (this[stage].length && (empty = check_empty(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`); 
    if (this[stage].length && (duplicate = check_duplicate(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} дублируются значения`);
    if (this[stage].length && (string = check_string(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && !duplicate && !string) {
      data.push({
        stage: stage,
        position: this.edit_position,
        createdate: createdate,
        data: this[stage].map(
          el => Object.keys(this.heads_plan).map(
            el1 => {return {
              coeff: +el.plan / 100,
              status: this.heads_plan[el1],
              money: +el[el1]
            }})).flat()
      });
      this.error_module = this.error_module.filter(el => el != stage)
    } else this[stage].length ? this.error_module.push(stage) : '';

    stage = 'avercheck';
    if (this[stage].length && (empty = check_empty(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`); 
    if (this[stage].length && (duplicate = check_duplicate(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} дублируются значения`);
    if (this[stage].length && (string = check_string(this[stage])))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && !duplicate && !string) {
      data.push({
        stage: stage,
        createdate: createdate,
        position: this.edit_position,
        data: this[stage].map(
          el => Object.keys(this.heads_plan).map(
            el1 => {return {
              check: +el.plan,
              status: this.heads_plan[el1],
              money: +el[el1],
              ...this.aver_daily ? {dailyplan: 100} : {}}})).flat()
      });
      this.error_module = this.error_module.filter(el => el != stage)
    } else this[stage].length ? this.error_module.push(stage) : '';

    stage = 'stable';
    if (this[stage].length && !this.basic)
      error.push(`- в блоке ${this.module_name[stage]} не задана базовая стоимость`);
    if (this[stage].length && isNaN(+this.basic))
      error.push(`- в блоке ${this.module_name[stage]} некоррекртная базовая стоимость`);
    if (this[stage].length && (empty = check_empty2(this[stage].map(el=> {delete el[this.hours_stable ? 'coef' : 'hour']; return el;}))))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`);
    if (this[stage].length && (string = check_string(this[stage].map(el=> {delete el[this.hours_stable ? 'coef' : 'hour']; return el;}))))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this[stage].length && !empty && this.basic && !isNaN(+this.basic) && !string) {
      data.push({
        stage: this.hours_stable ? 'hours' : 'stable',
        position: this.edit_position,
        createdate: createdate,
        basic: +this.basic,
        data: this.stable.map(
          el => {return {
            status: el.name,
            [this.hours_stable
             ? 'money' : 'coeff']: this.hours_stable ? +el.hour : +el.coef
          };
        })});
      this.error_module = this.error_module.filter(el => el != stage);
    } else this[stage].length ? this.error_module.push(stage) : '';

    if (!this.hours_stable && this.raising && !this.raisData)
      error.push(`- в блоке ${this.module_name[stage]} не задана дата для коэффициента увеличения`);
    if (!this.hours_stable && this.raising && (string = check_string(this.longday)))
      error.push(`- в блоке ${this.module_name[stage]} нечисловые значения 1`);
    if (!this.hours_stable && this.raising &&
        (empty = check_empty2(this.longday)))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`);
    if (!this.hours_stable && this.raising && !empty && !string && this.raisData) {
      data.push({
        stage: 'longday',
        position: this.edit_position,
        createdate: new Date(this.raisData + 'T03:00:00'),
        data: this.longday.map(
          el => {return {
            status: el.name,
            coeff: +el.coef
          };
        })});
    } else {
      if (!this.hours_stable && this.raising) this.error_module.push(stage);
    }

    if (this.selected && (string = check_string(this.leader)))
       error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this.selected &&
        (empty = check_empty2(this.leader)))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`);
    if (this.selected && !empty && !string) {
      data.push({
        stage: 'leader',
        createdate: createdate,
        position: this.edit_position,
        data: this.leader.map(
          el => {return {
            status: el.name,
            coeff: +el.coef
          };
        })});
    } else this.selected ? this.error_module.push(stage) : '';

    if (this.smena && (string = check_string(this.othercity)))
       error.push(`- в блоке ${this.module_name[stage]} нечисловые значения`);
    if (this.smena &&
        (empty = check_empty2(this.othercity)))
      error.push(`- в блоке ${this.module_name[stage]} есть строки с нулевыми значениями`);
    if (this.smena && !empty && !string) {
      data.push({
        stage: 'othercity',
        createdate: createdate,
        position: this.edit_position,
        data: this.othercity.map(
          el => {return {
            status: el.name,
            coeff: +el.coef
          };
        })});
    } else this.smena ? this.error_module.push(stage) : '';
  
    console.log('data', data);
    this.type_message = error.length ? 0 : 1;
    this.save_message = this.type_message
      ? '' : [...new Set(error)].join('\n');
    if (this.type_message) {
      let result = this.private_set ? data.map(
        el => {return {...el, email: this.all_shop_info[id].email}}) : data;
      let check = result.map(el => el.stage);
      let check_main = this.private_set ? this.shop_set_data[id] : this.all_set_data;
      let deleted = (Object.values(check_main) || [])
      .filter(el => !check.includes(el.stage) && el.type != 'addon')
      .map(el => {return {...el, deletedate: createdate,
                          ...this.private_set ? {email: this.all_shop_info[id].email} : {}}});
      return [...result, ...deleted];
    }
    return [];
  },

  save_all(data) {
    data = data.map(el => {return {...el, position: this.edit_position}});
    // console.log(data);
    return axios.post(this.back_domen + '/service/updpaysettings', {
      settings: data
    })
    .then(res => res.data)
    .then(json => {
      console.log(json);
      if (json.response.length) {
        this.save_message = 'сохранено';
        this.shop_set_data = {};
        this.shop_pays = {};
        this.upd_shop_pays = {};
        if (!this.private_set) this.all_settings();
        else this.shop_settings()
        .then(() => this.active_set = {...this.shop_set_data[this.active_shop]})
        .then(() => this.pay())
        .then(() => this.active_pay = this.shop_pays[this.active_shop])
      } else {
        this.type_message = 1;
        this.save_message = 'в текущих настройках изменений нет';
      }
    }).catch((err) => {
      console.log(err);
      this.type_message = 0;
      this.save_message = 'произошла ошибка, обратитесь к администратору';
    });
  },

  private_to_common(table_name) {
    if (this.all_set_data[table_name]?.data) {
      this.active_set[table_name] = {...this.all_set_data[table_name]};
    } else {
      delete this.active_set[table_name];
    }
    if (table_name == 'stable') {
        for (let el of ['longday', 'othercity', 'leader', 'hours']){
          if (this.all_set_data[el]?.data) {
            this.active_set[el] = {...this.all_set_data[el]};
          } else {
            delete this.active_set[el];
          }
        }
    }
    this.active_set = {...this.active_set};
  },

  save (table) {
    if (table === this.stable) {
      this.stable = Object.values(this.heads_plan).map(el => {
        return {
          name: el,
          coef: 0,
          hour: 0
        }
      });
      return;
    }
    if (table === this.bonusroll) {
      this.bonusroll = Object.values(this.heads_plan).map(el => {
        return {
          name: el,
          coef: 0
        }
      });
      return;
    }
    if (table.length <= this.max_len) table.push({...this.editedItem});
  },

  delete_stable () {
    this.hours_stable = false;
    this.smena = false;
    this.raising = false;
    this.selected = false;
    this.stable = [];
    this.basic = undefined;
    this.othercity = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: undefined
      }
    });
    this.leader = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: undefined
      }
    });
    this.longday = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: undefined
      }
    });
    this.raisData = `${new Date().getFullYear()}-${
      ('0' + (new Date().getMonth() + 1)).slice(-2)}-${
        ('0' + new Date().getDate()).slice(-2)}`;
  },

  async all_settings(){
    // получаем общие настройки
    return axios.post(this.back_domen + '/service/paysettings', {
      year: this.year,
      month: this.month,
      position: this.edit_position
    })
    .then(res => res.data)
    .then(json => {
      this.all_set_data = json.response;
      this.all_set_data = {...this.all_set_data}
    });
  },

  async shop_settings(){
    // получаем настройки по магазину
    let id = this.active_shop;
    if (!id) return;
    return axios.post(this.back_domen + '/service/paysettings', {
      year: this.year,
      month: this.month,
      email: this.all_shop_info[id].email,
      position: this.edit_position
    })
    .then(res => res.data)
    .then(json => {
      this.shop_set_data[id] = json.response;
      this.shop_set_data = {...this.shop_set_data};
      // console.log(this.shop_set_data);
    });
  },

  async pay(settings){
    // получаем оплаты по магазину
    // console.log('settings', settings);
    let id = this.active_shop;
    if (!id) return;
    return axios.post(this.back_domen + '/service/repay', {
      year: this.local_year,
      month: this.local_month,
      shopID: id,
      settings: settings || this.active_set,
      position: this.edit_position
    })
    .then(res => res.data)
    .then(json => {
      if (settings) this.upd_shop_pays = {[id]: json.response};
      else {
        this.shop_pays = {};
        this.shop_pays[id] = json.response;}
    });
  }
  
},

watch: {

  edit_position() {
    this.idx_user = 0;
    this.upd_shop_pays = {};
    if (this.private_set) {
      this.shop_settings()
        .then(() => this.active_set = {...this.shop_set_data[this.active_shop]})
        .then(() => this.pay())
        .then(() => this.active_pay = this.shop_pays[this.active_shop]);
    }
    this.all_settings().then(() => this.active_set = this.all_set_data);
  },

  active_set () {
    console.log('active', this.active_set);
    this.error_module = [];
    this.hours_stable = false;

    // заполняем пероснальную мотивацию общими настройками
    if (this.active_set?.personal?.data) {
      let all_data_dict = this.active_set.personal.data.reduce(
        (acc, el) =>
          el.percent ? {...acc, [el.status + el.money]: el} : acc, {});
      let money = [...new Set(this.active_set.personal.data.map(
        el => el.money))].sort((a, b) => a - b);
      this.personal = money.map(el => {
        return {
          plan: el,
          st: all_data_dict[this.heads_plan.st + el]?.percent || 0,
          un: all_data_dict[this.heads_plan.un + el]?.percent || 0,
          ma: all_data_dict[this.heads_plan.ma + el]?.percent || 0,
          ex: all_data_dict[this.heads_plan.ex + el]?.percent || 0,
        }
      });
    } else this.personal = [];
    console.log('personal', this.personal);

    // заполняем экстра мотивацию общими настройками
    if (this.active_set?.extra?.data) {
      let all_data_dict = this.active_set.extra.data.reduce(
        (acc, el) =>
          el.coeff ? {...acc, [el.status + el.percent]: el} : acc, {});
      let percent = [...new Set(this.active_set.extra.data.map(
        el => el.percent))].sort((a, b) => a - b);
      this.extra = percent.map(el => {
        return {
          plan: el,
          st: all_data_dict[this.heads_plan.st + el]?.coeff || 0,
          un: all_data_dict[this.heads_plan.un + el]?.coeff || 0,
          ma: all_data_dict[this.heads_plan.ma + el]?.coeff || 0,
          ex: all_data_dict[this.heads_plan.ex + el]?.coeff || 0,
        }
      });
    } else this.extra = [];

    // заполняем бонусные товары общими настройками
    if (this.active_set?.bonusroll?.data) {
      let all_data_dict = this.active_set.bonusroll.data.reduce(
        (acc, el) =>
          el.money ? {...acc, [el.status]: el} : acc, {});
      this.bonusroll = Object.values(this.heads_plan).map(el => {
        return {
          name: el,
          coef: all_data_dict[el]?.money || 0
        }
      });
    } else this.bonusroll = [];

    // заполняем ежедневный план общими настройками
    if (this.active_set?.dailyplan?.data) {
      let all_data_dict = this.active_set.dailyplan.data.reduce(
        (acc, el) =>
          el.money ? {...acc, [el.status + el.coeff]: el} : acc, {});
      let coeff = [...new Set(this.active_set.dailyplan.data.map(
        el => el.coeff))].sort((a, b) => a - b);
      this.dailyplan = coeff.map(el => {
        return {
          plan: el * 100,
          st: all_data_dict[this.heads_plan.st + el]?.money || 0,
          un: all_data_dict[this.heads_plan.un + el]?.money || 0,
          ma: all_data_dict[this.heads_plan.ma + el]?.money || 0,
          ex: all_data_dict[this.heads_plan.ex + el]?.money || 0,
        }
      });
    } else this.dailyplan = [];

    // заполняем средний чек общими настройками
    if (this.active_set?.avercheck?.data) {
      let all_data_dict = this.active_set.avercheck.data.reduce(
        (acc, el) =>
          el.money ? {...acc, [el.status + el.check]: el} : acc, {});
      let check = [...new Set(this.active_set.avercheck.data.map(
        el => el.check))].sort((a, b) => a - b);
      this.avercheck = check.map(el => {
        return {
          plan: el,
          st: all_data_dict[this.heads_plan.st + el]?.money || 0,
          un: all_data_dict[this.heads_plan.un + el]?.money || 0,
          ma: all_data_dict[this.heads_plan.ma + el]?.money || 0,
          ex: all_data_dict[this.heads_plan.ex + el]?.money || 0,
        }
      });
      this.aver_daily = this.active_set.avercheck.data.filter(
        el => el.dailyplan).length ? true : false;
    } else this.avercheck = [];

    // заполняем оклад общими настройками
    if (this.active_set?.stable?.data
        || this.active_set?.hours?.data) {
      let hours_dict = {};
      let stable_dict = {};
      if (this.active_set?.stable?.data) {
        stable_dict = this.active_set.stable.data.reduce(
          (acc, el) =>
            el.coeff ? {...acc, [el.status]: el} : acc, {});
        this.basic = this.active_set.stable.basic;
      }
      if (this.active_set?.hours?.data) {
        hours_dict = this.active_set.hours.data.reduce(
          (acc, el) =>
            el.money ? {...acc, [el.status]: el} : acc, {});
        this.hours_stable = true;
        this.basic = this.active_set.hours.basic;
      } 
      this.stable = Object.values(this.heads_plan).map(el => {
        return {
          name: el,
          coef: stable_dict[el]?.coeff || 0,
          hour: hours_dict[el]?.money || 0
        }
      });
    } else this.stable = [];

    let all_data_dict = {};
    // заполняем настройки для старшего продавца
    if (this.active_set?.leader?.data) {
      this.selected = true;
      all_data_dict = this.active_set.leader.data.reduce(
        (acc, el) =>
          el.coeff ? {...acc, [el.status]: el} : acc, {});
    } else { all_data_dict = {}; this.selected = false}
    this.leader = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: all_data_dict[el]?.coeff
      }
    });


    // заполняем настройки для смен в другом городе
    if (this.active_set?.othercity?.data) {
      this.smena = true;
      all_data_dict = this.active_set.othercity.data.reduce(
        (acc, el) =>
          el.coeff ? {...acc, [el.status]: el} : acc, {});
    } else {all_data_dict = {}; this.smena = false;}
    this.othercity = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: all_data_dict[el]?.coeff
      }
    });

    // заполняем настройки для увеличенного коэффициента
    let date = new Date();
    if (this.active_set?.longday?.data) {
      date = new Date(this.active_set.longday.createdate);
      this.raising = this.active_set.stable ? true : false;
      all_data_dict = this.active_set.longday.data.reduce(
        (acc, el) =>
          el.coeff ? {...acc, [el.status]: el} : acc, {});
    } else all_data_dict = {};
    this.longday = Object.values(this.heads_plan).map(el => {
      return {
        name: el,
        coef: all_data_dict[el]?.coeff
      }
    });
    this.raisData = `${date.getFullYear()}-${
      ('0' + (date.getMonth() + 1)).slice(-2)}-${
        ('0' + date.getDate()).slice(-2)}`;
  },

  private_set () {
    // меняем активные настройки
    if (this.private_set) {
      if (this.shop_set_data && this.shop_set_data[this.active_shop]) {
        this.active_set = this.shop_set_data[this.active_shop];
        this.active_pay = this.shop_pays[this.active_shop];
      } else this.shop_settings()
        .then(() => this.active_set = {...this.shop_set_data[this.active_shop]})
        .then(() => this.pay())
        .then(() => this.active_pay = this.shop_pays[this.active_shop]);
    } else this.active_set = this.all_set_data;
  },

  active_shop () {
    // меняем ативные при смене магазина
    if (this.private_set) {
      this.idx_user = 0;
      if (this.shop_set_data && this.shop_set_data[this.active_shop]) {
        this.active_set = this.shop_set_data[this.active_shop];
        this.pay().then(() => this.active_pay = this.shop_pays[this.active_shop]);
      } else this.shop_settings()
        .then(() => this.active_set = {...this.shop_set_data[this.active_shop]})
        .then(() => this.pay())
        .then(() => this.active_pay = this.shop_pays[this.active_shop]);
    }
    this.upd_shop_pays = {};
  },

  all_shop_info () {
    if (!this.active_shop){
      let shop_list = Object.values(this.all_shop_info).filter(
        el => this.access.any == 'admin' || this.access[el.ID] == 'admin');
      if (shop_list.length) this.active_shop = shop_list[0].ID;
    }
  },

  hours_stable () {
    if (this.hours_stable) this.basic = this.active_set.hours?.basic;
    else if (this.stable.length) this.basic = this.active_set.stable?.basic;
  },

  startCal () {
    if (this.startCal) {
      let result = this.startCal.split('-');
      this.local_year = result[0];
      this.local_month = result[1];
      this.pay()
      .then(() => this.active_pay = this.shop_pays[this.active_shop]);
      if (this.upd_shop_pays[this.active_shop])
        this.pay(this.check_all());
    }
  },

  save_message () {
    if (this.save_message) this.save_set = true;
    else this.save_set = false;
  }
},

created: function() {
  this.active_shop = this.shop || Object.keys(this.all_shop_info)[0];
  if (this.access.any == 'admin') {
    this.total_set = true;
    this.all_settings().then(() => this.active_set = this.all_set_data);
  }
  else this.private_set = true;
}

}
</script>


<style lang="scss" scoped>

.btn_hint {
  float: left;
  margin: 1%;
}

.system_bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2%;
}

.header {
  height: auto;
}

.btn_shop {
  margin: 1%;
}

.shadow_card {
  background-color: rgba(255, 130, 130, 0.2);
  margin: 100px 0 0 100px;
  border-radius: 30%;
  animation: shadow 1s infinite alternate;
}

@keyframes shadow {
  from {
    box-shadow: 0 0 10px 3px rgba(255, 130, 130, 0.4);
  }
  to {
    box-shadow: 0 0 10px 6px rgba(255, 130, 130, 0.1);
  }
}

.scroll_card {
  overflow-x: auto;
  height: 700px;
  width: 500px;
  margin: 50px auto;
  scroll-snap-type: y mandatory;
}

.scroll_card::-webkit-scrollbar {
  width: 0;
}

.scroll_ggl {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.test {
  position: fixed;

}

</style>
