<template>
<v-main
  class="pb-0 pt-0"
  :style="style.background">
  <v-card
    color="grey6"
    max-width="800"
    height="100%"
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-6 pt-md-12 px-6 px-md-12 pb-16">
    <!--
         <video
         ref="video"
         id="video"
         width="200"
         height="300"
         style="border: 1px solid gray"
         ></video>
         <div>
         {{textContent}}
         </div> -->
    <!-- <div>{{lastKey}}</div>
         <div>{{response}}</div>
         <div>....</div> -->

    <v-row
      class="my-n4">
      <v-col cols="5" md="3"
             class="d-flex justify-end">
        <v-card width="128" height="128"
                :style="style.neocard">
          <a>
            <v-img
              :style="style.neocard"
              :src="api_data[product.article] &&
                    api_data[product.article].imgFrontal.s300x300 ? 
                    api_data[product.article].imgFrontal.s300x300 :
                    'https://c.tenor.com/8E3SIU76kHgAAAAC/barcode-scan.gif'">
            </v-img>

<!-- https://play-lh.googleusercontent.com/os72icmFlDtbxpYbZCP-v6kOereSLGDmlKsBl1ISTPdgbcpoc4rSIuXDuoDECvgcvoFJ -->
          </a>
        </v-card>
      </v-col>
      <v-col cols="7" md="4" v-if="product.article">
        <div class="text-12 mt-n1 blueLight--text">
             {{product.barcode ? product.barcode : ''}}
             </div>
             <div class="text-h3 text-md-h2 orangeMain--text">
             {{product.article ? product.article : ''}}
             </div>
             <div class="text-h5 mt-2 mt-md-0 blueGrey--text">
             {{api_data[product.article] ? api_data[product.article].collection : ''}}
             </div>
             <div class="text-h5 blueLight--text">
             {{api_data[product.article] ? api_data[product.article].vendor : ''}}
             </div>
      </v-col>
      <v-col cols="12" md="5" v-if="product.article">
        <div class="font-weight-medium py-2 d-flex"
             :style="style.neobtn">
          <v-btn icon x-large color="" class="mr-auto mt-12"
            :disabled="product.count > 0 ? false : true"
            @click="update_product(count=-1)">
            <v-icon>fas fa-chevron-left</v-icon>
          </v-btn>
          <span
            class="text-92 px-6 px-md-1 green--text"
            :style="style.neocard">
            {{product.count ? ('0' + product.count).slice(-2) : '00'}}
          </span>
          <v-btn icon x-large color="" class="ml-auto mt-12"
            @click="update_product()">
            <v-icon>fas fa-chevron-right</v-icon></v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="5" v-if="error">
        {{error}}
      </v-col>
    </v-row>
    <v-row class="mt-12" v-if="inventory.length > 0">
      <v-col cols="12">
        <div class="grey--text mr-3 text-right">данные инвентаризации за сегодня и вчера</div>
        <v-card :style="style.neocard">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    штрихкод
                  </th>
                  <th class="text-left">
                    артикул
                  </th>
                  <th class="text-left">
                    количество
                  </th>
                  <th class="text-left">
                    герои
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in inventory"
                  :key="item.barcode">
                  <td class="blueGrey--text">{{ item.barcode }}</td>
                  <td class="blueDark--text">{{ item.article }}</td>
                  <td class="blueDark--text">{{ item.amount }}</td>
                  <td class="blueGrey--text">
                    <div
                      v-for="user in item.user"
                      :key="user">
                      {{ user }}
                    </div>

                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

  </v-card>

</v-main>
</template>

<script>
// import { BrowserBarcodeReader } from '@zxing/library';
import {mapState, mapActions} from 'vuex'
import axios from 'axios'

export default {
name: 'Inventory',
data: () => ({
  // codeReader: new BrowserBarcodeReader(),
  // textContent: '--- barcode ---',
    product : {},
    error : '',
    lastKey: '',
    response: {},
    inventory: [],
}),

computed: {

  ...mapState(['user', 'shop', 'access', 'not_image', 'back_domen',
               'api_data', 'style', 'all_shop_info', 'fullname']),

},

methods: {

  ...mapActions(['add_api_data']),

  async update_product(count, barcode){ // пишем в базу
      if (!this.shop) {this.error = 'выберите магазин'; return;}
      return axios.post(this.back_domen + '/service/update/inventory',
      { barcode: barcode || this.product.barcode,
        email: this.all_shop_info[this.shop].email,
        user: this.access == 'shop' ? this.user.name : this.fullname,
        count: count == -1 ? -1 : 1
      })
      .then(res => res.data)
      .then(json => {
        this.response = json.response;
        if (json.response.status) {
          if (json.response.article != this.product.article)
            this.get_inventory();
          else {
            let product = this.inventory
              .filter(el => el.article == json.response.article);
            if (product.length == 1)
              if (json.response.count)
                product[0].amount = json.response.count;
              else
                this.inventory = this.inventory
                  .filter(el => el.article != json.response.article);
          }
          this.product = json.response;
          this.add_api_data([this.product.article, ]);
        }
        else 
          this.error = json.response.error || 'inknown error';
      });
  },

  async get_inventory(){ // получаем данные по инфентаризации
      if (!this.all_shop_info[this.shop]) return;
      return axios.post(this.back_domen + '/service/inventory',
      {
        email: this.all_shop_info[this.shop].email,
      })
      .then(res => res.data)
      .then(json => {
        this.inventory = json.response[this.all_shop_info[this.shop].email] || [];
      });
  },

  onKeyDown (e) {
    if (e.keyCode == 13){
      if (this.lastKey.length){
        this.update_product(1, this.lastKey);
      }
      this.lastKey = '';
    }
    else if (!~['{', '}'].indexOf(e.key) && e.keyCode != 16)
      this.lastKey = this.lastKey + e.key;
      // console.log(this.lastKey);
    },

},

watch: {

  error() {
    if (this.error) this.product = {};
  },

  product() {
    if (this.product.status) this.error = '';
  },

  shop() {
    this.product = {};
    this.error = '';
    this.get_inventory();
  },

  all_shop_info () {
    this.get_inventory();
  }
  
},


created: function() {
  this.get_inventory();
  document.addEventListener('keydown', this.onKeyDown);
},

beforeDestroy: function() {
  document.removeEventListener('keydown', this.onKeyDown);
}

}
</script>
