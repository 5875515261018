<template>
  <v-footer
    style="filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.7));"
    app
    color="grey3"
    class="pa-0 d-flex"
  >
    <div v-for="(menu, id) in [extra_seller, main]" :key="id">
      <div
        name="main_menu"
        class="d-flex MainBottom grey3"
        :class="id == 1 ? 'MainBottom' : 'ExtraBottom'"
        v-if="extra || id == 1"
      >
        <button
          :class="el.key == active_button ? 'grey5--text' : 'white--text'"
          :style="
            el.key == active_button
              ? `width: ${100 / menu.length}%; opacity: 0.4;`
              : `width: ${100 / menu.length}%;`
          "
          v-for="el in menu"
          :key="el.key"
          @click="click_menu(el.key)"
        >
          <v-icon
            class="white--text"
            :class="el.key == active_button ? 'grey5--text' : 'white--text'"
            v-text="
              extra && el.icon_active
                ? el.icon_active
                : el.icon
                ? el.icon
                : 'fas fa-ellipsis-h'
            "
          >
          </v-icon>
          <div
            style="text-transform: capitalize"
            v-text="el.name"
            class="text-12 font-weight-thin"
          >
            ???
          </div>
        </button>
      </div>
    </div>
    <div
      class="d-flex
             justify-space-around
             text-10
             grey2--text
             font-weight-light
             justify-center"
      style="width: 100%"
    >
      <div>v.{{ version ? version : "local" }}</div>
      <div class="d-flex">
        <div>oboi.ru&copy;</div>
        <div class="ml-1">{{ new Date().getFullYear() }}</div>
      </div>
    </div>
  </v-footer>
</template>
<script>
// надо чтобы при нажатии на любую кнопку, включая кнопку еще, исчезали extra_menu
import { mapState } from "vuex";
export default {
  name: "Footer",
  props: {},
  data: () => ({
    active_button: "home",
    other_button: false,
    extra: false,
    main: [
      {
        name: "главная",
        icon: "mdi-badge-account-outline",
        key: "home",
        func: "go_to"
      },
      {
        name: "зарплата",
        icon: "mdi-wallet-outline",
        key: "salary",
        func: "go_to"
      },
      {
        name: "продажи",
        icon: "mdi-point-of-sale",
        key: "sale",
        func: "go_to"
      },
      {
        name: "показатели",
        icon: "mdi-chart-arc",
        key: "indicators",
        func: "go_to"
      },
      {
        name: "еще",
        icon: "mdi-unfold-more-horizontal",
        icon_active: "mdi-unfold-less-horizontal",
        key: "other",
        func: "open_extra_menu"
      }
    ],
    extra_seller: [
      {
        name: "карьера",
        icon: "mdi-rocket-launch-outline",
        key: "career",
        func: "go_to"
      },
      { name: "знания", icon: "mdi-newspaper", key: "blog", func: "go_to" },
      {
        name: "рейтинги",
        icon: "mdi-chart-box-outline",
        key: "top",
        func: "go_to"
      },
      {
        name: "бонус тов.",
        icon: "mdi-fruit-cherries",
        key: "bonus",
        func: "go_to"
      },
      {
        name: "Заказ",
        icon: "mdi-truck-outline",
        key: "orders",
        func: "go_to"
      }
    ]
  }),
  watch: {
    $route() {
      // при переходах
      // console.log(this.$route.path);
      this.active_button = this.$route.path.split("/").slice(-1);
      // console.log(this.active_button);
    }
  },

  created() {
    this.active_button = this.$route.path.split("/").slice(-1);
  },

  methods: {
    click_menu(key) {
      let func = [...this.main, ...this.extra_seller].filter(
        el => el.key == key
      )[0]["func"];
      this[func](key);
    },
    go_to(key) {
      // console.log('go_to', this.$route.path.split('/').slice(-1)[0], key);
      if (this.$route.path.split("/").slice(-1)[0] != key)
        this.$router.push({ path: key });
      this.extra = false;
      this.active_button = key;
    },

    open_extra_menu() {
      this.extra = !this.extra;
    }
  },

  computed: {
    ...mapState(["version"])
  }
};
</script>

<style scoped>
.MainBottom {
  position: fixed !important;
  bottom: 15px;
  height: 56px;
  width: 100%;
}
.ExtraBottom {
  position: fixed !important;
  bottom: 71px;
  height: 56px;
  width: 100%;
}
</style>
