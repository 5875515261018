<template>
  <v-card  flat >
    <v-card-title class="text-h5 blueMain--text" v-text="$props.title" />
        <v-card-text v-html="$props.content" id="article_text" />
      </v-card>
</template>

<script>

export default {
name: 'FillForBlog',
props: {
  title: {
    type: String,
    required: false,
    default: "---",
    validator: v => typeof v === "string"
  },
  content: {
    type: String,
    required: false,
    default: "---",
    validator: v => typeof v === "string"
  },
},

data: () => ({}),
}
</script>