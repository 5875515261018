<!-- Кредитная карточка с зарплатой
     2 размера, меньший с параметром small=true

     <SalaryCreditCard
         money="26 789"
         previous_month="янв"
         previous_money="28 903"
         user_name="Инна Мумрина"
         :small="true"/>
-->
<template>
  <!-- :elevation="small ? 0 : 6" -->
  <v-card
    flat
    :style="
      small
        ? 'filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));'
        : 'filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));'
    "
    :min-width="small ? 214 : '93%'"
    :min-height="small ? 114 : 196"
    class="creditCard mx-auto px-5"
    v-on:scroll.native="handleScroll"
  >
    <div class="d-flex flex-column align-end">
      <div class="mt-2">
        <span class="mr-2 grey4--text" :class="small ? 'text-24' : 'text-46'"
          >₽</span
        >
        <span class="grey1--text" :class="small ? 'text-24' : 'text-46'">{{
          get_money(money)
        }}</span>
      </div>
      <div class="mt-n2">
        <span v-text="previous_month" class="mr-1 grey4--text text-17"></span>
        <span class="grey4--text text-18">
          {{ get_money(previous_money) }}
        </span>
      </div>
    </div>
    <div class="d-flex flex-column grey7--text" style="position: relative;">
      <div
        :class="small ? 'text-16' : 'text-30'"
        class="d-flex justify-space-between"
      >
        <div>0000</div>
        <div>0000</div>
        <div>{{ ("000" + default_shop).slice(-4) }}</div>
        <div>{{ ("000" + user_id).slice(-4) }}</div>
        <!-- {{
             `0000 0000 ${("000" + default_shop).slice(-4)} ${("000" + user_id).slice(-4)}`
             }} -->
      </div>
      <div :class="small ? 'text-10' : 'text-13'">
        {{ user_name | transcription }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CreditCard",
  components: {},
  data: () => ({}),
  props: {
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    money: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    previous_month: {
      type: String,
      required: false,
      default: "---",
      validator: v => typeof v === "string"
    },
    previous_money: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    user_name: {
      type: String,
      required: false,
      default: "----- ---------",
      validator: v => typeof v === "string"
    },
    user_id: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    default_shop: {
      type: String,
      required: false,
      default: "0",
      validator: v => typeof v === "string"
    }
  },
  methods: {
    handleScroll() {
      console.log("handleScroll");
    },
    get_money(money) {
      let res = ("00000" + money).slice(-5);
      return res.slice(0, 2) + " " + res.slice(2, 5);
    }
  }
};
</script>

<style scoped>
.creditCard {
  background: url("https://251317.selcdn.ru/oboi.ru/sup/2022/SalaryCreditCard.jpg");
  background-position: center;
  border-radius: 18px !important;
  font-family: Ubuntu, Roboto;
  background-size: 103%;
}
</style>
