<template>
  <div class="d-flex flex-column">
    <SalaryCreditCard
      :money="salary"
      :previous_month="months[!(month - 1) ? 11 : month - 2].slice(0, 3)"
      :previous_money="prev_salary"
      :user_name="user.name"
      :user_id="user.id"
      :default_shop="user.default_shop"
      class="mb-4 PositionSalaryCreditCard"
      :small="lastScrollPosition > 56"
    />
    <div style="height: 200px"></div>
    <Indicator name="оклад" color_name="orangeMain--text" :progress="loading">
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.stable).length"
      >
        <v-skeleton-loader
          v-if="loading || !salary_list.filter(el => el.stable).length"
          type="list-item"
          style="height: 21px"
        ></v-skeleton-loader>
        <SalaryItem
          v-else
          v-for="el in salary_list.filter(el => el.stable)"
          :key="el.email"
          :shop="el.shop"
          :decompose="
            [{ name: 'за старшего продавца', sum: el.SData.leader }].filter(
              el => el.sum
            )
          "
        >
          <template v-slot:header>
            <div>
              <span class="grey1--text">{{ el.SData.count }}</span
              ><span class="grey3--text mx-1"
                >{{ w_days(el.SData.count) }} /</span
              ><span>{{ el.SData.hours }}</span
              ><span class="grey3--text mx-1">{{ hours(el.SData.hours) }}</span>
            </div>
          </template>
          <template v-slot:sum>
            <div>
              {{ el.stable | money }}
            </div>
          </template>
        </SalaryItem>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="личные продажи"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.personal).length"
      >
        <v-skeleton-loader
          v-if="loading || !salary_list.filter(el => el.personal).length"
          style="height: 21px"
          type="list-item"
        ></v-skeleton-loader>
        <SalaryItem
          v-else
          :decompose="
            [
              {
                name: 'корректировка продаж ' + el.PData.correction,
                filter: el.PData.correction 
              }
            ].filter(el => el.filter)
          "
          v-for="el in salary_list.filter(el => el.personal)"
          :key="el.email"
        >
          <template v-slot:header>
            <div>
              <span class="grey1--text">{{ el.PData.percent }}</span>
              <span class="grey2--text text-13">%</span>
              <span class="grey3--text mx-1 text-12">от суммы продаж</span>
              <span class="grey3--text">{{ el.PData.selling | money }}</span>
            </div>
          </template>
          <template v-slot:sum>
            <div>
              {{ el.personal | money }}
            </div>
          </template>
        </SalaryItem>
        <div v-for="(el, idx) in salary_list.filter(el => el.personal)" :key="idx"
          name="Расшифровка бонусов - Личные продажи" 
          class="text-10 ml-2 font-weight-thin"
        >
          <div class="mt-n1" v-for="(el1, el2) in el.PData.settings" :key="el1">
            <span
              :class="
                el1 == el.PData.percent
                  ? 'orangeDark--text'
                  : +el1 < el.PData.percent
                  ? 'grey4--text'
                  : 'grey2--text'
              "
              >{{el1}}% от {{el2 | money}}</span
            >
          </div>
        </div>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="бонус товар"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.bonusroll).length"
      >
        <v-skeleton-loader
          style="height: 21px"
          v-if="loading || !salary_list.filter(el => el.bonusroll).length"
          type="list-item"
        ></v-skeleton-loader>
        <div
          v-else
          v-for="el in salary_list.filter(el => el.bonusroll)"
          :key="el.email"
        >
          <SalaryItem :shop="el.shop">
            <template v-slot:header>
              <div>
                <span class="grey1--text">{{ el.BRData.quantity }}</span>
                <span class="grey3--text mx-1">шт</span>
              </div>
            </template>
            <template v-slot:sum>
              <div>
                {{ el.bonusroll | money }}
              </div>
            </template>
          </SalaryItem>
        </div>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="план магазина"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.extra).length"
      >
        <v-skeleton-loader
          style="height: 21px"
          v-if="loading || !salary_list.filter(el => el.extra).length"
          type="list-item"
        ></v-skeleton-loader>
        <SalaryItem
          v-else
          v-for="el in salary_list.filter(el => el.extra)"
          :key="el.email"
          :shop="el.shop"
          :decompose="[{ name: 'от личных продаж и бонус товаров' }]"
        >
          <template v-slot:header>
            <div>
              <span class="grey1--text">{{
                (((el.extraC || 1) - 1) * 100) | money
              }}</span>
              <span class="grey2--text text-13">%</span>
              <span class="grey3--text mx-1 text-12"
                >за выполнение плана магазина</span
              >
            </div>
          </template>
          <template v-slot:sum>
            <div>{{ el.extra | money }}</div>
          </template>
        </SalaryItem>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="конверсия магазина"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.conversion).length"
      >
        <v-skeleton-loader
          style="height: 21px"
          v-if="loading || !salary_list.filter(el => el.conversion).length"
          type="list-item"
        ></v-skeleton-loader>
        <SalaryItem
          v-else
          v-for="el in salary_list.filter(el => el.conversion)"
          :key="el.email"
          :shop="el.shop"
        >
          <template v-slot:sum>
            <div>{{ el.conversion | money }}</div>
          </template>
        </SalaryItem>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="дневной план"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.dailyplan).length"
      >
        <v-skeleton-loader
          style="height: 21px"
          v-if="loading || !salary_list.filter(el => el.dailyplan).length"
          type="list-item"
        ></v-skeleton-loader>
        <div
          v-else
          v-for="el in salary_list.filter(el => el.dailyplan)"
          :key="el.email"
        >
          <SalaryItem :shop="el.shop">
            <template v-slot:header>
              <div>
                <span class="grey1--text">{{ el.DPData.quantity }}</span>
                <span class="grey3--text mx-1">{{
                  w_days(el.DPData.quantity)
                }}</span>
              </div>
            </template>
            <template v-slot:sum>
              <div>
                {{ el.dailyplan | money }}
              </div>
            </template>
          </SalaryItem>
        </div>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      name="средний чек"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template
        v-slot:content
        v-if="loading || salary_list.filter(el => el.avercheck).length"
      >
        <v-skeleton-loader
          style="height: 21px"
          v-if="loading || !salary_list.filter(el => el.avercheck).length"
          type="list-item"
        ></v-skeleton-loader>
        <div
          v-else
          v-for="el in salary_list.filter(el => el.avercheck)"
          :key="el.email"
        >
          <SalaryItem :shop="el.shop">
            <template v-slot:header>
              <div>
                <span class="grey1--text">{{ el.ACData.quantity }}</span>
                <span class="grey3--text mx-1">шт</span>
              </div>
            </template>
            <template v-slot:sum>
              <div>
                {{ el.avercheck | money }}
              </div>
            </template>
          </SalaryItem>
        </div>
      </template>
      <template v-slot:content v-else>
        <span class="grey5--text">{{ not_data }}</span>
      </template>
    </Indicator>
    <div class="DelimiterIndicator"></div>

    <Indicator
      v-if="salary_list.filter(el => el.corrections.length).length"
      name="корректировка"
      color_name="orangeMain--text"
      :progress="loading"
    >
      <template v-slot:content>
        <div
          v-for="el in salary_list.filter(el => el.corrections)"
          :key="el.email"
        >
          <SalaryItem
            :shop="el.shop"
            v-for="(el1, idx) in el.corrections"
            :key="idx"
          >
            <template v-slot:header>
              <div>
                <span class="grey1--text">{{ el1.describe }}</span>
              </div>
            </template>
            <template v-slot:sum>
              <div>
                {{ el1.amount | money }}
              </div>
            </template>
          </SalaryItem>
        </div>
      </template>
    </Indicator>
  </div>
</template>

<script>
import Indicator from "./IndicatorComponent";
import SalaryItem from "./SalaryItem";
import SalaryCreditCard from "./SalaryCreditCardComponent";
import { back_request } from  "../../mixins/back";
// import GoToButton from "./GoToButtonComponent";
import { mapState } from "vuex";
export default {
  name: "Salary",
  mixins: [back_request],
  components: {
    Indicator,
    SalaryCreditCard,
    SalaryItem
    // GoToButton
  },
  data: () => ({
    not_data: "пока нет данных",
    prev_salary: undefined,
    salary_list: [],
    loading: true,
    lastScrollPosition: 0
  }),
  computed: {
    ...mapState(["user", "months", "month", "year", "shop"]),
    salary() {
      if (!this.salary_list.length) return undefined;
      return this.salary_list.reduce((acc, el) => acc + el.pay, 0);
    }
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      this.lastScrollPosition = currentScrollPosition;
    },

    w_days(count) {
      let count1 = +String(count).slice(-1);
      let count2 = String(count).length == 1 ? 0 : +String(count).slice(-2)[0];
      if (count2 != 1 && count1 == 1) return "смена";
      if (count2 != 1 && count1 && count1 < 5) return "смены";
      return "смен";
    },
    hours(count) {
      let count1 = +String(count).slice(-1);
      let count2 = String(count).length == 1 ? 0 : +String(count).slice(-2)[0];
      if (count2 != 1 && count1 == 1) return "час";
      if (count2 != 1 && count1 && count1 < 5) return "часа";
      return "часов";
    },
    add_salary() {
      this.loading = true;
      Promise.all([
        this.get_user_pay(this.month, this.year, this.user.id).then(res => {
          this.salary_list = res;
          // console.log(res);
        }),
        this.get_user_pay(
          !(this.month - 1) ? 12 : this.month - 1,
          !(this.month - 1) ? this.year - 1 : this.year,
          this.user.id
        ).then(
          res => (this.prev_salary = res.reduce((acc, el) => acc + el.pay, 0))
        )
      ])
        .then(() => (this.loading = false))
        .catch(() => (this.loading = false));
    }
  },
  watch: {
    user() {
      this.add_salary();
    },
    month() {
      this.add_salary();
    },
    year() {
      this.add_salary();
    }
  },
  created() {
    if (this.user.id) this.add_salary();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }
};
</script>

<style scoped>
.DelimiterIndicator {
  height: 18px;
}
.PositionSalaryCreditCard {
  position: fixed;
  top: 9px;
  right: 12px;
  z-index: 2;
}
</style>
