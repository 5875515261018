<template>
  <v-card flat rounded="md" class="Indicator" color="grey7">
    <div :class="color_name" class="d-flex ml-4 text-14" color="grey4">
      <div v-text="$props.name" style="height: 21px; opacity: 0.8"></div>
      <v-progress-circular
        v-if="$props.progress"
        :indeterminate="true"
        class="ml-2"
        width="1"
        size="14"
        style="bottom: -3px;"
      ></v-progress-circular>
    </div>
    <div class="PosisionIndicatorButton">
      <slot name="button" />
    </div>
    <div class="pl-6 pr-5 pb-4">
      <slot name="content" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Indicator",
  props: {
    progress: { type: Boolean, required: false, default: true },
    color_name: {
      type: String,
      required: false,
      default: "grey4--text",
      validator: v => typeof v === "string"
    },
    name: {
      type: String,
      required: false,
      default: "---",
      validator: v => typeof v === "string"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.Indicator {
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
}
.PosisionIndicatorButton {
  position: absolute;
  top: 3px;
  right: 3px;
}
</style>
