<template>
  <v-card flat class="rounded-lg">
    <div
      class="d-flex flex column justify-space-between mx-3"
      :class="
        selling.reduce((acc, el1) => acc + el1.money, 0) < 0
          ? 'redLight--text'
          : 'grey3--text'
      "
    >
      <div class="d-flex flex-row mt-1">
        <div
          :class="
            selling.reduce((acc, el1) => acc + el1.money, 0) < 0
              ? 'redLight'
              : 'grey4'
          "
          class="text-10 rounded-lg white--text ml-n2 px-1"
          style="height: 14px; margin-top: 1px"
        >
          {{ date ? date.split(" ")[1].slice(0, 5) : "" }}
        </div>
        <div class="text-10 ml-1">{{ document }}</div>
      </div>
      <div class="mr-n1">
        <span class=" text-10">{{
          selling.reduce((acc, el1) => acc + el1.money, 0) | money
        }}</span>
      </div>
    </div>
    <v-row class="py-4">
      <v-col
        cols="12"
        md="3"
        v-for="(el, idx) in selling"
        :key="idx"
        class="py-0 px-5"
      >
        <SoldArticleComponent
          :collection="
            products[el.article] ? products[el.article].Коллекция : ''
          "
          :name="el.name"
          :article="el.article"
          :quantity="el.quantity"
          height="50"
          :price="el.price"
          :bonus="el.quantityB"
          :bonus_money="bonus_money"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SoldArticleComponent from "./SoldArticleComponent";

export default {
  name: "BlockWithSoldArticle",
  components: {
    SoldArticleComponent
  },

  props: {
    date: {
      type: String,
      required: false,
      default: undefined,
      validator: v => typeof v === "string"
    },
    document: {
      type: String,
      required: false,
      default: undefined,
      validator: v => typeof v === "string"
    },
    selling: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    products: {
      type: Object,
      required: false,
      default: function() {
        return {};
      }
    },
    bonus_money: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
  },
  data: () => ({}),
  computed: {
    ...mapState(["api_data"])
  }
};
</script>

<style scoped></style>
