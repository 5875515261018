import axios from "axios";

export const back_request = {
  methods: {
    async get_user_average(month_count, user_id) {
      return axios
        .post("/service/average", {
          month: month_count,
          userID: user_id
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []))
    },
    async get_store_users(options) {
      return axios
        .post("/service/shop_users", {
          month: options.month,
          year: options.year,
          shifts: options.shifts,
          shopID: options.shopID,
          points: options.points,
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []))
    },
    async get_user_pay(month, year, id) {
      return axios
        .post("/service/user_pay", {
          month: month,
          year: year,
          user_id: id
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_user_points(id, startdate, finishdate) {
      return axios
        .post("/service/points", {
          startdate: startdate,
          finishdate: finishdate,
          user_id: id
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_user_workdays_clear(id, startdate, finishdate) {
      return axios
        .post("/service/workdays_clear", {
          startdate: startdate,
          finishdate: finishdate,
          user_id: id
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_producer_data(key, producer, startdate, finishdate) {
      return axios
        .post("/service/producer_data", {
          startdate: startdate,
          finishdate: finishdate,
          key: key,
          producer: producer,
          email: '763@oboi.ru'
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_shop_average(shop) {
      if (!shop) return {};
      return axios
        .post("/service/shop_average", {
          shop: shop,
          month_count: 3,
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : {}));
    },
    async get_datatree(month, year, shop, attributes) {
      return axios
        .post("/service/datauser", {
          month: month,
          year: year,
          shopID: shop,
          attributes: attributes
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : {}));
    },
  }
}