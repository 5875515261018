<template>
  <Indicator name="текущая смена" :progress="loading">
    <template v-slot:button> </template>
    <template v-slot:content>
      <div class="d-flex">
        <v-card flat color="transparent" height="100" width="90">
          <div>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              style="display: none;"
            >
              <symbol id="wave">
                <path
                  d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"
                ></path>
                <path
                  d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"
                ></path>
                <path
                  d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"
                ></path>
                <path
                  d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"
                ></path>
              </symbol>
            </svg>
            <div class="box darken-1 white" style="border-radius: 60">
              >
              <div
                class="percent"
                :class="
                  per_fact > 60
                    ? 'white--text'
                    : per_fact > 39
                    ? 'grey3--text'
                    : 'grey4--text'
                "
              >
                <div class="text-22" id="count">
                  {{ per_fact }}
                </div>
                <div class="text-18 mt-1">%</div>
              </div>
              <div
                id="water"
                class="water"
                :style="{
                  transform:
                    'translate(0,' +
                    (per_fact < 100 ? 100 - per_fact : 0) +
                    '%)'
                }"
              >
                <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                  <use xlink:href="#wave"></use>
                </svg>
                <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                  <use xlink:href="#wave"></use>
                </svg>
              </div>
            </div>
          </div>
        </v-card>
        <div style="width: 100%" class="ml-3">
          <div
            v-for="(el, idx) in [shop_data, user_data]"
            :key="idx"
            class="d-flex mt-3"
          >
            <ShopCheap :shop="idx == 1 ? 'Я' : shop_id" style="bottom: -4px;" />
            <v-row class="ml-1">
              <v-col cols="12">
                <div class="d-flex justify-space-between grey2--text">
                  <div class="ml-1">
                    {{ el.sellingMoney || 0 | money }}
                    <span class="grey5--text"> ₽ </span>
                  </div>
                  <div class="mr-1">
                    {{ el.planmoney || 0 | money }}
                    <span class="grey5--text"> ₽ </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="mt-n7">
                <v-progress-linear
                  class="rounded-xl"
                  :value="((el.sellingMoney || 0) / el.planmoney) * 100 || 0"
                  stream
                  color="grey4"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>
  </Indicator>
</template>

<script>
import Indicator from "./IndicatorComponent";
import { mapState } from "vuex";
// import GoToButton from "./GoToButtonComponent";
import ShopCheap from "./ShopCheapComponent";
import { back_request } from "../../mixins/back";

export default {
  name: "IndicatorShift",
  mixins: [back_request],
  components: {
    // GoToButton,
    Indicator,
    ShopCheap
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    },
    shop_id: {
      type: String,
      required: false,
      default: undefined,
      validator: v => typeof v === "string"
    }
  },
  data: () => ({
    loading: false,
    shop: {},
    user: {}
  }),
  computed: {
    ...mapState(["back_domen", "year", "month"]),
    per_fact() {
      // факт выполения в процентах для графика
      if (!this.shop_data.planmoney) return 0;
      return Math.round(
        (this.shop_data.sellingMoney / this.shop_data.planmoney) * 100
      );
    },
    user_data() {
      if (!this.shop.sellers || !this.user_id) return {};
      for (let el of this.shop.sellers) {
        if (el.id == this.user_id) {
          for (let el1 of el.shift) {
            if (+el1.day == new Date().getDate() && el1.status == "open")
              return el1;
          }
        }
      }
      return {};
    },
    shop_data() {
      if (!this.shop.sellers || !this.user_id) return {};
      let result = {
        planmoney:
          this.user_data.planmoney ||
          this.shop.compl100Day[('0' + new Date().getDate()).slice(-2)].money ||
          0,
        sellingMoney: 0
      };
      for (let el of this.shop.sellers) {
        if (!el.shift) continue;
        for (let el1 of el.shift) {
          if (+el1.day == new Date().getDate())
            result.sellingMoney += el1.sellingMoney || 0;
        }
      }
      return result;
    }
  },
  watch: {
    shop_id() {
      this.add_data();
    }
  },
  methods: {
    add_data() {
      if (!this.shop_id) return;
      this.loading = true;
      this.get_store_users({ shopID: this.shop_id, shifts: true })
        .then(data => {
          if (data.ID) this.shop = data;
        })
        .then(() => (this.loading = false));
    }
  },
  created() {
    this.add_data();
  }
};
</script>

<style lang="scss" scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
$bgColor: #ffffff;
$boxColor: #ffffff;
$waterFColor: #ffaa00;
$waterBColor: #bdbdbd;

body {
  background: $bgColor;
  font: 14px/1 "Open Sans", helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.box {
  height: 70px;
  width: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  overflow: hidden;
  .percent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 64px;
  }
  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
    background: $waterFColor;
    transition: all 0.3s;
    &_wave {
      width: 200%;
      position: absolute;
      bottom: 100%;
      &_back {
        right: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }
      &_front {
        left: 0;
        fill: $waterFColor;
        margin-bottom: -1px;
        animation: wave-front 0.7s infinite linear;
      }
    }
  }
}
@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}
</style>
