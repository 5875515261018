<template>
  <div>
    <!-- открытие смены -->
    <v-btn
      style="position: fixed; right: 10px; top: 8px; z-index: 7"
      elevation="0"
      height="42"
      min-width="50"
      class="border-16 ma-0 pa-0 px-2 orangeMain grey7--text"
      :class="user.workNow == 'no' ? 'grey5' : 'orangeMain'"
      v-if="access.any != 'admin' && user.workNow && user.workNow != 'open'"
      @click="
        user.workNow == 'close'
          ? open_shift()
          : user.workNow == 'no'
          ? (openshift = true)
          : ''
      "
    >
      <div class="text-16">
        открыть смену
        <v-icon
          class="red--text text--lighten-1"
          :class="user.workNow == 'no' ? 'red--text' : 'orangeMain--text'"
          >fas fa-key</v-icon
        >
      </div>
    </v-btn>

    <!-- добавить продажу -->
    <v-btn
      v-if="user.workNow == 'open' && user.seller == true"
      style="position: fixed; right: 10px; top: 8px; z-index: 7"
      elevation="0"
      height="42"
      min-width="50"
      class="border-16 ma-0 pa-0 px-2 grey3 white--text"
      @click="sale_dialog = !sale_dialog"
    >
      <div class="text-16">
        добавить
        <v-icon class="white--text">mdi-cart-plus</v-icon>
      </div>
    </v-btn>

    <div class="DelimiterIndicator"></div>
    <div class="DelimiterIndicator"></div>

    <div>
      <v-expansion-panels focusable accordion v-model="panel">
        <v-expansion-panel
          v-for="(el, i) in workdays"
          :key="i"
          class="px-0"
          elevation="0"
        >
          <v-expansion-panel-header
            style="height: 60px"
            color="white"
            class="px-0"
            :style="panel && panel != i ? 'opacity: 0.3' : ''"
          >
            <v-row class="pr-6">
              <v-col cols="2" class="py-0 d-flex ml-3">
                <DayNumberComponent
                  :daynumber="el.day"
                  :weekday="el.dayweek.toUpperCase()"
                />
                <div v-if="false" style="opacity: 0.1">
                  {{ el.status }}
                </div>
              </v-col>

              <v-col
                class="pa-0 d-flex justify-end align-end pb-2 grey3--text"
                v-if="el.status == 'virtual'"
              >
                <div>
                  Виртуальная смена
                </div>
                <div class="ml-3 text-16 font-weight-thin">
                  {{ el.sellingMoney | money }}
                </div>
              </v-col>
              <v-col class="py-0 " align="right" v-if="el.status != 'virtual'">
                <div v-if="i === panel" class="text-9 grey3--text">
                  мой средний чек
                </div>
                <IndicatorSale
                  name="мой сред.чек"
                  :sum="el.avercheck2"
                  content="+300р"
                >
                  <template v-slot:badge>
                    <div>
                      <v-badge
                        v-if="pay.ACData && pay.ACData[el.day]"
                        bordered
                        label
                        :content="`+${pay.ACData[el.day]}р`"
                        style="position: relative; right: 16px;"
                        class="white--text"
                        color="orangeMain"
                      >
                      </v-badge>
                    </div>
                  </template>
                </IndicatorSale>
              </v-col>

              <v-col class="py-0" align="right" v-if="el.status != 'virtual'">
                <div v-if="i === panel" class="text-9 grey3--text">
                  продажи за смену
                </div>
                <IndicatorSale
                  name="продажи магазина"
                  :sum="
                    pay.DPData && pay.DPData[el.day]
                      ? pay.DPData[el.day].fact
                      : 0
                  "
                >
                  <template v-slot:badge>
                    <div v-if="i === panel">
                      <v-badge
                        v-if="el.planmoney"
                        bordered
                        label
                        left
                        :content="
                          `${(
                            (el.sellingMoney / el.planmoney) *
                            100
                          ).toFixed()}%`
                        "
                        style="position: relative; right: 5px;"
                        class="white--text"
                        color="grey"
                      >
                      </v-badge>
                    </div>
                  </template>
                </IndicatorSale>
              </v-col>
              <v-col class="pa-0" align="right" v-if="el.status != 'virtual'">
                <div v-if="i === panel" class="text-9 grey3--text">
                  план магазина
                </div>
                <IndicatorSale
                  name="план магазина"
                  :sum="el.planmoney"
                  sum_color="orangeMain"
                >
                  <template v-slot:badge>
                    <v-badge
                      v-if="
                        pay.DPData &&
                          pay.DPData[el.day] &&
                          pay.DPData[el.day].money
                      "
                      bordered
                      label
                      :content="`+${pay.DPData[el.day].money}р`"
                      class="white--text"
                      style="position: relative; right: 50px"
                      color="orangeMain"
                    >
                    </v-badge>
                    <v-badge
                      v-if="pay.DPData && pay.DPData[el.day]"
                      bordered
                      label
                      :content="
                        `${(pay.DPData[el.day].coeff * 100).toFixed()}%`
                      "
                      class="grey2--text"
                      style="position: relative; right: 16px"
                      :color="
                        pay.DPData[el.day].coeff >= 1
                          ? 'green lighten-4'
                          : 'red lighten-4'
                      "
                    >
                    </v-badge>
                  </template>
                </IndicatorSale>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="grey5">
            <div class="d-flex flex-wrap">
              <div
                style="width: 50%"
                class=""
                v-for="el1 in el.selling
                  .reduce(
                    (acc, el) =>
                      ~acc.map(el => el[0]).indexOf(el.document, el.date)
                        ? acc
                        : [...acc, [el.document, el.date]],
                    []
                  )
                  .reverse()"
                :key="el1[0]"
              >
                <SaleReceiptComponent
                  style="margin: 2px; margin-top: 14px"
                  :document="el1[0]"
                  :date="el1[1]"
                  :selling="el.selling.filter(el => el.document == el1[0])"
                  :products="products"
                  :bonus_money="pay.BRData ? pay.BRData.money[el.day] : 0"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div></div>
    <v-dialog
      v-model="sale_dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <SaleAddNew style="z-index: 10" @close_dialog="close_sale_adding" />
    </v-dialog>
    <!-- открытие смены-->
    <v-dialog
      overlay-color="black"
      overlay-opacity="0.8"
      width="440"
      v-model="openshift"
    >
      <v-card flat class="pa-4">
        <v-card-title> </v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-alert outlined type="warning" prominent>
            <div class="text-12">магазин {{ shop }} обеспокоен</div>
            <div>
              нет смены в графике работы
            </div>
          </v-alert>
          <div class="mt-6 mb-n3 blueGrey--text text-18 text-center">
            открыть смену?
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between pb-5">
          <v-btn text width="40%" @click="openshift = false">
            <span class="blueGrey--text">
              Нет
            </span>
          </v-btn>

          <v-btn
            text
            width="40%"
            @click="
              openshift = false;
              open_shift();
            "
          >
            <span class="blueGrey--text">
              Да
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SaleReceiptComponent from "./SaleReceiptComponent";
import SaleAddNew from "./SaleAddNew";
import IndicatorSale from "./IndicatorSellerComponent";
import DayNumberComponent from "./DayNumberComponent";

import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Sale",
  components: {
    SaleReceiptComponent,
    SaleAddNew,
    IndicatorSale,
    DayNumberComponent
  },
  data: () => ({
    sale_dialog: false,
    workdays: [],
    panel: "",
    products: {},
    pay: {},
    openshift: false
  }),

  computed: {
    ...mapState(["user", "shop", "access", "api_data", "month", "year"])

    // arcticles() {
    //   return [...new Set(this.workdays.map(
    //     el => el.selling).flat().map(el => el.article))]
    // }
  },

  methods: {
    ...mapActions(["add_api_data"]),

    close_sale_adding() {
      this.sale_dialog = false;
      this.get_user_pay();
    },

    async open_shift() {
      // открываем смену
      return axios
        .post("/service/openshift", this.user)
        .then(res => res.data)
        .then(json => {
          if (json.response.status == "open") this.user.workNow = "open";
        });
    },

    async get_user_workdays(month, year, id) {
      return axios
        .post("/service/user_workdays", {
          month: month,
          year: year,
          user_id: id,
          selling: true
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_products(article) {
      if (!article.length) return [];
      return axios
        .post("/service/products", {
          article: article
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    async get_user_pay() {
      return axios
        .post("/service/user_pay", {
          month: this.month,
          year: this.year,
          user_id: this.user.id
        })
        .then(res => res.data)
        .then(
          json =>
            (this.pay = json.response
              ? json.response.filter(el => this.shop == el.shop)[0]
              : {})
        );
    },
    add_workdays() {
      this.get_user_workdays(this.month, this.year, this.user.id)
        .then(
          data =>
            (this.workdays = data
              .filter(el => ["open", "virtual"].includes(el.status))
              .reverse())
        )
        .then(() =>
          this.get_products([
            ...new Set(
              this.workdays
                .map(el => el.selling)
                .flat()
                .map(el => el.article)
            )
          ])
        )
        .then(
          data =>
            (this.products = data.reduce(
              (acc, el) => (el ? { ...acc, [el.Артикул]: el } : acc),
              {}
            ))
        );
    }
  },

  watch: {
    user() {
      if (this.user.id) {
        this.add_workdays();
        this.get_user_pay();
      }
    },

    month() {
      if (this.user.id) {
        this.add_workdays();
        this.get_user_pay();
      }
    },

    year() {
      if (this.user.id) {
        this.add_workdays();
        this.get_user_pay();
      }
    },

    shop() {
      if (this.user.id) {
        this.add_workdays();
        this.get_user_pay();
      }
    }
    // articles () {
    //   this.add_api_data(this.articles);
    // }
  },

  created: function() {
    if (this.user.id) {
      this.add_workdays();
      this.get_user_pay();
    }
  }
};
</script>

<style scoped>
.DelimiterIndicator {
  height: 22px;
}
</style>
