<template>
  <v-card flat rounded="0" min-height="260">
    <v-img
      max-height="300"
      :src="
        api_data[product.Артикул] &&
        api_data[product.Артикул].imgFrontal.s600x400
          ? api_data[product.Артикул].imgFrontal.s600x400
          : product.pic600x400 || product.pic100x100 || not_image
      "
    >
      <div class="grey3 rounded-b-xl pt-3 px-6" style="height: 100px">
        <div class="d-flex">
          <div>
            <div class="grey5--text text-h4">
              {{ product.Артикул }}
            </div>
            <div class="grey4--text text-h5">
              {{ product.Коллекция }}
            </div>
          </div>
          <div class="ml-auto d-flex flex-column align-end">
            <div class="grey5--text text-h4">
              {{ product.Цена | money }}<span class="text-h5">р</span>
            </div>
            <div
              v-for="el in ['ОстатокНаСкладе', 'СвободныйОстаток']"
              :key="el"
            >
              <span class="grey4--text">{{
                good_title[el] || el.toLowerCase()
              }}</span>
              <span class="text-12"> {{ product[el] | money }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-img>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SaleProductAbout",
  props: {
    product: { type: Object, required: false }
  },
  data: () => ({
    good_title: {
      ОстатокНаСкладе: "у поставщика",
      СвободныйОстаток: "свободно у поставщика",
      ТорговаяМарка: "ТМ"
    }
  }),
  computed: {
    ...mapState(["api_data"])
  }
};
</script>

<style scoped></style>
