<template>
  <div class="mt-1">
    <!-- <div class="Header" v-text="$props.name"></div> -->
    <div class="BadgeHeight ml-10" style="margin-bottom: -7px">
      <slot name="badge" />
    </div>
    <div>
      <span class="Plan text-16 font-weight-thin" :class="sum_color + '--text'">
        {{ sum | money }}
      </span>
      <span class="grey4--text">₽</span>
    </div>
    <!--     <div class="Header" v-text="$props.name"></div> -->
  </div>
</template>

<script>
export default {
  name: "IndicatorSellerComponent",
  props: {
    name: {
      type: String,
      required: false,
      default: "---",
      validator: v => typeof v === "string"
    },
    sum_color: {
      type: String,
      required: false,
      default: "black",
      validator: v => typeof v === "string"
    },

    sum: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.Header {
  width: 63px;
  font-size: 9px;
  line-height: 10px;
  color: #bdbdbd;
}
.Plan {
  line-height: 22px;
}
.BadgeHeight {
  height: 20px;
}
</style>
