<template>
  <div>
    <v-navigation-drawer
      app
      permanent
      mini-variant
      mini-variant-width="100"
      class="background5"
      style="width: 100px"
    >
      <div class="d-flex flex-column mb-10 mt-7">
        <button
          :class="settings ? 'background4' : 'first2'"
          class="mx-auto mt-2 pt-2"
          style="width: 72px; height: 72px; border-radius: 14px"
          @click="open_settings_menu()"
        >
          <ShopCheap
            v-if="shop"
            class="mb-1"
            :shop="shop"
            size="36"
            text_size="text-14"
            :color="settings ? 'background4' : 'first2'"
          />
          <v-icon v-else x-large class="background9--text"
            >mdi-account-circle-outline
          </v-icon>
          <div
            style="text-transform: capitalize"
            class="text-12 background9--text"
          >
            {{ months[month - 1].slice(0, 3) }} {{ year }}
          </div>
          <div class="AppAvatarUserName white--text text-center mt-2">
            {{ user.mininame }}
          </div>
        </button>
      </div>
      <div v-if="!settings">
        <div
          class="d-flex flex-column"
          v-for="(menu, id) in [main_menu]"
          :key="id"
        >
          <button
            class="mx-auto my-2"
            :class="el.key == active_button ? 'background6' : 'background9'"
            style="width: 68px; height: 68px; border-radius: 14px"
            v-for="el in menu"
            :key="el.key"
            @click="click_menu(el.key)"
          >
            <v-icon large class="background3--text mt-2" v-text="el.icon">
            </v-icon>
            <div
              style="text-transform: capitalize"
              v-text="el.name"
              class="text-12 background5--text"
            >
              ???
            </div>
          </button>
        </div>
      </div>
      <div v-if="!settings && extra_menu.length" class="d-flex flex-column">
        <button
          class="background9 mx-auto mt-10 mb-10"
          style="width: 72px; height: 72px; border-radius: 14px"
          @click="open_extra_menu()"
        >
          <v-icon x-large class="background5--text mt-2">
            {{
              extra ? "mdi-unfold-less-vertical" : "mdi-unfold-more-vertical"
            }}
          </v-icon>
          <div
            style="text-transform: capitalize"
            class="text-12 background5--text"
          >
            ещё
          </div>
        </button>
      </div>
      <div
        class="text-12 background8--text"
        style="position: fixed !important; bottom: 1px; left: 5px"
      >
        <div class="text-center background6--text">v {{ version }}</div>
        <div class="text-center">
          OBOI.RU &copy; {{ new Date().getFullYear() }}
        </div>
      </div>
    </v-navigation-drawer>
    <div v-if="extra" class="extraMenu background5">
      <div
        class="d-flex flex-column"
        v-for="(menu, id) in [extra_menu]"
        :key="id"
      >
        <button
          class="background9 mx-auto my-2"
          style="width: 68px; height: 68px; border-radius: 14px"
          v-for="el in menu"
          :key="el.key"
          @click="click_menu(el.key)"
        >
          <v-icon
            large
            class="background5--text mt-2"
            v-text="
              extra && el.icon_active
                ? el.icon_active
                : el.icon
                ? el.icon
                : 'fas fa-ellipsis-h'
            "
          >
          </v-icon>
          <div
            style="text-transform: capitalize"
            v-text="el.name"
            class="text-12 background5--text"
          >
            ???
          </div>
        </button>
      </div>
    </div>
    <div v-if="settings" class="settingsMenu background4">
      <AppAvatarDesktop />
    </div>
  </div>
</template>
<script>
// надо чтобы при нажатии на любую кнопку, включая кнопку еще, исчезали extra_menu
import { mapState } from "vuex";
import ShopCheap from "./ShopCheapComponent";
import AppAvatarDesktop from "./AppAvatarDesktop";
export default {
  name: "NavigationBar",
  components: {
    ShopCheap,
    AppAvatarDesktop,
  },
  emits: ["change_theme"],
  props: {},
  data: () => ({
    active_button: "home",
    other_button: false,
    settings: false,
    extra: false,
  }),

  computed: {
    ...mapState(["shop", "user", "version", "year", "month", "months", "menu"]),
    main_menu() {
      return this.menu.producer.main;
    },
    extra_menu() {
      return this.menu.producer.extra;
    },
  },
  watch: {
    $route() {
      // при переходах
      // console.log(this.$route.path);
      this.active_button = this.$route.path.split("/").slice(-1);
      // console.log(this.active_button);
    },
  },

  created() {
    this.active_button = this.$route.path.split("/").slice(-1);
  },

  methods: {
    click_menu(key) {
      let func = [...this.main_menu, ...this.extra_menu].filter(
        (el) => el.key == key
      )[0]["func"];
      this[func](key);
    },
    go_to(key) {
      // console.log('go_to', this.$route.path.split('/').slice(-1)[0], key);
      if (this.$route.path.split("/").slice(-1)[0] != key)
        this.$router.push({ path: key });
      this.extra = false;
      this.active_button = key;
    },
    open_extra_menu() {
      this.extra = !this.extra;
    },
    open_settings_menu() {
      this.extra = false;
      this.settings = !this.settings;
    },
  },
};
</script>

<style scoped>
.extraMenu {
  position: fixed !important;
  padding-top: 120px;
  left: 100px;
  top: 0px;
  width: 100px;
  height: 100%;
  z-index: 10;
}
.settingsMenu {
  position: fixed !important;
  padding-top: 12px;
  padding-right: 20px;
  left: 100px;
  top: 0px;
  width: 330px;
  height: 100%;
  z-index: 10;
}
thead.v-data-table-header {
  position: absolut !important;
  bottom: 0px;
}
</style>
