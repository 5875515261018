<template>
  <v-main class="pt-0 pb-0" :style="style.background">
    <v-card
      color="grey6"
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      height="100%"
      max-width="1000"
      class="mx-auto pt-3 pl-md-16 px-md-0 px-5 pb-16"
    >
      <v-tabs
        center-active
        height="100"
        background-color="grey6"
        v-model="tabs"
        slider-size="0"
        slider-color="grey6"
        color="black"
      >
        <v-tab
          class="mx-2 my-8"
          :style="style.neobtn"
          v-for="el in tabsName"
          @click="show_graph(el.key)"
          :key="el.key"
        >
          {{ el.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-4 pt-md-3 grey6" v-model="tabs">
        <v-tab-item v-for="tab in tabsName" :key="tab.key">
          <!--    структура запасов -->
          <div v-if="tab.key === 'structure'">
            <v-row
              v-for="reg in data_tree.regions"
              class="mb-10"
              :key="reg.name"
            >
              <v-col cols="12">
                <div class="grey--text">
                  {{ reg.name }}
                </div>
              </v-col>

              <v-col
                cols="12"
                md="5"
                v-for="el in reg.shops"
                :style="style.neobtn"
                :key="el.id"
                class="blueGrey--text px-md-6 ma-md-3 my-2"
              >
                <v-row>
                  <v-col cols="5" class="pr-0 d-flex flex-column">
                    <div class="text-24">
                      {{ el.ID }}
                    </div>
                    <div class="text-12 grey4--text mt-n3">
                      {{ el.name }}
                    </div>
                  </v-col>
                  <v-col cols="2" class="px-0 d-flex flex-column">
                    <div class="text-24 orangeMain--text">
                      {{
                        data_abc[el.email]
                          ? data_abc[el.email].marjin.toFixed()
                          : ""
                      }}
                      <span class="text-14 grey4--text ml-n1"> %</span>
                    </div>
                    <div class="text-12 grey4--text mt-n3">
                      маржа
                    </div>
                  </v-col>
                  <v-col cols="2" class="px-0 d-flex flex-column align-end">
                    <div class="text-24">
                      {{
                        data_abc[el.email]
                          ? data_abc[el.email].turnover_ratio_day.toFixed()
                          : ""
                      }}
                    </div>
                    <div class="text-12 grey4--text mt-n3">
                      обор-ть
                    </div>
                  </v-col>
                  <v-col cols="3" class="pl-0 d-flex flex-column align-end">
                    <div class="text-24">
                      <span class="text-14 grey4--text mr-n1"> %</span>
                      {{ el.prognoz.toFixed() }}
                    </div>
                    <div class="text-12 grey4--text mt-n3">
                      прогноз
                    </div>
                  </v-col>
                  <v-col cols="6" class="pl-0 pt-0">
                    <v-card flat height="120" color="grey6" class="pr-12 mt-3">
                      <apexchart
                        v-if="
                          data_collection[el.email] &&
                            el.equipment &&
                            el.equipment.shelf &&
                            active_tab == 'structure'
                        "
                        type="pie"
                        height="120"
                        :options="polki"
                        :series="
                          shelf_shop(
                            data_collection[el.email],
                            el.equipment.shelf
                          )
                        "
                      ></apexchart>
                      <apexchart
                        v-else-if="active_tab == 'structure'"
                        type="pie"
                        height="120"
                        :options="polki"
                        :series="[0, 0, 0, 100]"
                      ></apexchart>
                    </v-card>
                    <div
                      :style="style.foreground"
                      class="d-flex flex-row-reverse mr-5 mt-n7 text-24 blueGrey--text"
                    >
                      {{ el.equipment.shelf || 0 }}
                    </div>
                    <div class="d-flex flex-row-reverse mr-5 mt-n4 grey4--text">
                      кол-во полок
                    </div>
                    <div
                      class="d-flex flex-row-reverse mr-5 text-24 blueGrey--text"
                    >
                      {{
                        el.equipment.shelf && data_collection[el.email]
                          ? (
                              data_collection[el.email].reduce(
                                (acc, el) => acc + el.sale,
                                0
                              ) / el.equipment.shelf
                            ).toFixed(1)
                          : 0
                      }}
                    </div>
                    <div
                      class="d-flex flex-row-reverse mr-5 mt-n3 grey4--text text-9"
                    >
                      продано рулонов с полки
                    </div>
                  </v-col>
                  <v-col cols="6" class="pl-0 pt-0">
                    <div
                      class="mb-n9 d-flex flex-column align-end"
                      :style="style.foreground"
                    >
                      <div class="text-12 grey4--text mb-n2">
                        ср. продажи
                      </div>
                      <div class="text-24">
                        {{
                          data_farabc[el.email]
                            ? data_farabc[el.email].aversale
                            : 0
                        }}
                      </div>
                    </div>
                    <v-card flat height="120" color="grey6" class="pr-12">
                      <apexchart
                        v-if="
                          data_abc[el.email] &&
                            el.equipment &&
                            el.equipment.volume &&
                            active_tab == 'structure'
                        "
                        type="pie"
                        height="120"
                        :options="sklad"
                        :series="
                          volume_shop(
                            data_article_abc[el.email],
                            el.equipment.volume
                          )
                        "
                      ></apexchart>
                      <apexchart
                        v-else-if="active_tab == 'structure'"
                        type="pie"
                        height="120"
                        :options="sklad"
                        :series="[0, 0, 100]"
                      ></apexchart>
                    </v-card>
                    <div
                      :style="style.foreground"
                      class="d-flex flex-row-reverse mr-3 mt-n7 text-24 blueGrey--text"
                    >
                      {{ el.equipment.volume }}
                    </div>
                    <div class="d-flex flex-row-reverse mr-3 mt-n4 grey4--text">
                      емкость склада
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <!--  <div > -->
          <v-row v-if="tab.key === 'indicators'">
            <v-col>
              <v-card
                class="pa-4 ma-6 mx-10"
                hide-details
                :style="{
                  ...style.neobtn,
                  border: '1px solid rgba(255,212,128,0.3)'
                }"
                v-if="Object.keys(best).length"
              >
                <v-simple-table dense style="background:#F2F2F2" class="ma-2">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          style="font-size:18px; font-weight: normal"
                          class="blueMain--text px-0"
                        >
                          Эталонные показатели
                        </th>
                        <th class="text-left subtitle-1 blueGrey--text">
                          Значение
                        </th>
                        <th class="text-left subtitle-1 blueGrey--text">
                          Магазин
                        </th>
                        <th class="text-left subtitle-1 blueGrey--text">
                          Месяц
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="el in indicators_name" :key="el.key">
                        <td class="blueGrey--text subtitle-1">{{ el.name }}</td>
                        <td>{{ best[el.key].value | money }}</td>
                        <td>
                          {{ best[el.key].email | shop_ID }}
                        </td>
                        <td>
                          {{ best[el.key].date | month_year }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-card
                class="pa-4 ma-6 mx-10"
                hide-details
                :style="{
                  ...style.neobtn,
                  border: '1px solid rgba(255,212,128,0.3)'
                }"
                v-for="indicator in indicators_name"
                :key="indicator.key"
              >
                <v-simple-table dense style="background:#F2F2F2" class="ma-2">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          style="font-size:18px; font-weight: normal"
                          class="blueMain--text px-0"
                        >
                          {{ indicator.name }}
                        </th>
                        <th
                          v-for="(month, i) in month_indicators"
                          :key="i"
                          class="text-left subtitle-1 blueGrey--text"
                        >
                          {{ month }}
                        </th>
                        <th class="text-left subtitle-1 blueGrey--text">
                          Тренд
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="shop in shop_indicators" :key="shop">
                        <td class="blueGrey--text subtitle-1">{{ shop }}</td>
                        <td v-for="el in month_indicators" :key="el">
                          {{ find_indicator(shop, el, indicator.key) | money }}
                        </td>
                        <td>
                          <apexchart
                            type="line"
                            width="80"
                            height="35"
                            :options="lineOptions"
                            :series="line_series(shop, indicator.key)"
                          ></apexchart>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <!--  </div> -->
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-main>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "Monitor",
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    data_abc: {}, // данные из абс анализа
    data_collection: {}, // данные из абс по коллекциям
    data_farabc: {},
    data_article_abc: {},
    tabs: null,
    active_tab: "structure",
    indicators: [],
    best: {},
    tabsName: [
      { name: "структура запасов", key: "structure" },
      { name: "ключевые показатели", key: "indicators" }
    ],

    indicators_name: [
      { name: "выручка на м2", key: "proceeds" },
      { name: "средний чек (руб)", key: "average_check" },
      { name: "конверсия в продажу (%)", key: "conversion" },
      { name: "рентабельность продаж (%)", key: "marginality" },
      { name: "NPS (%)", key: "nps" },
      { name: "оборачиваемость запасов (дни)", key: "turnover_ratio" }
    ],
    polki: {
      labels: [
        "артикулы коллекций с представленностью<br>больше 60%<br>",
        "артикулы коллекций с хорошей<br>представленностью но с остатками артикулов<br>меньше 4шт<br>",
        "артикулы коллекций с плохой<br>представленностью<br>",
        "свободно полок<br>"
      ],
      colors: ["#B9E3C6", "#FFFD98", "#FFB7C3", "#DEDEDE"],
      legend: { show: false },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          colors: ["grey"]
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          color: "#FFF",
          opacity: 1
        }
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false
      }
    },
    sklad: {
      labels: [
        "остатки с<br>допустимой<br>оборачиваемостью<br>",
        "остатки более<br>чем в 5 раз<br>превышают<br>продажи за<br>месяц<br>",
        "свободно<br>на складе<br>"
      ],
      colors: ["#B9E3C6", "#FFB7C3", "#DEDEDE"],
      legend: { show: false },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          colors: ["grey"]
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          color: "#FFF",
          opacity: 1
        }
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false
      }
    },

    lineOptions: {
      colors: ["#6899F8"],
      chart: {
        id: "vuechart-trend",
        toolbar: { show: false },
        sparkline: { enabled: true }
      },
      grid: { show: false },
      stroke: { width: 1.5 },
      tooltip: { enabled: false, enabledOnSeries: undefined, shared: false }
    }
  }),

  computed: {
    ...mapState([
      "month", // глобальный месяц
      "year", // глобальный год
      "not_image", // заглушка на отстутствие изображения
      "style",
      "shop", // ид выбранного магазина
      "access",
      "back_domen",
      "loading", // бегунок загрузки страницы
      "data_tree"
    ]),

    // shops () { // данные по магазам из дерева
    //   if (!this.data.regions) return [];
    //   return this.data.regions.map(el => el.shops).flat();
    // }

    shop_indicators() {
      return [...new Set(this.indicators.map(el => el.email.split("@")[0]))];
    },

    month_indicators() {
      return [
        ...new Set(
          this.indicators.map(el => {
            let data = el.date.split("-");
            return `${data[1]}.${data[0]}`;
          })
        )
      ];
    }
  },

  methods: {
    ...mapMutations([
      "change_show_help", // изменение названия выводимой подсказки
      "change_shopID_show" // изменение ид магазина для показа
    ]),

    async get_abc_data() {
      // группируем все до категории
      return axios
        .post(this.back_domen + "/service/abc", {
          startdate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 29
          ),
          group: ["all"],
          category: "Обои",
          operations: false
        })
        .then(res => res.data)
        .then(json => {
          this.data_abc = json["response"].goods.reduce(
            (acc, el) => (el.email ? { ...acc, [el.email]: el } : acc),
            {}
          );
          // console.log('abc', this.data_abc);
        });
    },

    async get_abc_fardata() {
      // группируем все до категории за 90 дней
      return axios
        .post(this.back_domen + "/service/abc", {
          startdate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 89
          ),
          group: ["all"],
          category: "Обои",
          operations: false
        })
        .then(res => res.data)
        .then(json => {
          this.data_farabc = json["response"].goods.reduce(
            (acc, el) =>
              el.email
                ? { ...acc, [el.email]: { aversale: Math.round(el.sale / 3) } }
                : acc,
            {}
          );
          // console.log('abc', this.data_abc);
        });
    },

    async get_abc_collection() {
      // группируем все до коллекции
      return axios
        .post(this.back_domen + "/service/abc", {
          startdate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 29
          ),
          group: ["collection"],
          category: "Обои",
          health: true,
          operations: false
        })
        .then(res => res.data)
        .then(json => {
          this.data_collection = json["response"].goods.reduce(
            (acc, el) =>
              el.email
                ? { ...acc, [el.email]: [el, ...(acc[el.email] || [])] }
                : acc,
            {}
          );
          // console.log('collection', this.data_collection);
        });
    },

    async get_abc_article() {
      // все по артикулам
      return axios
        .post(this.back_domen + "/service/abc", {
          startdate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 29
          ),
          category: "Обои",
          operations: false
        })
        .then(res => res.data)
        .then(json => {
          this.data_article_abc = json["response"].goods.reduce(
            (acc, el) =>
              el.email
                ? { ...acc, [el.email]: [el, ...(acc[el.email] || [])] }
                : acc,
            {}
          );
          // console.log('collection', this.data_collection);
        });
    },

    shelf_shop(collection, shelf) {
      // заполненность полок
      if (!shelf) return {};
      let result = collection.reduce(
        (acc, el) =>
          el.health
            ? { ...acc, [el.health]: (acc[el.health] || 0) + el.shelf }
            : acc,
        {}
      );
      // result = Object.entries(result).reduce((acc, el) =>
      //   el[0] ? {...acc, [el[0]]: Math.round(el[1] / shelf * 100)} : acc, {});
      // result['empty'] = 100 - Object.values(result).reduce((a, b) => a + b, 0);
      result["empty"] =
        shelf - Object.values(result).reduce((a, b) => a + b, 0);
      result = [
        result["healthy"] || 0,
        result["sick"] || 0,
        result["died"] || 0,
        result["empty"]
      ];
      return result;
    },

    volume_shop(abc, volume) {
      // заполненность магазина
      if (!volume || !abc) return {};
      let result = abc.reduce(
        (acc, el) =>
          el.balance
            ? {
                ...acc,
                [el.sale / el.balance >= 0.2 ? "product" : "gabadge"]:
                  (acc[el.sale / el.balance >= 0.2 ? "product" : "gabadge"] ||
                    0) + el.balance
              }
            : acc,
        {}
      );
      result["empty"] =
        volume - Object.values(result).reduce((a, b) => a + b, 0);
      result = [
        result["product"] || 0,
        result["gabadge"] || 0,
        result["empty"]
      ];
      return result;
    },

    load_indicators() {
      return axios
        .post(this.back_domen + "/service/indicators", {})
        .then(res => res.data)
        .then(json => {
          this.indicators = json["response"];
        });
    },

    load_best_indicators() {
      return axios
        .post(this.back_domen + "/service/bestindicators", {})
        .then(res => res.data)
        .then(json => {
          this.best = json["response"];
        });
    },

    find_indicator(shop, month, key) {
      let data = this.indicators.filter(
        el =>
          el.email.split("@")[0] == shop &&
          String(
            el.date
              .split("-")
              .slice(0, 2)
              .sort()
          ) == String(month.split(".").sort())
      );
      if (data.length == 1) return data[0][key];
      return 0;
    },

    line_series(shop, key) {
      return [
        {
          name: "line-1",
          data: this.indicators
            .filter(el => el.email.split("@")[0] == shop)
            .map(el => el[key] || 0)
        }
      ];
    },

    show_graph(key) {
      setTimeout(() => (this.active_tab = key), 1200);
    }
  },

  created: function() {
    this.get_abc_data();
    this.get_abc_collection();
    this.get_abc_fardata();
    this.get_abc_article();
    this.load_indicators();
    this.load_best_indicators();
  },

  watch: {
    access() {
      if (
        Object.keys(this.access).length > 0 &&
        Object.keys(this.data).length == 0
      ) {
        this.get_abc_data();
        this.get_data();
        this.get_abc_collection();
        this.get_abc_fardata();
        this.get_abc_article();
      }
    }
  }
};
</script>
