<template>
<v-main
  class="pb-0 pt-0"
  :style="style.background">
  <v-card
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-9 pb-16"
    height="100%"
    max-width="800">
    <v-expansion-panels multiple flat
                        :readonly="editing"
                        >
      <v-expansion-panel
        class="grey6 my-1"
        v-for="el in data.filter(el=>!el.disabled)" :key="el.id">
        <v-expansion-panel-header class="pb-0 pr-2">

          <v-alert colored-border
                   class="mb-0"
                   :style="style.neocard"
                   :color="el.leader ? 'orangeMain' : 'blueLight'"
                   border="left">
            <v-row>
              <v-col cols="12" md="7" class="d-flex flex-rows justify-space-between">
                <div>
                  <editcomp :attr="el.name" :type="'text'"
                            :key="el.name"
                            @freez="editing = $event"
                            @save="update_user(el, {name: $event})">
                  </editcomp>
                </div>
                <v-card flat
                        color="grey6"
                        class="orangeMain--text"
                        max-width="160">
                  <div class="d-flex flex-column align-end mt-n1">
                    <v-row>
                    <v-col class="pr-0 pb-0">
                    <v-icon class="mt-1"
                      v-if="el.career && el.career.ready">mdi-account-arrow-up</v-icon>
                    <v-icon class="mt-1 redLight--text"
                      v-else-if="el.career && !el.career.nextStatus && !el.career.ready_grade">mdi-account-arrow-down</v-icon>
                    </v-col>
                    <v-col class="pl-0">
                    <div class="text-10 grey4--text" v-if="!editing">
                      {{positions[el.position]}}
                    </div>
                    <editcomp
                            :title="el.grade"
                            :attr="`${positions[el.position]} ${el.grade.toUpperCase()}`"
                            :type="'select'"
                            :items="positions && statuses ? Object.values(positions).map(el1 => statuses.map(el2 =>`${el1} ${el2.toUpperCase()}`)).flat() : []"
                            :key="el.name + el.grade + el.position"
                            @freez="editing = $event"
                            @save="update_status(el, $event)">
                    </editcomp>
                    </v-col>
                    </v-row>
                </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" class="d-flex flex-rows justify-space-between">
                <div
                  class="blueLight--text"
                  v-if="shop_info[el.shopD]">
                  <editcomp :attr="shop_info[el.shopD].ID"
                            :type="'select'"
                            :key="el.name + el.shopD"
                            @save="update_shop(el, $event)"
                            @freez="editing = $event"
                            :items="el.allShop.map(el => shop_info[el].ID)">
                  </editcomp>
                </div>
                <div
                  class="blueLight--text"
                  v-if="el.phone">
                  <editcomp :attr="el.phone" :type="'text'"
                            :key="el.name + el.phone"
                            @freez="editing = $event"
                            @save="update_user(el, {phone: $event})">
                  </editcomp>
                </div>
              </v-col>
            </v-row>
          </v-alert>

        </v-expansion-panel-header>
        <v-expansion-panel-content class="mb-3">
          <!-- <v-row class="mt-n2"> -->
            <!--   <v-col cols="12" md="6" class="d-flex flex-rows justify-space-between"> -->
              <!--     <div class="ml-4 text-12 grey4--text">фамилия имя</div> -->
              <!--     <div class="mr-4 text-12 grey4--text">статус</div> -->
              <!--   </v-col> -->
            <!--   <v-col cols="12" md="6" class="d-flex flex-rows justify-space-between"> -->
              <!--     <div class="ml-n2 text-12 grey4--text">основной магазин</div> -->
              <!--     <div class="mr-10 text-12 grey4--text">телефон</div> -->
              <!--   </v-col> -->
            <!-- </v-row> -->
          <v-row>
            <v-col cols="12" md="6">
              <v-list dense
                      :style="{...style.neobtn, 'background': 'white'}"
                      class=" py-6 pl-2 mr-7 mt-6"
                      v-if="el.career">
                <v-list-item
                  v-if="el.grade == 'эксперт'">
                  <v-img
                    :style="{'opacity': '0.1'}"
                    src="https://251317.selcdn.ru/oboi.ru/sup/mountain-climber-silhouette-2.png"></v-img>
                </v-list-item>
                <!-- обучение -->
                <v-list-item dense
                             class="my-n4"
                             v-for="el1 in el.career.education[el.career.nextStatus]"
                             :key = "el.name + el1.id">
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="font-weight-regular text--secondary">
                      <a class="blueLight--text">
                        <editcomp :attr="el1.complete"
                                  :text="el1.name"
                                  :type="'boolean'"
                                  @save="update_education(el, {...el1, complete: $event})"
                                  :classes="el1.complete ?
                                            'text-decoration-line-through font-weight-thin' :
                                            'font-weight-regular text--secondary'">
                        </editcomp>
                      </a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" >
              <v-switch hide-details v-if="el.position == 'seller'"
                        :disabled="data.filter(el1 => el1.shopD == el.shopD
                                   && el.id != el1.id
                                   && el1.leader).length > 0"
                        class="mt-5"
                        v-model="el.leader"
                        @change="update_leader(el, el.shopD, el.leader)"
                        label="старший продавец"
                        color="orange"
                        ></v-switch>
              <!-- условия -->
              <div
                v-if="el.career"
                class="blueLight--text mt-10 mb-2 ml-1">условия перехода</div>
              <v-list dense
                      color="grey6"
                      v-if="el.career">
                <v-list-item class="my-n4 ml-n3"
                             v-for="el1 in el.career.todo[el.career.nextStatus || el.career.status]"
                             :key = "el1.id">
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      :class="el1.complete ? 'orangeMain--text': 'grey--text text--darken-1'"
                      v-text="el1.name"
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</v-main>
</template>

<script>

const editcomp = {
    props: ['attr', 'title', 'type', 'items', 'classes', 'text'],
    data () {
        return {
            editing: false,
            input : this.attr,
            start: this.attr
        }
    },
    template:  `
    <v-hover v-slot:default="{ hover }">
      <div class="d-flex flex-rows ma-0 pa-0">
<!-- до наведения -->
        <span v-if="(!hover && !editing && type=='text')
                    || (type=='select' && !editing)"
          @click="editing=true; $emit('freez', editing)">
          {{title || input}}
        </span>

<!-- наведение -->
<!-- редактирование текста -->
        <input
          v-if="type=='text' && (hover || editing)"
          type="text"
          class="pa-0 ma-0 mb-n1"
          @click="editing=true; $emit('freez', editing)"
          :style="editing ? 'border: none; outline: none; background-color: white; border-radius: 5px; z-index: 9999; position: relative; width: 170px; font-size: 11pt;':
                            'border: none; outline: none; background-color: transparent; width: 180px;'"
          v-model="input"/>

<!-- редактирование выпадающий список -->
        <v-select
          v-if="type=='select' && editing"
          class="pa-0 ma-0 text-12"
item-color="orangeMain"
          :items="items"
          v-model="input"
          ></v-select>

<!-- редактирование кнопули -->
      <div v-if="editing"
class="d-flex flex-rows">
        <v-btn icon small
          class="orangeMain mx-1"
          @click.stop="editing=false; input=start; $emit('freez', editing)">
          <v-icon
            color="white"> close </v-icon>
        </v-btn>
        <v-btn icon small
          class="greenLight"
          @click.stop="editing=false; $emit('save', input); $emit('freez', editing)">
          <v-icon
            color="white"> done </v-icon>
        </v-btn>
       </div>

<!-- да нет не знаю.... фигня какая то -->
        <span v-if="type=='boolean'"
          @click="input=!input; $emit('save', input)"
          :class="hover ? 'orangeMain--text' : '' + classes">
          {{text}}
        </span>
      </div>
    </v-hover>


  `
};




import {mapState, mapMutations} from 'vuex'
import axios from 'axios'
export default {
name: 'Sellers_Admin',
components: {
  editcomp,
},
data: () => ({
  data: [],
  data_career: {},
  editing: false,
  reference: {
    'стажер': {
    color: 'orange',
    name: 'Стажер',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/2.jpg"
    },
    'юниор': {
    color: 'orange',
    name: 'Юниор',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/3.jpg"
    },
    'мастер': {
    color: 'orange',
    name: 'Мастер',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/6.jpg"
    },
    'эксперт': {
    color: 'orange',
    name: 'Эксперт',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/7.jpg"
    }
  },
}),

computed: {
  ...mapState([
    'user', 'shop', 'access', 'back_domen', 'style', 'all_shop_info',
    'data_tree', 'positions', 'statuses']),

  statuses: function () {
    if (this.data.length == 0) return [];
    return this.data[0].career?.statusHierarchy.slice(1);
  },

  shop_info () {
    if (Object.values(this.all_shop_info).length == 0) return {};
    return Object.values(this.all_shop_info).reduce((acc, el) =>
      el.email ? {...acc, [el.email]: el} : acc, {});
  }

},

methods: {
  ...mapMutations([]),

  async get_data() {
    // console.log(Object.entries(this.access));
    let admin = Object.entries(this.access)
    .reduce((acc, [ k, v ]) => v == 'admin' ? { ...acc, [k]: v } : acc, {});
    if (this.access.any != 'admin' && Object.keys(admin).length == 0)
      return;
    return axios.post(this.back_domen + '/service/career', {
      'shopID': this.access.any ? 'all' : Object.keys(admin)
    }).then(res => res.data)
    .then(json => {
      let data_career = json['response'].reduce(
        (acc, el) => el.id ? {...acc, [el.id]: el} : acc, {});
      this.data = this.data.map(el => {return {...el, ...data_career[el.id]}});
    })
    .catch(err => console.warn(err))
  },

  update_education (user, data) {
    // отравляем данные по обучению по клику
    console.log('update_education', user, data);
    return axios.post(this.back_domen + '/service/userchange', {
      shopID: user.shopD.split('@')[0],
      userID: user.id,
      education: data
    }).then(res => res.data)
      .then(json => {
        if (json.response)
          user.career = json.response;
      })
  },

  update_user (user, data) {
    // отправляем простые данные по пользователю на изменение
    console.log(data);
    return axios.post(this.back_domen + '/service/userchange', 
      Object.assign({
        shopID: user.shopD.split('@')[0],
        userID: user.id
      }, data)
    ).then(res => res.data)
    .then(json => {
      if (json.response)
        user = {...user, ...data};
    })
  },

  update_leader (user, email, status) {
    // назначаем старшего продавца
    return axios.post(this.back_domen + '/service/userchange', {
      shopID: user.shopD.split('@')[0],
      userID: user.id,
      leaderemail: email,
      leader: status
    }).then(res => res.data)
    .then(json => {
      user.leader = json.response;
      console.log(user.leader, json.response);
    })
  },

  update_status (user, data_str) {
    // отправляем данные на изменение статуса
    console.log(data_str);
    let position;
    let status;
    try {
      position = Object.keys(this.positions).filter(el => 
        this.positions[el] == data_str.split(' ')[0].toLowerCase())[0];
      status = data_str.split(' ')[1].toLowerCase();
    }
    catch (err) {
      console.log(err);
      return;
    }
    return axios.post(this.back_domen + '/service/userchange', {
        shopID: user.shopD.split('@')[0],
        email: user.shopD,
        userID: user.id,
        position: position,
        status: status
    }).then(res => res.data)
    .then(json => {
      if (json.response)
        user.career = json.response;
        user.position = position;
        user.grade = status;
    })
  },

  update_shop (user, shopID) {
    // отправляем данные на изменение дефолтного магазина
    let shop = Object.values(this.shop_info)
    .filter(el => el.ID == shopID)
    .map(el => el.email);
    if (shop.length != 1) return;
    shop = shop[0];
    return axios.post(this.back_domen + '/service/userchange', {
        shopID: user.shopD.split('@')[0],
        userID: user.id,
        shopDefault: shop
    }).then(res => res.data)
    .then(json => {
      if (json.response) {
        if (user.shopD != shop)
          user.leader = false;
        user.shopD = shop;
      }
    })
  },

},

watch: {
 
  data_tree () {
    if (this.data_tree.regions) {
      this.data = this.data_tree.regions.map(el => el.shops)
        .flat().map(el => Object.keys(this.positions).map(el1 => el[el1 + 's']).flat()).flat()
        .filter(el => !el.disabled && el.email == el.shopD);
      this.get_data();
    }
  },

},

created: function() {
  if (this.data_tree.regions) {
    this.data = this.data_tree.regions.map(el => el.shops)
      .flat().map(el => Object.keys(this.positions).map(el1 => el[el1 + 's']).flat()).flat()
      .filter(el => !el.disabled && el.email == el.shopD);
    this.get_data();
  }
}

}
</script>
