<template>
<v-main>
  <v-card flat
          width="1340"
          class="mb-12 mx-auto"
          >
    <v-row>
      <v-col cols="12">
        <v-card
          class="blueGrey--text px-4"
          elevation="1"
          >
          <v-row>
            <v-col cols="12" md="6"
                   class="d-flex flex-column">
              <div class="text-h4">
                {{name[type]}}
              </div>
              <div class="pb-3">
              </div>
              <div>
                <span class="text-h6 blueLight--text text--lighten-2 py-1  mr-2">POST</span>
                https://{{back_domen}}/service/update/{{type}}
              </div>
              <div>
                <v-icon class="blueLight--text text--lighten-2 py-1  mr-10">fas fa-hourglass-start</v-icon>
                {{update[type]}}
              </div>
              <!-- <div> -->
              <!--   <v-icon class="orangeMain--text py-1 mr-8">fas fa-link</v-icon> -->
              <!--   скачать обработку для 1C -->
              <!-- </div> -->
              <div class="mt-auto blueGrey--text">
                <div><v-icon class="mr-1 blueLight--text text--lighten-2">fas fa-info-circle</v-icon>
                  если нет последних выгрузок</div>
                 запустите синхронизацию и нажмите обновить

              </div>
              <div class="ml-auto">
                <v-btn
                  raised
                  :disabled="download ? true : false"
                  @click="get_data()">
                  <v-icon
                    class="orangeMain--text mr-3"
                    v-text="download ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                    >fas fa-sync</v-icon>
                  обновить
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div><span class="orange--text">{</span> "request": [</div>
              <div><span class="blue--text pl-2">{</span></div>
              <div class="d-flex flex-row pl-5" v-for="el in request[type]" :key="el.key">
                <span class="blueMain--text">{{request_data[el].key}}</span><pre>: </pre>
                <span :class="isNaN(request_data[el].value) ||
                              request_data[el].type == 'text' ?
                              'orangeDark--text' : 'blue--text'" >
                  {{request_data[el].value}}</span>,
                <span class="text--disabled ml-auto">
                  {{request_data[el].comment}}</span>
              </div>
              <div><span class="blue--text pl-2">}</span>, ...</div>
              <div class="ml-12">]<span class="orange--text">}</span></div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <v-card
          v-if="jsonData.length != 0"
          class="blueGrey--text"
          elevation="1"
          >
          <v-card-title>
            Статистика
          </v-card-title>
          <v-card-text class="d-flex flex-column blueGrey--text subtitle-1">
            <v-row>
              <v-col cols="6">
                <div v-for="el in state_data[type].filter(
                                  el => state_notes[el].type == 'text')"
                     :key="state_notes[el].key">
                  {{state_notes[el].text}}
                  <span :class="state_notes[el].error && !isNaN(active_index) &&
                                  state[active_index][state_notes[el].key] ?
                                  'red--text' : 'blue--text'">
                    {{!isNaN(active_index) ?
                      state[active_index][state_notes[el].key] || 0 : 0}}
                  </span>
                </div>
              </v-col>
              <v-col>
                <div v-for="el in state_data[type].filter(
                                  el => state_notes[el].type == 'list')"
                  :key="state_notes[el].key">
                    {{state_notes[el].text}}
                  <v-chip-group multiple column>
                    <v-chip label small
                            active-class="blueGrey orangeMain--text"
                            v-for="item in !isNaN(active_index) ?
                                   state[active_index][state_notes[el].key] : []"
                            :value="item"
                            :key="item">
                      {{ item }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

  
      

      
      <v-col cols="12" md="6">
        <v-card flat
                class="blueGrey--text px-4">
          <div class="orangeDark--text">последние выгрузки</div>
          <v-tabs right hide-slider
                  color="orangeDark">
            <v-spacer></v-spacer>
            <v-tab @click="active_index=index"
              v-for="(el, index) in jsonData"
              :key="el.time">
              <v-icon class="grey--text text--lighten-2">mdi-clock</v-icon>
              {{el.time.split(' ')[1].split('.')[0]}}
            </v-tab>
            <v-tab-item
              v-for="el in jsonData"
              :key="el.time">
              <div>
                <v-icon
                  @click="change_ed()"
                  class="orangeMain--text mr-3"
                  v-text="expand_depth == 3 ? 'unfold_less' : 'unfold_more'"
                  >unfold_more</v-icon>
                - раскрыть всё, для поиска на странице
              </div>
              <json-viewer copyable sort
                           theme="vue-json-vuewer-theme"
                           :key="'v-' + el.time"
                           :value="el.data"
                           :expand-depth="expand_depth"
                           ></json-viewer>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</v-main>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import {mapState} from 'vuex';
import axios from 'axios'

export default {
  name: 'IntegrationReq',
  components: {JsonViewer},
  props: ['type'],
  data: () => ({
    name: {
      goods: 'Обновление номенклатуры',
      incoming: 'Загрузка поступлений',
      sale: 'Загрузка продаж/возвратов'
    },
    update: {
      goods: 'рекомендуется выгружать каждые 6 часов',
      incoming: 'рекомендуется выгружать каждые 6 часов',
      sale: 'рекомендуется выгружать каждые 10 минут. отбор за последние 5 дней'
    },
    jsonData: [],
    active_index: undefined,
    download: false,
    expand_depth: 2,
    state: [],

    request_data: {
      GUID: {key: 'GUID',
             value: 'e8ec66aa-fadd-11e9-c195-001e14413289',
             comment: 'уникальный код товара'},
      vendor: {key: 'vendor',
               value: 'Victoria Stenova',
               comment: 'производитель товара'},
      goodsname: {key: 'goodsname',
                  value: '889256 Босфор мотив (6)',
                  comment: 'наименование товара'},
      article: {key: 'article',
                value: '889256',
                comment: 'артикул товара',
                type: 'text'},
      category: {key: 'category',
                 value: 'Обои',
                 comment: 'тип товара'},
      collection: {key: 'collection',
                   value: 'COMPASS',
                   comment: 'коллекция'},
      price: {key: 'price',
              value: 1890,
              comment: 'цена продажи единицы товара'},
      discountSum: {key: 'discountSum',
                    value: 0,
                    comment: 'сумма скидок на единицу товара'},
      quantity: {key: 'quantity',
                 value: 2,
                 comment: 'количество'},
      balance: {key: 'balance',
                value: 8,
                comment: 'остаток в магазине на момент выгрузки'},
      email:  {key: 'email',
               value: '621@shopstenova.ru',
               comment: 'email магазина - привязка к складу'},
      user: {key: 'user',
             value: 'Фамилия Имя Отчество',
             comment: 'ФИО продавца'},
      document:  {key: 'document',
                  value: '00ЦБ-002542',
                  comment: 'номер документа'},
      date: {key: 'date',
             value: 'ISODate("2020-05-17T20:04:56.517Z")',
             comment: 'дата документа'},
      code: {key: 'code',
             value: '00-000123',
             comment: 'уникальный код из основной базы (если несколько баз)'},
      costprice: {key: 'costprice',
                  value: '1202',
                  comment: 'себестоимость единицы товара (или закупочная цена)'}
    },

    request: {
      sale: ['GUID', 'vendor', 'goodsname', 'article', 'price',
             'discountSum', 'quantity', 'balance', 'email',
             'user', 'document', 'date'],
      goods: ['GUID', 'code', 'category', 'vendor', 'collection',
              'goodsname', 'article', 'email', 'price', 'balance',
              'costprice'],
      incoming: ['GUID', 'vendor', 'article', 'price', 'costprice',
                 'quantity', 'email', 'document', 'date']},

    state_notes: {
      length : {text: 'записей', key: 'length', type: 'text'},
      complete: {text: 'обработано СУПом', key: 'complete', type: 'text'},
      selling: {text: 'продаж на сумму', key: 'selling', type: 'text'},
      return: {text: 'возвратов на сумму', key: 'return', type: 'text'},
      sale: {text: 'всего на сумму', key: 'sale', type: 'text'},
      discount: {text: 'скидок на сумму', key: 'discount', type: 'text'},
      article: {text: 'артикул не указан', key: 'article', type: 'text', error: true},
      balance: {text: 'остаток не указан', key: 'balance', type: 'text', error: true},
      quantity: {text: 'количество не указано', key: 'quantity', type: 'text', error: true},
      price: {text: 'цена не указана', key: 'price', type: 'text', error: true},
      costprice: {text: 'себестоимость не указана', key: 'costprice', type: 'text', error: true},
      bigcost: {text: 'себестоимость превышает цену', key: 'bigcost', type: 'text'},
      email: {text: 'нет привязки складов', key: 'email', type: 'text', error: true},
      user: {text: 'не указан продавец', key: 'user', type: 'text', error: true},
      shop: {text: 'магазин', key: 'shop', type: 'list'},
      users: {text: 'список продавцов', key: 'users', type: 'list'},
      vendor: {text: 'список производителей', key: 'vendor', type: 'list'},
      category: {text: 'список категорий', key: 'category', type: 'list'},
      document: {text: 'список документов', key: 'document', type: 'list'}},

    state_data: {
      sale: ['length', 'complete', 'selling', 'return', 'sale', 'discount',
             'article', 'balance', 'price', 'email', 'user', 'users', 'vendor',
             'shop'],
      goods: ['length', 'complete', 'balance', 'costprice', 'price',
              'bigcost', 'shop', 'category', 'vendor'],
      incoming: ['length', 'complete', 'costprice', 'price', 'quantity',
                 'bigcost', 'shop', 'document']}

  }),

  computed: {
      ...mapState(['back_domen']),
  },

  methods: {

    async get_data() {
        this.download = true;
        return axios.post(this.back_domen + '/service/deb_data', {
            'type': this.type,
        }).then(res => res.data)
            .then(json => {
                this.jsonData = json['response'].reverse();
                this.download = false;
        });
    },

    change_ed () {
      if (this.expand_depth == 3) {
        this.expand_depth = 2
      } else {
        this.expand_depth = 3;
      }
    },
},

watch: {

  jsonData () {
    if (this.jsonData.length > 0) {
      if (!this.active_index) this.active_index = 0;
      let result = [];
      for (let el of this.jsonData) {
        let stat = {};
        stat.length = el.data.request.length;
        for (let el1 of el.data.request) {
          if (el1.complete) stat.complete = (stat.complete || 0) + 1;
          if (!el1.quantity) stat.quantity = (stat.quantity || 0) + 1;
          if (el1.price && el1.quantity > 0)
            stat.selling = (stat.selling || 0) + el1.price * el1.quantity;
          if (el1.price && el1.quantity < 0)
            stat.return = (stat.return || 0) + el1.price * el1.quantity;
          if (!el1.costprice) stat.costprice = (stat.costprice || 0) + 1;
          if (el1.discountSum)
            stat.discount = (stat.discount || 0) + el1.discountSum;
          if (!el1.article) stat.article = (stat.article || 0) + 1;
          if (!el1.balance) stat.balance = (stat.balance || 0) + 1;
          if (!el1.price) stat.price = (stat.price || 0) + 1;
          if (!el1.email) stat.email = (stat.email || 0) + 1;
          if (!el1.user) stat.user = (stat.user || 0) + 1;
          if (el1.costprice && el1.price && el1.costprice > el1.price)
            stat.bigcost = (stat.bigcost || 0) + 1;
          stat.category = [...new Set([...(stat.category || []), el1.category])];
          stat.users = [...new Set([...(stat.users || []), el1.user])];
          stat.shop = [...new Set([...(stat.shop || []), el1.email])];
          stat.vendor = [...new Set([...(stat.vendor || []), el1.vendor])];
          stat.document = [...new Set([...(stat.document || []), el1.document])];
        }
        stat.sale = stat.selling + stat.return;
        result.push(stat);
      }
      this.state = result;
    } else this.active_index = undefined;
  },

  expand_depth (){
    let elements = document.querySelectorAll(
      'div.jv-code > div > span > div > span.jv-push > div > span > span.jv-toggle');
    for (let el of elements) {
      if (this.expand_depth == 3 && !el.classList.contains('open'))
        el.click();
      else if (this.expand_depth == 2 && el.classList.contains('open'))
        el.click();
    }
  },

  type () {
    this.get_data();
  }
 
},

created: function() {
  this.get_data();
}

}
</script>
