<template>
  <v-btn
    rounded
    x-small
    text
    :min-width="$props.min_width"
    class="GoToButton"
    style="text-transform: uppercase;"
    :class="color ? color : ''"
    :to="to ? to : ''"
  >
    <div v-text="text" class="white--text text-12 font-weight-light"></div
  ></v-btn>
</template>
<script>
export default {
  name: "GoToButton",
  props: {
    text: {
      type: String,
      required: false,
      default: "перейти",
      validator: v => typeof v === "string"
    },
    color: {
      type: String,
      required: false,
      default: "grey4",
      validator: v => typeof v === "string"
    },
    to: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },
    min_width: {
      type: String,
      required: false,
      default: "96px",
      validator: v => typeof v === "string"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.GoToButton {
  padding-top: 1px;
  font-family: Ubuntu, Roboto;
}
</style>
