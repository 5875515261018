<template>
  <v-menu
    attach
    :close-on-content-click="user.id && shop ? true : false"
    :close-on-click="user.id && shop ? true : false"
    v-model="opend"
    max-width="100%"
    min-width="100%"
    elevation="0"
    rounded="0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        height="42"
        min-width="50"
        v-bind="attrs"
        v-on="on"
        style="border-radius: 16px"
        class="AppAvatarButton ma-0 pa-0"
        :style="
          !opend
            ? 'filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7));'
            : 'filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));'
        "
        :class="!opend ? 'grey4 ' : 'grey4 darken-1'"
      >
        <div>
          <div v-if="!opend" class="d-flex align-center mr-auto">
            <ShopCheap
              style="margin-left: 2px"
              :shop="shop"
              size="32"
              text_size="text-14"
              color="orangeDark"
            />
            <div flat class="mx-3 d-flex flex-column text-16">
              <div class="AppAvatarUserName white--text text-center">
                {{ user.mininame }}
              </div>
              <div class="mt-n1 text-13 text-center grey2--text">
                {{ months[month - 1].slice(0, 3) }} {{ year }}
              </div>
            </div>
          </div>
          <div v-else>
            <v-btn icon>
              <v-icon large class="white--text ml-n3">
                mdi-close-circle-outline
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-btn>
    </template>

    <v-card elevation="0" flat class="grey3 pa-2 rounded-0">
      <div class="d-flex justify-space-between">
        <div class="pt-9 pl-3">
          <v-switch
            v-if="false"
            dense
            x-small
            inset
            persistent-hint
            color="grey darken-2"
            v-model="$vuetify.theme.dark"
            @click="$emit('change_theme')"
          ></v-switch>
        </div>
        <div>
          <v-date-picker
            class="rounded-xl ml-auto"
            style=""
            color="orangeDark"
            v-model="picker"
            no-title
            type="month"
            locale="ru"
          ></v-date-picker>
        </div>
      </div>
      <div class="mt-3 pl-2 text-14 font-weight-thin white--text"></div>
      <div
        class="d-flex flex-wrap justify-end ml-6"
        v-if="shops_list && (access_shop.length > 1 || !shop)"
      >
        <v-btn
          fab
          text
          v-for="i in access_shop"
          :key="i"
          small
          @click="change_shop(i)"
        >
          <ShopCheap
            :color="i === shop ? 'orangeMain' : 'white'"
            :text_color="i !== shop ? 'orangeMain' : 'white'"
            size="38"
            :shop="i"
          />
        </v-btn>
      </div>
      <div
        class=""
        v-if="workers_list && (Object.values(userinfo).length > 1 || !user.id)"
      >
        <div class="mt-3 pl-2 text-14 font-weight-thin white--text">
          сотрудники
        </div>
        <div class="mb-3">
          <v-card
            color="grey4"
            flat
            v-for="l in Object.values(userinfo)"
            :key="l.id"
            class="rounded-xl white ma-1 pl-1 text-18 pl-6"
            :class="!user.id ? 'jitter' : ''"
            @click="change_user(l)"
          >
            <Manager
              class="ml-4 py-1"
              size="18"
              text_size="text-16"
              :color="l.id == user.id ? 'white' : 'grey6'"
              :text_color="l.id == user.id ? 'grey3' : 'grey1'"
              :id="l.id"
              :user_name="l.name"
              :user_status="l.status"
              :work_now="l.workNow == 'no' ? false : true"
            />
          </v-card>
        </div>
      </div>
      <div class="mt-4 mb-2 d-flex px-3">
        <div class="grey4--text ma-2 mr-3 ml-auto text-16 font-weight-thin">
          {{ fullname || username }}
        </div>
        <v-btn
          text
          class="orangeDark rounded-xl"
          color="white"
          @click="logout()"
        >
          Выход
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
// сохранять и читать при загрузке приложения состояние темы
import { mapState, mapMutations } from "vuex";
import ShopCheap from "./ShopCheapComponent";
import Manager from "./ManagerComponent";
export default {
  name: "AppAvatar",
  components: {
    ShopCheap,
    Manager,
  },
  emits: ["change_theme"],
  data: () => ({
    opend: false,
    workers_list: false,
    shops_list: false,
    rainbow: [
      "violet",
      "indigo",
      "blue",
      "green",
      "orange",
      "red",
      "violet",
      "indigo",
      "blue",
      "green",
      "orange",
      "red",
    ],
    picker: new Date().toISOString().substr(0, 7),
  }),
  computed: {
    ...mapState([
      "user",
      "logoWhite",
      "shop",
      "loading",
      "access",
      "access_shop",
      "no_logo",
      "username",
      "year",
      "month",
      "months",
      "all_shop_info",
      "userinfo",
      "login_show",
      "fullname",
      "ready",
    ]),
  },

  methods: {
    ...mapMutations([
      "login_on",
      "change_user",
      "change_footer_date",
      "change_shop",
      "change_username",
      "change_month",
      "change_year",
    ]),
    logout() {
      //выход из системы
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.login_on();
      // this.$router.push({ name: "Login" });
    },
    check_access() {
      if (this.access.any == "admin") {
        this.workers_list = false;
        this.shops_list = true;
      } else if (Object.values(this.access).includes("shop")) {
        this.workers_list = true;
        this.shops_list = false;
      } else if (Object.values(this.access).includes("seller")) {
        this.workers_list = false;
        this.shops_list = true;
      }
    },
  },
  watch: {
    picker() {
      if (this.month != +this.picker.split("-")[1]) {
        this.change_month(+this.picker.split("-")[1]);
      }
      if (this.year != +this.picker.split("-")[0]) {
        this.change_year(+this.picker.split("-")[0]);
      }
    },
    access() {
      this.check_access();
    },
    ready() {
      if (!this.user.id && this.ready) {
        this.opend = true;
      }
    },
  },
  created() {
    this.check_access();
    if (!this.user.id && this.ready) {
      this.opend = true;
    }
  },
};
</script>
<style scoped>
.AppAvatarButton {
  /* filter: drop-shadow(0px 0px 3px white); */
  z-index: 9;
  position: fixed;
  left: 10px;
  top: 8px;
}
.v-menu__content {
  position: fixed !important;
  left: 0px !important;
  top: 0px !important;
}
.AppAvatarUserName {
  font-family: Pattaya, Roboto;
}
.v-picker {
  background: #bdbdbd !important;
}

/***** Animation CSS Code *****/
/* Copy this code with the specified HTML to get your animation effect up and running! */
.jitter {
  animation: move 0.1s 1s 6;
  -webkit-animation: move 0.1s 1s 6;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}

@keyframes move {
  50% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
