<template>
  <v-avatar
    :size="$props.size"
    :color="$props.color"
    style="border: 1px solid #ffffff !important;"
  >
    <span
      v-if="$props.shop"
      v-text="$props.shop"
      :class="$props.text_size + ' ' + $props.text_color + '--text'"
    ></span>
    <img v-else src="https://251317.selcdn.ru/oboi.ru/shop-icons.png" />
  </v-avatar>
</template>

<script>
export default {
  name: "ShopCheap",
  props: {
    text_color: {
      type: String,
      required: false,
      default: "white",
      validator: v => typeof v === "string"
    },
    color: {
      type: String,
      required: false,
      default: "grey5",
      validator: v => typeof v === "string"
    },
    size: {
      type: String,
      required: false,
      default: "29",
      validator: v => typeof v === "string"
    },
    text_size: {
      type: String,
      required: false,
      default: "text-12",
      validator: v => typeof v === "string"
    },
    shop: {
      type: String,
      required: false,
      default: "---",
      validator: v => typeof v === "string"
    }
  },
  data: () => ({})
};
</script>

<style scoped></style>
