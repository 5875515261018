<template>
  <div
    rounded="0"
    class="white pb-12"
    flat
    style="position: absolute; width: 100%; min-height: 100%"
  >
    <!-- <div class="text-right mr-16 mb-n16 mt-6 orangeMain--text red" -->

    <!-- инфа о товаре -->
    <div v-if="Object.keys(active_product).length > 0">
      <SaleProductAbout :product="active_product" />
    </div>

    <!-- строка поиска -->
    <div
      class="d-flex flex-rows orangeMain--text px-3 pt-2"
      style="position: absolute"
    >
      <span v-if="count"
        >{{ count }} x {{ active_product.Цена | money }} =
        {{ (active_product.Цена * count) | money }}</span
      >
    </div>

    <div class="pa-2">
      <v-text-field
        outlined
        hide-details
        :autofocus="copy_products.lenth > 0 ? true : false"
        id="search"
        class="my-6 rounded-xl rounded-tl-0"
        :label="headers.join(' - ').toLowerCase()"
        maxlength="20"
        v-model.trim="search"
        color="orangeMain"
        @input="active_product = {}"
      >
        <v-icon
          v-show="search"
          v-if="search"
          color="grey4"
          slot="append"
          @click="
            search = '';
            count = '';
          "
          >fas fa-backspace</v-icon
        >
      </v-text-field>

      <!-- таблица с результатами -->
      <v-simple-table
        class="px-md-12 py-4"
        color="grey6"
        v-if="
          Object.keys(this.active_product).length == 0 &&
            search_products.length > 0 &&
            full_products.filter(el => (el.Артикул+el.ТорговаяМарка+el.Коллекция).indexOf(search) != -1).length > 0
        "
      >
        <template>
          <thead>
            <tr>
              <th
                class="text-center grey4--text"
                v-for="el in [...headers, ...headers_add]"
                :key="el"
              >
                {{ good_title[el] || el.toLowerCase() }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr
              v-for="el in full_products.filter(el => (el.Артикул+el.ТорговаяМарка+el.Коллекция).indexOf(search) != -1)"
              :key="el.Артикул"
              @click="active_product = el"
            >
              <td
                v-for="el1 in [...headers, ...headers_add]"
                :key="el1"
                :width="el == 'Коллекция' ? '40%' : ''"
              >
                <a class="black--text">
                  {{ el[el1] ? el[el1] : "" }}
                </a>
              </td>
              <td>
                <v-img
                  v-if="el.Бонус"
                  contain
                  width="20px"
                  :src="$root.coin"
                ></v-img>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- количество -->
      <v-row
        v-if="
          Object.keys(active_product).length > 0 &&
            $route.name == 'Sale' &&
            user.workNow == 'open' &&
            user.seller
        "
      >
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="7">
              <v-row class="ml-md-2">
                <v-col cols="4" v-for="i in 6" :key="'row1' + i" class="">
                  <v-btn
                    height="40"
                    class=" rounded-xl transparent"
                    @click="count = i"
                    >{{ i }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details
                    class="rounded-xl rounded-tl-0"
                    color="orangeMain"
                    label="количество"
                    maxlength="3"
                    v-model="count"
                    @keydown="check_number($event)"
                  >
                    <v-icon
                      v-show="count"
                      v-if="count"
                      color="grey4"
                      slot="append"
                      @click="count = ''"
                      >fas fa-backspace</v-icon
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    @click="count > 1 ? count-- : (count = '')"
                    class="mt-n4 rounded-xl transparent"
                    height="40"
                    >-</v-btn
                  >
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    @click="count++"
                    class="ml-n2 ml-md-n0 mt-n4 rounded-xl transparent"
                    height="40"
                    >+</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <v-row class="mt-3">
            <v-col cols="6" md="12">
              <v-btn
                large
                width="100%"
                v-if="$route.name == 'Sale'"
                :disabled="count == 0 || false"
                @click="
                  count = -count;
                  add_sale();
                "
                class="rounded-xl transparent"
                style="box-shadow: 0px 0px 2px red;"
                ><span class="text-18">возврат</span>
              </v-btn>
            </v-col>
            <v-col cols="6" md="12">
              <v-btn
                large
                width="100%"
                :disabled="count == 0 || false"
                @click="add_sale()"
                class="rounded-xl transparent"
                style="box-shadow: 0px 0px 2px green;"
                ><span class="text-18">
                  продажа
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn
        style="position: fixed; bottom: 0px; left: 0px"
        elevation="0"
        large
        width="100%"
        @click="$emit('close_dialog')"
        class="rounded-0 transparent grey2 orangeMain--text"
      >
        <v-icon large class="mx-3">
          mdi-keyboard-return
        </v-icon>
        <span class="text-18">
          закрыть
        </span>
      </v-btn>
    </div>

    <!-- добавление продажи уведомление-->
    <v-snackbar
      top
      right
      :timeout="1500"
      v-model="addsale"
      :color="
        ~message.indexOf('ошибка')
          ? 'error'
          : ~message.indexOf('возврат')
          ? 'warning'
          : 'success'
      "
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="addsale = false">
          <v-icon small>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SaleProductAbout from "./SaleProductAboutComponent";

import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Sale",
  components: {
    SaleProductAbout
  },
  data: () => ({
    headers: ["ТорговаяМарка", "Коллекция", "Артикул"],
    headers_add: ["Цена"],
    search: "",
    search1: "00006849",
    active_product: {},
    openshift: false,
    products: [],
    full_products: [],
    count: "",
    copy_products: [],
    good_title: {
      ОстатокНаСкладе: "у поставщика",
      СвободныйОстаток: "свободно у поставщика",
      ТорговаяМарка: "ТМ"
    },
    message: "",
    addsale: false,
    editing: true
  }),

  computed: {
    ...mapState([
      "user",
      "shop",
      "access",
      "not_image",
      "back_domen",
      "api_data",
      "style"
    ]),

    search_products: function() {
      if (!this.search) return [];
      let check = ["Артикул", "ТорговаяМарка", "Коллекция"];
      let result = this.products.filter(
        el =>
          ~check
            .map(el1 => el[el1])
            .join(" ")
            .toLowerCase()
            .indexOf(this.search.toLowerCase())
      );
      if (result.length < 13) return result;
      return [];
    }
  },

  methods: {
    ...mapActions(["add_api_data"]),

    async get_products() {
      //получаем список продуктов
      if (!this.shop) return;
      return axios
        .post(this.back_domen + "/service/productlist")
        .then(res => res.data)
        .then(js => (this.products = js["response"]));
    },

    async get_full_products() {
      // получаем расширенные продукты
      return axios
        .post(this.back_domen + "/service/product", {
          products: this.search_products
        })
        .then(res => res.data)
        .then(json => (this.full_products = json["response"]));
    },

    async add_sale() {
      // регистрируем продажу
      let article = this.active_product.Артикул;
      return axios
        .post(
          this.back_domen + "/service/addsale",
          Object.assign(this.active_product, this.user, {
            count: this.count,
            shopID: this.shop
          })
        )
        .then(res => res.data)
        .then(json => {
          if (this.count > 0)
            this.message = json.response.article + " продажа оk";
          else this.message = json.response.article + " возврат ok";
          this.count = 0;
          this.addsale = true;
          this.search = "";
          if (this.active_product.Артикул == article) this.active_product = {};
        })
        .catch(() => {
          this.message = "произошла ошибка, " + article + " не был добавлен";
          this.addsale = true;
        });
    },

    async open_shift() {
      // открываем смену
      return axios
        .post(this.back_domen + "/service/openshift", this.user)
        .then(res => res.data)
        .then(json => {
          if (json.response.status == "open") this.user.workNow = "open";
        });
    },

    find_product_full: function(el) {
      for (let prod of this.full_products) {
        let result = Object.values(prod).filter(
          val => val && ~Object.values(el).indexOf(val)
        );
        // console.log(el, prod, result);
        if (result.length == Object.values(el).filter(el => el).length)
          return prod;
      }
      return {};
    },

    check_number: function(event) {
      if (event.keyCode != 13 && event.keyCode != 8 && event.keyCode != 9) {
        if (isNaN(event.key) || +event.key == 0) event.preventDefault();
      }
    },

    focus(id, focus) {
      // снимаем фокус с элемента
      let el = document.getElementById(id);
      focus ? el.click() : el.blur();
    }
  },

  watch: {
    search_products() {
      // запрашиваем новые продукты при изменении результатов поиска
      if (this.search_products.length > 0) {
        let set1 = this.search_products.map(el => el.Артикул);
        let set2 = new Set(this.copy_products.map(el => el.Артикул));
        if ([...set1].filter(el => !set2.has(el)).length > 0) {
          this.full_products = [];
          this.get_full_products();
          this.copy_products = [...this.search_products];
        }
      }
      // автоматическое открытие продукта, если найден только один
      // console.log('!!!', this.search_products);
      if (
        this.search_products.length == 1 &&
        Object.keys(this.active_product).length == 0
      )
        this.active_product = this.find_product_full(this.search_products[0]);
    },

    active_product() {
      // заполняем строку поиска при выборе продукта  и снимаем фокус
      if (Object.keys(this.active_product).length > 0) {
        this.search = Object.values(this.active_product)
          .filter(
            el =>
              typeof el == "string" &&
              ~el.toLowerCase().indexOf(this.search.toLowerCase())
          )[0]
          .toLowerCase();
        this.focus("search", false);
      }
      // запрашиваем данные из апи
      if (this.active_product.Артикул)
        this.add_api_data([this.active_product.Артикул]);
    },

    search() {
      // при удалении поискового запроса чистим результат
      if (!this.search) this.active_product = {};
      // console.log('search', this.search_products);
      // console.log('full', this.full_products);
    },

    full_products() {
      // ищем продукт
      if (this.full_products.length > 0 && this.search_products.length == 1)
        this.active_product = this.find_product_full(this.search_products[0]);
    },

    shop() {
      if (this.shop) {
        this.active_product = {};
        this.get_products().then(() => {
          if (this.search_products.length > 0) {
            let set1 = this.search_products.map(el => el.Артикул);
            let set2 = new Set(this.copy_products.map(el => el.Артикул));
            if ([...set1].filter(el => !set2.has(el)).length > 0) {
              this.full_products = [];
              this.get_full_products();
              this.copy_products = [...this.search_products];
            }
          }
        });
      }
    }
  },

  created: function() {
    this.get_products();
  }
};
</script>

<style scoped>
.DelimiterIndicator {
  height: 22px;
}
</style>
