<template>
  <div class="mt-12">
    <v-card
      v-for="(el, l) in reference"
      :key="l"
      :disabled="l != career.nextStatus && el.name.toLowerCase() != user.status"
      class="mx-0 my-6 Card pb-2"
      elevation="0"
    > 
      <div style="max-height: 100px" class="d-flex justify-end">
        <v-btn
          rounded
          x-small
          :color="l === career.status ? 'orangeMain' : 'grey3'"
          min-width="100px"
          class="red pr-0"
          style="z-index: 1;"
        >
          <span class="white--text text-16 font-weight-light">
            {{ el.name }}
          </span>
          <v-avatar size="30" color="white" class="Avatar">
            <img :src="el.src" alt="alt" />
          </v-avatar>
        </v-btn>
      </div>

      <template v-if="career.education || career.todo">
      <div class="px-5 mt-4" v-if="career.education[l].length">
        <div class="TitleText mb-1">обучение:</div>
        <div v-for="(ed, idx) in career.education[l]" :key="idx" class="text-12">
          <v-icon small v-if="ed.complete" class="mr-1">
            mdi-checkbox-marked-circle-plus-outline
          </v-icon>
          <span class="grey--text">{{ ed.name }}</span>
        </div>
      </div>
      <div class="px-5 mt-3" v-if="career.todo[l].length">
        <div class="TitleText mb-1">условия:</div>
        <div v-for="(td, idx) in career.todo[l]" :key="idx" class="text-12">
          <v-icon small v-if="td.complete">
            mdi-checkbox-marked-circle-plus-outline
          </v-icon>
          <span :class="!td.complete && el.name.toLowerCase() == user.status ? 'redLight--text' : 'grey--text'">
          {{ td.name }}</span>
        </div>
      </div>
      </template>
      <div v-else>
        <div class="px-5 mt-4 text-12">{{loading ? "загрузка" : "нет данных по данному этапу"}}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  components: {},

  name: "Career",
  data: () => ({
    career: {},
    reference: {
      стажер: {
        color: "orange",
        name: "Стажер",
        src: "https://251317.selcdn.ru/oboi.ru/BlogSrc/2.jpg",
        growin: "в юниора",
      },
      юниор: {
        color: "orange",
        name: "Юниор",
        src: "https://251317.selcdn.ru/oboi.ru/BlogSrc/3.jpg",
        growin: "в мастера",
      },
      мастер: {
        color: "orange",
        name: "Мастер",
        src: "https://251317.selcdn.ru/oboi.ru/BlogSrc/6.jpg",
        growin: "в эксперта",
      },
      эксперт: {
        color: "orange",
        name: "Эксперт",
        src: "https://251317.selcdn.ru/oboi.ru/BlogSrc/7.jpg",
        growin: "",
      }
    }
  }),
computed: {
    ...mapState(["user", "loading"]),

    complete_status() {
      if (!this.career.statusHierarchy.length) return [];
      return this.career.statusHierarchy.join(' ').split(this.career.nextStatus)[0].split(' ');

    }
},
methods: {
  async datauser () {
    return axios.post('/service/career', {
      'id': this.user.id
    }).then(res => res.data)
    .then(json => this.career = json.response)
    .catch(err => console.warn(err))
  }
},
watch: {
  user() {
      this.datauser();
  }
},
created: function() {
  if (this.user.id)
    this.datauser();
}
};
</script>

<style scoped>
.Card {
  background: #fbfbfb;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.TitleText {
  color: #8e8e8e;
  font-size: 16px;
}
/* .ButtonCareer {
   font-family: Ubuntu, Roboto;
   margin-top: 10px;
   margin-left: auto;
   margin-right: 20px;
   } */
.Avatar {
  margin-left: auto;
  margin-right: 0;
}
</style>
