<template>
  <div class="d-flex text-10 mt-2">
    <v-img
      v-if="$props.image"
      :src="$props.image"
      height="30"
      width="38"
      class="rounded-lg mr-1"
      style="opacity: 0.8"
    ></v-img>
    <div :style="$props.image ? 'width: 75%' : 'width: 100%'">
      <v-badge
        style="position: relative; right: -36%; top: 14px"
        v-if="bonus"
        left
        bordered
        color="orangeMain"
        :content="'бонус +' + (bonus * bonus_money) + 'р'"
        class="white--text"
      >
      </v-badge>
      <div
        class="orangeMain--text font-weight-thin text-right text-12 mt-n1"
        style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
      >
        <span>{{ $props.collection }}</span>
      </div>
      <div class="d-flex align-end" style="margin-top: -1px">
        <div
          class="font-weight-thin text-12"
          :style="$props.image ? 'width: 50%' : 'width: 63%'"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <span v-if="$props.article">
            {{ $props.article }}
          </span>
          <span v-else>
            {{ $props.name }}
          </span>
        </div>
        <div
          class="text-12 d-flex"
          :class="$props.quantity < 0 ? 'redLight--text' : ''"
          style="position: absolute; right: 8px"
        >
          <div>{{ $props.quantity }}</div>
          <div class="d-flex">
            <span style="padding: 1px" class="text-10">x</span>
            <span>{{ $props.price | money }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SoldArticleComponent",
  components: {},

  props: {
    collection: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },

    name: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },

    article: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },

    image: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },

    quantity: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },

    price: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },

    bonus: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    bonus_money: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
  },
  data: () => ({}),
  computed: {
    ...mapState(["api_data"])
  }
};
</script>

<style scoped></style>
