<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pt-5 pb-12 px-md-9"
      width="1000"
      height="100%"
    >
      <div>
        <div style="position: relative; width: 500px">
          <iframe
            style="position: absolute; width: 100%; height: 100%"
            allowfullscreen="true"
            src="https://www.youtube.com/embed/jgQToPVjGKs?showinfo=0"
            frameborder="0"
          ></iframe>
        </div>
        <v-chip-group
          multiple
          column
          v-model="activeTag"
          color="blueGrey"
          active-class="orangeDark white--text"
        >
          <!--  <v-chip label v-if="access.any == 'admin'"
          class="mx-2 elevation-1"
          key="create"
          @click="$router.push({name: 'Article', params:{id: 'new'}})">
          <v-icon>create</v-icon>
        </v-chip> -->
          <v-chip
            label
            class="mx-2 elevation-1"
            v-for="tag in tags"
            :key="tag"
            :value="tag"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="mb-12 mt-4">
        <v-row class="pa-0">
          <v-col
            v-for="post in showPost"
            :key="post.key"
            cols="12"
            md="6"
            class="pa-0"
          >
            <v-card
              dark
              class="ma-md-1"
              elevation="6"
              v-if="post.name"
              @click="
                open = true;
                active_article = post;
              "
            >
              <v-img
                :src="post.cover.s600x400 || post.cover.main || not_image"
                height="300px"
                max-weight="mx-auto"
              >
                <v-card-title
                  v-text="post.name"
                  class="text-md-h5"
                  v-bind:style="{ 'text-shadow': '1px 1px 10px black' }"
                >
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="open" width="1100">
      <v-card id="dialog">
        <v-card-title class="text-h5 blueMain--text">
          {{ active_article.name }}
          <v-row justify="end" class="mb-1">
            <v-btn icon @click="open = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text v-html="active_article.text" id="article_text">
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Blog",
  data: () => ({
    posts: [],
    show: true,
    postFlex: 12,
    activeTag: [],
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
    open: false,
    active_article: {}
  }),

  computed: {
    ...mapState([
      "shop",
      "user",
      "back_domen",
      "access",
      "style",
      "not_image",
      "jwt_domen"
    ]),

    showPost() {
      if (this.posts.length == 0) return [];
      if (this.activeTag.length == 0) return this.posts;
      return this.posts.filter(
        el => el.tags.filter(el1 => ~this.activeTag.indexOf(el1)).length > 0
      );
    },

    tags() {
      return [...new Set(this.posts.map(el => el.tags).flat())].sort();
    }
  },

  methods: {
    async getArticles() {
      return axios
        .post("https://media.service.oboi.ru/news/back/article/list", {
          section: ["sup"],
          sup_clients: [this.jwt_domen.split(".")[0]],
          active: true
        })
        .then(res => res.data)
        .then(
          json =>
            (this.posts = json["response"].sort((a, b) => {
              if (a.date_public < b.date_public) return 1;
              if (a.date_public > b.date_public) return -1;
              return 0;
            }))
        )
        .catch(err => console.warn(err));
    },

    check_text() {
      setTimeout(() => {
        if (this.active_article.text) {
          let elem = document.getElementById("article_text");
          let width = document.getElementById("dialog").clientWidth;
          this.active_article.text = this.active_article.text
            .split('<iframe class="ql-video"')
            .join(
              `<div style="position: relative;width:${width -
                40}px;height: ${(width - 40) /
                2}px;"><iframe <class="ql-video" style="position: absolute;width: 100%;height: 100%;"`
            )
            .split("</iframe>")
            .join("</iframe></div>");
          if (elem) {
            let img = elem.getElementsByTagName("img");
            for (let el of img)
              if (el.width > width - 40) el.width = width - 40;
          }
        }
      }, 100);
    }
  },

  watch: {
    open() {
      this.check_text();
    }
  },

  created: function() {
    this.getArticles();
  }
};
</script>
