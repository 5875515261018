<template>
  <div>
    <GoToButton
      v-if="false"
      style="z-index: 3; position: absolute; right: 20px; top: 88px;"
      text="в зарплату"
      to="salary"
      color="grey4"
    />
    <div style="height: 30px;"></div>
    <SalaryCreditCard
      class="PositionSalaryCreditCard"
      :money="salary"
      :previous_month="months[!(month - 1) ? 11 : month - 2].slice(0, 3)"
      :previous_money="prev_salary"
      :user_name="user.name"
      :user_id="user.id"
      :default_shop="user.default_shop"
      :small="true"
    />
    <div name="статус" class="ml-3 mt-6">
      <div>
        <!-- <span class="grey4--text text-14"> статус: </span> -->
        <span class="text-18 ml-2"> {{ user.status }} </span>
      </div>
      <div class="text-10 mt-n1 grey4--text">
        {{ user.leader_seller ? "старший продавец" : "" }}
      </div>
      <GoToButton text="в карьеру" to="career" color="grey4" />
    </div>
    <div class="mt-6"></div>
    <div v-if="user.workNow != 'no'" description="Если смена сегодня в этом магазине">
      <IndicatorShift :user_id="user.id" :shop_id="shop" />
      <div class="DelimiterIndicator"></div>
    </div>

    <IndicatorImplementation :user_id="user.id" :shop_id="shop"/>
    <div class="DelimiterIndicator"></div>

    <IndicatorEffectiveness :user_id="user.id" :shop_id="shop"/>
    <div class="DelimiterIndicator"></div>

    <IndicatorWorkDays name="смены" :user_id="user.id" />

    <div class="text-center mt-4">
      <GoToButton
        text="читать"
        to="blog"
        color="grey4"
        min_width="106px"
        style="z-index: 3"
      />
    </div>
    <BlogComponent class="mt-1" />
  </div>
</template>

<script>
import SalaryCreditCard from "./SalaryCreditCardComponent";
import GoToButton from "./GoToButtonComponent";
import IndicatorWorkDays from "./IndicatorWorkDays";
import IndicatorImplementation from "./IndicatorImplementation";
import IndicatorShift from "./IndicatorShift";
import BlogComponent from "./BlogComponent";
import IndicatorEffectiveness from "./IndicatorEffectiveness";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Salary",

  components: {
    SalaryCreditCard,
    GoToButton,
    IndicatorWorkDays,
    IndicatorImplementation,
    IndicatorShift,
    BlogComponent,
    IndicatorEffectiveness
  },
  data: () => ({
    salary: undefined,
    prev_salary: undefined
  }),
  computed: {
    ...mapState(["user", "months", "month", "year", "back_domen", "shop"])
  },
  methods: {
    async get_user_pay(month, year, id) {
      if (!id) return [];
      return axios
        .post(this.back_domen + "/service/user_pay", {
          month: month,
          year: year,
          user_id: id
        })
        .then(res => res.data)
        .then(json => (json.response ? json.response : []));
    },
    add_salary() {
      this.get_user_pay(this.month, this.year, this.user.id).then(
        res => (this.salary = res.reduce((acc, el) => acc + el.pay, 0))
      );
      this.get_user_pay(
        !(this.month - 1) ? 12 : this.month - 1,
        !(this.month - 1) ? this.year - 1 : this.year,
        this.user.id
      ).then(
        res => (this.prev_salary = res.reduce((acc, el) => acc + el.pay, 0))
      );
    }
  },
  watch: {
    user() {
      this.add_salary();
    },
    month() {
      this.add_salary();
    },
    year() {
      this.add_salary();
    }
  },
  created() {
    if (this.user.id) this.add_salary();
  }
};
</script>

<style scoped>
.PositionSalaryCreditCard {
  position: absolute;
  top: 2px;
  right: 12px;
}

.DelimiterIndicator {
  height: 20px;
}
</style>
