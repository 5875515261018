var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Indicator',{attrs:{"name":"текущая смена","progress":_vm.loading},scopedSlots:_vm._u([{key:"button",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-card',{attrs:{"flat":"","color":"transparent","height":"100","width":"90"}},[_c('div',[_c('svg',{staticStyle:{"display":"none"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px"}},[_c('symbol',{attrs:{"id":"wave"}},[_c('path',{attrs:{"d":"M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"}}),_c('path',{attrs:{"d":"M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"}}),_c('path',{attrs:{"d":"M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"}}),_c('path',{attrs:{"d":"M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"}})])]),_c('div',{staticClass:"box darken-1 white",staticStyle:{"border-radius":"60"}},[_vm._v(" > "),_c('div',{staticClass:"percent",class:_vm.per_fact > 60
                  ? 'white--text'
                  : _vm.per_fact > 39
                  ? 'grey3--text'
                  : 'grey4--text'},[_c('div',{staticClass:"text-22",attrs:{"id":"count"}},[_vm._v(" "+_vm._s(_vm.per_fact)+" ")]),_c('div',{staticClass:"text-18 mt-1"},[_vm._v("%")])]),_c('div',{staticClass:"water",style:({
                transform:
                  'translate(0,' +
                  (_vm.per_fact < 100 ? 100 - _vm.per_fact : 0) +
                  '%)'
              }),attrs:{"id":"water"}},[_c('svg',{staticClass:"water_wave water_wave_back",attrs:{"viewBox":"0 0 560 20"}},[_c('use',{attrs:{"xlink:href":"#wave"}})]),_c('svg',{staticClass:"water_wave water_wave_front",attrs:{"viewBox":"0 0 560 20"}},[_c('use',{attrs:{"xlink:href":"#wave"}})])])])])]),_c('div',{staticClass:"ml-3",staticStyle:{"width":"100%"}},_vm._l(([_vm.shop_data, _vm.user_data]),function(el,idx){return _c('div',{key:idx,staticClass:"d-flex mt-3"},[_c('ShopCheap',{staticStyle:{"bottom":"-4px"},attrs:{"shop":idx == 1 ? 'Я' : _vm.shop_id}}),_c('v-row',{staticClass:"ml-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between grey2--text"},[_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm._f("money")(el.sellingMoney || 0))+" "),_c('span',{staticClass:"grey5--text"},[_vm._v(" ₽ ")])]),_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm._f("money")(el.planmoney || 0))+" "),_c('span',{staticClass:"grey5--text"},[_vm._v(" ₽ ")])])])]),_c('v-col',{staticClass:"mt-n7",attrs:{"cols":"12"}},[_c('v-progress-linear',{staticClass:"rounded-xl",attrs:{"value":((el.sellingMoney || 0) / el.planmoney) * 100 || 0,"stream":"","color":"grey4"}})],1)],1)],1)}),0)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }