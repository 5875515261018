<template>
  <div>
    <div v-if="shiftdata.length == 0">
      <v-row>
        <v-col cols="5"> </v-col>
        <v-col cols="7">
          <v-skeleton-loader type="list-item, list-item"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-skeleton-loader
        type="list-item, list-item, list-item"
      ></v-skeleton-loader>
    </div>
    <div
      v-else
    >
      <!-- таблица со сменами -->
      <v-card flat class="transparent">
        <v-simple-table
          style="background: transparent !important"
          v-if="shiftdata.length"
        >
          <template>
            <thead>
              <tr>
                <th></th>
                <th
                  v-for="day in daystable"
                  class="pa-0 text-center"
                  :key="day[0] + Math.random()"
                  :class="
                    calendar[day[0]] && calendar[day[0]].work == 'non-working'
                      ? 'orangeDark--text'
                      : ''
                  "
                >
                <div>{{ day[0] }}</div>
                <div>{{ day[1] }}</div>
                </th>
              </tr>
            </thead>

            <tbody class="text-left">
              <tr
                v-for="el in shiftdata"
                :key="el.id"
              >
                <!-- продавцы -->
                <td class="pa-0">
                  <div style="width: 140px">
                    <div style="position: absolute">
                      <div
                        style="
                          position: relative;
                          z-index: 3;
                          bottom: -2px;
                          -webkit-transform: translate3d(0, 0, 0);
                          -webkit-overflow-scrolling: touch;
                        "
                      >
                        <v-badge
                          bordered
                          style="position: absolute; right: 5px"
                          :content="
                            Object.values(el.shift).filter(
                              (el) =>
                                el.status != 'pass' && el.status != 'virtual'
                            ).length > 0
                              ? Object.values(el.shift).filter(
                                  (el) =>
                                    el.status != 'pass' &&
                                    el.status != 'virtual'
                                ).length
                              : ''
                          "
                          color="grey4 white--text"
                        >
                        </v-badge>
                        <div
                          class="white px-1 rounded-r-lg d-flex text-10"
                          :class="
                            user && user.id == el.id
                              ? 'orangeDark--text'
                              : 'grey2--text'
                          "
                        >
                          <div>
                            {{ el.name.split(" ")[0] }}
                          </div>
                          <div class="ml-1" v-if="~el.name.indexOf(' ')">
                            {{ el.name.split(" ")[1] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <!-- смены -->
                <td
                  v-for="day in daystable"
                  :key="day[0] + Math.random()"
                  class="pa-0 ma-0 td_clear"
                >
                  <v-card flat style="margin: 1px" height="33" width="33">
                    <v-chip
                      label
                      v-if="
                        el.shift[day[0]] && el.shift[day[0]].status != 'virtual'
                      "
                      :class="
                        el.shift[day[0]].responsible
                          ? 'marker'
                          : el.shift[day[0]].othercity
                          ? 'marker_travel'
                          : ''
                      "
                      :style="{
                        'box-shadow': `${
                          el.vocation[day[0]] ? 'inset 0 0 8px #FF6B6C' : 'none'
                        }`,
                        'background-color': `${
                          get_color(el.shift[day[0]]) == 'blueLight'
                            ? '#868EAA'
                            : '#B4BACE'
                        }`,
                      }"
                    >
                      <v-icon left v-if="el.shift[day[0]].hours" color="white">
                        {{ get_icon(el.shift[day[0]].hours) }}
                      </v-icon>
                      <v-icon
                        small
                        v-else-if="el.shift[day[0]].responsible"
                        color="white"
                      >
                        star
                      </v-icon>
                      <v-icon
                        small
                        v-else-if="el.shift[day[0]].othercity"
                        color="white"
                      >
                        flight
                      </v-icon>
                      <v-icon
                        small
                        v-else-if="
                          !el.shift[day[0]].sellingMoney &&
                          el.shift[day[0]].status == 'open' &&
                          el.position == 'seller'
                        "
                        color="white"
                      >
                        far fa-lemon
                      </v-icon>
                      <v-icon small v-else-if="!el.shift[day[0]].hours">
                        t</v-icon
                      >
                    </v-chip>
                    <v-chip
                      label
                      style="width: 100%"
                      v-else-if="el.vocation[day[0]]"
                      :color="get_color(el.vocation[day[0]])"
                    >
                    </v-chip>
                    <v-chip
                      v-else
                      label
                      style="width: 100%"
                      class="calendar[day[0]] && calendar[day[0]].work == 'non-working' ? 'orange' : 'grey1'"
                      :outlined="
                        !el.hours &&
                        (access.any == 'admin' || access[shop] == 'admin') &&
                        check_date(day[0])
                          ? true
                          : false
                      "
                    >
                    </v-chip>
                  </v-card>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "WDay",
  props: ["local_month", "local_year"],
  data: () => ({
    smenaexist: false,
    shiftdata: [],
    actions: {
      "расстановка смен": "editing_shift",
      отпуск: "vocation",
      "выбор ответственного": "responsible",
      "редактирование времени": "editing_time",
      // "день инвентаризации": 'inventory',
      "смена в другом городе": "othercity",
    },
    active_button: "",
    dialog: false,
    active_shift: {},
    calendar: [],
    shift_plan: {},
    today: new Date(),
    sheet: false,
    editing: true,
    message: "",
    names: [
      "ФИО",
      "всего часов",
      "переработка (сколько часов)",
      "переработка (с какого числа)",
    ],
    start: "2020-11-30",
    startMenu: false,
    next_years: ["2020", "2021", "2022", "2023", "2024"],
    positions: {
      seller: [
        "editing_shift",
        "vocation",
        "editing_time",
        "othercity",
        "responsible",
      ],
      cashier: ["editing_shift", "vocation", "editing_time"],
      storekeeper: ["editing_shift", "vocation", "editing_time"],
    },
  }),

  computed: {
    ...mapState([
      "shop",
      "days",
      "month",
      "months",
      "year",
      "user",
      "access",
      "back_domen",
      "style",
    ]),

    daystable: function () {
      let result = [];
      let count = 1;
      let check = true;
      while (check) {
        let date = new Date(
          this.local_year || this.year,
          (this.local_month || this.month) - 1,
          count
        );
        if (date.getMonth() == (this.local_month || this.month) - 1) {
          result.push([
            ("0" + date.getDate()).slice(-2),
            this.days[date.getDay()],
          ]);
          count++;
        } else check = false;
      }
      return result;
    },

    shift_plan_week: function () {
      if (
        Object.keys(this.calendar).length == 0 ||
        Object.keys(this.shift_plan).length == 0
      )
        return {};
      let days = [...new Set([...this.daystable.map((el) => el[1])])];
      days = days.reduce((acc, k) => {
        return {
          ...acc,
          [k]: Math.min.apply(
            null,
            this.daystable
              .filter((el) => el[1] == k)
              .map((el) => +this.shift_plan[el[0]])
              .filter((el) => el)
          ),
        };
      }, {});
      return days;
    },
  },

  methods: {
    async get_calendar() {
      if (!this.shop) return;
      this.calendar = {};
      return axios
        .post(this.back_domen + "/service/calendar", {
          year: this.local_year || this.year,
          month: this.local_month || this.month,
        })
        .then((res) => res.data)
        .then((json) => (this.calendar = json["response"]));
    },

    async get_shift() {
      if (!this.shop) return;
      return axios
        .post(this.back_domen + "/service/shift", {
          year: this.local_year || this.year,
          month: this.local_month || this.month,
          shopID: this.shop,
        })
        .then((res) => res.data)
        .then((json) => {
          this.shiftdata = json["response"]["shift"];
          this.shift_plan = json["response"]["shiftPlan"];
        });
    },

    async post_shift(data) {
      return axios
        .post(this.back_domen + "/service/shiftupd", data)
        .then((res) => res.data)
        .then((json) => json["response"]);
    },

    get_color(shift) {
      let today = new Date();
      let date = new Date(+shift.year, +shift.month - 1, +shift.day);
      if (shift.status == "vocation") return "#FCBFB7";
      if (
        (shift.extraHours && this.access.any == "admin") ||
        this.access[this.shop] == "admin"
      )
        return "redLight";
      if (date > today) return "blueMiddle";
      if (shift.status == "pass") return "grey";
      return "blueLight";
    },

    check_date(day) {
      let date = new Date();
      if (
        +day > date.getDate() &&
        (this.local_month || this.month) == date.getMonth() + 1 &&
        (this.local_year || this.year) == date.getFullYear()
      )
        return true;
      return false;
    },

    get_icon(hours) {
      return hours < 10 ? "filter_" + hours : "filter_9_plus";
    },

    click_shift(day, element, shift, vocation) {
      if (!this.editing) return undefined;
      this.active_shift = shift ? Object.assign({}, shift) : {};
      let today = new Date();
      let date = new Date(
        +(this.local_year || this.year),
        +(this.local_month || this.month) - 1,
        +day
      );

      if (this.active_button == "editing_shift") {
        if (
          date >
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 1
            ) ||
          this.active_shift.status == "pass" ||
          this.access.root
        ) {
          if (shift) this.active_shift["delete"] = true;
          else {
            this.active_shift = {
              day: day,
              month: this.local_month || this.month,
              year: this.local_year || this.year,
              name: element.name,
              id: element.id,
              shopID: this.shop,
              create: true,
            };
            if (
              date <=
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              )
            )
              this.active_shift.status = "open";
          }
          this.post_shift(this.active_shift).then((json) => {
            if (json && this.active_shift["delete"]) delete element.shift[day];
            else if (json && !json.error) element.shift[day] = json;
            else if (json && json.error) {
              this.message = String(json.error).split("@")[0];
              this.smenaexist = true;
            }
            this.shiftdata = [...this.shiftdata];
          });
        }
      } else if (this.active_button == "vocation") {
        if (vocation) vocation["delete"] = true;
        else {
          vocation = {
            day: day,
            month: this.local_month || this.month,
            year: this.local_year || this.year,
            name: element.name,
            id: element.id,
            status: "vocation",
            create: true,
          };
        }
        this.post_shift(vocation).then((json) => {
          if (json && vocation["delete"]) delete element.vocation[day];
          else if (json && !json.error) element.vocation[day] = json;
          else if (json && json.error) {
            this.message = String(json.error).split("@")[0];
            this.smenaexist = true;
          }
          this.shiftdata = [...this.shiftdata];
        });
      } else if (this.active_button == "responsible") {
        if (
          date >
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        ) {
          if (shift) {
            this.active_shift.responsible = +shift.responsible ? false : true;
            this.post_shift(this.active_shift).then((json) => {
              if (json) {
                element.shift[day] = json;
                if (json.responsible) {
                  for (let el of this.shiftdata) {
                    if (el == element) continue;
                    if (el.shift[day] && el.shift[day].responsible) {
                      el.shift[day].responsible = false;
                      this.post_shift(el.shift[day]);
                    }
                  }
                }
              }
              this.shiftdata = [...this.shiftdata];
            });
          }
        }
      } else if (this.active_button == "othercity") {
        if (
          date >
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 1
            ) ||
          this.access.root
        ) {
          if (shift) {
            this.active_shift.othercity = +shift.othercity ? false : true;
            this.post_shift(this.active_shift).then((json) => {
              if (json) element.shift[day] = json;
              this.shiftdata = [...this.shiftdata];
            });
          }
        }
      } else if (this.active_button == "editing_time") {
        if (shift && !this.dialog) this.dialog = true;
        else if (
          shift &&
          this.dialog &&
          this.active_shift.hours &&
          this.active_shift.hours != this.active_shift.hours_old
        ) {
          this.active_shift.money =
            (this.active_shift.money / this.active_shift.hours_old) *
            this.active_shift.hours;
          this.post_shift(this.active_shift).then((json) => {
            this.dialog = false;
            if (json) element.shift[day] = json;
            this.shiftdata = [...this.shiftdata];
          });
        }
      }
    },
  },

  watch: {
    month() {
      this.get_shift();
      this.get_calendar();
    },

    year() {
      this.get_shift();
      this.get_calendar();
    },

    shop() {
      this.get_shift();
      if (this.calendar.length == 0) this.get_calendar();
    },

    local_month() {
      this.get_shift();
      this.get_calendar();
    },

    local_year() {
      this.get_shift();
      this.get_calendar();
    },

    smenaexist() {
      if (!this.smenaexist) this.message = "";
    },
  },

  created: function () {
    this.get_shift();
    this.get_calendar();
  },
};
</script>

<style scoped>
td {
  height: 34px !important;
}
.td_clear {
  border-bottom: white !important;
}
.v-skeleton-loader {
  background: transparent !important;
}

.v-skeleton-loader__actions,
.v-skeleton-loader__article,
.v-skeleton-loader__card-heading,
.v-skeleton-loader__card-text,
.v-skeleton-loader__date-picker,
.v-skeleton-loader__list-item,
.v-skeleton-loader__list-item-avatar,
.v-skeleton-loader__list-item-text,
.v-skeleton-loader__list-item-two-line,
.v-skeleton-loader__list-item-avatar-two-line,
.v-skeleton-loader__list-item-three-line,
.v-skeleton-loader__list-item-avatar-three-line,
.v-skeleton-loader__table-heading,
.v-skeleton-loader__table-thead,
.v-skeleton-loader__table-tbody,
.v-skeleton-loader__table-tfoot {
  background: !transparent;
}
.position_ios_fix {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
