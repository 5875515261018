<template>
<v-main
  :style="style.background"
  class="pt-0 pb-0">
  <v-card
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-3 pb-12 px-md-6"
    width="1260"
    height="100%">
    <v-tabs center-active
            height="100"
            background-color="grey6"
            v-model="tabs"
            slider-size="0"
            slider-color="grey6"
            color="black">
      <v-tab
        class="mx-2 my-8"
        :style="style.neobtn"
        v-for="name in Object.keys(ratingnames)"
        :key="name">
        {{ name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      class="mt-4 pt-md-3"
      v-model="tabs"
      :style="style.neocard">
      <v-tab-item
        v-for="name in Object.keys(ratingnames)"
        :key="name">
        <v-container grid-list-md>
          <v-layout row>
            <v-flex xs12>
              <div v-if="Object.keys(userinfo).length > 0 &&
                         Object.keys(all_shop_info).length > 0 &&
                         rating[ratingnames[name]]">
                <div
                  class="blueLight--text"
                  v-for="(period, key) in rating[ratingnames[name]]"
                  :key="name + key">
                  <span v-if="period.filter(el => userinfo[el.id]).length">{{key}}</span>
                  <div v-if="ratingnames[name] == 'glueSeller'">
                  <v-simple-table
                    class="mt-2">
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left blueLight white--text">Место</th>
                          <th class="text-left blueLight white--text">Mагазин</th>
                          <th class="text-right blueLight white--text">Результат</th>
                        </tr>
                      </thead>

                      <tbody class="text-left">
                        <tr
                          v-for="(item, index) in rating.glueShop[key]"
                          :key="index">
                          <td >{{index + 1}}</td>
                          <td>
                            {{`${item.ID} ${
                              all_shop_info[item.ID] ? all_shop_info[item.ID].name : 'удален'}`}}
                          </td>
                          <td class="text-right">
                            <span v-if="item.result > 1">
                              {{item.result | money}}
                            </span>
                            <span v-else>
                              {{item.result}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br>
                  </div>
                  <v-simple-table v-if="period.filter(el => userinfo[el.id]).length"
                    class="mt-2">
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left blueLight white--text">Место</th>
                          <th class="text-left blueLight white--text">Продавец</th>
                          <th class="text-left blueLight white--text">Домашний магазин</th>
                          <th class="text-right blueLight white--text">Результат</th>
                        </tr>
                      </thead>

                      <tbody class="text-left">
                        <tr :class="user && user.id == item.id ?
                            'grey lighten-2' : ''"
                          v-for="(item, index) in period.filter(el => userinfo[el.id])"
                          :key="index">
                          <td >{{index + 1}}</td>
                          <td>
                            {{userinfo[item.id].name}} 
                            <v-rating readonly dense
                              background-color="grey"
                              color="grey darken-1"
                              :value='userinfo[item.id].status ? status[userinfo[item.id].status] : 0'
                              length="4"
                              size="12">
                            </v-rating>
                          </td>
                          <td>
                            {{all_shop_info[userinfo[item.id].shop] ? `${all_shop_info[userinfo[item.id].shop].ID} ${all_shop_info[userinfo[item.id].shop].name}` : userinfo[item.id].shop + ' закрыт'}}
                          </td>
                          <td class="text-right">
                            <span v-if="item.result > 1">
                              {{item.result | money}}
                            </span>
                            <span v-else>
                              {{item.result}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br>
                </div>
              </div>
              <div v-else>
                <v-img :src="rating_img"> </v-img>
                <!-- <div class="orangeDark--text text-center">загружаю данные, подожди :)</div> -->
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <!-- <v-progress-linear -->
        <!--   v-if="!Object.keys(userinfo).length > 0 && -->
        <!--         !Object.keys(shopinfo).length > 0 && -->
        <!--         !rating[ratingnames[name]]" -->
        <!--   class="mt-2" -->
        <!--   indeterminate -->
        <!--   color="orangeDark"></v-progress-linear> -->
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</v-main>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios'
export default {
name: 'Top',
data: () => ({
  tabs: null,
  vs_glue: false,
  ratingdict: {
    'Дневной план': 'daymark',
    'Эффективность': 'efficiency',
    'Средний чек': 'averCheck',
    'Трудолюбие': 'workingDays',
    'Stenova Home': 'stenovahome',
    'Чеки с телефонами': 'percent_phone',
    'Возвраты': 'returns',
    'Продажи': 'sales',
    'Лучший дебют': 'bestJunior',
    'Наибольший чек': 'check',
    'Клей': 'glueSeller'
  },
  userinfo: {},
  // shopinfo: {},
  rating: {},
  ratingperoid: {
    'Продажи': [{'name': 'за текущий квартал', 'quarter': true}],
    'Эффективность': [{'name': 'за текущий квартал'}],
    'Лучший дебют': [{'name': 'за все время работы'}],
    'Средний чек': [{'name': 'за текущий квартал', 'quarter': true},
                    {'name': 'за текущий месяц'}],
    'Трудолюбие': [{'name': 'за текущий квартал', 'quarter': true},
                   {'name': 'за текущий месяц'}],
    'Stenova Home': [{'name': 'за текущий квартал', 'quarter': true},
                     {'name': 'за текущий месяц'}],
    'Чеки с телефонами': [{'name': 'за текущий квартал, в процентах', 'quarter': true},
                       {'name': 'за текущий месяц, в процентах'}],
    'Возвраты': [{'name': 'за 6 месяцев, процент возврата от продаж', month: 5},
                       {'name': 'за текущий месяц, процент возврата от продаж'}],
    'Наибольший чек': [{'name': 'за текущий квартал', 'quarter': true},
                       {'name': 'за текущий месяц'}],
    'Клей': [{'name': 'за текущий месяц, отношение площади клея к площади обоев'}],
    'Дневной план': [{'name': 'текущий квартал, за выполнение дневного плана: до 150% = 1балл, от 150% = 1,5балла, от 200% = 2балла', 'quarter': true}]
  },
  // year: new Date().getFullYear(),
  // month: new Date().getMonth(),
  rating_img: 'https://251317.selcdn.ru/oboi.ru/sup/rating.jpg',
  status: {'стажер': 1, 'юниор': 2, 'мастер': 3, 'эксперт': 4},
}),

computed: {

    ...mapState(['back_domen', 'user', 'style', 'all_shop_info', 'access',
                 'month', 'year']),

    ratingnames () {
      let admin = Object.values(this.access)
          .reduce((acc, v) => 
            v != 'producer' && typeof(v) == 'string' ? [ ...acc, v] : acc, []);
      if (admin.length == 0) {
        return Object.entries(this.ratingdict).reduce(
          (acc, el) => 
            ~['averCheck', 'check', 'stenovahome'].indexOf(el[1])
              ? {...acc, [el[0]]: el[1]} : acc,
          {});
      } else {
        return this.ratingdict;
      }
    }
},


methods: {
  
  async datauser () {
    return axios.post(this.back_domen + '/service/userinfo', {
      'year': this.year,
      'month': this.month,
      'shopID': 'all',
      'every': true
    }).then(res => res.data)
      .then(json => {
      let userinfo = {}
      for (let el of json['response'])
        userinfo[el.id] = el;
      this.userinfo = {...this.userinfo, ...userinfo};
      // console.log('user', this.userinfo);
    })
      .catch(err => console.warn(err))
  },

  // async datashop () {
  //   return axios.post(this.back_domen + '/service/shopinfo', {
  //     'year': this.year,
  //     'month': this.month + 1,
  //   }).then(res => res.data)
  //     .then(json => {
  //     let shopinfo = {};
  //     for (let el of json['response'])
  //       shopinfo[el.ID] = el;
  //     this.shopinfo = {...this.shopinfo, ...shopinfo};
  //     console.log(this.shopinfo);
  //     console.log(this.all_shop_info);
  //   })
  //     .catch(err => console.warn(err))
  // },

  async getrating (ratingname) {
    let rating = this.ratingnames[ratingname]
    // if (this.rating[rating]) return {};
    let data = this.ratingperoid[ratingname];
    for (let element of data){
      // console.log(ratingname, data)
      // let date = new Date(this.year, this.month - (element.month || 0), 1);
      // let year = date.getFullYear();
      // let month = date.getMonth() + 1;
      let name = element.name;
      axios.post(this.back_domen + '/service/rating', {
        'year': this.year,
        'month': this.month,
        'rating': rating,
        'vs_glue': this.vs_glue,
        'quarter': element.quarter,
      }).then(res => res.data)
        .then(json => {
        rating = {};
        for (let key in json['response']){
          rating[key] = this.rating[key] ? this.rating[key] : {};
          rating[key][name] = json['response'][key];
        }
        this.rating = {...this.rating, ...rating};
      })
        .catch(err => console.warn(err))
    }
  }  
},

watch: {

  month() {
    for (let el of Object.keys(this.ratingnames)) {
      console.log('!!!!')
      this.getrating(el);
    }
    this.datauser();
  }
  
},

created: function() {
  for (let el of Object.keys(this.ratingnames))
    this.getrating(el);
  this.datauser();
  // this.datashop();
}

}
</script>
