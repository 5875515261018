<template>
<v-main class="pb-0 pt-0"
        :style="style.background">
  <v-card
    color="grey6"
    max-width="800"
    height="100%"
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-6 pt-md-12 pb-12">
    <template v-if="(paylist.length > 0 &&
                     paylist.filter(el => el.pay).length == 0) ||
                    (userdata.id != userID) && (!datatree || datatree.sellers)">
      <v-row
        align="center"
        justify="center">
        <v-col cols="12" sm="3">
          <v-img
            :src="not_found"></v-img>
        </v-col>
      </v-row>

    </template>
    <template v-if="userdata.id == userID &&
                    paylist.filter(el => el.pay).length > 0"
              class="mb-12">
      <v-card flat
              color="grey6"
              width="800"
              class="mx-auto px-3 px-md-8">
        <div class="title  mx-2">
          <span v-if="userdata.name != user.name">
            {{userdata.name}}
          </span>
        </div>

        <v-row>

          <!-- карточка - личные показатели -->

          <v-col md="6" cols="12" >
            <v-card class="pa-7" :style="style.neocard" min-width="342" min-height="254">
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column align-start">
                  <div class="text-14 grey4--text mb-n5">личная</div>
                  <div class="text-48 mb-n5"
                       :class="userdata.personaleffectiv < 80  ? 'redLight--text' :
                               userdata.personaleffectiv < 90  ? 'orangeMain--text' :
                               userdata.personaleffectiv < 100 ? 'black--text' : 'green--text'">
                    {{`${(userdata.personaleffectiv || 0).toFixed()}`
                    }}<span class="text-36">%</span>
                  </div>

                  <div class="text-14 grey4--text">эффективность</div>
                </div>


                <!--      % выпонения личный -->
                <div class="d-flex flex-column align-end">
                  <div class="text-14 grey4--text mb-n3">сейчас</div>
                  <div class="text-24 mb-n4">
                    {{`${(userdata.planpercent || 0).toFixed()}`}}
                  </div>
                  <div class="text-24 mb-n3"
                       :class="userdata.prognoz < 90  ?
                               'redLight--text' : 'green--text'">
                    <span class="text-18">%</span>{{
                    `${(userdata.prognoz  || 0).toFixed()}`}}
                  </div>
                  <div class="text-14 grey4--text">прогноз</div>
                </div>
                <!--   личный план -->
                <div class="d-flex flex-column align-end">
                  <div class="text-14 grey4--text mb-n3">сейчас</div>
                  <div class="text-24 mb-n4">
                    {{userdata.moneyI | money}}
                  </div>
                  <div class="text-24 grey4--text mb-n3">
                    {{userdata.planmoney | money}}
                  </div>
                  <div class="text-14 grey4--text">твой план</div>
                </div>
              </div>


              <!--  бонус от продаж -->
              <div class="d-flex flex-row justify-space-between align-end">
                <div class="d-flex flex-column mt-3" >
                  <div v-if="Object.keys(personalstage).length">
                  <div v-for="(el, idx) in Object.keys(personalstage)" :key="idx"
                       class="text-14 mb-n1"
                       :class="paymain.PData && paymain.PData.selling >= el
                               && (!Object.keys(personalstage)[idx+1]
                               || paymain.PData.selling
                               < Object.keys(personalstage)[idx+1])
                                 ? 'orangeDark--text' : ' blueGrey--text'">
                    {{(personalstage[el] + (~String(personalstage[el]).indexOf('.') ? '' : '.') + '00').slice(0,4)
                    }}<span class="text-12">%</span>
                    от {{el | money}}
                  </div>
                  <div class="text-14 grey4--text mt-n1">бонус от продаж</div>
                  <div class="text-14 grey4--text mt-n2">по всем магазинам</div>
                 </div>
                </div>
                <!--  кнопка -->
                <v-card width="131"
                        height="60"
                        class="d-flex align-center justify-center"
                        :style="style.neobtn">
                  <div class="text-18 blueLight--text">
                    {{userdata.grade}}
                  </div>
                </v-card>
              </div>
            </v-card>


            <!-- карточка - показатели магазина -->
            <v-card class="pa-7 mt-4" :style="style.neocard" min-width="342">
              <div class="d-flex flex-row justify-space-between">
                <div>
                  <div class="text-14 grey4--text mb-n3">за смену</div>
                  <div class="text-24 mb-n3"
                       :class="shift.day ? 'orangeMain--text': 'grey4--text'">
                    {{fact | money}}
                  </div>
                </div>

                <!--    % выполнения  -->
                <div class="ml-n7 d-flex flex-column align-end"
                     :style="style.foreground"
                     >
                  <div class="text-14 grey4--text mb-n3">сейчас</div>
                  <div class="text-24 mb-n4">
                    {{`${(datatree.moneypercent || 0).toFixed()}`}}
                  </div>
                  <div class="text-24 mb-n3"
                       :class="datatree.prognoz > 90 ?
                                 'black--text' : 'redLight--text'">
                    <span class="text-18">%</span>{{
                    `${(datatree.prognoz || 0).toFixed()}`}}
                  </div>
                  <div class="text-14 grey4--text mb-n3"
                       >прогноз</div>
                </div>
                <!--      план магазина -->
                <div class="d-flex flex-column align-end ml-n12">
                  <div class="text-14 grey4--text mb-n3">сейчас</div>
                  <div class="text-24 mb-n4">
                    {{datatree.moneyI | money}}
                  </div>
                  <div class="text-24 grey4--text mb-n3">
                    {{datatree.planmoney ? datatree.planmoney : 0| money}}
                  </div>
                  <div class="text-14 grey4--text mb-n3">общий план</div>
                </div>
              </div>

              <!-- конверсия -->
              <div class="d-flex flex-column align-end mt-5">
                <div class="text-24 grey4--text mb-n3">
                  <span class="text-18 mr-n1">%</span>
                     {{datatree.conversion || 0}}
                </div>
                <div class="text-14 grey4--text mb-n3">конверсия</div>
              </div>
    
              <div class="d-flex flex-row justify-space-between align-end">

                <!-- график -->
                <div class="d-flex flex-column grey--text mt-n13">


                  <v-card flat class="ml-n2"
                          color="grey6"
                          height="122"
                          width="122"
                          >
                    <div>
                      <svg version="1.1"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink"
                           x="0px" y="0px"
                           style="display: none;">
                        <symbol id="wave">
                          <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
                          <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
                          <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
                          <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
                        </symbol>
                      </svg>
                      <div class="box darken-1"
                           :class="shift.day ? 'white' : ''"
                           :style="shift.day ?
                                   {...style.neocard,
                                   ...style.whiteborder,
                                   ...style.background,
                                   'border-radius': '60'} :
                                   {...style.neocard,
                                   ...style.whiteborder,
                                   'border-radius': '60'}">
                        <div v-if="shift.day"
                             class="percent"
                             :class="per_fact > 60 ? 'orangeMain--text' :
                                     per_fact > 39 ? 'orangeMain--text' :
                                     'blueLight--text'">
                          <div class="percentNum text-h3" id="count">
                            {{per_fact}}
                          </div>
                          <div class="percentB text-h4 mb-n3">%</div>
                        </div>
                        <div
                          v-if="shift.day"
                              id="water"
                              class="water"
                             :style="{'transform': 'translate(0,' +
                             (per_fact < 100 ? 100 - per_fact : 0) + '%)'}">
                          <svg viewBox="0 0 560 20"
                               class="water_wave water_wave_back">
                            <use xlink:href="#wave"></use>
                          </svg>
                          <svg viewBox="0 0 560 20"
                               class="water_wave water_wave_front">
                            <use xlink:href="#wave"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <div class="text-24 grey4--text mt-2">
                    {{shift.planmoney || 0 | money}}
                  </div>
                  <div class="text-14 grey4--text mt-n3">план смены</div>
                </div>

                <!--   кнопка -->
                <div class="d-flex flex-column align-end">
                  <v-card width="131" height="60"
                          class="d-flex align-center justify-center"
                          :style="style.neobtn">
                    <div class="text-18 blueLight--text text-center"
                         v-if="datatree.paysett">
                      {{datatree.ID}}
                      <div class="text-12 orange--text text--lighten-3 mt-n3"
                      v-if="userdata.leader">
                      старший продавец
                    </div>
                    </div>

                  </v-card>
                </div>
              </div>
            </v-card>
          </v-col>

          <!-- карточка - подробные показатели -->
          <v-col md="6" cols="12" >
            <v-card min-width="342"
                    class="pa-7 d-flex flex-column"
                    :style="style.neocard"
                    min-height="556">
              <div >
                <div class="d-flex justify-space-between text-18"
                     v-if="(money = paylist.map(el => el.stable)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.stable">
                  <div class="grey4--text">оклад</div>
                  <div class="orangeMain--text">
                    {{money | money}}
                  </div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.stable)"
                       :key="el.shop + 'stable'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col cols="6" class="text-left ">
                    {{el.SData.count}} смен {{el.SData.hours}}ч.
                    <div class="grey4--text ml-n12 mt-n1 text-14"
                         v-if="el.SData.leader">
                      за старшего продавца {{el.SData.leader | money}}</div>
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.stable | money}}
                  </v-col>
                </v-row>


                <div class="d-flex justify-space-between text-18"
                     v-if="(money = paylist.map(el => el.personal)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.personal">
                  <div class="grey4--text mt-3">личные продажи</div>

                  <div class="orangeMain--text mt-3">
                    {{money | money}}
                  </div>
                </div>
                <div class="grey4--text mt-n2 text-14">
                      основной магазин {{userdata.shopD.split('@')[0]}}</div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.personal)"
                       :key="el.shop + 'personal'">
                  <v-col cols="3" class="text-center ">
                    {{el.PData.percent}}<span class="text-14">%</span>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span>от {{el.PData ? el.PData.selling : 0 | money}}</span>
                    <div class="grey4--text ml-n15 mt-n1 text-14"
                         v-if="userdata.correction">
                      корректировка {{userdata.correction | money}}
                    </div>
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.personal | money}}
                  </v-col>
                </v-row>


                <div class="d-flex justify-space-between text-18"
                     v-if="(money = paylist.map(el => el.bonusroll)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.bonusroll">
                  <div class="grey4--text mt-3">бонус рулоны</div>
                  <div class="orangeMain--text mt-3">{{money | money}}</div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.bonusroll)"
                       :key="el.shop + 'bonus'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col cols="6" class="text-left">
                    {{el.BRData.quantity}} шт.
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.bonusroll}}
                  </v-col>
                </v-row>

              

                <div class="d-flex justify-space-between text-18"
                     v-if="(money=paylist.map(el => el.extra)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.extra">
                  <div class="grey4--text mt-3">план магазина</div>
                  <div class="orangeMain--text mt-3">
                    {{money | money}}
                  </div>
                </div>
                <div v-if="(money=paylist.map(el => el.extra)
                           .reduce((a,b) => a+b)) > 0"
                     class="text-14 grey4--text mt-n2">
                  от личных продаж и бонус рулонов
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.extra)"
                       :key="el.shop + 'extra'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col cols="6" class="text-left ">
                    {{(((el.extraC || 1) - 1) * 100).toFixed()
                    }}<span class="text-14">%</span>
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.extra | money}}
                  </v-col>
                </v-row>


                <div class="d-flex justify-space-between text-18"
                     v-if="(money=paylist.map(el => el.conversion)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.conversion">
                  <div class="grey4--text mt-3">конверсия магазина</div>
                  <div class="orangeMain--text mt-3">
                    {{money | money}}
                  </div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.conversion)"
                       :key="el.shop + 'conversion'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.conversion | money}}
                  </v-col>
                </v-row>


                <div class="d-flex justify-space-between text-18"
                     v-if="(money=paylist.map(el => el.dailyplan)
                           .reduce((a,b) => a+b)) > 0 ||
                           datatree.paysett.dailyplan">
                  <div class="grey4--text mt-3">дневной план</div>
                  <div class="orangeMain--text mt-3">
                    {{money | money}}
                  </div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.dailyplan)"
                       :key="el.shop + 'daily'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col cols="6" class="text-left">
                    {{el.DPData.quantity}} смен
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.dailyplan | money}}
                  </v-col>
                </v-row>


                <div class="d-flex justify-space-between text-18"
                     v-if="(money=paylist.map(el => el.avercheck).reduce((a,b) => a+b)) > 0 || datatree.paysett.avercheck">
                  <div class="grey4--text mt-3">средний чек</div>
                  <div class="orangeMain--text mt-3">
                    {{money | money}}
                  </div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="(el) in arr = paylist.filter(el => el.avercheck)"
                       :key="el.shop + 'aver'">
                  <v-col cols="3" class="text-center">
                    {{el.shop}}
                  </v-col>
                  <v-col cols="6" class="text-left">
                    {{el.ACData.quantity}} шт.
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.avercheck | money}}
                  </v-col>
                </v-row>

                <div class="d-flex justify-space-between text-18"
                     v-if="(corrections=paylist.map(el => el.corrections)
                           .flat().filter(el => !el.deleted))
                           && corrections.length">
                  <div class="grey4--text mt-3">корректировки</div>
                  <div class="orangeMain--text mt-3">
                    {{corrections.reduce((a,b) => a + b.amount, 0) | money}}
                  </div>
                </div>
                <v-row class="blueGrey--text text-18"
                       v-for="el in corrections"
                       :key="el.date + 'correct'">
                  <v-col cols="3" class="text-center">
                    {{el.email.split('@')[0]}}
                  </v-col>
                  <v-col cols="6" class="text-left">
                    {{el.describe}}
                  </v-col>
                  <v-col class="text-end pl-0">
                    {{el.amount | money}}
                  </v-col>
                </v-row>
              </div>

              <div class="mt-auto">
                <v-card width="131"
                        height="60"
                        class="ml-auto d-flex align-center justify-center"
                        :style="style.neobtn">
                  <div class="text-18 blueLight--text">
                    {{paylist.map(el => el.pay).reduce((a,b) => a+b) | money}}
                  </div>
                </v-card>
              </div>
            </v-card>
          </v-col>


          <!-- средняя эф-ть, средний штраф.балл -->
          <v-col md="5" cols="12" class="px-0">
            <v-card  :style="style.neocard"
                      v-if="averageData && averageData[userID]"
                      min-width="302"
                      min-height="auto"
                      class="pa-6 mx-3">
              <div>
                <div class="d-flex flex-row justify-start grey--text">
                  <div>личная эффективность за 3 мес.</div>
                </div>
                <v-progress-linear
                      :color="averageData[userID].effectiv < 80  ? 'redLight' :
                              averageData[userID].effectiv < 90  ? 'orange lighten-3' :
                              averageData[userID].effectiv < 100 ? 'blueLight' : 'green lighten-1'"
                      :value="averageData[userID].effectiv"
                      stream
                      height="20"
                      class="mt-4"
                      >
                    <strong class="white--text">{{averageData[userID].effectiv ?
                                                  averageData[userID].effectiv + '%' : ''}}
                                                </strong>
                </v-progress-linear>  
                <v-row v-if="!~['стажер', 'юниор'].indexOf(userdata.grade) 
                            && averageData[userID].month > 2">
                  <v-col cols="2">
                    <v-icon size="40" v-if="averageData[userID].effectiv < 90 && datatree.planmoney" color="red">error_outline</v-icon>
                    <v-icon size="35" v-else-if="averageData[userID].effectiv < 95 && datatree.planmoney" color="orange lighten-2 text-12">warning</v-icon>  
                  </v-col>
                  <v-col cols="10">
                    <div v-if="averageData[userID].effectiv < 90 && datatree.planmoney" class="grey4--text text-12">
                        Катапульта сработает в следующем месяце. Оклад будет начисляться по статусу Мастер, бонусная часть будет начисляться по статусу Юниор
                    </div>
                    <div v-else-if="averageData[userID].effectiv < 95 && datatree.planmoney" class="grey4--text text-12">Есть угроза катапульты по личной эффективности</div>
                  </v-col>
                </v-row>
              </div>
              <div class="d-flex flex-row justify-start grey--text mt-4">
                <div>штрафные баллы за 3 мес.</div>
              </div>
              <div>
                <v-progress-linear
                  :color="averageData[userID].points < 4 ? 'blueLight' :
                  averageData[userID].points < 8 ? 'orange' : 'redLight'"
                  :value="averageData[userID].points / 9 * 100"
                  stream
                  height="20"
                  class="mt-4">
                  <div class="white--text">
                    {{averageData[userID].points}}<span class="text-10">б.</span>
                  </div>
                </v-progress-linear> 
              </div>
              <v-row v-if="!~['стажер', 'юниор'].indexOf(userdata.grade)">
                <v-col cols="2">
                  <v-icon size="40" v-if="averageData[userID].points > 8" color="red">error_outline</v-icon> 
                  <v-icon size="35" v-else-if="averageData[userID].points > 6" color="orange lighten-2">warning</v-icon> 
                </v-col>
                <v-col cols="10">
                  <div v-if="averageData[userID].points > 8" class="grey4--text text-12">
                      Катапульта сработает в следующем месяце. Оклад будет начисляться по статусу Юниор, бонусная часть будет начисляться по статусу Мастер
                  </div>
                  <div v-else-if="averageData[userID].points > 6" class="grey4--text text-12">Есть угроза катапульты по штрафным баллам</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div v-for="(point, idx) in userdata.points"
                       :key="idx+point"
                       class="redLight--text font-italic mx-3 ml-0">
                    <span class="grey--text">{{point.date | date2}} - </span>
                    <span class="grey--text">{{point.count}}<span class="text-10">б. </span></span>
                    <span>{{point.comment}} </span>

                  </div>
                </v-col>
              </v-row>
            </v-card>
          <v-card flat class="pa-3 mx-md-0 text-16 grey6"
            v-if="userdata.addon.length > 0">
            <div>
              <div class="blueGrey--text">действует штраф</div>
              <div  v-for="(gaddon, idx) in userdata.addon"
                :key="idx+gaddon"
                class="red--text text--lighten-2 font-italic">
                <v-alert text outlined
                  color="deep-orange"
                  icon="mdi-fire">
                  {{addon[gaddon]}}
                </v-alert>
              </div>
            </div>
          </v-card>


       <!--  лимиты бонус рулонов -->     
          <v-card  :style="style.neocard"
                    v-if="averageData && averageData[userID]"
                    min-width="302"
                    min-height="auto"
                    class="pa-6 ma-3">   
            <div>
              <div class="d-flex flex-row justify-start grey--text">
                <div class="mb-3">{{`${datatree.plan_ss}` == 0 ? 'бонус рулонов нет' :'лимит бонус рулонов'}}</div>
              </div>
              <div class="d-flex flex-row justify-space-between grey--text text-14 mb-n4">
                <div>{{userdata.pay.BRData.balance}}шт.</div>
                <div>{{`${datatree.plan_ss}`}}шт.</div>
              </div>
              <v-progress-linear
                      color="orangeMain"
                      :value="datatree.plan_ss ?
                              userdata.pay.BRData.balance / datatree.plan_ss * 100 : 0"
                      stream
                      height="8"
                      class="mt-4">
              </v-progress-linear> 
              <!-- <div class="d-flex flex-row justify-space-between grey--text text-12 mt-5 mb-n4">
                <div>
                  <v-icon size="15" color="orangeMain">
                    fiber_manual_record
                  </v-icon>
                  остаток</div>
                <div>всего
                  <v-icon size="15" color="orange lighten-3">
                    fiber_manual_record
                  </v-icon></div>
              </div> -->
               <div class="d-flex flex-row justify-space-between grey--text text-12 mt-n1">
                <div>остаток</div>
                <div>всего</div>
              </div>
            </div>
          </v-card>
        </v-col>

        <!--     смены -->
        <v-col md="7" cols="12" class="pa-0">
          <v-expansion-panels multiple flat>
            <template v-for="(sm, idx) in userdata.shift.slice()
                             .reverse().filter(el => el.status == 'open'
                             || el.status == 'virtual')">
              <v-expansion-panel :key="idx">
                <!--    заголовок -->
                <v-expansion-panel-header
                  color="grey6"
                  class="pl-6 pr-1 pb-6">

                  <!--  левый блок -->
                  <v-row>
                    <v-col cols="3"
                           :style="sm.status == 'open' ? {...style.neocard, 'border': '2px solid rgba(255,255,255,1)'} : ''"
                           >
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-rows">
                          <div class="text-24 grey--text">
                            {{sm.dayweek.toLowerCase()}}
                          </div>
                          <div
                            class="text-36 font-weight-bold"
                            :class="sm.status == 'open' ? 'blueLight--text' : 'grey--text'">
                            {{sm.day}}
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column align-end"
                          v-if="sm.status == 'open'">
                          <template v-if="userdata.pay.DPData && userdata.pay.DPData[sm.day]
                                          && userdata.pay.DPData[sm.day].money">
                            <div class="text-18 orangeDark--text mb-n1 d-flex flex-rows">
                              <v-img contain position="right"
                                height="16px" width="16px"
                                :src="coin"></v-img>
                              <span class="pl-1">
                                {{userdata.pay.DPData ? userdata.pay.DPData[sm.day].money : 0}}
                              </span>
                            </div>
                            <div class="text-12 grey4--text">за план</div>
                          </template>
                          <template v-if="userdata.pay.ACData && userdata.pay.ACData[sm.day]">
                            <div class=" text-18 orangeDark--text mb-n1 mt-1 d-flex flex-rows">
                              <v-img contain position="right"
                                height="16px" width="16px"
                                :src="coin"></v-img>
                              <span class="pl-1">
                                {{userdata.pay.ACData[sm.day]}}
                              </span>
                            </div>
                            <div class="text-12 grey4--text">за ср.чек</div>
                          </template>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2" v-if="sm.status == 'open'">
                    </v-col>
                    <v-col>


                      <!--  правый блок -->
                      <div
                        class="d-flex flex-row justify-space-between text-end"
                        v-if="sm.status == 'open'">
                        <div>
                          <div class="text-12 grey4--text">за смену</div>
                          <div class="text-24 blueGrey--text mb-n1">
                            {{sm.sellingMoney | money}}
                          </div>
                          <div class="text-24 grey4--text mb-n1">
                            {{sm.avercheck2 | money}}
                          </div>
                          <div class="text-14 grey4--text">ср.чек</div>
                        </div>
                        <div>
                          <div class="text-12 grey4--text">выпол-е</div>
                          <div class="text-24 blueGrey--text mb-n1">
                            <span class="text-18 mr-n1">%</span>
                            {{userdata.pay.DPData && userdata.pay.DPData[sm.day] ?
                            userdata.pay.DPData[sm.day].fact /
                            userdata.pay.DPData[sm.day].plan * 100 : 0 | money}}
                          </div>
                          <div class="text-24 grey4--text mb-n1">
                            {{sm.planmoney | money}}
                          </div>
                          <div class="text-12 grey4--text">план магазина</div>
                        </div>
                      </div>
                      <div
                        class="d-flex flex-column align-end blueLight--text"
                        v-if="sm.status == 'virtual'">
                        <div class="text-24 grey4--text">виртуальная смена</div>
                        <div class="text-12 grey4--text mb-n1">возврат или продажа в нерабочие дни</div>
                        <div class="text-12 grey4--text mt-2">сумма</div>
                        <div class="text-24"
                             :class="sm.sellingMoney >= 0 ? 'blueMain--text' : 'redLight--text'">
                          {{sm.sellingMoney | money}}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>


                <!--   раскрытие смены -->
                <v-expansion-panel-content
                  color="grey6">
                  <div v-for="el in sm.selling.reduce((acc, el) =>
                              ~acc.map(el => el[0]).indexOf(el.document, el.date) ?
                              acc : [...acc, [el.document, el.date]], [])
                              .reverse()" :key="el[0]">
                    <!-- чек - время сумма -->
                    <v-row class="blueMain--text">
                      <v-col cols="3" class="d-flex justify-end">
                        <div class="text-18">
                          {{el[1].split(' ')[1].slice(0,5)}}
                        </div>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end">

                      </v-col>
                      <v-col class="d-flex justify-end">
                        <div class="text-14">
                          {{el[0]}}
                          <div class="text-18 mt-n1 text-right grey4--text"
                               v-if="sm.selling.length">
                            {{sm.selling.reduce((acc, el1) => el1.document == el[0] ? acc + el1.money : acc, 0) | money}}
                          </div>
                        </div>
                      </v-col>
                    </v-row>


                    <!-- строка с товаром -->
                    <v-row
                      v-for="(sel, idx1) in sm.selling
                             .filter(el1 => el1.document == el[0])"
                      :key="idx1"
                      class="my-n4">
                      <v-col cols="3" class="d-flex justify-end">
                        <v-card width="64" height="64"
                                :style="style.neocard">
                        <a>
                          <v-img
                            :style="style.neocard"
                            @click="api_data[sel.article] ?
                                    change_pic_art(api_data[sel.article]) : ''"
                            :src="api_data[sel.article] ? api_data[sel.article].imgFrontal.s300x300 || '' : ''">
                          </v-img>
                        </a>
                        </v-card>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end px-0">
                        <div v-if="sel.bonus" 
                          class="d-flex orangeMain--text mr-auto ml-n7">
                          <v-img contain position="right"
                            height="16px" width="16px" 
                            :src="coin"></v-img>
                          <span class="mt-n1 ml-2">
                            {{(userdata.pay.BRData.money[sm.day] || 0) * sel.quantityB}}
                          </span>
                        </div>
                        <div class="d-flex flex-column align-end mt-auto">
                          <div class="text-18 blueGrey--text mb-n2"
                            v-if="sel.article">
                            {{sel.article}}
                          </div>
                          <div class="text-12 blueGrey--text text-end"
                            v-else>
                            {{sel.name}}
                          </div>
                          <div class="text-12 blueLight--text"
                            v-if="sel.product">
                            {{((sel.product.Коллекция || '') + ' ' + 
                               (sel.vendor || '')).slice(0, 30)}}
                          </div>
                        </div>
                      </v-col>
                      <v-col class="d-flex justify-end pl-0">
                        <div class="d-flex flex-column align-end mt-auto">
                          <div class="text-18 blueMain--text mb-n2"
                               :class="sel.money >= 0 ? 'blueMain--text' : 'redLight--text'">
                            {{sel.money | money}}
                          </div>
                          <div class="text-12 blueLight--text">
                            {{sel.quantity}} x {{sel.price | money}}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="grey4--text text-12 text-center my-6">
                      ／＼／＼／＼／＼／＼／＼／＼／＼／＼／＼／＼／＼／＼／＼
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>
  </template>
  </v-card>
</v-main>
</template>



<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import axios from 'axios'
import { back_request } from "../mixins/back"


export default {
name: 'State',
mixins: [back_request],
props: ['local_month', // локальный месяц для входа из админки
        'local_year'], // локальный год для входа из админки
data: () => ({
  datatree: {}, // дерево с данными целое
  addon: {"offence": "штрафные баллы",
          "efficiency": "низкая эффективность",
          "noleader": "↓ старший продавец"}, // для пометок об эффективности
  averageData: {}, // средине показатели пользователя
  average_conversion : undefined,
}),

computed: {

  ...mapState(['user', // данные о пользователе
               'not_found', // сслыка на заглушку если нет данных
               'shop', // ид магазина
               'coin', // ссылка на монетку для бонусов
               'api_data', // данные из апи по артикулам
               'not_image', // ссылка на заглушку если нет картинки
               'month', // сквозной месяц
               'year', // сквозной год
               'back_domen',
               'style',
               'data_tree']),

  userID() { //id пользователя из адреса или хранилища
      if (this.$route.params.userid)
        return this.$route.params.userid;
      else return this.user.id;
  },

  userdata() { // данные о пользователе из дерева
      if (!this.datatree) return {};
      // for (let i=0; i<this.datatree.sellers.length; i++) {
      //   if (this.datatree.sellers[i].id == this.userID)
      //     return this.datatree.sellers[i];
      // }
      // return {}
      let res = [...(this.datatree.sellers || []),
                 ...(this.datatree.cashiers || [])]
      .filter(el => el.id == this.userID);
      if (res.length) return res[0];
      return {};
  },

  personalstage() { // этапы бонусов личных продаж
      if (Object.keys(this.paymain).length == 0) return {};
      // console.log(this.paymain.PData.settings);
      return this.paymain.PData.settings;
  },

  paymain() { // расчеты с личными продажами
      if (Object.keys(this.paylist).length == 0) return {};
      let paymain = this.paylist.filter((el) => el.PData && el.PData.selling > 0);
      if (Object.keys(paymain).length != 0) return paymain[0];
      return {};
  },

  paylist() { // список зарплат по всем магазинам
      if (Object.keys(this.userdata).length == 0) return [];
      let paylist = this.userdata.payOther.map((el) => el);
      paylist.unshift(this.userdata.pay);
      return paylist;
  },

  shift() { // смена на текущую дату
      if (Object.keys(this.userdata).length == 0) return {};
      for (let i=0; i<this.userdata.shift.length; i++) {
        if (new Date().getDate() == +this.userdata.shift[i].day &&
            new Date().getMonth() + 1 == +this.userdata.shift[i].month &&
            new Date().getFullYear() == +this.userdata.shift[i].year)
          return this.userdata.shift[i];
      }
      return {};
  },

  fact() { // факт выполнения плана за смену
      if (Object.keys(this.shift).length == 0) return 0;
      let fact = 0;
      for (let i=0; i<this.datatree.sellers.length; i++) {
        for (let j=0; j<this.datatree.sellers[i].shift.length; j++) {
          if (this.shift.day == this.datatree.sellers[i].shift[j].day &&
              this.shift.month == this.datatree.sellers[i].shift[j].month &&
              this.shift.year == this.datatree.sellers[i].shift[j].year) {
            fact += this.datatree.sellers[i].shift[j].sellingMoney;
          }
        }
      }
      return fact;
  },

  per_fact() { // факт выполения в процентах для графика
      if (!this.shift.planmoney) return 0;
      return ((this.fact / this.shift.planmoney) * 100).toFixed();
  }


},

methods: {

  ...mapMutations(['change_pic_art', // отправка картинки для показа в карусели
                   ]),

  ...mapActions(['add_api_data', // обновление апи данных
                ]),

  async datauser() { // получение дерева данных
      if (!this.shop) return;
      this.datatree = {};
      return axios.post(this.back_domen + '/service/datauser', {
        'year': this.local_year || this.year,
        'month': this.local_month || this.month,
        'attributes' : {'shop': ['planmoney', 'money', 'prognoz', 'cashiers',
                                 'moneypercent', 'moneyI', 'paysett', 'plan_ss',
                                 'conversion'],
                        'user': ['addon', 'grade', 'moneyI',
                                 'correction', 'personaleffectiv', 'planpercent',
                                 'prognoz', 'planmoney', 'pay', 'payOther',
                                 'points', 'shopD'],
                        'shift': ['status', 'dayweek', 'day', 'sellingMoney',
                                  'avercheck2', 'planmoney', 'month', 'year'],
                        'selling': ['document', 'money', 'quantityB',
                                    'vendor', 'product', 'bonus', 'name']}
      }).then(res => res.data)
        .then(json => {
          // console.log(json['response']);
          this.datatree = json['response'];
        });
  },

  async getaver() { // получение средних показателей по пользвоателям магазина
      if (!this.datatree.sellers) return;
      this.averageData = {};
      return axios.post(this.back_domen + '/service/average', {
        'userID': this.datatree.sellers.map(el => el.id),
        'month': 3,
      }).then(res => res.data)
        .then(json => this.averageData = json['response']);
  },
  
},

watch: {

  month () { // обновление дерева при смене месяца
      this.datauser();
  },

  year () { // обновление дерева при смене года
      this.datauser();
  },

  local_month () { // обновление дерева при смене локального месяца
      this.datauser();
  },

  shop () { // обновление данных при смене магазина
    this.datauser().then(()=>this.getaver());
  },

  userdata () { // обновление картинок при смене пользователя
      // console.log(this.userdata);
      let result = this.userdata?.shift;
      if (!result) return;
      result = [...new Set(result.map(el => el.selling).flat()
        .map(el => el.article))];
      if (result.length > 0) this.add_api_data(result);
  }
  
},

created: function() {
    this.datauser().then(()=>this.getaver());
}

}
</script>


<style lang="scss" scoped>
  *, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}
$bgColor:#FFFFFF;
$boxColor:#FFFFFF;
$waterFColor:#868EAA;
$waterBColor:#FFAA00;

body {
  background:$bgColor;
  font: 14px/1 'Open Sans', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.box{
  height: 120px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius:100%;
  overflow: hidden;
  .percent{
    position: absolute;
    left: 0;
    top: 0;
    z-index:3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items:center;
    justify-content:center;
    color:#FFFFFF;
    font-size:64px;
  }
  .water{
    position: absolute;
    left: 0;
    top: 0;
    z-index:2;
    width: 100%;
    height: 100%;
    transform:translate(0,100%);
    background:$waterFColor;
    transition: all .3s;
    &_wave{
      width: 200%;
      position: absolute;
      bottom: 100%;
      &_back {
        right: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }
      &_front {
        left: 0;
        fill: $waterFColor;
        margin-bottom: -1px;
        animation: wave-front .7s infinite linear;
      }
    }
  }
}
@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}
</style>
