<template>
  <v-main>
  <div class="px-12 mx-12 py-9">
    <p>access_token: {{access_token}}</p>
    <p>refresh_token: {{refresh_token}}</p>
    <p>{{response}}</p>
  </div>
  </v-main>
</template>

<script>
import axios from 'axios'

export default {
name: 'Auth',
data: () => ({
  response: 'response',
  access_token: 'access_token',
  refresh_token: 'refresh_token'
}),
mounted () {
},

computed: {
},

methods: {
  download () {
  axios
    .post('https://auth.oboi.ru/user/claims', {}, )
    .then(response => {this.response = response.data})
    .then(() => this.access_token = localStorage.getItem('access_token'))
    .catch(error => console.log(error))
  }
},


created: function() {
  this.download();
  // setInterval (() => this.download(), 100);
  this.access_token = localStorage.getItem('access_token');
  this.refresh_token = localStorage.getItem('refresh_token');
}

}
</script>
