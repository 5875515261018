<template>
  <v-main class="pt-0 pb-0" :style="style.background">
    <v-card
      max-width="1140"
      color="grey6"
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      height="100%"
      class="mx-auto pt-4 px-md-6 pb-16"
    >
      <!-- Общие итоговые цифры -->
      <template v-if="data_tree.money">
        <v-row>
          <v-col class="text-h6 blueLight--text" cols="7" md="3">
            {{ data_tree.money | money }} / {{ data_tree.plan | money }}
          </v-col>
          <v-col
            class="text-center text-h6 blueLight--text"
            cols="4"
            md="2"
            lg="2"
            xl="2"
          >
            {{
              data_tree.plan
                ? ((data_tree.money / data_tree.plan) * 100).toFixed(1)
                : 0
            }}% /
            {{
              data_tree.plan
                ? (data_tree.prognoz / data_tree.plan) * 100
                : 0 | money
            }}%
          </v-col>
        </v-row>
      </template>

      <v-card
        flat
        class="grey6"
        v-for="region in data_tree.regions"
        :key="region.number"
      >
        <!-- Область -->
        <v-row class="px-4 py-2">
          <v-col cols="2" md="1" lg="1" xl="1">
            <v-avatar><img :src="region.pic" /></v-avatar>
          </v-col>
          <v-col cols="10" md="5" lg="5" xl="5">
            <div class="pt-4 ml-1">{{ region.name }}</div>
          </v-col>
          <v-col cols="6" md="3" lg="3" xl="3">
            <div class="pt-4 title text-right">
              {{ region.money | money }} ₽
            </div>
          </v-col>
          <v-col cols="6" md="3" lg="3" xl="3">
            <div class="pt-4 text-right pr-5">
              <span class="title"
                >{{
                  region.plan
                    ? ((region.money / region.plan) * 100).toFixed()
                    : 0
                }}%
              </span>
              <v-icon color="grey lighten-1">fas fa-route</v-icon>
              <span
                class="title"
                :class="
                  region.prognozP < 90
                    ? 'red--text'
                    : region.prognozP >= 100
                    ? 'green--text'
                    : ''
                "
              >
                {{ region.prognozP.toFixed() }}%</span
              >
            </div>
          </v-col>
        </v-row>
        <!-- Магазины -->
        <v-expansion-panels accordion focusable :value="panel">
          <v-expansion-panel v-for="shop in region.shops" :key="shop.email">
            <!-- Заголовок магазина -->
            <v-expansion-panel-header class="py-0" @click="opencard(shop.ID)">
              <v-row>
                <v-col class="py-0 pr-0" cols="3" md="1" lg="1" xl="1">
                  <div>
                    <!-- @mouseover="change_shopID_show(shop.ID)"> -->
                    {{ shop.ID }}
                    <v-icon
                      v-if="
                        check_date() &&
                        (!shop.planmoney ||
                          !shop.wdayscount ||
                          shop.sellers.length == 0)
                      "
                      class="mr-3 mb-1"
                      >error_outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col class="py-0" cols="0" md="5" lg="5" xl="5">
                  {{ shop.name }}
                </v-col>
                <v-col
                  class="py-0 pr-0 text-right"
                  cols="8"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <p class="ma-0">{{ shop.money | money }} ₽</p>
                  <p class="ma-0 pr-3 grey--text" v-if="shop.correct">
                    {{ shop.correct > 0 ? "+" : "-" }}
                    {{
                      shop.correct > 0 ? shop.correct : -shop.correct | money
                    }}
                  </p>
                </v-col>
                <v-col
                  class="py-0 text-right pr-5"
                  cols="4"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <span> {{ shop.moneypercent.toFixed() }}% </span>
                  /
                  <span
                    :class="
                      shop.prognoz < 90
                        ? 'red--text'
                        : shop.prognoz >= 100
                        ? 'green--text'
                        : ''
                    "
                  >
                    {{ shop.prognoz.toFixed() }}%
                  </span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <!-- Тело магазина -->
            <v-expansion-panel-content class="mt-6">
              <v-tabs
                horizontal
                v-model="menu"
                slider-color="orangeDark"
                slider-size="5"
                color="orangeDark"
              >
                <!-- кнопки вкладок настройки магазина -->
                <template>
                  <!--          кнопка Показатели -->
                  <v-tab class="mt-1">
                    <v-icon left>fas fa-balance-scale-left</v-icon>
                  </v-tab>

                  <!--          кнопка Сотрудники -->
                  <v-tab class="mt-1">
                    <v-badge avatar bordered overlap>
                      <template v-slot:badge>
                        <v-avatar color="orangeDark">{{
                          Object.keys(positions).reduce(
                            (acc, el) => acc + (shop[el + "s"].length || 0),
                            0
                          )
                        }}</v-avatar>
                      </template>
                      <v-avatar size="35"
                        ><v-icon left>fas fa-users-cog</v-icon></v-avatar
                      >
                    </v-badge>
                  </v-tab>

                  <!--          кнопка Распродажа -->
                  <v-tab class="mt-1">
                    <v-badge avatar bordered overlap>
                      <template v-slot:badge>
                        <v-avatar color="orangeDark">{{
                          shop.art_ss.length
                        }}</v-avatar>
                      </template>
                      <v-avatar size="35"
                        ><v-icon left>fas fa-carrot</v-icon></v-avatar
                      >
                    </v-badge>
                  </v-tab>

                  <!--          кнопка Реклама -->
                  <!-- <v-tab class="mt-1">
                  <v-icon left>fas fa-ad</v-icon>
                </v-tab> -->
                </template>

                <!--            Показатели -->
                <v-tab-item>
                  <v-card flat min-height="620">
                    <v-card-text class="px-xs-0 px-sm-0 px-md-2">
                      <div class="title text-center">Показатели</div>
                      <v-row>
                        <!--            card -->
                        <v-col cols="12" lg="4" md="6" sm="12">
                          <template>
                            <v-row>
                              <v-col>
                                <v-card min-height="250">
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                    >Планы на месяц</v-card-title
                                  >
                                  <v-divider></v-divider>
                                  <v-list dense>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div @click="change_show_help('plan')">
                                          <v-icon class="mr-1"
                                            >far fa-money-bill-alt</v-icon
                                          >
                                          выручка:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <editattr
                                          :attr="editing[shop.ID]['.planmoney']"
                                          :plan="true"
                                          :key="editing[shop.ID]['.planmoney']"
                                          v-if="
                                            editing[shop.ID] &&
                                            editing[shop.ID]['.planmoney'] !=
                                              undefined
                                          "
                                          @save="
                                            shopupd(shop, {
                                              '.planmoney': +$event,
                                            }).then(() =>
                                              update_editing(
                                                shop.ID,
                                                '.planmoney',
                                                undefined
                                              )
                                            )
                                          "
                                          @plan="get_plan(shop.ID)"
                                          @close="
                                            update_editing(
                                              shop.ID,
                                              '.planmoney',
                                              undefined
                                            )
                                          "
                                        ></editattr>
                                        <div v-else>
                                          <span>{{
                                            shop.planmoney | money
                                          }}</span>
                                          р.
                                          <v-btn
                                            icon
                                            v-if="check_date()"
                                            @click="
                                              update_editing(
                                                shop.ID,
                                                '.planmoney',
                                                shop.planmoney || 0
                                              )
                                            "
                                          >
                                            <v-icon
                                              small
                                              class="mb-2"
                                              :color="
                                                shop.planmoney
                                                  ? 'blueLight lighten-2'
                                                  : 'red'
                                              "
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div>
                                          <v-icon class="mr-3"
                                            >fas fa-carrot</v-icon
                                          >
                                          бонус-товары:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <editattr
                                          :attr="editing[shop.ID]['.plan_ss']"
                                          v-if="
                                            editing[shop.ID] &&
                                            editing[shop.ID]['.plan_ss'] !=
                                              undefined
                                          "
                                          @save="
                                            shopupd(shop, {
                                              '.plan_sales_stimulation':
                                                +$event,
                                            }).then(() =>
                                              update_editing(
                                                shop.ID,
                                                '.plan_ss',
                                                undefined
                                              )
                                            )
                                          "
                                          @close="
                                            update_editing(
                                              shop.ID,
                                              '.plan_ss',
                                              undefined
                                            )
                                          "
                                        ></editattr>
                                        <div v-else>
                                          <span>{{ shop.plan_ss }} шт.</span>
                                          <v-btn
                                            icon
                                            v-if="check_date()"
                                            @click="
                                              update_editing(
                                                shop.ID,
                                                '.plan_ss',
                                                shop.plan_ss || 0
                                              )
                                            "
                                          >
                                            <v-icon
                                              small
                                              class="mb-2"
                                              color="blueLight lighten-2"
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div>
                                          <v-icon class="mr-3"
                                            >far fa-calendar-alt</v-icon
                                          >
                                          смены:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <div>
                                          <span>{{ shop.wdayscount }}</span> дн.
                                          <v-btn icon>
                                            <v-icon
                                              small
                                              class="mb-2"
                                              :color="
                                                !shop.wdayscount && check_date()
                                                  ? 'red'
                                                  : 'blueLight lighten-2'
                                              "
                                              @click="
                                                show_graph_w = true;
                                                change_shop(shop.ID);
                                              "
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>

                        <!--            card -->
                        <v-col cols="12" lg="4" md="6" sm="12">
                          <template>
                            <v-row>
                              <v-col>
                                <v-card min-height="250">
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                    >Уточнения</v-card-title
                                  >
                                  <v-divider></v-divider>
                                  <v-list dense>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div>
                                          <v-icon class="mr-1"
                                            >far fa-money-bill-alt</v-icon
                                          >
                                          точная выручка:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <editattr
                                          :attr="editing[shop.ID]['.sales']"
                                          v-if="
                                            editing[shop.ID] &&
                                            editing[shop.ID]['.sales'] !=
                                              undefined
                                          "
                                          @save="
                                            shopupd(shop, {
                                              '.sales': +$event,
                                            }).then(() =>
                                              update_editing(
                                                shop.ID,
                                                '.sales',
                                                undefined
                                              )
                                            )
                                          "
                                          @close="
                                            update_editing(
                                              shop.ID,
                                              '.sales',
                                              undefined
                                            )
                                          "
                                        ></editattr>
                                        <div v-else>
                                          <span>{{
                                            shop.sales_itog | money
                                          }}</span>
                                          р.
                                          <v-btn
                                            icon
                                            v-if="!check_date()"
                                            @click="
                                              update_editing(
                                                shop.ID,
                                                '.sales',
                                                shop.sales_itog || 0
                                              )
                                            "
                                          >
                                            <v-icon
                                              small
                                              class="mb-2"
                                              color="blueLight lighten-2"
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div class="my-1">
                                          <v-icon class="mr-1"
                                            >fas fa-user-slash</v-icon
                                          >
                                          без продавца:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <span
                                          >{{
                                            shop.freemoney.toFixed() | money
                                          }}
                                          р.</span
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--            line -->

                                    <!-- <v-list-item> -->
                                    <!--   <v-list-item-content> -->
                                    <!--     <div class="py-2"> -->
                                    <!--       <v-icon class="mr-3">fab fa-adversal</v-icon> -->
                                    <!--       рекламный бюджет: -->
                                    <!--     </div> -->
                                    <!--   </v-list-item-content> -->
                                    <!--   <v-list-item-content class="align-end"> -->
                                    <!--     <span>{{shop.adM | money}} р.</span> -->
                                    <!--   </v-list-item-content> -->
                                    <!-- </v-list-item> -->

                                    <v-list-item>
                                      <v-list-item-content>
                                        <div class="py-2">
                                          <v-icon class="mr-3"
                                            >fas fa-boxes</v-icon
                                          >
                                          остатки на:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <div>
                                          <span>{{
                                            shop.balanceDate | date2
                                          }}</span>
                                          <v-btn
                                            icon
                                            @click="
                                              add_balance = true;
                                              ex_shop = shop;
                                            "
                                          >
                                            <v-icon
                                              small
                                              class="mb-2"
                                              color="blueLight lighten-2"
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>

                        <!--            card -->
                        <v-col cols="12" lg="4" md="6" sm="12">
                          <template>
                            <v-row>
                              <v-col>
                                <v-card min-height="250">
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                    >Параметры</v-card-title
                                  >
                                  <v-divider></v-divider>
                                  <v-list dense>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div @click="change_show_help('tbu')">
                                          <v-icon class="mr-2"
                                            >fab
                                            fa-creative-commons-zero</v-icon
                                          >
                                          точка безубыт-ти:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <editattr
                                          :attr="editing[shop.ID]['tochkabu']"
                                          v-if="
                                            editing[shop.ID] &&
                                            editing[shop.ID]['tochkabu'] !=
                                              undefined
                                          "
                                          @save="
                                            shopupd(shop, {
                                              tochkabu: +$event,
                                            }).then(() =>
                                              update_editing(
                                                shop.ID,
                                                'tochkabu',
                                                undefined
                                              )
                                            )
                                          "
                                          @close="
                                            update_editing(
                                              shop.ID,
                                              'tochkabu',
                                              undefined
                                            )
                                          "
                                        ></editattr>
                                        <div v-else>
                                          <span>{{
                                            shop.tochkabu | money
                                          }}</span>
                                          р.
                                          <v-btn
                                            icon
                                            @click="
                                              update_editing(
                                                shop.ID,
                                                'tochkabu',
                                                shop.tochkabu || 0
                                              )
                                            "
                                          >
                                            <v-icon
                                              small
                                              class="mb-2"
                                              color="blueLight lighten-2"
                                              >fas fa-pencil-alt</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--            line -->
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div>
                                          <v-icon class="mr-2"
                                            >fas fa-key</v-icon
                                          >
                                          пароль магазина:
                                        </div>
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        <editattr
                                          :attr="editing[shop.ID]['.pass']"
                                          v-if="
                                            editing[shop.ID] &&
                                            editing[shop.ID]['.pass'] !=
                                              undefined
                                          "
                                          @save="
                                            shopupd(shop, {
                                              '.pass': +$event,
                                            }).then(() =>
                                              update_editing(
                                                shop.ID,
                                                '.pass',
                                                undefined
                                              )
                                            )
                                          "
                                          @close="
                                            update_editing(
                                              shop.ID,
                                              '.pass',
                                              undefined
                                            )
                                          "
                                        ></editattr>
                                        <div v-else>
                                          <span>*********</span>
                                          <!-- <v-btn icon
                                          @click="update_editing(shop.ID, '.pass', '')">
                                          <v-icon small
                                            class="mb-2"
                                            color="blueLight lighten-2"
                                            >fas fa-pencil-alt</v-icon>
                                        </v-btn> -->
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>
                      </v-row>

                      <!--            line -->
                      <div
                        id="chart"
                        v-if="
                          show_graph[shop.ID] &&
                          shop.series &&
                          chartOptions.labels
                        "
                      >
                        <apexchart
                          class="mr-3"
                          type="line"
                          height="250"
                          :options="chartOptions"
                          :series="shop.series"
                        ></apexchart>
                        <apexchart
                          class="mt-n8"
                          v-if="shop.peopleCount"
                          type="line"
                          height="160"
                          :options="chartOptionsPeople"
                          :series="shop.series_people"
                        ></apexchart>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!--красату отсюда наводить          Сотрудники -->
                <v-tab-item>
                  <v-card flat min-height="800">
                    <v-card-text class="px-xs-0 px-sm-0 px-md-2">
                      <div class="title text-center mb-4">Сотрудники</div>
                      <v-row>
                        <v-col
                          cols="12"
                          xl="3"
                          lg="3"
                          md="4"
                          sm="6"
                          xs="12"
                          v-for="user in [
                            ...shop.sellers,
                            ...shop.storekeepers,
                            ...shop.cashiers,
                          ]"
                          :key="shop.ID + user.id"
                        >
                          <v-card max-width="300" height="410">
                            <v-img
                              dark
                              height="80px"
                              :src="
                                shop.sellers.includes(user)
                                  ? 'https://251317.selcdn.ru/oboi.ru/avatars/white.png'
                                  : 'https://251317.selcdn.ru/oboi.ru/avatars/storekeeper.png'
                              "
                            >
                              <div class="text-right">
                                <v-btn
                                  icon
                                  right
                                  :disabled="user.disabled"
                                  class="text-right"
                                  @click="
                                    ex_user = user;
                                    ex_user.shopID = shop.ID;
                                    del_user = true;
                                  "
                                >
                                  <v-icon small color="grey lighten-2"
                                    >far fa-trash-alt</v-icon
                                  >
                                </v-btn>
                              </div>
                              <v-row>
                                <v-card-title class="pa-0 ma-0">
                                  <a>
                                    <div
                                      class="white--text blueGrey px-5"
                                      @click="
                                        show_state_w = true;
                                        change_shop(shop.ID);
                                        change_user({ id: user.id });
                                      "
                                    >
                                      {{ user.name }}
                                      {{ user.disabled ? " (ув.)" : "" }}
                                      <span
                                        class="orange--text font-weight-bold"
                                        v-if="user.workNow == 'open'"
                                      >
                                        &deg;
                                      </span>
                                    </div>
                                  </a>
                                </v-card-title>
                              </v-row>
                            </v-img>
                            <v-list two-line>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="blueGrey"
                                    >fas fa-mobile-alt</v-icon
                                  >
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    user.phone
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >телефон</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="blueGrey"
                                    >fas fa-user-graduate</v-icon
                                  >
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    user.grade ||
                                    (shop.storekeepers.includes(user)
                                      ? positions.storekeeper
                                      : "")
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ positions[user.position] }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-if="shop.sellers.includes(user)">
                                <v-divider inset></v-divider>
                                <div class="subtitle-2 grey--text text-center">
                                  Личные продажи
                                </div>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon color="blueGrey"
                                      >fas fa-percent</v-icon
                                    >
                                  </v-list-item-icon>

                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span> {{ user.planpercent }}% </span>
                                      /
                                      <span
                                        :class="
                                          user.prognoz < 90
                                            ? 'red--text'
                                            : user.prognoz >= 100
                                            ? 'green--text'
                                            : ''
                                        "
                                      >
                                        {{ user.prognoz }}%
                                      </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                      >факт - прогноз</v-list-item-subtitle
                                    >
                                  </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon color="blueGrey"
                                      >fas fa-ruble-sign</v-icon
                                    >
                                  </v-list-item-icon>

                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span>{{ user.planmoney | money }} </span>
                                      /
                                      <span
                                        :class="
                                          user.prognoz < 90
                                            ? 'red--text'
                                            : user.prognoz >= 100
                                            ? 'green--text'
                                            : ''
                                        "
                                      >
                                        {{ user.moneyI | money }}</span
                                      >
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                      >план - факт</v-list-item-subtitle
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-btn
                            v-if="check_date()"
                            class="mt-12 ml-12"
                            color="blueGrey"
                            dark
                            left
                            bottom
                            fab
                            @click="
                              add_user = true;
                              userinfo();
                              new_user.shopID = shop.ID;
                            "
                          >
                            <v-icon>fas fa-user-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!--             Распродажа -->
                <v-tab-item>
                  <v-card flat min-height="800">
                    <v-card-text class="px-xs-0 px-sm-0 px-md-2">
                      <div class="title text-center mb-4">Бонус-товары</div>
                      <v-row>
                        <v-col
                          v-for="article in shop.art_ss"
                          :key="article + shop.ID"
                          class="pa-1"
                          cols="3"
                          xl="1"
                          lg="1"
                          md="1"
                          sm="2"
                          xs="3"
                        >
                          <v-card flat tile class="d-flex">
                            <!-- {{pic[article]}} -->
                            <v-img
                              :src="
                                api_data[article]
                                  ? api_data[article].imgFrontal.s300x300
                                  : not_image
                              "
                              :lazy-src="
                                api_data[article]
                                  ? api_data[article].imgFrontal.s300x300
                                  : not_image
                              "
                              aspect-ratio="1"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  >
                                  </v-progress-circular>
                                </v-row>
                              </template>
                              <div class="py-9"></div>
                              <div
                                class="blueLight black--text text-right pr-1 overline"
                              >
                                {{ article }}
                              </div>
                            </v-img>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-btn
                            v-if="check_date()"
                            class="mt-12 ml-12"
                            color="blueGrey"
                            dark
                            left
                            bottom
                            fab
                            @click="
                              add_bonus = true;
                              ex_shop = shop;
                            "
                          >
                            <v-icon>cached</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
              <!--             Адрес и телефон -->
              <v-row class="pr-5 pt-2">
                <v-col class="py-0 pr-0" cols="9">
                  <v-icon color="grey lighten-1">fas fa-map-marked-alt</v-icon>
                  <p>
                    {{ shop.address }}
                  </p>
                </v-col>
                <v-col class="py-0 pr-0 text-right" cols="3">
                  <v-icon color="grey lighten-1">fas fa-phone-square</v-icon>
                  <p>
                    {{ shop.phone }}
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-card>

    <!-- диалог для добавления сотрудника -->
    <template v-if="add_user">
      <v-dialog
        persistent
        no-click-animation
        v-model="add_user"
        max-width="500"
      >
        <v-card height="560px">
          <v-card-title></v-card-title>
          <v-card-text>
            <span class="red--text text-14 mb-7">
              {{ error_add }}
            </span>
            <v-text-field
              label="Фамилия Имя"
              v-model.trim="new_user.name"
              :disabled="new_user.id ? true : false"
            ></v-text-field>
            <v-text-field
              label="Телефон"
              v-model.trim="new_user.phone"
              @input="set_new_user()"
              maxlength="12"
            ></v-text-field>
            <masked-input
              v-show="false"
              type="text"
              name="phone"
              class="form-control"
              v-model="new_user.phone"
              :mask="[
                '+7',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]"
              :guide="false"
              placeholderChar="#"
            >
            </masked-input>
            <v-select
              class="mt-2"
              label="должность"
              @change="
                new_user.position = Object.entries(positions)
                  .filter((el) => el[1] == new_user.position_name)
                  .map((el) => el[0])[0];
                set_new_user(new_user);
              "
              :items="Object.values(positions)"
              v-model.trim="new_user.position_name"
              color="blueGrey"
            >
            </v-select>
            <v-select
              class="mt-2"
              label="статус"
              :items="statuses"
              :disabled="
                !['seller', 'cashier'].includes(new_user.position) ||
                (new_user.status &&
                  new_user.id &&
                  users.filter((el) => el.id == new_user.id && el.status)
                    .length)
                  ? true
                  : false
              "
              v-model.trim="new_user.status"
              color="blueGrey"
            >
            </v-select>

            <v-simple-table height="150px">
              <template>
                <!--  <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead> -->
                <tbody class="text-left">
                  <tr
                    v-for="el in find_users()"
                    :key="el.id"
                    @click="set_new_user(el)"
                  >
                    <td>
                      {{ el.name }}
                    </td>
                    <td>
                      {{ el.phone }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text color="blue-grey darken-4" @click="add_user = false"
              >Закрыть</v-btn
            >
            <v-btn
              text
              color="blue-grey darken-4"
              :disabled="
                new_user.name &&
                new_user.phone &&
                new_user.position &&
                check_exist()
                  ? false
                  : new_user.status || new_user.position != 'seller'
                  ? true
                  : false
              "
              @click="update_user(new_user, 'new')"
              >Сохранить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <!-- диалог для удаления сотрудника -->
    <v-dialog v-model="del_user" max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <span>Выберите дату увольнения сотрудника</span>
          <v-date-picker
            class="mt-3"
            v-model="date_del"
            no-title
            locale="ru"
            color="orangeMain"
            type="date"
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue-grey darken-4" text @click="del_user = false"
            >Закрыть</v-btn
          >
          <v-btn
            color="blue-grey darken-4"
            text
            :disabled="date_del ? false : true"
            @click="
              update_user(
                Object.assign({}, ex_user, {
                  date: date_del,
                  status: ex_user.grade,
                }),
                'del'
              ).then(() => (del_user = false))
            "
          >
            Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- диалог для добавления рулонов -->
    <v-dialog v-model="add_bonus" max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <span>Загрузите список артикулов (через пробел)</span>
          <v-textarea
            solo
            class="mt-3"
            name="input-7-4"
            label="Список атрикулов"
            v-model="bonus_art"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text color="blue-grey darken-4" @click="add_bonus = false"
            >Закрыть</v-btn
          >
          <v-btn
            text
            color="blue-grey darken-4"
            :disabled="bonus_art.length > 0 ? false : true"
            @click="
              add_bonus = false;
              shopbonus(ex_shop);
            "
            >Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- остатки -->
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="add_balance"
    >
      <v-card>
        <v-toolbar dark :style="{ 'z-index': '203' }" color="orangeDark">
          <v-btn icon dark @click="add_balance = false">
            <v-icon>fas fa-arrow-down</v-icon>
          </v-btn>
          <v-progress-linear
            indeterminate
            absolute
            v-if="loading"
            height="5"
            color="white"
            class="align-self-end mb-n1"
          ></v-progress-linear>
        </v-toolbar>
        <v-card
          width="800"
          height="100%"
          :style="{ ...style.neobtn, 'border-radius': '0px' }"
          class="mx-auto pa-6 pt-9"
        >
          <v-row>
            <v-col cols="12">
              <div class="text-h5">загрузка остатков {{ ex_shop.ID }}</div>
            </v-col>
            <v-col cols="5">
              <v-alert
                colored-border
                class="mb-0"
                :style="style.neocard"
                color="blueLight"
                icon="looks_one"
                border="left"
              >
                <div>выберите дату</div>
              </v-alert>
              <v-date-picker
                no-title
                :style="style.neobtn"
                class="mt-3 ml-2"
                v-model="date_balance"
                locale="ru"
                color="orangeMain"
                type="date"
              ></v-date-picker>
              <v-alert
                colored-border
                class="mb-2 mt-10"
                :style="style.neocard"
                :color="
                  date_balance &&
                  data_balance.length &&
                  data_balance.reduce(
                    (acc, el) => (el[0] || el[1] ? [...acc, el] : acc),
                    []
                  ).length != 0 &&
                  data_balance.reduce(
                    (acc, el) =>
                      (el[0] || el[1]) && !(el[0] && el[1])
                        ? [...acc, el]
                        : acc,
                    []
                  ).length == 0
                    ? 'blueLight'
                    : 'grey5'
                "
                icon="looks_3"
                border="left"
              >
                <div>примените изменения</div>
              </v-alert>
              <div class="text-center">
                <v-btn
                  icon
                  class="text-center"
                  @click="save_balance(ex_shop)"
                  :disabled="
                    !date_balance ||
                    data_balance.reduce(
                      (acc, el) => (el[0] || el[1] ? [...acc, el] : acc),
                      []
                    ).length == 0 ||
                    data_balance.reduce(
                      (acc, el) =>
                        (el[0] || el[1]) && !(el[0] && el[1])
                          ? [...acc, el]
                          : acc,
                      []
                    ).length > 0
                  "
                >
                  <v-icon x-large color="blueLight">save</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="7">
              <v-alert
                colored-border
                class="mb-0"
                :style="style.neocard"
                :color="date_balance ? 'blueLight' : 'grey5'"
                icon="looks_two"
                border="left"
              >
                <div>
                  вставте данные в таблицу
                  <span class="blueLight--text">Ctrl+V</span>
                </div>
              </v-alert>
              <div @change="data_balance = [...data_balance]" class="pt-4 pl-2">
                <hot-table
                  v-if="show_balance"
                  :data="data_balance"
                  :colHeaders="['Артикул', 'Остаток', 'Результат']"
                  :rowHeaders="true"
                  :colWidths="120"
                  licenseKey="non-commercial-and-evaluation"
                ></hot-table>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="show_graph_w"
    >
      <v-card>
        <v-toolbar dark :style="{ 'z-index': '203' }" color="orangeDark">
          <v-btn icon dark @click="show_graph_w = false">
            <v-icon>fas fa-arrow-down</v-icon>
          </v-btn>

          <v-row>
            <v-col cols="3" class="pl-6 text-right py-0">
              <v-btn text small @click="set_date(local_month - 1, local_year)">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center py-0">
              {{ months[local_month - 1] }} {{ local_year }}
            </v-col>
            <v-col cols="3" class="text-left py-0">
              <v-btn text small @click="set_date(local_month + 1, local_year)">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-progress-linear
            indeterminate
            absolute
            v-if="loading"
            height="5"
            color="white"
            class="align-self-end mb-n1"
          ></v-progress-linear>
        </v-toolbar>
        <graph :local_month="local_month" :local_year="local_year"></graph>
      </v-card>
    </v-dialog>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="show_state_w"
    >
      <v-card>
        <v-toolbar dark :style="{ 'z-index': '203' }" color="orangeDark">
          <v-btn icon dark @click="show_state_w = false">
            <v-icon>fas fa-arrow-down</v-icon>
          </v-btn>

          <!-- листалка дат для зарплатной карточки на index-admin -->
          <v-row>
            <v-col cols="3" class="pl-6 text-right py-0">
              <v-btn text @click="set_date(local_month - 1, local_year)">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center py-0">
              {{ months[local_month - 1] }} {{ local_year }}
            </v-col>
            <v-col cols="3" class="text-left py-0">
              <v-btn text @click="set_date(local_month + 1, local_year)">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-progress-linear
            indeterminate
            absolute
            v-if="loading"
            height="5"
            color="white"
            class="align-self-end mb-n1"
          ></v-progress-linear>
        </v-toolbar>
        <state
          :local_month="local_month"
          :local_year="local_year"
          :key="user.id"
        ></state>

        <!-- полоса загрузки -->
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import MaskedInput from "vue-text-mask";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import WDay from "../components/working_days.vue";
import State from "../components/state.vue";
import { HotTable } from "@handsontable/vue";

const editattr = {
  props: ["attr", "plan"],
  data() {
    return {
      input1: this.attr,
    };
  },
  template: `
  <div>
<!-- редактирование текста -->
    <input id="edit"
      type="text"
      class="pa-0 ma-0 mb-n1"
      style="outline: none; background-color: white; border-radius: 5px; z-index: 9999; position: relative;"
      v-model="input1"/>

<!-- редактирование кнопули -->
        <v-btn icon small
          class="orangeMain"
          @click="$emit('close')">
          <v-icon
            color="white"> close </v-icon>
        </v-btn>
        <v-btn icon small
          class="greenLight mx-1"
          :disabled="input1=='' || isNaN(input1) ? true : false"
          @click="$emit('save', input1)">
          <v-icon
            color="white"> done </v-icon>
        </v-btn>
        <v-btn icon small v-if="plan"
          @click="$emit('plan')"
          class="blueGrey">
          <v-icon small
            color="white">fas fa-calculator</v-icon>
        </v-btn>
</div>

`,
};

export default {
  name: "Admin",
  components: {
    editattr: editattr,
    apexchart: VueApexCharts,
    graph: WDay,
    state: State,
    HotTable,
    MaskedInput,
  },
  data: () => ({
    panel: [], // список открытых магазинов
    data_balance: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ], // пустые строички для таблицы
    add_balance: false, // диалог для загрузки остатков
    date_balance: undefined, // дата загружаемых остатков
    show_balance: false, // флаг для показа таблицы загрузки с остатками
    local_month: new Date().getMonth() + 1, // локальный месяц для переключения в диалогах
    local_year: new Date().getFullYear(), // локальный год для перелючения в диалогах
    show_graph_w: false, // диалог с графиком
    show_state_w: false, // диалог с зарплатой
    // data: {}, // диалог с данными
    menu: 0,
    pic: {}, // словарь с картинками для бонус-рулонов
    add_user: false, // диалог добавления пользователя
    error_add: "",
    del_user: false, // диалог удаления пользователя
    date_del: undefined, // дата удаления бонуса
    new_user: {}, // для нового пользователя
    ex_user: {}, // выбраный пользователь
    users: [], // инфо по пользователям
    add_bonus: false, // диалог для добавления бонус-рулонов
    ex_shop: {}, // выбранный магазин
    bonus_art: [], // бонусные артикулы для отправки
    editing: {}, // накопитель для редактируемых параметров магазина
    show_graph: {}, // флаг отображения графиков магазинов
    graphs: {}, // наборы данных для графиков
    series: [
      {
        name: "продажи",
        type: "line",
        data: [],
      },
      {
        name: "план",
        type: "area",
        data: [],
      },
      {
        name: "безубыточность",
        type: "line",
        data: [],
      },
    ],
    series_people: [
      {
        name: "посетители",
        type: "bar",
        data: [],
      },
      {
        name: "конверсия",
        type: "line",
        data: [],
      },
    ],
    chartOptionsPeople: {
      colors: ["#E0E0E0", "#FFAA00"],
      fill: { opacity: [1, 0.8] },
      stroke: {
        width: [2, 0.7],
        curve: "smooth",
        dashArray: [0, 0],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val;
        },
      },
      xaxis: {
        position: "top",
        labels: { show: false },
        axisTicks: { show: false },
        axisBorder: { show: false },
        tooltip: { enabled: false },
      },
      yaxis: [
        {
          reversed: false,
          title: {
            text: "посетители",
          },
          labels: { show: false, style: { colors: ["#BDBDBD"] } },
          axisTicks: { show: false },
          axisBorder: { show: false },
          tickAmount: 1,
          min: 0,
        },
        {
          show: false,
          reversed: false,
          opposite: true,
          title: {
            text: "конверсия",
          },
          axisTicks: { show: false },
          axisBorder: { show: false },
          tickAmount: 1,
          min: 0,
        },
      ],
      tooltip: {
        y: [
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "";
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;
            },
          },
        ],
      },
      chart: {
        toolbar: {
          show: false,
        },
        stacked: true,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    },
    chartOptions: {
      colors: ["#FFAA00", "#868EAA", "#000000"],
      stroke: {
        width: [2, 2, 0.7],
        curve: "smooth",
        dashArray: [0, 0, 10],
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      fill: {
        opacity: [1, 0.1, 1],
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: [1, 0, 0],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0],
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM",
            day: "ddd dd",
            hour: "HH:mm",
          },
        },
      },
      yaxis: {
        title: {
          text: "выручка тыс.руб.",
        },
        tickAmount: 5,
        min: 0,
      },
      tooltip: {
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " тыс. руб.";
            }
            return y;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
      },
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        defaultLocale: "ru",
        locales: [
          {
            name: "ru",
            options: {
              months: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
              ],
              shortMonths: [
                "Янв",
                "Фев",
                "Мар",
                "Апр",
                "Май",
                "Июн",
                "Июл",
                "Авг",
                "Сен",
                "Окт",
                "Ноя",
                "Дек",
              ],
              days: [
                "Воскресенье",
                "Понедельник",
                "Вторник",
                "Среда",
                "Четверг",
                "Пятница",
                "Суббота",
              ],
              shortDays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              toolbar: {
                exportToSVG: "Сохранить SVG",
                exportToPNG: "Сохранить PNG",
                menu: "Меню",
                selection: "Выбор",
                selectionZoom: "Выбор с увеличением",
                zoomIn: "Увеличить",
                zoomOut: "Уменьшить",
                pan: "Перемещение",
                reset: "Сбросить увеличение",
              },
            },
          },
        ],
      },
    },
  }),

  computed: {
    ...mapState([
      "month", // глобальный месяц
      "year", // глобальный год
      "not_image", // заглушка на отстутствие изображения
      "statuses", // список статусов карьеры
      "style",
      "shop", // ид выбранного магазина
      "access",
      "back_domen",
      "months", // название месяцев строковые
      "loading", // бегунок загрузки страницы
      "data_tree", // дерево данных
      "user",
      "api_data",
      "positions",
    ]),
  },

  methods: {
    ...mapMutations([
      "change_user", // изменение глобального пользователя
      "change_shop", // изменение глобального магазина
      "change_show_help", // изменение названия выводимой подсказки
      "change_shopID_show", // изменение ид магазина для показа
    ]),

    ...mapActions(["add_api_data"]),

    check_exist() {
      if (!this.new_user.id) return true;
      if (!this.new_user.shopID) return false;
      for (let el of this.data_tree.regions) {
        for (let shop of el.shops) {
          if (shop.ID != this.new_user.shopID) continue;
          for (let user of [...shop.sellers, ...shop.storekeepers]) {
            if (user.id == this.new_user.id) return false;
          }
        }
      }
      return true;
    },

    async get_pic() {
      // получение картинок для бонусов
      let art = [];
      if (!this.data_tree.regions) return;
      for (let el of this.data_tree.regions) {
        for (let shop of el.shops) art = [...art, ...shop.art_ss];
      }
      art = [...new Set(art)];
      if (art.length == 0) return;
      this.add_api_data(art);
    },

    async userinfo() {
      // получение инфы по пользователям (нужно для добавления)
      let date = new Date();
      return axios
        .post(this.back_domen + "/service/userinfo", {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          shopID: "all",
        })
        .then((res) => res.data)
        .then((json) => (this.users = json["response"]));
    },

    async shopbonus(shop) {
      // загрузка бонусных артикулов
      return axios
        .post(this.back_domen + "/service/bonus", {
          year: this.year,
          month: this.month,
          shopID: shop.ID,
          value: this.bonus_art,
        })
        .then((res) => res.data)
        .then((json) => {
          shop.art_ss = json.response;
          this.bonus_art = [];
          this.get_pic();
        });
    },

    async shopupd(shop, data) {
      // обновление показателей магазина
      return axios
        .post(this.back_domen + "/service/planupdate", {
          year: this.year,
          month: this.month,
          shopID: shop.ID,
          data: data,
        })
        .then((res) => res.data)
        .then((json) => {
          // console.log(json.response);
          for (let el of this.data_tree.regions) {
            if (el.number != shop.ID.slice(0, 2)) continue;
            el.shops = el.shops.map((el) =>
              el.ID != json.response.ID
                ? el
                : {
                    balanceDate: el.balanceDate,
                    series: el.series,
                    ...json.response,
                  }
            );
          }
        });
    },

    update_editing(shopID, attr, value) {
      // обновление словаря редактирования
      this.editing[shopID][attr] = value;
      this.editing = Object.assign({}, this.editing);
      setTimeout(
        () =>
          document.getElementById("edit")
            ? document.getElementById("edit").focus()
            : "",
        100
      );
    },

    set_new_user(user) {
      // установка показателей для нового пользователя (для добавления)
      // console.log("!!!!", user);
      if (user) {
        this.new_user.phone = user.phone;
        this.new_user.name = user.name;
        this.new_user.id = user.id;
        this.new_user.position = user.position;
        this.new_user.position_name = this.positions[this.new_user.position];
        this.new_user.status = (
          this.users.filter(
            (el) =>
              el.id == this.new_user.id && el.position == this.new_user.position
          )[0] || {}
        ).status || 'стажер';
        // this.new_user.status = user.status;
      } else
        this.new_user = {
          ...this.new_user,
          id: undefined,
          position: "seller",
          status: "стажер",
          position_name: this.positions.seller,
        };
      if (!["seller", "cashier"].includes(this.new_user.position)){
        this.new_user.status = undefined;
        // console.log(this.new_user.position);
      }
      // console.log('new_user', this.new_user);
    },

    async update_user(user, key) {
      // добавление-удаление пользователя
      // console.log(Object.assign({}, user, {key: key}));
      return axios
        .post(
          this.back_domen + "/service/userupdate",
          Object.assign({}, user, { key: key })
        )
        .then((res) => res.data)
        .then((json) => {
          // console.log(json);
          let shopU = user.shopID ? user.shopID : user.email.split("@")[0];
          for (let el of this.data_tree.regions) {
            if (el.number != shopU.slice(0, 2)) continue;
            for (let shop of el.shops) {
              if (shop.ID != shopU) continue;
              let domain = process.env.VUE_APP_JWT_DOMEN || document.domain;
              if (key == "new") {
                let users = shop[user.position + "s"].filter(
                  (el) => el.id == user.id
                );
                if (users.length > 0) users[0].disabled = false;
                else shop[user.position + "s"].push(json["response"]);
              } else if (key == "del")
                shop[user.position + "s"].filter(
                  (el) => el.id == user.id
                )[0].disabled = true;
              this.update_user_claims(user, {
                [domain]: {
                  [shopU]: key == "new" ? user.position : "disabled",
                },
              });
            }
          }
        })
        .then(() => (this.add_user = false))
        .catch((err) => {
          console.log(err.response);
          this.error_add = err.response.data.error;
        });
    },

    async get_plan(shopID) {
      // рассчитанный план
      return axios
        .post(this.back_domen + "/service/getplan", {
          shopID: shopID,
          year: this.year,
          month: this.month,
        })
        .then((res) => res.data)
        .then((json) => {
          this.editing[shopID][".planmoney"] = json.response;
          this.editing = { ...this.editing };
        });
    },

    async update_user_claims(user, claims) {
      //добавление-удаление прав
      // console.log(userphone, claims);
      return axios
        .post("https://auth.oboi.ru/admin/add_upd_user", {
          username: user.phone,
          name: user.name,
          claims: claims,
        })
        .then((res) => res.data);
    },

    check_date() {
      //проверяем возможность правки по дате
      let date = new Date();
      let date2 = new Date(this.year, this.month - 1, date.getDate() + 1);
      if (date2 >= date) return true;
      return false;
    },

    graph_labels() {
      // строим метки для графика
      let date = new Date(this.year, this.month - 1, 1);
      let labels = [];
      while (date.getMonth() == this.month - 1) {
        labels.push(
          `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
            "0" + date.getDate()
          ).slice(-2)}`
        );
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 1
        );
      }
      this.chartOptions.labels = labels;
    },

    async data_graph() {
      // получаем данные для графика
      let admin = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "admin" ? { ...acc, [k]: v } : acc),
        {}
      );
      if (this.access.any != "admin" && Object.keys(admin).length == 0) return;
      return axios
        .post(this.back_domen + "/service/grafic", {
          year: this.year,
          month: this.month,
          shopID: this.access.any ? "all" : Object.keys(admin),
        })
        .then((res) => res.data)
        .then((json) => {
          this.graphs = json["response"];
        });
    },

    create_graph() {
      // строим графики по магазинам
      this.data_graph().then(() => {
        if (!this.data_tree.regions) return;
        for (let el of this.data_tree.regions) {
          for (let shop of el.shops) {
            if (this.graphs[shop.ID]) {
              let graph = this.graphs[shop.ID];
              shop.series = this.series.map((el) => {
                let el1 = Object.assign({}, el);
                el.name == "продажи"
                  ? (el1.data = graph.fact)
                  : el.name == "план"
                  ? (el1.data = graph.plan)
                  : (el1.data = graph.tbu);
                return el1;
              });
              shop.series_people = this.series_people.map((el) => {
                let el1 = Object.assign({}, el);
                el.name == "посетители"
                  ? (el1.data = graph.people)
                  : (el1.data = graph.conversion);
                return el1;
              });
              // this.show_graph[shop.ID] = true;
              // this.show_graph = Object.assign({}, this.show_graph);
            }
          }
        }
      });
    },

    find_users() {
      // ищем пользователя по имени
      let check = ["name", "phone"];
      if (this.new_user.id) return [];
      let checkUser = check.map((el) => this.new_user[el]).filter((el) => el);
      if (checkUser.length == 0) return [];
      let search = this.users.filter((el) => {
        if (el.disabled) return false;
        for (let c of check) {
          if (
            this.new_user[c] &&
            (!el[c] ||
              !~el[c].toLowerCase().indexOf(this.new_user[c].toLowerCase()))
          )
            return false;
        }
        return true;
      });
      if (search.length <= 20) return search;
      else return [];
    },

    set_date(month, year) {
      // расчет локальный даты
      let new_year = month < 1 ? year - 1 : month > 12 ? year + 1 : year;
      let new_month = new Date(year, month - 1, 1).getMonth() + 1;
      if (this.local_month != new_month) this.local_month = new_month;
      if (this.local_year != new_year) this.local_year = new_year;
    },

    save_balance(shop) {
      //сохраняем остатки по магазину
      let result = this.data_balance.reduce(
        (acc, el) =>
          el[0] && el[1] ? [...acc, { article: el[0], balance: el[1] }] : acc,
        []
      );
      // console.log('save', result);
      if (result.length == 0 || !this.date_balance) return;
      return axios
        .post(this.back_domen + "/service/addbalance", {
          shopID: shop.ID,
          balance: result,
          date: this.date_balance,
        })
        .then((res) => {
          if (res.data.response.length > 0)
            res = res.data.response.reduce(
              (acc, el) =>
                el.article ? { ...acc, [el.article]: el.complete } : acc,
              {}
            );
          // console.log('res', res);
          this.data_balance = this.data_balance
            .filter((el) => el[0])
            .map((el) => [el[0], el[1], res[el[0]] ? "загружено" : "ошибка"]);
          // console.log('balance', this.data_balance);
          this.show_balance = false;
          setTimeout(() => (this.show_balance = true), 100);
          if (this.data_balance.filter((el) => res[el[0]]).length > 0)
            shop.balanceDate = this.date_balance;
        });
    },

    opencard(shopid) {
      setTimeout(() => {
        // console.log('!!!');
        this.show_graph[shopid] = true;
        this.show_graph = Object.assign({}, this.show_graph);
      }, 500);
    },

    // phone_mask() {
    //     let x = this.new_user.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    //     if(x[1] !== '' && !x[2]) {
    //       this.new_user.phone = x[1] === '7' ? '+' + x[1] : '+' + 7;
    //     } else {
    //       this.new_user.phone = !x[3] ? '+' +  x[1] + x[2] :
    //                             '+' + x[1] + '(' + x[2] + ') ' + x[3] +
    //                              (x[3] ? '-' + x[4] : '') + (x[4] ? '-' + x[5] : '');
    //     }
    // }
  },

  created: function () {
    if (this.data_tree.regions) {
      for (let el of this.data_tree.regions) {
        for (let shop of el.shops) {
          this.editing[shop.ID] = {};
          this.show_graph[shop.ID] = false;
        }
      }
      this.get_pic();
      this.graph_labels();
      this.create_graph();
    }

    if (this.data_tree.regions) {
      let _shop = this.data_tree.regions.map((el) => el.shops).flat();
      // console.log(_shop);
      if (_shop.length == 1) {
        this.panel = 0;
        this.opencard(_shop[0].ID);
      }
    }
  },

  watch: {
    show_state_w() {
      // сброс локальной даты при открыти окна с зарплатой
      this.local_month = this.month;
      this.local_year = this.year;
    },

    show_graph_w() {
      // сброс локальной даты при октрыти окна с графиком
      this.local_month = this.month;
      this.local_year = this.year;
    },

    data_tree() {
      // обновляем данные при изменении дерева
      if (!this.data_tree.regions) return;
      for (let el of this.data_tree.regions) {
        for (let shop of el.shops) {
          this.editing[shop.ID] = {};
          if (this.show_graph[shop.ID]) {
            this.show_graph[shop.ID] = false;
            setTimeout(() => (this.show_graph[shop.ID] = true), 2000);
          } else this.show_graph[shop.ID] = false;
        }
      }
      this.get_pic();
      this.graph_labels();
      this.create_graph();
      if (this.panel) {
        let _shop = this.data_tree.regions.map((el) => el.shops).flat();
        this.panel = _shop.length > 1 ? this.panel : 0;
        if (_shop.length == 1) this.opencard(_shop[0].ID);
      }
    },

    add_balance() {
      // отрисовываем таблицу чуть позже для отрисовки в фокусе
      if (this.add_balance) {
        setTimeout(() => {
          this.show_balance = true;
        }, 500);
      }
      if (!this.add_balance) {
        this.show_balance = false;
        this.data_balance = [
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
          ["", "", ""],
        ];
        this.date_balance = undefined;
      }
    },

    add_user() {
      // уляляем данные нового пользователя после закрытия обновления
      if (!this.add_user) {
        this.new_user = {};
        this.error_add = "";
      } else this.set_new_user();
    },

    del_user() {
      // удаляем дату удаления после удаления пользователя
      if (!this.del_user) this.date_del = undefined;
    },
  },
};
</script>

<style scoped>
.v-date-picker-table {
  position: relative;
  padding: 0 12px;
  height: 100px;
}
</style>
