<template>
  <div id="app" class="red mx-0">
    <v-app
      v-if="
        $vuetify.breakpoint.name == 'xs' &&
          !Object.values(access).includes('admin')
      "
    >
      <AppAvatar
        v-if="!login_show"
        class="ml-2 mt-2"
        change_theme="change_theme"
      />
      <v-main>
        <v-container
          fluid
          class="px-1"
          style="padding-bottom: 80px; height: 100%"
        >
          <router-view> </router-view>
        </v-container>
      </v-main>
      <Footer2022 />
      <Login
        v-if="login_show"
        @finish="close_login_dialog"
        :login_dialog="login_show"
        :email_enabled="true"
        color_background="white"
        color_main="blueMain"
        color_text_main="white"
        color_second="white"
        color_text_second="blueMain"
        button_height="50"
      />
    </v-app>

    <v-app
      v-else-if="
        // username == '+79106894492' ||
        // username == '+71109992412' ||
        username == 'Artur.Kalinin@erismann.ru' ||
          username == 'Aleksey.Grabovskiy@erismann.ru' ||
          username == 'vog@oboi.ru' ||
          username == 'stenova@oboi.ru' ||
          username == 'ag-group@oboi.ru' ||
          username == 'sale_msk@ag-group.com.ru' ||
          username == 'arteks@oboi.ru'
      "
    >
      <!-- <AppAvatar
           v-if="!login_show"
           class="ml-2 mt-2"
           change_theme="change_theme"
           /> -->
      <NavigationBar />
      <v-main>
        <v-container
          fluid
          class="pa-0 mb-0"
          style="padding-bottom: 80px; height: 100.7%"
        >
          <router-view> </router-view>
        </v-container>
      </v-main>
      <Login
        v-if="login_show"
        @finish="close_login_dialog"
        :login_dialog="login_show"
        :email_enabled="true"
        color_background="white"
        color_main="blueMain"
        color_text_main="white"
        color_second="white"
        color_text_second="blueMain"
        button_height="50"
      />
    </v-app>

    <v-app v-else>
      <Header></Header>

      <router-view class="px-1"></router-view>
      <Footer></Footer>

      <!-- окно с подсказкой -->
      <v-snackbar v-model="showHelp" top timeout="-1" multi-line>
        <p v-html="help[show_help]"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="orangeMain"
            v-bind="attrs"
            @click="change_show_help('')"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <!-- окно для просмотра картинок -->
      <v-dialog v-model="open_picture" max-width="900">
        <v-card>
          <v-card-title class="pa-0"> </v-card-title>
          <v-card-text class="pa-1">
            <v-carousel>
              <v-carousel-item
                v-for="(item, i) in Object.entries(pic_art).reduce(
                  (acc, [k, v]) =>
                    ~k.indexOf('img') && v.s800x800
                      ? [...acc, v.s800x800]
                      : acc,
                  []
                )"
                :key="i"
                :src="item"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
          <v-card-actions class="pa-0"></v-card-actions>
        </v-card>
      </v-dialog>

      <!-- окно для просмотра инфы по магазину -->
      <v-dialog v-model="open_shop" max-width="900">
        <v-card>
          <v-card-title class="pa-0"> </v-card-title>
          <v-card-text class="pa-1">
            {{ all_shop_info[shopID_show] }}
          </v-card-text>
          <v-card-actions class="pa-0"></v-card-actions>
        </v-card>
      </v-dialog>
      <Login
        v-if="login_show"
        @finish="close_login_dialog"
        :login_dialog="login_show"
        :email_enabled="true"
        color_background="white"
        color_main="blueMain"
        color_text_main="white"
        color_second="white"
        color_text_second="blueMain"
        button_height="50"
      />
    </v-app>
  </div>
</template>

<script>
import Login from "./components/2022/Login";
import AppAvatar from "./components/2022/AppAvatar.vue";
import Footer2022 from "./components/2022/Footer.vue";
import NavigationBar from "./components/2022/NavigationBar.vue";
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "app",
  components: {
    AppAvatar,
    Footer,
    Header,
    Footer2022,
    Login,
    NavigationBar
  },
  data() {
    return {
      showHelp: false, // показывать или скрыть окно подсказки
      open_picture: false, // показывать или скрыть карусель картинки
      open_shop: false //показывать или скрвывать инфо по магазину
    };
  },
  computed: {
    ...mapState([
      "access_shop", // досутпные магазины
      "userinfo", // словарь с инфо по пользователям
      "user", // данные про пользователя
      "shop", // ид активного магазина
      "access",
      "username", // логин пользователя
      "show_help", // ключ подсказки для показа
      "help", // словарь с текстами подсказок
      "pic_art", // артикул для открытия в карусели
      "all_shop_info", //инфа по магазинам для всплывалки
      "shopID_show", // магазин для показа
      "month",
      "year",
      "data_tree",
      "monitor",
      "login_show",
      "style"
    ])
  },

  methods: {
    ...mapMutations([
      "login_on",
      "login_off",
      "change_user",
      "change_shop",
      "change_show_help",
      "change_pic_art",
      "change_shopID_show",
      "change_monitor",
      "change_ready"
    ]),

    change_theme() {
      if (this.$vuetify.theme.dark)
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark);
      else localStorage.removeItem("dark_theme");
    },

    close_login_dialog() {
      Promise.all([
        this.$store.dispatch("init_access_shop"),
        this.$store.dispatch("init_all_shop_info")
      ]);
      this.login_off();
      this.$router.go(this.$router.currentRoute);
    }
  },

  watch: {
    $route() {
      // при переходах
      if (this.data_tree.time - Math.floor(Date.now() / 1000) < 0)
        this.$store.dispatch("init_data_tree");
    },

    show_help() {
      // отслеживаем нужно ли показывать подсказку
      this.showHelp = this.show_help ? true : false;
    },

    access_shop() {
      // выбираем магазин после изменения списка доступных
      if (this.access_shop.length == 1) this.change_shop(this.access_shop[0]);
      else if (!~this.access_shop.indexOf(localStorage.getItem("shop")))
        this.change_shop("");
      else this.change_shop(localStorage.getItem("shop"));
    },

    userinfo() {
      // выбираем пользователя после изменения списка пользователей
      let user =
        Object.keys(this.user) > 0
          ? this.user
          : localStorage.getItem("supuser")
          ? JSON.parse(localStorage.getItem("supuser"))
          : {};
      let check = [];
      if (~["seller", "admin", "storekeeper"].indexOf(this.access[this.shop]))
        check = Object.values(this.userinfo).filter(
          el => el.phone.slice(-10) == this.username.slice(-10)
        );
      else if (this.access[this.shop] == "shop")
        check = Object.values(this.userinfo).filter(el => el.id == user.id);
      if (check.length > 0) {
        if (check[0].id != this.user.id) this.change_user(check[0]);
        else Object.assign(this.user, check[0]);
      } else this.change_user({});

      this.change_ready(true);
    },

    shop() {
      // обновляем инфу о пользователях при смене магазина
      if (this.shop) this.$store.dispatch("init_userinfo");
    },

    access() {
      // при смене прав доступа меняем даннные
      if (this.access.any == "admin") this.change_user({});
      this.$store
        .dispatch("init_access_shop")
        .then(() => this.$store.dispatch("init_userinfo"));
      this.$store.dispatch("init_data_tree");
    },

    pic_art() {
      // открытие окна при наличии картинки для открытия
      if (Object.keys(this.pic_art).length > 0) this.open_picture = true;
    },

    open_picture() {
      // удаление картинки при закрытии она с картинкой
      if (!this.open_picture) this.change_pic_art({});
    },

    open_shop() {
      // удаление магазина для открытия
      if (!this.open_shop) this.change_shopID_show("");
    },

    shopID_show() {
      // октрытие подсказки
      if (this.shopID_show) this.open_shop = true;
    },

    month() {
      // обновление при смене месяца
      this.$store.dispatch("init_data_tree");
    },

    year() {
      // обновление при смене года
      this.$store.dispatch("init_data_tree");
    }
  },

  created: function() {
    this.$store.dispatch("init_settings");
    Promise.all([
      this.$store.dispatch("init_access_shop"),
      this.$store.dispatch("init_all_shop_info")
    ]);
    setTimeout(() => this.$store.dispatch("init_data_tree"), 100);
    this.$vuetify.theme.dark = localStorage.getItem("dark_theme")
      ? true
      : false;
    this.change_monitor(this.$vuetify.breakpoint.name);

    let refresh = localStorage.getItem("refresh_token");
    let access = localStorage.getItem("access_token");
    if (!refresh || !access) {
      this.login_on();
    }
  }
};
</script>
<style scoped></style>
