<template>
  <Indicator name="смены" :progress="loading">
    <template v-slot:button>
      <v-btn
        rounded
        x-small
        text
        style=" padding-bottom: 1px;"
        min-width="96px"
        class="grey4"
        @click="show_calendar = !show_calendar"
      >
        <div
          style="text-transform: uppercase;"
          class="white--text text-12 font-weight-light"
        >
          {{ show_calendar ? "мои смены" : "в график" }}
        </div></v-btn
      >
    </template>
    <template v-slot:content>
      <div class="mt-4"></div>
      <v-skeleton-loader
        v-if="loading"
        type="list-item, list-item, list-item"
      ></v-skeleton-loader>
      <WorkDaysCalendar
        v-if="show_calendar"
        style="margin-left: -6%; margin-right: -6%; opacity: 0.9"
      />
      <div v-else>
        <div v-for="(el, idx) in workDays" :key="idx" class="d-flex mt-1">
          <ShopCheap :shop="el.shop" color="grey5" style="bottom: 4px;" />
          <div class="ml-3 text-14" :class="cascade_colors[idx]">
            {{ el.date | timedelta_ndays }}
          </div>
          <div class="ml-auto text-14" :class="cascade_colors[idx]">
            {{ el.date | date_weekday }}
          </div>
        </div>
      </div>
    </template>
  </Indicator>
</template>

<script>
// если el.date | timedelta_ndays == 'сегодня'  class="orangeMain--text"
// <ShopCheap shop="371" :color="grey4" /> если shop != основной магазин

import WorkDaysCalendar from "./WorkDaysCalendar";
import ShopCheap from "./ShopCheapComponent";
import Indicator from "./IndicatorComponent";
// import GoToButton from "./GoToButtonComponent";
import { back_request } from "../../mixins/back";
// import axios from "axios";
export default {
  name: "IndicatorWorkDays",
  mixins: [back_request],
  components: {
    // GoToButton,
    Indicator,
    ShopCheap,
    WorkDaysCalendar
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    }
  },
  data: () => ({
    show_calendar: false,
    cascade_colors: ["grey2--text", "grey3--text", "grey4--text"],
    workDays: [],
    loading: false
  }),
  computed: {},
  watch: {
    user_id() {
      this.add_workdays();
    }
  },
  methods: {
    add_workdays() {
      this.loading = true;
      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
      this.get_user_workdays_clear(
        this.user_id,
        new Date(date.getFullYear(), date.getMonth(), date.getDate(), 3)
      )
        .then(data => {
          this.workDays = data
            .filter(el => el.email)
            .map(el => {
              return {
                date: new Date(el.date.replace(/-/g, "/")),
                shop: el.email.split("@")[0]
              };
            })
            .filter(el => el.date >= date)
            .sort((a, b) => a.date - b.date)
            .slice(0, 3);
        })
        .then(() => (this.loading = false));
    }
  },
  created() {
    if (this.user_id) this.add_workdays();
  }
};
</script>

<style scoped>
.v-skeleton-loader {
  background: transparent !important;
}
</style>
