<template>
  <v-card flat>
    <v-row class="px-3" v-if="!articles.length">
      <v-col cols="4" v-for="id in 3" :key="id">
        <div>
          <v-skeleton-loader
            type="image"
            style="height: 110px"
            class="rounded-xl"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-3" v-else>
      <v-col cols="4" v-for="(el, id) in articles" :key="id">
        <div v-if="el.cover.s300x300">
          <v-img
            style="filter: grayscale(50%); opacity: 0.8"
            v-if="el.cover"
            :src="el.cover.s300x300"
            class="Article rounded-xl"
          >
          </v-img>
          <div class="text-10 px-1 mt-1">
            {{ el.name.toLowerCase() }}
          </div>
        </div>
        <div v-else></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Blog",
  props: {
    user_id: {
      type: String,
      required: false,
      default: "---",
      validator: v => typeof v === "string"
    }
  },
  data: () => ({
    articles: []
  }),

  computed: {
    ...mapState(["jwt_domen"])
  },

  methods: {
    async get_articles(count) {
      return (
        axios
          .post("https://media.service.oboi.ru/news/back/article/list", {
            section: ["sup"],
            cut: [
              "text",
              "section",
              "description",
              "tags",
              "sup_clients",
              "access_tags"
            ],
            sup_clients: [this.jwt_domen.split(".")[0]],
            active: true
          })
          .then(res => res.data)
          .then(
            json =>
              (this.articles = json["response"]
                .sort((a, b) => {
                  if (a.date_public < b.date_public) return 1;
                  if (a.date_public > b.date_public) return -1;
                  return 0;
                })
                .slice(0, count))
          )
          // .then(() => console.log(this.articles))
          .catch(err => console.warn(err))
      );
    }
  },
  created() {
    this.get_articles(3);
  }
};
</script>

<style scoped>
.Article {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  padding: 5px;
}
.ArticleText {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 1));
}
</style>
