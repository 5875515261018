<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pt-3 pb-12 px-md-6"
      width="1060"
      height="100%"
    >
      <v-tabs
        center-active
        height="100"
        background-color="grey6"
        v-model="tabs"
        slider-size="0"
        slider-color="grey6"
        color="black"
      >
        <v-tab
          class="mx-2 my-8"
          :style="style.neobtn"
          v-for="el in access_menu"
          @click="load_section(el.key)"
          :key="el.key"
        >
          {{ el.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-4 pt-md-3 grey6" v-model="tabs">
        <v-tab-item v-for="el in access_menu" :key="el.key">
          <!-- ввод посетителей -->
          <div v-if="el.key == 'peopleCount'" class="text-left ml-10">
            <v-btn
              outlined
              color="blueGrey"
              :style="{ ...style.neobtn, 'border-radius': '5px' }"
            >
              <download-excel
                :data="excel"
                :name="`посетители-${year}.${month}.xls`"
              >
                скачать excel
                <v-icon color="blueLight">save</v-icon>
              </download-excel>
            </v-btn>
          </div>
          <v-container grid-list-md v-if="el.key == 'peopleCount'">
            <v-layout row>
              <v-flex xs12>
                <v-btn
                  v-if="shopsID.length > 0"
                  color="orangeMain"
                  class="white--text ma-4"
                  @click="write()"
                  :disabled="changeData.length == 0"
                >
                  записать
                </v-btn>
                <v-data-table
                  dense
                  v-if="shopsID.length > 0"
                  :headers="
                    (headers = [
                      { text: '', value: 'date' },
                      ...shopsID.map(el => {
                        return { text: el, value: el };
                      })
                    ])
                  "
                  :items="dateList"
                  :items-per-page="999"
                  hide-default-footer
                  class="elevation-1 ma-4"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="(item, idx) in items"
                        :key="idx"
                        :class="{ grey6: ~[0, 6].indexOf(item.getDay()) }"
                      >
                        <td v-for="(elem, idx1) in headers" :key="idx1">
                          <template v-if="elem.value == 'date'">
                            {{ dateFormat(item) }}
                          </template>
                          <template v-else>
                            <input
                              v-model="data[elem.value][dateFormat(item)]"
                              :class="{
                                'orangeMain--text':
                                  !readData[elem.value] ||
                                  readData[elem.value][dateFormat(item)] !=
                                    data[elem.value][dateFormat(item)]
                              }"
                              type="text"
                              size="2"
                              style="border: none; outline: none;"
                              @input="
                                data[elem.value][dateFormat(item)] = checkValue(
                                  data[elem.value][dateFormat(item)]
                                )
                              "
                            />
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>

          <!-- купоны -->
          <div v-if="el.key == 'coupons'" class="text-left ml-10"></div>
          <v-container grid-list-md v-if="el.key == 'coupons'">
            <v-layout row>
              <v-flex xs12>
                <v-data-table
                  dense
                  v-if="shopsID.length > 0"
                  :headers="coupon_headers"
                  :items="coupons"
                  :items-per-page="999"
                  hide-default-footer
                  :sort-by="['st3_document']"
                  :sort-desc="[true]"
                  class="elevation-1 ma-4"
                >
                  <template v-slot:item.st3_sum="{ item }">
                    {{ item.st3_sum | money }}
                  </template>
                  <template v-slot:item._pay_st3="{ item }">
                    <v-btn
                      v-if="item._pay_st3 === false"
                      color="orangeMain"
                      x-small
                      outlined
                      @click="pay_coupon(item)"
                    >
                      оплачен
                    </v-btn>
                    <span v-else>обработан</span>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>

          <!--  исходные данные -->
          <v-container v-if="el.key == 'sourceData'">
            <v-row>
              <div class="grey4--text text-18 mx-2 mt-1">NPS</div>
              <div class="text-22 blueGrey--text">
                <!-- <span class="text-14">
                  <span class="text-14 grey4--text">от</span>%
                </span>
                {{ nps.all ? nps.all.NPS_real[0] : 0 }}
                <span class="text-14 ml-3">
                  <span class="text-14 grey4--text">до</span>%
                </span>
                {{ nps.all ? nps.all.NPS_real[1] : 0 }} -->
                <span class="text-14">
                  %
                </span>
                {{ nps.all ? nps.all.NPS : 0 }}
              </div>
            </v-row>
            <v-row v-for="region in data_tree.regions" :key="region.name">
              <v-col cols="12" class="text-18 grey4--text">
                {{ region.name }}
              </v-col>
              <v-col
                cols="12"
                v-for="shop in region.shops"
                :key="shop.ID"
                :style="style.neobtn"
                class="my-8 pa-4"
              >
                <v-row>
                  <v-col>
                    <div class="text-14 grey4--text mb-n2">{{ shop.name }}</div>
                    <div class="text-22 orange--text text--lighten-2">
                      {{ shop.ID }}
                    </div>
                    <div class="blueMain--text text-22 mb-n3 mt-n3">
                      {{ data_abc[shop.email] ? data_abc[shop.email].sale : 0 }}
                    </div>
                    <div class="text-14 grey4--text">рулоны</div>
                  </v-col>
                  <v-col class="text-end">
                    <div
                      class="text--lighten-3 text-72 mt-n5"
                      :class="
                        ('0' + shop.marjin.toFixed()).slice(-2) > 30
                          ? 'green--text'
                          : 'red--text'
                      "
                    >
                      <span class="text-24">%</span
                      >{{ ("0" + shop.marjin.toFixed()).slice(-2) }}
                    </div>
                    <div class="text-14 grey4--text mt-n8">рентабельность</div>
                  </v-col>
                  <v-col>
                    <div
                      class="red--text text--lighten-3 text-72 mt-n5"
                      :class="
                        ('0' + shop.conversion.toFixed()).slice(-2) > 10
                          ? 'green--text'
                          : 'red--text'
                      "
                    >
                      <span class="text-24">%</span
                      >{{ ("0" + shop.conversion.toFixed()).slice(-2) }}
                    </div>
                    <div class="text-14 grey4--text mt-n8 text-center">
                      конверсия
                    </div>
                  </v-col>
                  <v-col class="text-md-end">
                    <div class="grey4--text text-14 mb-n2">покупки</div>
                    <div class="text-22 blueGrey--text">
                      {{ shop.buyingCount | money }}
                    </div>
                    <div class="text-22 blueMain--text mb-n3 mt-n3">
                      {{ shop.peopleCount | money }}
                    </div>
                    <div class="grey4--text text-14">посетители</div>
                  </v-col>
                  <v-col class="text-end">
                    <div class="grey4--text text-14 mb-n2">ср.вал.чек</div>
                    <div class="text-22 blueGrey--text">
                      {{ ((shop.averCheck / 100) * shop.marjin) | money }}
                    </div>
                    <div class="text-22 blueMain--text mb-n3 mt-n3">
                      {{ shop.averCheck | money }}
                    </div>
                    <div class="grey4--text text-14">ср.чек</div>
                  </v-col>
                  <v-col class="text-end">
                    <div class="grey4--text text-14 mb-n2">на 1м2</div>
                    <div class="text-22 blueGrey--text">
                      {{ shop.space ? shop.moneyI / shop.space : 0 | money }}
                    </div>
                    <div class="text-22 blueMain--text mb-n3 mt-n3">
                      {{ shop.moneyI | money }}
                    </div>
                    <div class="grey4--text text-14">выручка</div>
                  </v-col>
                  <v-col class="text-no-wrap text-end">
                    <div class="grey4--text text-14 mb-n2">цена покупки</div>
                    <div class="text-22 blueGrey--text">
                      {{
                        shop.buyingCount
                          ? shop.adM / shop.buyingCount
                          : 0 | money
                      }}
                    </div>
                    <div class="text-22 blueMain--text mb-n3 mt-n3">
                      {{ shop.adM | money }}
                    </div>
                    <div class="grey4--text text-14">затраты на рекламу</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="grey4--text text-14 mb-n2">NPS</div>
                    <div class="text-22 blueGrey--text">
                      <!-- <span class="text-14">
                        <span class="text-14 grey4--text">от</span>%
                      </span>
                      {{
                        nps.shop && nps.shop[shop.ID]
                          ? nps.shop[shop.ID].NPS_real[0]
                          : 0
                      }}
                      <span class="text-14 ml-3">
                        <span class="text-14 grey4--text">до</span>%
                      </span>
                      {{
                        nps.shop && nps.shop[shop.ID]
                          ? nps.shop[shop.ID].NPS_real[1]
                          : 0
                      }} -->
                      <!--   <span class="text-14">]</span> -->
                      <span class="text-14">%</span>
                      {{
                        nps.shop && nps.shop[shop.ID]
                          ? nps.shop[shop.ID].NPS_real[1]
                          : 0
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <!--   мас-адреса-->

          <!--   <v-container grid-list-md v-if="el.key == 'macAddress'">
          <v-layout row>
            <v-flex xs12>
              <v-card :style="style.neocard" class="pa-2 px-6 mb-7">
                <div class="d-flex flex-row justify-space-between">
                  <div>
                    <v-checkbox large label="убрать персонал" color="orangeMain"
                    v-model="no_workers"></v-checkbox>
                  </div>
                  <div class="text-center grey--text text--darken-2 mt-4">
                    <download-excel :data="excel" :name="`${shop}-${year}.${month}.xls`">
                      скачать excel
                      <v-btn icon><v-icon class="ml-n1"  color="grey">save</v-icon>
                      </v-btn>
                    </download-excel>
                  </div>
                </div> -->
          <!-- <div class="d-flex flex-row justify-space-between grey--text text--darken-2">
                  <div>какой-то отбор 1</div>
                  <div>какой-то отбор 2</div>
                  <div>какой-то отбор 3</div>
                </div>
              </v-card>
              <v-row>
                <v-col cols="2"
                  v-for="device in devices" :key="device" class="blueGrey--text  mt-n1 text-center"> {{device}}
                </v-col> 
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>

      <--   акции -->

          <v-container grid-list-md v-if="el.key == 'stocks'">
            <v-layout row>
              <v-flex xs12>
                <v-card :style="style.neocard" class="pa-2 px-6 mb-7">
                  <v-row class="justify-center">
                    <v-col md="12">
                      <v-card-actions
                        class="d-flex flex-row justify-md-space-between"
                      >
                        <!--      переключатель -->
                        <v-switch
                          label="раскрыть"
                          v-model="turnSwitch"
                          hide-details
                          color="blueLight"
                          class="ml-md-4"
                        >
                        </v-switch>
                        <!--    добавление акции -->
                        <v-dialog v-model="dialog" max-width="800px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              class="blueGrey--text mt-2 me-md-14 ml-4"
                              v-bind="attrs"
                              v-on="on"
                              @click="dialog = true"
                            >
                              добавить
                            </v-btn>
                          </template>
                          <v-card class="pa-5 py-7">
                            <v-row>
                              <v-text-field
                                label="название акции"
                                class="mx-3"
                                color="orangeMain"
                                v-model="new_name"
                              ></v-text-field>
                            </v-row>
                            <v-row class="mt-4">
                              <v-col cols="12" md="2">
                                <v-dialog
                                  v-model="new_data_with"
                                  :close-on-content-click="false"
                                  max-width="500"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="new_picker_with"
                                      label="дата с"
                                      v-bind="attrs"
                                      v-on="on"
                                      color="orangeMain"
                                      outlined
                                      dense
                                      :style="{
                                        ...style.neocard,
                                        'border-radius': '5px'
                                      }"
                                      hide-details
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="new_picker_with"
                                    no-title
                                    locale="ru"
                                    color="orangeMain"
                                    type="date"
                                    @input="new_data_with = false"
                                  ></v-date-picker>
                                </v-dialog>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-dialog
                                  v-model="new_data_by"
                                  :close-on-content-click="false"
                                  max-width="500"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="new_picker_by"
                                      v-bind="attrs"
                                      v-on="on"
                                      color="orangeMain"
                                      label="дата по"
                                      dense
                                      outlined
                                      hide-details
                                      :style="{
                                        ...style.neocard,
                                        'border-radius': '5px'
                                      }"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="new_picker_by"
                                    no-title
                                    locale="ru"
                                    color="orangeMain"
                                    type="date"
                                    :min="new_picker_with"
                                    @input="new_data_by = false"
                                  ></v-date-picker>
                                </v-dialog>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-combobox
                                  :items="Object.keys(all_shop_info)"
                                  hide-details
                                  outlined
                                  multiple
                                  dense
                                  label="магазины"
                                  color="orangeMain"
                                  v-model="new_shops"
                                  item-color="orangeMain"
                                >
                                </v-combobox>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-combobox
                                  :items="Object.keys(indicators)"
                                  hide-details
                                  outlined
                                  multiple
                                  dense
                                  label="показатели"
                                  color="orangeMain"
                                  v-model="new_indicators"
                                  item-color="orangeMain"
                                >
                                </v-combobox>
                              </v-col>
                            </v-row>

                            <div class="blueGrey--text mt-6">
                              Период для сравнения:
                            </div>
                            <v-row class="mt-1">
                              <v-col cols="12" md="2">
                                <v-dialog
                                  v-model="new_period_with"
                                  :close-on-content-click="false"
                                  max-width="500"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="choice_with"
                                      label="дата с"
                                      v-bind="attrs"
                                      v-on="on"
                                      color="orangeMain"
                                      outlined
                                      dense
                                      :style="{
                                        ...style.neocard,
                                        'border-radius': '5px'
                                      }"
                                      hide-details
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="choice_with"
                                    no-title
                                    locale="ru"
                                    color="orangeMain"
                                    type="date"
                                    :disabled="!new_picker_with"
                                    :max="new_picker_with"
                                    @input="new_period_with = false"
                                  ></v-date-picker>
                                </v-dialog>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-dialog
                                  v-model="new_period_by"
                                  :close-on-content-click="false"
                                  max-width="500"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="choice_by"
                                      v-bind="attrs"
                                      v-on="on"
                                      color="orangeMain"
                                      label="дата по"
                                      dense
                                      outlined
                                      hide-details
                                      :style="{
                                        ...style.neocard,
                                        'border-radius': '5px'
                                      }"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="choice_by"
                                    no-title
                                    locale="ru"
                                    color="orangeMain"
                                    type="date"
                                    :max="new_picker_with"
                                    :min="choice_with"
                                    :disabled="!new_picker_by"
                                    @input="new_period_by = false"
                                  ></v-date-picker>
                                </v-dialog>
                              </v-col>
                            </v-row>

                            <v-card-actions class="mt-md-n12 mt-2 px-0">
                              <v-spacer></v-spacer>
                              <v-btn
                                color="orangeMain"
                                small
                                :disabled="
                                  new_name.length == 0 ||
                                    new_name.length < 5 ||
                                    new_shops == 0 ||
                                    new_indicators == 0 ||
                                    !new_picker_with ||
                                    !new_picker_by ||
                                    !choice_with ||
                                    !choice_by
                                "
                                outlined
                                @click="
                                  save_action();
                                  dialog = false;
                                "
                              >
                                сохранить
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col md="11">
                      <!--    список акций -->
                      <v-expansion-panels multiple flat v-model="panel">
                        <v-expansion-panel
                          expand
                          focusable
                          class="my-3"
                          v-for="(stock, i) in exist"
                          :key="i"
                          :style="{ ...style.ordneo, background: '#f2f2f2' }"
                        >
                          <v-expansion-panel-header class="grey4--text">
                            <v-row>
                              <v-col cols="12" md="3">
                                <div class="mb-2 text-16">
                                  название
                                </div>
                                <div class="text-16 blueMain--text">
                                  {{ stock.name || "----" }}
                                </div>
                              </v-col>
                              <v-col cols="12" md="2">
                                <div class="mb-2 text-16">
                                  дата с
                                </div>
                                <div
                                  class="text-18 green--text text--lighten-2"
                                >
                                  {{ stock.start_date | date2 }}
                                </div>
                              </v-col>
                              <v-col cols="12" md="2">
                                <div class="mb-2 text-16">
                                  дата по
                                </div>
                                <div
                                  class="text-18 green--text text--lighten-2"
                                >
                                  {{ stock.finish_date | date2 }}
                                </div>
                              </v-col>
                              <v-col cols="12" md="2">
                                <div class="mb-2 text-16">
                                  магазины
                                </div>
                                <div class="text-18 blueMain--text">
                                  {{
                                    stock.email
                                      .map(el => el.split("@")[0])
                                      .sort()
                                      .join(", ")
                                  }}
                                </div>
                              </v-col>
                              <v-col cols="12" md="3">
                                <div class="mb-2 text-16">
                                  показатели
                                </div>
                                <div class="text-18 blueMain--text mt-n1">
                                  {{
                                    stock.indicators
                                      .map(
                                        el =>
                                          (Object.entries(indicators).filter(
                                            el1 => el1[1] == el
                                          ) || ["None", "None"])[0][0]
                                      )
                                      .sort()
                                      .join(", ")
                                  }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="12" md="3" class="grey4--text"
                                >период для сравнения</v-col
                              >
                              <v-col cols="12" md="2">
                                <div class="grey4--text ml-n1">
                                  дата с
                                </div>
                                <div
                                  class="text-18 green--text text--lighten-2 mt-n1 ml-n1"
                                >
                                  {{ stock.start_period | date2 }}
                                </div>
                              </v-col>
                              <v-col cols="12" md="2">
                                <div class="grey4--text ml-n3">
                                  дата по
                                </div>
                                <div
                                  class="text-18 green--text text--lighten-2 mt-n1 ml-n3"
                                >
                                  {{ stock.finish_period | date2 }}
                                </div>
                              </v-col>

                              <v-col
                                md="2"
                                class="d-none d-sm-flex text-20 grey4--text ml-n3"
                              >
                                <div>было</div>
                              </v-col>
                              <v-col
                                md="3"
                                class="d-none d-sm-flex text-20 grey--text text--lighten-1 ml-n1"
                              >
                                <div>стало</div>
                              </v-col>

                              <v-col
                                md="2"
                                class="d-md-none d-flex justify-end text-20 grey--text text--lighten-1"
                              >
                                <div class="mx-4">было</div>
                                <div>стало</div>
                              </v-col>
                            </v-row>
                            <v-simple-table
                              dense
                              :style="{
                                background: '#f2f2f2'
                              }"
                              class="mt-3"
                            >
                              <template v-slot:default>
                                <tbody>
                                  <tr
                                    v-for="el in stock.email
                                      .reduce(
                                        (acc, el) =>
                                          el
                                            ? [
                                                ...acc,
                                                ...stock.indicators.map(el1 => [
                                                  el,
                                                  el1
                                                ])
                                              ]
                                            : acc,
                                        []
                                      )
                                      .sort()"
                                    :key="el[0] + el[1]"
                                    class="blueMain--text"
                                  >
                                    <td class="px-0">
                                      {{ el[0].split("@")[0] }}
                                    </td>
                                    <td width="268">
                                      {{
                                        (Object.entries(indicators).filter(
                                          e => e[1] == el[1]
                                        ) || ["None", "None"])[0][0]
                                      }}
                                    </td>
                                    <td width="140">
                                      {{
                                        (stock.data_period.filter(
                                          e => e.email == el[0]
                                        )[0] || {})[el[1]] || 0
                                      }}
                                    </td>
                                    <td width="210" class="text-start">
                                      {{
                                        (stock.data.filter(
                                          e => e.email == el[0]
                                        )[0] || {})[el[1]] || 0
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>

                            <!--    удаление акции -->
                            <v-row class="justify-end">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    small
                                    class="my-5"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon
                                      color="grey5"
                                      class="ml-4"
                                      @click="del_action(stock.id_action)"
                                    >
                                      delete_outlined
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>удалить акцию</span>
                              </v-tooltip>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>

          <!-- NPS -->
          <v-container grid-list-md v-if="el.key == 'surveyNps'">
            <v-layout row>
              <v-flex xs12>
                <v-row class="justify-center">
                  <v-col md="9">
                    <v-expansion-panels multiple flat v-model="panel">
                      <v-expansion-panel
                        expand
                        focusable
                        v-for="(order, i) in tasks_nps"
                        :key="i"
                        class="my-3 "
                        :style="style.ordneo"
                      >
                        <v-expansion-panel-header>
                          <v-row>
                            <v-col md="3" sm="3" xs="3">
                              <div class="text-14 mb-2 blueGrey--text">
                                {{ order.weekday.toUpperCase() }}
                                <span class="text-18 blueMain--text">{{
                                  order.date | date_short
                                }}</span>
                              </div>
                              <div class="text-16 grey--text">
                                {{ order.sum | money }} руб.
                              </div>
                            </v-col>
                            <v-col class="text-16 text-center blueGrey--text"
                              >{{ order.phone | phone }}
                              <div class="text-14 grey--text mt-2">
                                {{ order.name }}
                              </div>
                            </v-col>
                            <v-col>
                              <div class="text-16 text-center blueGrey--text">
                                {{
                                  order.sellers.map(el => el.name).join(", ")
                                }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mt-4">
                          <div
                            v-if="
                              typeof order.history === 'object' &&
                                order.history.length > 0
                            "
                            class="px-5 pt-3 pb-5 mb-5 white rounded"
                          >
                            <!-- <div> -->
                            <!--   {{ order.history }} -->
                            <!-- </div> -->
                            <div class="text-center mb-2">
                              были звонки за последние 6 месяцев
                            </div>
                            <div
                              v-for="histOrder of order.history"
                              :key="histOrder.date"
                              class="d-flex justify-space-between text-16 white--bg"
                            >
                              <div>
                                {{ histOrder.complete_date.split(" ")[0] }}
                              </div>
                              <div>
                                {{ histOrder.comment }}
                              </div>
                              <div>
                                {{ histOrder.source }}
                              </div>
                              <div>
                                {{ histOrder.nps }}
                              </div>
                            </div>
                          </div>
                          <v-row>
                            <v-col cols="12" md="9">
                              <v-textarea
                                label="Комментарий..."
                                outlined
                                height="75px"
                                v-model="order.comment"
                                color="orangeMain"
                              >
                              </v-textarea>
                            </v-col>
                            <v-col md="1"></v-col>
                            <v-col cols="12" md="2" class="text-end">
                              <v-text-field
                                v-model.number="order.nps"
                                name="name"
                                label="NPS"
                                id="id"
                                outlined
                                dense
                                color="orangeMain"
                                clearable
                                maxlength="2"
                                @keydown="check_nps($event)"
                              ></v-text-field>
                              <v-btn
                                color="blueMain"
                                outlined
                                x-small
                                class="mt-n7"
                                @click="save_nps(order, true)"
                              >
                                отказ
                              </v-btn>
                              <v-btn
                                color="blueMain"
                                outlined
                                x-small
                                class="ml-n6 mt-n4"
                                @click="dont_call(order)"
                                v-show="!order.comment"
                              >
                                не дозвонился
                              </v-btn>
                            </v-col>
                          </v-row>
                          <!-- <v-row>
                            <v-col md=9>
                              <v-textarea
                                :placeholder="text_nps"
                                persistent-placeholder
                                outlined
                                v-model="order.convenience"
                                height="70px"
                                color="orangeMain"
                              >
                              </v-textarea>
                            </v-col>
                          </v-row> -->
                          <div class="text-16 blueGrey--text my-2">
                            Откуда Вы о нас узнали?
                          </div>
                          <v-row>
                            <v-col
                              cols="3"
                              v-for="tag in radio_find"
                              :key="tag"
                              class="py-0"
                            >
                              <v-radio-group
                                dense
                                column
                                hide-details
                                v-model="order.source"
                              >
                                <v-radio
                                  color="red lighten-2"
                                  class="mb-n8"
                                  :value="tag"
                                ></v-radio>
                                <p
                                  class="ml-9 mt-2 text-14 grey--text text--darken-1"
                                >
                                  {{ tag }}
                                </p>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-row class="mt-12">
                            <v-col
                              cols="3"
                              v-for="(art, k) in order.products"
                              :key="k"
                              class="d-flex flex-row"
                            >
                              <div>
                                <v-img
                                  :src="
                                    api_data[art.article]
                                      ? api_data[art.article].imgFrontal
                                          .s300x300 || not_image
                                      : not_image
                                  "
                                  width="30px"
                                  height="30px"
                                  :style="{ 'border-radius': '5px' }"
                                >
                                </v-img>
                              </div>
                              <div
                                class="d-flex align-self-end text-14 ms-2 grey--text"
                              >
                                {{ art.article }} x {{ art.count }} шт.
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="d-flex flex-column align-end mt-6">
                              <v-btn
                                color="green lighten-1"
                                outlined
                                x-small
                                class="ml-n6 mt-n4"
                                @click="save_nps(order)"
                                v-show="
                                  order.comment && order.nps && order.nps <= 10
                                "
                              >
                                сохранить
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container grid-list-md v-if="el.key == 'tools'">
            <div class="mb-12 mt-4">
              <v-row class="pa-0">
                <v-col
                  v-for="post in article_list"
                  :key="post.key"
                  cols="12"
                  md="6"
                  class="pa-0"
                >
                  <v-card
                    dark
                    class="ma-md-1"
                    elevation="6"
                    v-if="post.name"
                    @click="
                      toolsDialog = true;
                      active_article = post;
                    "
                  >
                    <v-img
                      :src="post.cover.s600x400 || post.cover.main || not_image"
                      height="300px"
                      max-weight="mx-auto"
                    >
                      <v-card-title
                        v-text="post.name"
                        class="text-md-h5 orangeMain"
                        v-bind:style="{ 'text-shadow': '1px 1px 1px black' }"
                      >
                      </v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="toolsDialog" width="980">
      <v-card>
        <v-card-title class="text-h5 blueMain--text">
          {{ active_article.name }}
          <v-row justify="end" class="mb-1">
            <v-btn icon @click="toolsDialog = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text
          class="black--text mt-3 text-16"
          v-html="active_article.text"
        >
          <!-- {{ active_article }} -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import "../../public/quill.css"; // import styles

export default {
  name: "Marketing",
  components: {
    downloadExcel: JsonExcel
  },
  data: () => ({
    tabs: null,
    data: {}, // данные с импутов
    readData: {}, // данные из базы
    data_abc: {}, // данные из абс анализа
    tabsName: [
      {
        name: "каталог инструментов",
        key: "tools",
        access: ["admin"],
        load: ["article"]
      },
      { name: "монитор", key: "sourceData", access: ["admin"] },
      { name: "посетители", key: "peopleCount", access: ["admin"] },
      // {name: 'mac-адреса', key: 'macAddress'},
      {
        name: "акции",
        key: "stocks",
        access: ["admin"],
        load: ["find_action"]
      },
      {
        name: "NPS",
        key: "surveyNps",
        access: ["admin", "seller", "shop"],
        load: ["nps_list"]
      },
      {
        name: "купоны",
        key: "coupons",
        access: ["admin"],
        domain: ["tapeti.oboi.ru"],
        load: ["read_coupons"]
      }
    ], // имена вкладок
    mac_data: {}, // данные по мак адресам
    no_workers: false,
    turnSwitch: false, //раскрытие списка с акциями

    dialog: false,
    panel: [], //список развернутых акций
    shops: ["331", "371", "372", "373"],
    indicators: { "средний чек": "average_check", маржа: "marjin" },

    exist: [],
    new_name: "",
    new_shops: [],
    new_indicators: [],

    new_data_with: false, // диалог добавления новой акции - выбор "дата с"
    new_picker_with: undefined, // диалог добавления новой акции - открытие календаря "дата с"
    new_data_by: false, // диалог добавления новой акции - выбор "дата по"
    new_picker_by: undefined, // диалог добавления новой акции - открытие календаря "дата с"
    new_period_with: false, // диалог добавления новой акции - выбор периода "дата с"
    choice_with: undefined, // диалог добавления новой акции - открытие календаря период "дата с"
    new_period_by: false, // диалог добавления новой акции - выбор периода "дата по"
    choice_by: undefined, // диалог добавления новой акции - открытие календаря период "дата с"
    article_list: [],
    active_article: {},
    tasks_nps: [],
    coupons: [], // купоны в периоде
    coupon_headers: [
      { text: "ключ", value: "key" },
      { text: "выдан", value: "st2_phone" },
      { text: "активирован", value: "st3_phone" },
      { text: "основание", value: "st3_document" },
      { text: "сумма", value: "st3_sum", align: "right" },
      { text: "магазин", value: "st3_user" },
      { text: "статус", value: "_pay_st3" }
    ],

    nps: {},
    toolsDialog: false,
    text_nps:
      "Удобна ли наша программа лояльности? Хотели бы Вы получать кешбэк вместо скидки?",
    radio_find: [
      "радио",
      "телевидение",
      "листовки/буклеты/визитки",
      "интернет",
      "наружная реклама (щиты, билборды)",
      "узнал от знакомых",
      "увидел вывеску/шел мимо",
      "затрудняюсь ответить"
    ]
  }),

  computed: {
    ...mapState([
      "back_domen",
      "user",
      "style",
      "shop",
      "api_data",
      "not_image",
      "all_shop_info",
      "month",
      "year",
      "username",
      "data_tree",
      "username",
      "user",
      "access",
      "jwt_domen"
    ]),

    access_list() {
      return [
        ...new Set(
          Object.entries(this.access)
            .filter(el => el[0] != "root")
            .map(el => el[1])
        )
      ];
    },

    access_menu() {
      let result = this.tabsName
        .filter(el =>
          el.only
            ? el.access.join() == this.access_list.join()
            : el.access.filter(x => this.access_list.includes(x)).length > 0
        )
        .filter(el => !el.domain || el.domain == this.jwt_domen);
      return result;
    },

    shopsID() {
      // список доступных магазинов для правки
      return Object.keys(this.all_shop_info);
    },

    dateList() {
      // список дней в месяце
      let date = new Date(this.year, this.month - 1, 1);
      let result = [];
      while (date.getMonth() == this.month - 1) {
        result.push(date);
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 1
        );
      }
      return result;
    },

    changeData() {
      // данные для изменения
      // console.log('data', this.data);
      return Object.entries(this.data).reduce(
        (acc, el) =>
          Object.keys(el[1]).length > 0
            ? [
                ...acc,
                ...Object.entries(el[1]).reduce(
                  (acc1, el1) =>
                    (!this.readData[el[0]] ||
                      this.readData[el[0]][el1[0]] != +el1[1]) &&
                    el1[1] != ""
                      ? [
                          ...acc1,
                          {
                            email: this.all_shop_info[el[0]].email,
                            people: +el1[1],
                            date: this.stringToDate(el1[0]),
                            author: this.username
                          }
                        ]
                      : acc1,
                  []
                )
              ]
            : acc,
        []
      );
    },

    devices() {
      if (!this.mac_data.data) return [];
      if (!this.no_workers) return Object.keys(this.mac_data.data).slice(0, 54);
      return Object.entries(this.mac_data.data)
        .filter(el => el[1] < this.mac_data.limit)
        .map(el => el[0])
        .slice(0, 54);
    },

    excel() {
      return this.dateList.map(el => {
        return {
          Дата: this.dateFormat(el),
          ...this.shopsID.reduce(
            (acc, el1) =>
              el1
                ? {
                    ...acc,
                    ["Магазин " + el1]: this.data[el1][this.dateFormat(el)]
                  }
                : acc,
            {}
          )
        };
      });
    }
  },

  methods: {
    ...mapActions(["add_api_data"]),

    load_section(key) {
      let data = this.tabsName.filter(el => el.key == key);
      if (data.length && data[0].load) {
        for (let el of data[0].load) {
          this[el]();
        }
      }
    },

    dateFormat(date) {
      // приводим дату к читаемому формату
      return `${("0" + date.getDate()).slice(-2)}.${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}.${date.getFullYear()}`;
    },

    stringToDate(date) {
      // обратно из строки в дату
      let res = date.split(".");
      return new Date(+res[2], +res[1] - 1, +res[0] + 1);
    },

    checkValue(value) {
      // проверяем чтобы данные были числом
      if (isNaN(+value)) return value.slice(0, value.length - 1);
      return value;
    },

    async write() {
      // пишем в базу
      let result = [...this.changeData];
      // console.log('result', result);
      if (result.length == 0) return;
      return axios
        .post(this.back_domen + "/service/update/people", { data: result })
        .then(res => res.data)
        .then(json => {
          // console.log(json.response);
          let newData = json.response.reduce(
            (acc, el) =>
              el.complete
                ? {
                    ...acc,
                    [el.email.split("@")[0]]: {
                      ...(acc[el.email.split("@")[0]] || {}),
                      [this.dateFormat(
                        new Date(el.date.split("T")[0])
                      )]: el.people
                    }
                  }
                : acc,
            {}
          );
          // console.log(newData);
          for (let el in this.data) this.data[el] = {};
          this.data = { ...this.data };
          for (let el in newData)
            this.readData[el] = {
              ...(this.readData[el] || {}),
              ...newData[el]
            };
          this.readData = { ...this.readData };
        });
    },

    async read() {
      // читаем существующие
      return axios
        .post(this.back_domen + "/service/people", {
          month: this.month,
          year: this.year
        })
        .then(res => res.data)
        .then(json => {
          if (json.response.length == 0) return;
          let newData = json.response.reduce(
            (acc, el) =>
              el
                ? {
                    ...acc,
                    [el.email.split("@")[0]]: {
                      ...(acc[el.email.split("@")[0]] || {}),
                      [this.dateFormat(new Date(el.date))]: el.people
                    }
                  }
                : acc,
            {}
          );
          for (let el in newData)
            this.readData[el] = {
              ...(this.readData[el] || {}),
              ...newData[el]
            };
          this.readData = { ...this.readData };
          // console.log('read', this.readData);
        });
    },

    async mac() {
      // получаем мак адреса
      if (!this.all_shop_info[this.shop]) return;
      return axios
        .post(this.back_domen + "/service/peoplemac", {
          month: this.month,
          year: this.year,
          email: this.all_shop_info[this.shop].email
        })
        .then(res => res.data)
        .then(json => (this.mac_data = json.response));
    },

    async get_abc_data() {
      // группируем все до категории
      // this.data_abc = {};
      return axios
        .post(this.back_domen + "/service/abc", {
          startdate: new Date(this.year, this.month - 1, 2),
          finishdate: new Date(this.year, this.month, 2),
          group: ["all"],
          category: "Обои"
        })
        .then(res => res.data)
        .then(json => {
          this.data_abc = json["response"].goods.reduce(
            (acc, el) => (el.email ? { ...acc, [el.email]: el } : acc),
            {}
          );
          // console.log('abc', this.data_abc);
        });
    },

    save_action() {
      let new_action = {
        name: this.new_name,
        start_date: new Date(this.new_picker_with),
        finish_date: new Date(this.new_picker_by),
        start_period: new Date(this.choice_with),
        finish_period: new Date(this.choice_by),
        email: this.new_shops.map(el => this.all_shop_info[el].email),
        indicators: this.new_indicators.map(el => this.indicators[el])
      };
      console.log(new_action);
      return axios
        .post(this.back_domen + "/service/add_mark_action", new_action)
        .then(res => res.data)
        .then(() => {
          // this.exist = [...this.exist, json.response];
          this.new_name = "";
          this.new_picker_with = undefined;
          this.new_picker_by = undefined;
          this.choice_with = undefined;
          this.choice_by = undefined;
          this.new_shops = [];
          this.new_indicators = [];
        })
        .then(() => this.find_action());
    },

    find_action() {
      return axios
        .post(this.back_domen + "/service/mark_action", {
          start_date: new Date(this.year, this.month - 1, 1, 3),
          finish_date: new Date(this.year, this.month, 1, 3)
        })
        .then(res => res.data)
        .then(json => (this.exist = json.response));
    },

    del_action(id) {
      return axios
        .post(this.back_domen + "/service/upd_mark_action", {
          id_action: id,
          deleted: true
        })
        .then(res => res.data)
        .then(json => {
          this.exist = this.exist.filter(
            el => el.id_action != json.response.id_action
          );
          if (json.response.id_action) this.panel = [];
        });
    },

    pay_coupon(data) {
      return axios
        .post(this.back_domen + "/service/coupon", { pay: { key: data.key } })
        .then(res => res.data)
        .then(json => {
          if (!json.response.error && !json.error) {
            data._pay_st3 = true;
            this.coupons = [...this.coupons];
          }
        });
    },

    nps_res() {
      return axios
        .post(this.back_domen + "/service/nps_result", {
          month: this.month,
          year: this.year
        })
        .then(res => res.data)
        .then(json => {
          this.nps = json.response || {};
        });
    },

    read_coupons() {
      return axios
        .post(this.back_domen + "/service/coupon", {
          read: { year: this.year, month: this.month }
        })
        .then(res => res.data)
        .then(json => {
          if (json && !json.response.error && !json.error) {
            this.coupons = json.response.filter(el => !el.event);
          }
        });
    },

    save_nps(data, cancel) {
      return axios
        .post(this.back_domen + "/service/save_nps", {
          ...data,
          user: this.user.phone || this.username,
          cancel: cancel ? true : false
        })
        .then(res => res.data)
        .then(json => {
          this.panel = json.response.complete
            ? this.panel.filter(el => el != this.tasks_nps.indexOf(data))
            : this.panel;
          json.response.complete
            ? (this.tasks_nps = [...this.tasks_nps.filter(el => el != data)])
            : undefined;
        });
    },

    dont_call(data) {
      return axios
        .post(this.back_domen + "/service/save_nps", {
          ...data,
          calls: [
            ...data.calls,
            {
              user: this.user.phone || this.username,
              date: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours() + 3
              )
            }
          ]
        })
        .then(res => res.data)
        .then(json => {
          this.panel =
            json.response?.calls.length > data.calls.length
              ? this.panel.filter(el => el != this.tasks_nps.indexOf(data))
              : this.panel;
          json.response?.calls.length > data.calls.length
            ? (this.tasks_nps = [...this.tasks_nps.filter(el => el != data)])
            : undefined;
        });
    },

    nps_list() {
      if (!this.shop) return;
      return axios
        .post(this.back_domen + "/service/nps_task", {
          month: this.month,
          year: this.year,
          shopID: this.shop
        })
        .then(res => res.data)
        .then(json => (this.tasks_nps = json.response));
    },

    check_nps(event) {
      if (event.keyCode != 8) if (isNaN(event.key)) event.preventDefault();
    },

    article() {
      return axios
        .post("https://media.service.oboi.ru/news/back/article/list", {
          section: ["marketing"],
          active: true
        })
        .then(res => res.data)
        .then(json => (this.article_list = json.response));
    }
  },

  watch: {
    shopsID() {
      // делаем словарь для импутов после получения магазинов
      for (let el of this.shopsID) if (!this.data[el]) this.data[el] = {};
      this.data = { ...this.data };
    },

    readData() {
      //заливаем полученные данные в словарь для импутов
      for (let el in this.readData)
        this.data[el] = { ...this.data[el], ...this.readData[el] };
      this.data = { ...this.data };
    },

    month() {
      // обновляем при переключении месяца
      if (this.access_list.includes("admin")) {
        this.read();
        this.get_abc_data();
        this.find_action();
        this.read_coupons();
        this.nps_res();
      }
      this.nps_list();
    },

    year() {
      // обновляем при переключении месяца
      if (this.access_list.includes("admin")) {
        this.read();
        this.get_abc_data();
        this.find_action();
        this.read_coupons();
        this.nps_res();
      }
      this.nps_list();
    },

    shop() {
      // обновляем при смене магазина
      this.nps_list();
    },

    turnSwitch() {
      // скрытие и раксрытие заказов по переключателю
      if (this.turnSwitch)
        this.panel = [...Array(this.exist.length)].map((k, i) => i);
      else this.panel = [];
    },

    orders() {
      // скрытие и раксрытие заказов при изменении списка заказов
      if (this.turnSwitch)
        this.panel = [...Array(this.exist.length)].map((k, i) => i);
      else this.panel = [];
    },

    tasks_nps() {
      let articles = this.tasks_nps
        .map(el => el.products)
        .flat()
        .map(el => el.article);
      this.add_api_data(articles);
    }

    // panel () {
    //   console.log(this.panel);
    // }
  },

  created: function() {
    for (let el of this.shopsID) if (!this.data[el]) this.data[el] = {};
    if (this.access_list.includes("admin")) {
      this.read().then(() => this.get_abc_data());
      // this.find_action();
      // this.read_coupons();
      this.nps_res();
      this.article();
    }
    // this.nps_list();
    // .then(() => this.mac());
    // this.get_abc_data();
    // this.mac();
  }
};
</script>
