<template>
  <div>
    <v-app-bar app dark dense color="blueMain" class="d-print-none">
      <v-icon @click.stop="showMenu = !showMenu">
        {{ showMenu ? "fas fa-arrow-left" : "fas fa-arrow-right" }}
      </v-icon>
      <!--    <a>
      <v-img class="mt-4"
            @click.stop="showMenu = !showMenu" 
            src="https://251317.selcdn.ru/oboi.ru/background/red%20boll.png" 
            max-width="55" max-height="55"
            :style="{'z-index':'999'}">
      </v-img>
    </a> -->

      <!-- календарь -->
      <div
        class="ml-6 ml-md-16 d-flex flex-row"
        v-if="!~no_date_comp.indexOf($route.name)"
      >
        <v-btn
          fab
          text
          x-small
          color="white"
          class="d-none d-sm-flex"
          @click="set_date(month - 1, year)"
          :style="style.toggle"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn text @click="startMenu = true">
          <v-icon @click="startMenu = true" class="mr-1 blueGrey--text"
            >mdi-calendar</v-icon
          >
          <div>{{ start }}</div>
        </v-btn>

        <v-menu offset-y v-model="startMenu">
          <v-card class="text-center pt-3">
            <v-date-picker
              v-model="start"
              no-title
              locale="ru"
              color="orangeMain"
              type="month"
            ></v-date-picker>
          </v-card>
        </v-menu>
        <v-btn
          :style="style.toggle"
          class="d-none d-sm-flex"
          text
          x-small
          fab
          color="white"
          @click="set_date(month + 1, year)"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <!-- имя пользователя -->

      <template v-if="user">
        <div class="text-14">
          {{ user.mininame }}
          <span
            v-if="user.workNow == 'open'"
            class="orange--text font-weight-bold "
          >
            &deg;
          </span>
        </div>
      </template>

      <!-- выбор пользователя -->
      <!-- <v-btn text fab small> -->
      <div v-show="user_menu">
        <template>
          <v-menu
            offset-y
            :disabled="access[shop] != 'shop' ? true : false"
            transition="scale-transition"
          >
            <template v-if="user && user.name" v-slot:activator="{ on, attrs }">
              <v-avatar
                :style="style.btntop"
                size="37"
                class="ml-1"
                v-on="on"
                v-bind="attrs"
              >
                <span class="white--text text-14">{{
                  user.name
                    .split(" ")
                    .map(n => n[0])
                    .join("")
                }}</span>
              </v-avatar>
            </template>
            <template v-else v-slot:activator="{ on, attrs }">
              <v-avatar v-on="on" v-bind="attrs" size="42" class="mr-2">
                <v-img :src="no_logo"></v-img>
              </v-avatar>
            </template>

            <v-card
              v-if="Object.values(userinfo).length > 0"
              class="pa-2"
              color="grey6"
            >
              <v-list color="grey6">
                <v-list-item
                  v-for="elem in Object.values(userinfo).filter(
                    el => el.id != user.id
                  )"
                  :key="elem.id"
                  @click="change_user(elem)"
                >
                  <v-avatar
                    :style="{ ...style.neomenu, height: '30px', width: '30px' }"
                    size="25"
                    class="mr-4"
                  >
                    <span class="orange--text text--lighten-2 text-12">{{
                      elem.name
                        .split(" ")
                        .map(n => n[0])
                        .join("")
                    }}</span>
                  </v-avatar>
                  <div class="text-14 blueGrey--text">{{ elem.mininame }}</div>
                  <span
                    v-if="elem.workNow == 'open'"
                    class="orange--text font-weight-bold"
                  >
                    &deg;
                  </span>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </div>
      <!-- </v-btn> -->

      <!-- выбор магазина -->
      <div v-show="shop_menu">
        <template>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                dark
                rounded
                max-height="34"
                class="ml-4"
                :style="style.btntop"
                v-bind="attrs"
                v-on="on"
              >
                <v-img v-if="!shop" :src="no_logo"></v-img>
                <span v-else>{{ shop }}</span>
              </v-avatar>
            </template>
            <v-list color="#F2F2F2" width="180">
              <v-list-item
                v-for="(item, index) in access_shop.filter(el => el != shop)"
                :key="index"
                dark
                @click="change_shop(item)"
              >
                <v-btn
                  label
                  small
                  :style="{
                    ...style.neomenu,
                    height: '35px',
                    'border-radius': '5px'
                  }"
                  class="blueGrey--text"
                >
                  <div>{{ item }}</div>
                </v-btn>
                <span
                  v-if="all_shop_info[item]"
                  class="blueGrey--text text-12 ml-4"
                >
                  {{ all_shop_info[item].name }}
                </span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </div>

      <!-- полоса загрузки -->
      <v-progress-linear
        indeterminate
        absolute
        v-if="loading"
        height="3"
        color="orangeMain"
        class="align-self-end"
      ></v-progress-linear>
    </v-app-bar>

    <v-card flat height="50" class="d-flex align-end"> </v-card>

    <v-navigation-drawer
      app
      touchless
      expand-on-hover
      v-model="showMenu"
      color="grey6"
    >
      <v-list class="pb-16">
        <v-list-item
          v-for="(el, idx) in access_menu"
          :key="idx"
          :class="
            idx != 0 && el.block != access_menu[idx - 1].block ? 'mt-6' : ''
          "
          @click="$router.push({ path: el.path })"
          :disabled="
            $route.path == el.path ||
              (el.user &&
                !user.id &&
                (!el.admin || !access_list.includes('admin')))
          "
        >
          <v-list-item-icon>
            <v-btn
              :style="$route.path == el.path ? style.pressbtn : style.neomenu"
              x-small
              class="mx-n2 my-n1"
            >
              <v-icon
                :color="
                  el.user &&
                  !user.id &&
                  (!el.admin || !access_list.includes('admin'))
                    ? 'grey4'
                    : $route.path == el.path
                    ? 'blueLight'
                    : 'blueGrey'
                "
                small
              >
                {{ el.icon }}
              </v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ el.name }}</v-list-item-title>
            <span v-if="el.path == '/logout'" class="grey4--text">{{
              username
            }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-btn :style="style.neomenu" x-small class="mx-n2 my-n1">
              <v-icon color="blueLight" small>fas fa-sign-out-alt </v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>выход</v-list-item-title>
            <span class="grey3--text">
              {{ username }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      start: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}`,
      startMenu: false,
      showMenu: false,
      header_shop_comp_admin: ["Sale", "Marketing", "Inventory", "Orders"],
      no_date_comp: ["ABC", "Wages"],
      menu: [
        {
          name: "поиск по товару",
          icon: "fas fa-search",
          path: "/sale",
          block: 1,
          access: ["admin", "storekeeper"]
          // only: true
        },
        {
          name: "монитор",
          icon: "fas fa-heartbeat",
          path: "/monitor",
          block: 1,
          access: ["admin"]
        },
        {
          name: "продажа",
          icon: "fas fa-plus-square",
          path: "/sale",
          block: 1,
          access: ["shop", "seller"]
        },
        {
          name: "админка",
          icon: "fas fa-cogs",
          path: "/admin",
          block: 1,
          access: ["admin"]
        },
        {
          name: "настройка зп",
          icon: "fas fa-wrench",
          path: "/wages",
          block: 1,
          any: true,
          access: ["admin"]
        },
        {
          name: "сотрудники",
          icon: "fas fa-users",
          path: "/sellers",
          block: 2,
          access: ["admin"]
        },
        {
          name: "ведомость ЗП",
          icon: "fas fa-calculator",
          path: "/salary_list",
          block: 2,
          access: ["admin"]
        },
        {
          name: "abc анализ",
          icon: "fas fa-chart-pie",
          path: "/abc",
          block: 3,
          access: ["admin", "producer"]
        },
        {
          name: "victoria stenova",
          icon: "fas fa-trademark",
          path: "/stenova",
          block: 3,
          access: []
        },
        {
          name: "маркетинг",
          icon: "fas fa-poll",
          path: "/marketing",
          block: 3,
          access: ["admin", "shop", "seller"],
          user: true,
          admin: true
        },
        {
          name: "заказы",
          icon: "fas fa-shopping-basket",
          path: "/orders",
          block: 3,
          access: ["admin", "shop", "seller"]
        },
        {
          name: "зарплата",
          icon: "fas fa-coins",
          path: "/state",
          block: 3,
          access: ["seller", "shop"],
          user: true
        },
        {
          name: "график",
          icon: "fas fa-calendar-alt",
          path: "/graph",
          block: 3,
          access: ["seller", "shop", "storekeeper"],
          user: true
        },
        {
          name: "карьера",
          icon: "fas fa-rocket",
          path: "/career",
          block: 3,
          access: ["seller", "shop"],
          user: true
        },
        {
          name: "бонус-рулоны",
          icon: "fas fa-apple-alt",
          path: "/bonus",
          block: 3,
          access: ["seller", "shop"]
        },
        {
          name: "рейтинги",
          icon: "fas fa-medal",
          path: "/top",
          block: 4,
          access: ["admin", "seller", "shop"]
        },
        {
          name: "знания",
          icon: "fas fa-graduation-cap",
          path: "/blog",
          block: 4,
          access: ["admin", "seller", "shop", "storekeeper"]
        },
        {
          name: "инвентаризация",
          icon: "fas fa-people-carry",
          path: "/inventory",
          block: 5,
          access: ["admin", "seller", "shop"]
        },
        {
          name: "1C debug",
          icon: "fas fa-bug",
          path: "/integration",
          block: 5,
          access: []
        }
        // {
        //   name: "выход",
        //   icon: "fas fa-sign-out-alt",
        //   path: "/login",
        //   block: 5,
        //   access: ["admin", "seller", "shop", "producer", "storekeeper"]
        // }
      ]
    };
  },

  computed: {
    ...mapState([
      "user",
      "userinfo",
      "logoWhite",
      "shop",
      "loading",
      "access",
      "access_shop",
      "no_logo",
      "username",
      "style",
      "year",
      "month",
      "all_shop_info",
      "userinfo"
    ]),

    access_list() {
      return [
        ...new Set(
          Object.entries(this.access)
            .filter(el => el[0] != "root")
            .map(el => el[1])
        )
      ];
    },

    access_menu: function() {
      let result = this.menu.filter(el =>
        el.only
          ? el.access.join() == this.access_list.join()
          : el.access.filter(x => this.access_list.includes(x)).length > 0
      );
      // console.log('menu', result);
      return result;
    },

    shop_menu: function() {
      if (this.access_shop.length > 1) {
        if (
          ~this.header_shop_comp_admin.indexOf(this.$route.name) &&
          this.access.any == "admin"
        )
          return true;
        if (this.access.any != "admin") return true;
      }
      return false;
    },

    // users () {
    //   if (Object.values(this.userinfo).length == 0) return [];
    //   return Object.values(this.userinfo).filter((el) =>
    //     this.shop == el.shop && el.workNow);

    // },

    user_menu: function() {
      // console.log(this.access[this.shop], this.user.name);
      if (
        (this.access && this.access[this.shop] == "shop") ||
        (this.user && this.user.name)
      )
        return true;
      return false;
    },

    admin_shop: function() {
      //доступные для администрирования магазины
      let admin = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "admin" ? { ...acc, [k]: v } : acc),
        {}
      );
      return Object.keys(admin);
    }
  },

  methods: {
    ...mapMutations([
      "login_on",
      "change_user",
      "change_footer_date",
      "change_shop",
      "change_username",
      "change_month",
      "change_year"
    ]),

    logout() {
      //выход из системы
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.login_on();
      // this.$router.push({ name: "Login" });
    },

    set_date(month, year) {
      let new_year = month < 1 ? year - 1 : month > 12 ? year + 1 : year;
      let new_month = new Date(year, month - 1, 1).getMonth() + 1;
      if (this.month != new_month) this.change_month(new_month);
      if (this.year != new_year) this.change_year(new_year);
    }
  },

  watch: {
    month() {
      this.start = `${this.year}-${("0" + this.month).slice(-2)}`;
    },

    start() {
      if (this.month != +this.start.split("-")[1]) {
        this.change_month(+this.start.split("-")[1]);
        this.startMenu = false;
      }
      if (this.year != +this.start.split("-")[0]) {
        this.change_year(+this.start.split("-")[0]);
        this.startMenu = false;
      }
    }
  },

  created: function() {
  }
};
</script>
