<template>
  <Indicator name="эффективность" :progress="loading">
    <template v-slot:button>
      <GoToButton v-if="!disable_button" text="в показатели" to="indicators" />
    </template>
    <template v-slot:content>
      <div
        v-if="offence"
        class="redLight--text text-center grey5 rounded-xl rounded-tr-0 mb-n1"
      >
        <v-icon class="mr-1 redLight--text">
          mdi-alert-circle-outline
        </v-icon>
        <span>
          действует штраф
        </span>
      </div>
      <div class="d-flex justify-space-between mt-1">
        <div style="min-width: 90px">
          <div>
            <span class="font-weight-light text-24">{{effectiv_month}}</span>
            <span class="font-weight-light text-22 grey3--text">%</span>
          </div>
          <div class="text-9 grey4--text mt-n1 ml-1">
            текущая
          </div>
        </div>
        <div style="min-width: 90px">
          <div>
            <span class="font-weight-light text-24">{{ effectiv }}</span>
            <span class="font-weight-light text-22 grey3--text">%</span>
          </div>
          <div class="text-9 grey4--text mt-n1 ml-1">
            средняя
          </div>
        </div>

        <div>
          <div>
            <span class="font-weight-light text-24">{{conversion}}</span>
            <span class="font-weight-light text-22 grey3--text">%</span>
          </div>
          <div class="text-9 grey4--text mt-n1 ml-1">
            конверсия
          </div>
        </div>

        <div>
          <div class="font-weight-light text-24 redLight--text text-center">
            {{ points }}
          </div>
          <div class="text-9 grey4--text mt-n1">штрафы за 3 месяца</div>
        </div>

   
      </div>
      <div
        v-if="points_message"
        style="background-color: rgba(255, 99, 71, 0.5);"
        class="text-12 grey2--text font-weight-thin d-flex align-end pl-3 py-1 rounded-xl rounded-tr-0"
      >
        {{ points_message }}
      </div>
      <div
        v-if="effective_message"
        class="text-12 grey2--text grey5 font-weight-thin d-flex align-end pl-1 pr-3 py-1 rounded-xl rounded-tl-0"
      >
        {{ effective_message }}
      </div>
    </template>
  </Indicator>
</template>

<script>
import Indicator from "./IndicatorComponent";
import GoToButton from "./GoToButtonComponent";
import { back_request } from  "../../mixins/back";
import {mapState} from 'vuex'

export default {
  name: "IndicatorEffectiveness",
  mixins: [back_request],
  components: {
    GoToButton,
    Indicator
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    },
    shop_id: {
      type: String,
      required: false,
      default: null,
      validator: v => typeof v === "string"
    },
    disable_button: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    loading: false,
    count: 0,
    points: 0,
    effectiv: 0,
    conversion: '0.00',
    users: [],
    message_points: [
      [
        8,
        "Катапульта по штрафным баллам сработает в следующем месяце. Оклад будет начисляться по статусу Юниор."
      ],
      [6, "Есть угроза катапульты по штрафным баллам"]
    ],
    message_effect: [
      [
        90,
        "Катапульта по личной эффективности сработает в следующем месяце. Бонусная часть будет начисляться по статусу Юниор."
      ],
      [95, "Eсть угроза катапульты по личной эффективности"]
    ]
  }),
  computed: {

    ...mapState([
               'shop',
               'month',
               'year']),

    effective_message() {
      return [
        ...this.message_effect.filter(
          el => this.effectiv && el[0] > this.effectiv
        ),
        ["", ""]
      ][0][1];
    },
    points_message() {
      return [
        ...this.message_points.filter(el => this.points && el[0] < this.points),
        ["", ""]
      ][0][1];
    },
    effectiv_month () {
      return Math.round(this.users.reduce(
        (acc, el) => el.id == this.user_id && el.personaleffectiv ? acc + el.personaleffectiv : acc, 0));
    },
    offence () {
      return this.users.reduce(
        (acc, el) => el.id == this.user_id && el.addon ? [...acc, el.addon] : acc, []
        ).length ? true : false;
    }
  },
  watch: {
    user_id() {
      this.add_average();
    },
    shop_id() {
      this.add_data_user();
      this.count ++;
      this.get_datatree(this.month,
                      this.year,
                      this.shop,
                      {shop: ['conversion'],
                       user: []}).then(res => {
                        this.conversion = res.conversion;
                        this.count --;});
    },
    count() {
      this.loading = this.count ? true : false;
    },
    month() {
      this.count ++;
      this.get_datatree(this.month,
                        this.year,
                        this.shop,
                       {shop: ['conversion'],
                        user: []}).then(res => {
                        this.conversion = res.conversion;
                        this.count --;
                      });
    },
    year() {
      this.count ++;
      this.get_datatree(this.month,
                        this.year,
                        this.shop,
                      {shop: ['conversion'],
                       user: []}).then(res => {
                        this.conversion = res.conversion;
                        this.count --;
                      });
    }
  },
  methods: {
    add_average() {
      if (this.user_id) {
        this.count++;
        this.get_user_average(3, this.user_id)
          .then(data => data[this.user_id])
          .then(data => {
            this.points = data.points || 0;
            this.effectiv = data.effectiv || 0;
          })
          .then(() => this.count--);
      }
    }, 
    add_data_user(){
      if (this.shop_id){
        this.count++;
        this.get_store_users({shopID: this.shop_id}).then(data => {
          if (data.sellers) this.users = data.sellers
        }).then(() => this.count--);
      }
    }
  },
  created() {
    this.add_average();
    this.add_data_user();
    this.get_datatree(this.month,
                      this.year,
                      this.shop,
                      {shop: ['conversion'],
                       user: []}).then(res => this.conversion = res.conversion);
  }
};
</script>

<style scoped></style>
