<template>
  <v-main>
    <v-container>
      <v-fade-transition mode="out-in">
        <v-layout row wrap v-if="show" class="ma-3">
          <v-flex xs12 sm12 xl12>
            <v-parallax v-if="post.src" :src="post.src" height="200">
            </v-parallax>

            <v-text-field v-if="editing" v-model="post.src"> </v-text-field>
          </v-flex>

          <v-flex sm12 my-5>
            <v-layout row ml-1 mr-1>
              <v-flex sm6>
                <div
                  v-text="post.title"
                  v-if="!editing"
                  class="display-1 font-weight-black text-left"
                ></div>
                <v-text-field
                  v-if="editing"
                  v-model="post.title"
                  label="Заголовок"
                >
                </v-text-field>
              </v-flex>

              <v-flex sm6>
                <div
                  v-text="post.date ? post.date.split(' ')[0] : ''"
                  v-if="!editing"
                  class="subtitle-2 text-right"
                ></div>
              </v-flex>
            </v-layout>

            <v-btn
              icon
              v-if="access.any == 'admin' && post.id"
              @click="
                editing = editing == true ? false : true;
                post =
                  editing == false ? JSON.parse(JSON.stringify(reserve)) : post;
              "
            >
              <v-icon>{{ editing == true ? "cancel" : "create" }}</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="saveArticle().then((editing = false))"
              v-show="editing"
            >
              <v-icon>save</v-icon>
            </v-btn>

            <template v-if="!editing">
              <v-chip class="ma-2" v-for="tag in post.tag" :key="tag" small>
                {{ tag }}
              </v-chip>
            </template>

            <v-chip-group
              v-if="editing"
              v-model="post.tag"
              multiple
              active-class="primary--text"
            >
              <v-chip
                class="ma-2"
                v-for="tag in tags"
                :key="tag"
                small
                :value="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </v-flex>
          <v-layout row class="ma-2 mb-12">
            <v-flex>
              <div
                v-text="post.subtitle"
                class="font-italic font-weight-medium text-left"
              ></div>
              <!-- <div> -->
              <!--   <vue-simplemde -->
              <!--     v-show="editing" -->
              <!--     v-model="post.text" -->
              <!--     ref="markdownEditor" -->
              <!--   /> -->
              <!--   <div ref="ph" class="subtitle-2" v-show="!editing"></div> -->
              <!-- </div> -->
              <div v-if="editing">
                <quillEditor v-model="post.text" />
              </div>
              <div v-else>
                <div v-html="post.text"></div>
              </div>
              <!-- <div v-show="!editing">
                  <v-btn
                    color="green darken-1"
                    text
                    :disabled="mark ? true : false"
                    @click="setMark('Полезно')">

                    Полезно
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    :disabled="mark ? true : false"
                    @click="setMark('Не полезно :(')" >

                    Не полезно :(
                  </v-btn>
                </div> -->
            </v-flex>
          </v-layout>
        </v-layout>
      </v-fade-transition>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
// import VueSimplemde from "vue-simplemde";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "Article",
  components: {
    // VueSimplemde,
    quillEditor
  },
  data: () => ({
    id: "",
    post: {},
    show: true,
    tags: [
      "1С",
      "возврат",
      "зарплата",
      "карьера",
      "команда",
      "конверсия",
      "лояльность",
      "обучение",
      "оплата",
      "остатки",
      "продажа",
      "сервис",
      "средний чек",
      "стандарты",
      "трафик"
    ],
    mark: "",
    editing: false,
    reserve: {}
  }),

  computed: {
    ...mapState(["shop", "user", "back_domen", "access"])

    //simplemde() {
    //  return this.$refs.markdownEditor.simplemde;
    //}
  },

  methods: {
    async getArticle() {
      return axios
        .post(this.back_domen + "/service/blog/getarticle", {
          id: this.id,
          user: this.user
        })
        .then(res => res.data)
        .then(json => {
          this.post = json["response"];
          this.reserve = JSON.parse(JSON.stringify(this.post));
        })
        .catch(err => console.warn(err));
    },

    // async setMark (mark) {
    //   return axios.post(this.back_domen + '/blog/setmark', {
    //     'artcicleID': this.id,
    //     'data': mark,
    //     'user': this.user,
    //   }).then(res => res.data)
    //     .then(json => this.mark = json['response'])
    //     .catch(err => console.warn(err))
    //   },

    async saveArticle() {
      if (JSON.stringify(this.reserve) === JSON.stringify(this.post))
        return undefined;
      return axios
        .post(this.back_domen + "/service/blog/savearticle", this.post)
        .then(res => res.data)
        .then(json => {
          this.post = json["response"];
          this.reserve = JSON.parse(JSON.stringify(this.post));
        })
        .catch(err => console.warn(err));
    }
  },

  watch: {
    post() {
      if (this.post.registry) {
        for (let i = 0; i < this.post.registry.length; i++) {
          if (this.post.registry[i].type == "helpful") {
            this.mark = this.post.registry[i].data;
            break;
          }
        }
      }
      //if (this.post.text) {
      //  let text = this.simplemde.markdown(this.post.text);
      //  this.$refs.ph.innerHTML = text;
      //}
    },

    editing() {
      if (this.editing) {
        setTimeout(() => this.simplemde.codemirror.refresh(), 500);
      }
    }
  },

  created: function() {
    this.id = this.$route.params.id;
    if (this.id && this.id != "new") this.getArticle();
    if (this.id == "new") this.editing = true;
  },

  mounted() {}
};
</script>

<style>
@import "~simplemde/dist/simplemde.min.css";
</style>
