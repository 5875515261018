import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./scss/main.scss";
import store from "./store";
import router from "./router";
import Vuetify from "vuetify";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// import VueJwtDecode from 'vue-jwt-decode'
import "handsontable/dist/handsontable.full.css";

import "./registerServiceWorker";

// let days_short = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
let days = [
  "воскресенье",
  "понедельник",
  "вторник",
  "среда",
  "четверг",
  "пятница",
  "суббота",
];
let months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

let count_req = 0;
// console.log(count_req);

// INTERCEPTORS
axios.interceptors.request.use((request) => {
  count_req++;
  // убери если все адреса почищены
  request.url = request.url.replace(process.env.VUE_APP_BACK_DOMEN, "");
  if (process.env.VUE_APP_BACK_DOMEN && !request.url.includes("http"))
    request.url = process.env.VUE_APP_BACK_DOMEN + request.url;
  if (store.state.loading === false) store.state.loading = true;
  // console.log(request)
  if (!request.url.includes('refresh'))
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  if (typeof request.data == "string") {
    request.data = JSON.parse(request.data);
  }
  if (store.state.shop) {
    if (!request.data || !request.data.shopID)
      request.data = { ...request.data, shopID: store.state.shop };
  }
  // console.log(request, count_req);
  return request;
});

axios.interceptors.response.use(
  (res) => {
    count_req--;
    // console.log(res, count_req);
    if (count_req <= 0) store.state.loading = false;
    return res;
  },
  (err) => {
    count_req--;
    // console.log(err.response);
    if (count_req <= 0) store.state.loading = false;
    if ([401, 422].includes(err.response && err.response.status)
        && err.response.config.url.includes('refresh')) {
      return;
    }
    // console.log(err.response.config.url);
    return Promise.reject(err);
  }
);

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(
      "https://auth.oboi.ru/token/refresh",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
          Access_token: localStorage.getItem("access_token")
            ? localStorage.getItem("access_token")
            : "",
        },
      }
    )
    .then((tokenRefreshResponse) => {
      if (!tokenRefreshResponse) return Promise.reject(failedRequest);
      localStorage.setItem(
        "access_token",
        tokenRefreshResponse.data.access_token
      );
      store.commit("change_access", tokenRefreshResponse.data.access_token);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.access_token;
      return Promise.resolve();
    })
    .catch((err) => {
      count_req--;
      // console.log(count_req);
      // console.log('здесь', err);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      store.commit("login_on");
      return Promise.reject(err);
      // router.push({ name: "Login" });
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401, 422],
});

// VUE
Vue.config.productionTip = false;

Vue.filter("moneyM", function (value) {
  if (isNaN(value)) return "0`000";
  return (Number(value) / 1000000).toFixed(3).replace(".", "`");
});

Vue.filter("moneyMln", function (value) {
  if (isNaN(value)) return "0.0";
  return (Number(value) / 1000000).toFixed(1);
});

Vue.filter("moneyT", function (value) {
  if (isNaN(value)) return "0 000";
  return (Number(value) / 1000).toFixed(3).replace(".", " ");
});

Vue.filter("money", function (number) {
  var format = new Intl.NumberFormat("ru");
  if (isNaN(number) || number == 0) return 0;
  if (number < 1 && number > -1) return (+number).toFixed(2);
  return format.format(Number(number).toFixed());
});

Vue.filter("money2", function (value) {
  if (isNaN(value)) return "0`000";
  return (Number(value) / 1000).toFixed(3).replace(".", "`");
});

Vue.filter("date", function (date) {
  // 2020-09-18 13:54:19
  return String(date).split(".")[0];
});

Vue.filter("date2", function (date) {
  if (!date) return "00.00.00";
  date = String(date).split(".")[0].split("T")[0].split(" ")[0].split("-");
  return `${date[2]}.${date[1]}.${date[0].slice(-2)}`;
});

Vue.filter("month_year", function (date) {
  if (!date) return "00.0000";
  date = date.split("-");
  return `${date[1]}.${date[0]}`;
});

Vue.filter("date1", function (date) {
  // if (typeof(date) != 'object' && date) date = new Date(date);
  // console.log(date, typeof(date));
  if (!date || String(date) == "Invalid Date") return "";
  return `${("0" + date.getDate()).slice(
    -2
  )}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
});

Vue.filter("phone", function (phone) {
  if (phone.length > 11) return phone;
  phone = `+7(${phone.slice(-10, -7)})${phone.slice(-7, -4)}-${phone.slice(
    -4,
    -2
  )}-${phone.slice(-2)}`;
  return phone;
});

Vue.filter("shop_ID", function (email) {
  return email ? email.split("@")[0] : "";
});

Vue.filter("date_short", function (date_string) {
  // return 21.05
  date_string = date_string.split("T")[0].split(" ")[0].split("-");
  return `${date_string[2]}.${date_string[1]}`;
});

// НОВЫЕ хорошие ФИЛЬТРЫ
Vue.filter("timedelta_ndays", function (datetime) {
  // return сегодня, завтра, 4 дн
  let timedelta = Math.ceil((datetime - new Date()) / (1000 * 60 * 60 * 24));
  if (timedelta == 0) return "сегодня";
  if (timedelta == 1) return "завтра";
  return timedelta + " дн";
});

Vue.filter("date_weekday", function (datetime) {
  // return пятница 13 февраля
  let weekday = days[datetime.getDay()];
  let month = months[datetime.getMonth()].slice(0, 3);
  let day = datetime.getDate();
  return `${weekday}, ${day} ${month}`;
});

Vue.filter("transcription", function (str) {
  let upper_case = {
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "YO",
    Ж: "ZH",
    З: "Z",
    И: "I",
    Й: "J",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "C",
    Ч: "CH",
    Ш: "SH",
    Щ: "SHH",
    Ъ: "",
    Ы: "Y",
    Ь: "",
    Э: "E",
    Ю: "YU",
    Я: "YA",
    "-": "-",
    " ": " ",
  };
  let name = [...str.toUpperCase()].map((el) => upper_case[el]).join("");
  return name;
});

Vue.use(Vuetify);

new Vue({
  vuetify,
  axios,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
