<template>
  <div class="py-12 text-12">
    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="name in Object.keys(ratingnames)" :key="name">
        <div
          v-if="
            Object.keys(userinfo).length > 0 &&
              Object.keys(all_shop_info).length > 0 &&
              rating[ratingnames[name]]
          "
        >
          <div
            v-for="(period, key) in rating[ratingnames[name]]"
            class="mb-6"
            :key="name + key"
          >
            <div class="text-right orangeDark--text text-14">
              {{ key }}
            </div>
            <div v-if="ratingnames[name] == 'glueSeller'">
              <GlueRating>
                <template v-slot:line>
                  <tr
                    v-for="(item, index) in rating.glueShop[key]"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ `${item.ID} ${all_shop_info[item.ID].name}` }}
                    </td>
                    <td class="text-right">
                      <span v-if="item.result > 1">
                        {{ item.result | money }}
                      </span>
                      <span v-else>
                        {{ item.result }}
                      </span>
                    </td>
                  </tr>
                </template>
              </GlueRating>
              <br />
            </div>
            <span
              v-if="(elem = ratingperoid[name].filter(el => el.name == key))"
            >
              {{ elem[0].name2 ? elem[0].name2 : "" }}
            </span>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th
                    v-for="item in [
                      '№',
                      'Продавец',
                      'Домашний магазин',
                      'Результат'
                    ]"
                    :key="item"
                    class="pa-0 ma-0 grey4 black--text font-weight-thin"
                  >
                    <span class="ml-1">
                      {{ item }}
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody class="text-left">
                <tr
                  :class="user && user.id == item.id ? 'grey5' : ''"
                  v-for="(item, index) in period.filter(el => userinfo[el.id])"
                  :key="index"
                >
                  <td class="px-0 pr-1 text-right">
                    <div class="text-12">
                      {{ index + 1 }}
                    </div>
                  </td>
                  <td class="px-0 text-left">
                    <div class="text-12 ml-1">
                      <Manager
                        size="15"
                        text_size="text-12"
                        text_color="black"
                        color="white"
                        :user_name="userinfo[item.id].name"
                        :user_status="userinfo[item.id].status"
                      />
                    </div>
                  </td>
                  <td class="px-0 text-left">
                    <div class="text-12">
                      {{
                        `${all_shop_info[userinfo[item.id].shop].ID} ${
                          all_shop_info[userinfo[item.id].shop].name
                        }`
                      }}
                    </div>
                  </td>
                  <td class="px-0 pr-3 text-right">
                    <div class="text-12 orangeDark--text">
                      <span v-if="item.result > 1">
                        {{ item.result | money }}
                      </span>
                      <span v-else>
                        {{ item.result }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <div v-else class="text-center">
          <v-img :src="rating_img"> </v-img>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div
      class="grey"
      style="position: fixed; left: 0px; bottom: 71px; height: 41px; width: 100.2%; "
    >
      <v-tabs
        background-color="grey3"
        height="40"
        grow
        show-arrows
        center-active
        color="white"
        v-model="tabs"
      >
        <v-tab
          style="text-transform: capitalize"
          class="grey3 text-12"
          v-for="name in Object.keys(ratingnames)"
          :key="name"
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import GlueRating from "./GlueRatingTableComponent";
import Manager from "./ManagerComponent";
export default {
  name: "Top",
  components: {
    GlueRating,
    Manager
  },
  data: () => ({
    tabs: null,
    vs_glue: false,
    ratingdict: {
      "Дневной план": "daymark",
      Эффективность: "efficiency",
      "Средний чек": "averCheck",
      Трудолюбие: "workingDays",
      "Stenova Home": "stenovahome",
      "Чеки с телефонами": "percent_phone",
      Возвраты: "returns",
      Продажи: "sales",
      "Лучший дебют": "bestJunior",
      "Наибольший чек": "check",
      Клей: "glueSeller"
    },
    userinfo: {},
    // shopinfo: {},
    rating: {},
    ratingperoid: {
      Продажи: [{ name: "за текущий квартал", quarter: true }],
      Эффективность: [{ name: "за текущий календарный квартал, в расчет попадает эффективность за 8 и более отработанных смен в месяце" }],
      "Лучший дебют": [{ name: "за все время работы" }],
      "Средний чек": [
        { name: "за текущий квартал", quarter: true },
        { name: "за текущий месяц" }
      ],
      Трудолюбие: [
        { name: "за текущий квартал", quarter: true },
        { name: "за текущий месяц" }
      ],
      "Stenova Home": [
        { name: "за текущий квартал", quarter: true },
        { name: "за текущий месяц" }
      ],
      "Чеки с телефонами": [
        { name: "за текущий квартал, в процентах", quarter: true },
        { name: "за текущий месяц, в процентах" }
      ],
      Возвраты: [
        { name: "за 6 месяцев, процент возврата от продаж", month: 5 },
        { name: "за текущий месяц, процент возврата от продаж" }
      ],
      "Наибольший чек": [
        { name: "за текущий квартал", quarter: true },
        { name: "за текущий месяц" }
      ],
      Клей: [
        { name: "за текущий месяц, отношение площади клея к площади обоев" }
      ],
      "Дневной план": [
        {
          name:
            "текущий квартал, за выполнение дневного плана: до 150% = 1балл, от 150% = 1,5балла, от 200% = 2балла",
          quarter: true
        }
      ]
    },
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    rating_img: "https://251317.selcdn.ru/oboi.ru/sup/spinner.gif",
    status: { стажер: 1, юниор: 2, мастер: 3, эксперт: 4 },
    gluetitle: ["место", "магазин", "результат"]
  }),

  computed: {
    ...mapState(["back_domen", "user", "style", "all_shop_info", "access"]),

    ratingnames() {
      let admin = Object.values(this.access).reduce(
        (acc, v) =>
          v != "producer" && typeof v == "string" ? [...acc, v] : acc,
        []
      );
      if (admin.length == 0) {
        return Object.entries(this.ratingdict).reduce(
          (acc, el) =>
            ~["averCheck", "check", "stenovahome"].indexOf(el[1])
              ? { ...acc, [el[0]]: el[1] }
              : acc,
          {}
        );
      } else {
        return this.ratingdict;
      }
    }
  },

  methods: {
    async datauser() {
      return axios
        .post(this.back_domen + "/service/userinfo", {
          year: this.year,
          month: this.month + 1,
          shopID: "all",
          every: true
        })
        .then(res => res.data)
        .then(json => {
          let userinfo = {};
          for (let el of json["response"]) userinfo[el.id] = el;
          this.userinfo = { ...this.userinfo, ...userinfo };
          // console.log('user', this.userinfo);
        })
        .catch(err => console.warn(err));
    },

    // async datashop () {
    //   return axios.post(this.back_domen + '/service/shopinfo', {
    //     'year': this.year,
    //     'month': this.month + 1,
    //   }).then(res => res.data)
    //     .then(json => {
    //     let shopinfo = {};
    //     for (let el of json['response'])
    //       shopinfo[el.ID] = el;
    //     this.shopinfo = {...this.shopinfo, ...shopinfo};
    //     console.log(this.shopinfo);
    //     console.log(this.all_shop_info);
    //   })
    //     .catch(err => console.warn(err))
    // },

    async getrating(ratingname) {
      let rating = this.ratingnames[ratingname];
      if (this.rating[rating]) return {};
      let data = this.ratingperoid[ratingname];
      for (let element of data) {
        let date = new Date(this.year, this.month - (element.month || 0), 1);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let name = element.name;
        axios
          .post(this.back_domen + "/service/rating", {
            year: year,
            month: month,
            rating: rating,
            vs_glue: this.vs_glue
          })
          .then(res => res.data)
          .then(json => {
            rating = {};
            for (let key in json["response"]) {
              rating[key] = this.rating[key] ? this.rating[key] : {};
              rating[key][name] = json["response"][key];
            }
            this.rating = { ...this.rating, ...rating };
          })
          .catch(err => console.warn(err));
      }
    }
  },

  watch: {
    tabs () {
      this.getrating(Object.keys(this.ratingnames)[this.tabs]);
    }
  },

  created: function() {
    for (let el of Object.keys(this.ratingnames).slice(0, 1)) this.getrating(el);
    this.datauser();
    // this.datashop();
  }
};
</script>

<style scoped></style>
