var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"pb-0 pt-0",style:(_vm.style.background)},[_c('v-card',{staticClass:"mx-auto pt-6 pt-md-12 px-6 px-md-12 pb-16",style:(Object.assign({}, _vm.style.neobtn, {'border-radius': '0px'})),attrs:{"color":"grey6","max-width":"800","height":"100%"}},[(_vm.access.any != 'admin' && _vm.user.workNow && _vm.user.workNow != 'open')?_c('v-btn',{staticClass:"my-2 my-md-6",style:(_vm.style.neobtn),attrs:{"height":"55","width":"100%"},on:{"click":function($event){_vm.user.workNow == 'close' ? _vm.open_shift() :
              _vm.user.workNow == 'no' ? _vm.openshift = true : ''}}},[_vm._v(" открыть смену "),_c('v-icon',{staticClass:"pl-6",attrs:{"color":"orangeMain"}},[_vm._v("fas fa-key")]),(_vm.user.workNow == 'no')?_c('v-icon',{staticClass:"pl-1",attrs:{"color":"orangeMain"}},[_vm._v("fas fa-key")]):_vm._e()],1):_vm._e(),(Object.keys(_vm.active_product).length > 0)?_c('div',{staticClass:"d-flex flex-rows"},[_c('div',{staticClass:" ml-auto mr-16 mb-n16 mt-10 mb-n2 orangeMain--text",style:(_vm.style.foreground)},[_c('span',{staticClass:"pr-md-10"},[(!_vm.count)?_c('span',[_vm._v(_vm._s(_vm._f("money")(_vm.active_product.Цена)))]):_c('span',[_vm._v(_vm._s(_vm.count)+" x "+_vm._s(_vm._f("money")(_vm.active_product.Цена))+" = "+_vm._s(_vm._f("money")(_vm.active_product.Цена * _vm.count)))]),_c('v-icon',{staticClass:"orangeMain--text ml-1",attrs:{"x-small":""}},[_vm._v("fas fa-ruble-sign")])],1)])]):_vm._e(),_c('v-text-field',{staticClass:"py-0 mb-4 mb-md-6 mt-6",style:(_vm.style.neocard),attrs:{"outlined":"","hide-details":"","autofocus":_vm.copy_products.lenth > 0 ? true : false,"id":"search","label":_vm.headers.join(' - ').toLowerCase(),"maxlength":"20","color":"orangeMain"},on:{"input":function($event){_vm.active_product={}}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}},[(_vm.search)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.search),expression:"search"}],attrs:{"slot":"append","color":"orangeMain"},on:{"click":function($event){_vm.search='' ;_vm.count=''}},slot:"append"},[_vm._v("fas fa-backspace")]):_vm._e()],1),(Object.keys(this.active_product).length == 0 &&
            _vm.search_products.length > 0 &&
            _vm.full_products.length > 0)?_c('v-simple-table',{staticClass:"px-md-12 py-4 neocard",attrs:{"color":"grey6"}},[[_c('thead',[_c('tr',[_vm._l((_vm.headers.concat( _vm.headers_add)),function(el){return _c('th',{key:el,staticClass:"text-center grey4--text"},[_vm._v(" "+_vm._s(_vm.good_title[el] || el.toLowerCase())+" ")])}),_c('th')],2)]),_c('tbody',{staticClass:"text-center"},_vm._l((_vm.full_products),function(el){return _c('tr',{key:el.Артикул,on:{"click":function($event){_vm.active_product=el}}},[_vm._l((_vm.headers.concat( _vm.headers_add)),function(el1){return _c('td',{key:el1,attrs:{"width":el == 'Коллекция' ? '40%' : ''}},[_c('a',{staticClass:"black--text"},[_vm._v(" "+_vm._s(el[el1] ? el[el1]: '')+" ")])])}),_c('td',[(el.Бонус)?_c('v-img',{attrs:{"contain":"","width":"20px","src":_vm.$root.coin}}):_vm._e()],1)],2)}),0)]],2):_vm._e(),(Object.keys(_vm.active_product).length > 0
                 && _vm.$route.name == 'Sale'
                 && _vm.user.workNow == 'open'
                 && _vm.user.seller)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{style:(_vm.style.neocard),attrs:{"outlined":"","hide-details":"","color":"orangeMain","label":"количество","maxlength":"3"},on:{"keydown":function($event){return _vm.check_number($event)}},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}},[(_vm.count)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.count),expression:"count"}],attrs:{"slot":"append","color":"orangeMain"},on:{"click":function($event){_vm.count=''}},slot:"append"},[_vm._v("fas fa-backspace")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-n4",style:(Object.assign({}, _vm.style.neobtn, _vm.style.redborder)),attrs:{"height":"40"},on:{"click":function($event){_vm.count > 1 ? _vm.count-- : _vm.count = ''}}},[_vm._v("-")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-n2 ml-md-n0 mt-n4",style:(Object.assign({}, _vm.style.neobtn, _vm.style.greenborder)),attrs:{"height":"40"},on:{"click":function($event){_vm.count++}}},[_vm._v("+")])],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{staticClass:"ml-md-2"},_vm._l((6),function(i){return _c('v-col',{key:'row1' + i,staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-btn',{style:(_vm.style.neobtn),attrs:{"height":"40"},on:{"click":function($event){_vm.count = i}}},[_vm._v(_vm._s(i))])],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pb-md-1",attrs:{"cols":"6","md":"12"}},[(_vm.$route.name == 'Sale')?_c('v-btn',{style:(_vm.count == 0 ?
                           Object.assign({}, _vm.style.neocard, _vm.style.redborder) :
                           Object.assign({}, _vm.style.neobtn, _vm.style.redborder)),attrs:{"large":"","width":"100%","disabled":_vm.count == 0 || false},on:{"click":function($event){_vm.count=-_vm.count; _vm.add_sale()}}},[_vm._v("возврат ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","md":"12"}},[_c('v-btn',{style:(_vm.count == 0 ?
                           Object.assign({}, _vm.style.neocard, _vm.style.greenborder) :
                           Object.assign({}, _vm.style.neobtn, _vm.style.greenborder)),attrs:{"large":"","width":"100%","disabled":_vm.count == 0 || false},on:{"click":function($event){return _vm.add_sale()}}},[_vm._v("продажа ")])],1)],1)],1)],1):_vm._e(),(Object.keys(_vm.active_product).length > 0)?_c('div',[_c('v-card',{staticClass:"pa-1 mt-6",style:(_vm.style.neobtn)},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-24  orangeMain--text"},[_vm._v(" "+_vm._s(_vm.active_product.Артикул))]),_c('div',{staticClass:"text-16 mt-n3 grey4--text"},[_vm._v(" "+_vm._s(_vm.active_product.Коллекция))])]),_c('v-col',{attrs:{"cols":"6"}},_vm._l((['ОстатокНаСкладе', 'СвободныйОстаток']),function(el){return _c('div',{key:el,staticClass:"mt-n1 mb-n3 text-end"},[_c('span',{staticClass:"text-12 grey4--text"},[_vm._v(_vm._s(_vm.good_title[el] || el.toLowerCase()))]),_c('span',{staticClass:"text-12"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.active_product[el])))])])}),0)],1),_c('v-parallax',{staticClass:"mt-3",attrs:{"dark":"","height":"300","src":_vm.api_data[_vm.active_product.Артикул] &&
                          _vm.api_data[_vm.active_product.Артикул].imgFrontal.s600x400 ?
                          _vm.api_data[_vm.active_product.Артикул].imgFrontal.s600x400 :
                          (_vm.active_product.pic600x400 ||
                          _vm.active_product.pic100x100 || _vm.not_image)}})],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"","right":"","timeout":1500,"color":~_vm.message.indexOf('ошибка') ? 'error' :
                        ~_vm.message.indexOf('возврат') ? 'warning' : 'success'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.addsale = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-trash-alt")])],1)]}}]),model:{value:(_vm.addsale),callback:function ($$v) {_vm.addsale=$$v},expression:"addsale"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-dialog',{attrs:{"overlay-color":"black","overlay-opacity":"0.8","width":"440"},model:{value:(_vm.openshift),callback:function ($$v) {_vm.openshift=$$v},expression:"openshift"}},[_c('v-card',{staticClass:"pa-4",style:(_vm.style.neodialog),attrs:{"flat":""}},[_c('v-card-title'),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":""}},[_c('div',{staticClass:"text-12"},[_vm._v(" магазин "+_vm._s(_vm.shop)+" обеспокоен ")]),_c('div',[_vm._v(" нет смены в графике работы ")])]),_c('div',{staticClass:"mt-6 mb-n3 blueGrey--text text-18 text-center"},[_vm._v(" открыть смену? ")])],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between pb-5"},[_c('v-btn',{style:(Object.assign({}, _vm.style.neobtn)),attrs:{"text":"","width":"40%"},on:{"click":function($event){_vm.openshift = false}}},[_c('span',{staticClass:"blueGrey--text"},[_vm._v(" Нет ")])]),_c('v-btn',{style:(Object.assign({}, _vm.style.neobtn)),attrs:{"text":"","width":"40%"},on:{"click":function($event){_vm.openshift = false; _vm.open_shift()}}},[_c('span',{staticClass:"blueGrey--text"},[_vm._v(" Да ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }