<template>
  <div>
    <v-avatar
      style="margin-top: -2px"
      :size="$props.size"
      :color="$props.color"
      :style="$data[user_status || undefined].border"
    >
      <div
        style="padding-bottom: 1px"
        :class="$props.text_size - 2 + ' ' + $props.text_color + '--text'"
      >
        {{ user_status ? user_status.slice(0, 1) : ''}}
      </div>
    </v-avatar>
    <span :class="$props.text_size + ' ' + $props.text_color + '--text'">
      {{ user_name }}
    </span>
    <v-icon v-if="work_now" small :class="$props.text_color + '--text'">
      mdi-account-clock-outline
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "ShopCheap",
  props: {
    id: {
      type: Number,
      required: false,
      default: 0
    },
    text_color: {
      type: String,
      required: false,
      default: "white",
      validator: v => typeof v === "string"
    },
    color: {
      type: String,
      required: false,
      default: "grey5",
      validator: v => typeof v === "string"
    },
    size: {
      type: String,
      required: false,
      default: "29",
      validator: v => typeof v === "string"
    },
    text_size: {
      type: String,
      required: false,
      default: "text-12",
      validator: v => typeof v === "string"
    },
    user_name: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },
    user_status: {
      type: String,
      required: false,
      default: "",
      validator: v => typeof v === "string"
    },
    work_now: {
      type: Boolean,
      default: false,
      validator: v => typeof v === "boolean"
    }
  },
  data: () => ({
    стажер: { border: "border: 1px solid #00BCD4 !important;" },
    юниор: { border: "border: 1px solid #6FDE00 !important;" },
    мастер: { border: "border: 1px solid #FFAA00 !important;" },
    эксперт: { border: "border: 1px solid #9C27B0 !important;" },
    undefined: { border: "border: 1px solid grey !important;" },
  })
};
</script>

<style scoped></style>
