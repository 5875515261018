<template>
  <div class="mb-2">
    <div class="d-flex justify-space-between">
      <slot name="header" />
      <div style="margin-top: -3px">
        <ShopCheap
          v-if="shop"
          :shop="shop"
          size="29"
          color="orangeDark"
          text_size="text-12"
          style="position: absolute; right: 25%; opacity: 0.4"
        />
      </div>
      <slot name="sum" />
    </div>
    <div v-if="decompose" class="pl-4">
      <div
        v-for="el in decompose"
        :key="el.name"
        class="d-flex justify-space-between mt-n1 text-12 font-weight-light grey3--text"
      >
        <div class="d-flex">
          <div style="margin-top: -2px">
            <v-icon small class="grey4--text">
              mdi-arrow-right-bottom
            </v-icon>
          </div>
          <div style="margin-top: 1px">{{ el.name }}</div>
        </div>
        <div v-if="el.sum" style="margin-top: 1px">{{ el.sum | money }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopCheap from "./ShopCheapComponent";
export default {
  name: "SalaryItem",
  components: {
    ShopCheap
  },
  props: {
    shop: {
      type: String,
      required: false,
      default: undefined,
      validator: v => typeof v === "string"
    },
    decompose: { default: false, required: false }
  },
  data: () => ({})
};
</script>

<style scoped></style>
