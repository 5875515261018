<template>
<v-main class="mx-auto d-flex flex-column pt-5 pb-16">
  <v-card flat
          width="800"
          class="mx-auto blueGrey--text"
          >
    <div class="text-h5 grey5--text text-right">удобный отладчик</div>
    <div class="d-flex justify-space-between my-6">
      <v-card
        @click="debag=true; comp='goods'"
        width="200"
        class="blueGrey--text py-2 text-center">
        <div class="pt-2 text-18">
          <v-icon class="orangeMain--text mr-2">fas fa-bug</v-icon>товары
        </div>
      </v-card>
      <v-card
        @click="debag=true; comp='sale'"
        width="200"
        class="blueGrey--text py-2 text-center">
        <div class="pt-2 text-18">
          <v-icon class="orangeMain--text mr-2">fas fa-bug</v-icon>продажи
          <div class="caption mt-n3 mr-n4 pl-8">возвраты</div>
        </div>
      </v-card>
      <v-card
        @click="debag=true; comp='incoming'"
        width="200"
        class="blueGrey--text py-2 text-center">
        <div class="pt-2 text-18">
          <v-icon class="orangeMain--text mr-2">fas fa-bug</v-icon>закупки</div>
      </v-card>
    </div>
    <v-card class="mx-auto my-12 pa-8 text-18 pb-0 neocard">
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text mr-auto">у каждого магазина (склада) должен быть указан уникальный email</div>
        <div class="blueGrey--text mx-auto mt-n1">
          <span class="purple--text text--lighten-2">37</span>
          <span class="blue--text">1</span>@{domain}.ru
        </div>
        <div class="text-14 mt-n3 mr-n12 ml-8">
          <span class="purple--text text--lighten-2">код региона</span>
          <span class="blue--text ml-1">порядковый номер магазина в регионе</span>
        </div>
      </v-list-item>
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text mr-auto">все поля, описанные в структуре запроса обязательные*. если нет себестоимости используйте закупочную цену</div>
        <div class="blueLight--text mr-auto text-14">*исключение - цена продажи для раздела ТОВАРЫ, ЗАКУПКИ</div>
      </v-list-item>
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text mr-auto">остатки и себестоимость берутся на текущий момент</div>
      </v-list-item>
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text mr-auto">если в документе на одном продавце было 2 одинаковых товара их нужно сложить. если цена у них разная - вычислить среднюю</div>
      </v-list-item>
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text mr-auto">услуги нужно исключить из выгрузки <span class="blueLight--text">например доставка товара</span></div>
      </v-list-item>
      <v-list-item class="d-flex flex-column">
        <div class="blueGrey--text ml-auto">техническая поддержка<v-icon class="ml-4 mr-2 blue--text">fab fa-telegram-plane</v-icon><a href="https://t.me/PavelKuzin" class="blue--text">+7 910 999 24 12</a></div>
      </v-list-item>
    </v-card>
  </v-card>
<!--
  <v-card flat
          width="800"
          class="mx-auto blueGrey--text"
          >
    <v-row class="text-18">
      <v-col cols="4">
        <v-card
          @click="debag=true; comp='goods'"
          width="200"
          class="blueGrey--text py-2 text-center">
          <div class="pt-2 text-button">
            API ключ
          </div>
        </v-card>
      </v-col>
      <v-col cols="8" class="d-flex flex-column ml-n6">
        <div class="orangeDark--text mt-5 pb-2">СЮДА КЛЮЧ <a class="blueGrey--text text-decoration-underline">скопировать</a></div>
        <div class="">добавь в HEAD (“apiKey:СЮДА КЛЮЧ”)</div>
      </v-col>
      <v-col cols="12">
      </v-col>
    </v-row>

    <v-row class="text-18">
      <v-col cols="4">
        <v-card
          @click="debag=true; comp='goods'"
          width="200"
          class="blueGrey--text py-2 text-center">
          <div class="pt-2 text-button">
            1. Товары
          </div>
        </v-card>
      </v-col>
      <v-col cols="8" class="d-flex flex-column ml-n6">
        <div class="mt-5 pb-2"><span class="orangeDark--text">POST</span> запрос на https://{domain}.oboi.ru/service/update/goods</div>
        <div>запускать 2 раза в день <a class="orangeDark--text">товар.epf</a></div>
      </v-col>
      <v-col cols="12" class="grey4--text text-right pt-0">структура запроса</v-col>


    </v-row>

    <v-row class="text-18">
      <v-col cols="4">
        <v-card
          @click="debag=true; comp='sale'"
          width="200"
          class="blueGrey--text py-2 text-center">
          <div class="pt-2 text-button">
            2. Продажи
            <div class="caption mt-n4 mr-n0 pl-8 text-12">возвраты</div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="8" class="d-flex flex-column ml-n6">
        <div class="mt-5 pb-2"><span class="orangeDark--text">POST</span> запрос на https://{domain}.oboi.ru/service/update/sale</div>
        <div>запускать каждые 10 минут <a class="orangeDark--text">продажи.epf</a></div>
      </v-col>
      <v-col cols="12" class="grey4--text text-right pt-0">структура запроса</v-col>


    </v-row>

    <v-row class="text-18">
      <v-col cols="4">
        <v-card
          @click="debag=true; comp='incoming'"
          width="200"
          class="blueGrey--text py-2 text-center">
          <div class="pt-2 text-button">
            2. закупки
          </div>
        </v-card>
      </v-col>
      <v-col cols="8" class="d-flex flex-column ml-n6">
        <div class="mt-5 pb-2"><span class="orangeDark--text">POST</span> запрос на https://{domain}.oboi.ru/service/update/incoming</div>
        <div>запускать каждые 3 часа <a class="orangeDark--text">закупки.epf</a></div>
      </v-col>
      <v-col cols="12" class="grey4--text text-right pt-0">структура запроса</v-col>


    </v-row>

  </v-card>
-->

  <v-dialog fullscreen
            transition="dialog-bottom-transition"
            v-model="debag">
    <v-card>
      <v-toolbar dark color="orangeDark">
        <v-btn icon dark @click="debag=false">
          <v-icon>fas fa-arrow-down</v-icon>
        </v-btn>
      </v-toolbar>
      <req :type="comp"></req>
    </v-card>
  </v-dialog>

</v-main>
</template>

<script>

import {mapState} from 'vuex'
import IntegrationReq from './request'

export default {
    name: 'Integration',
    components: {
        req: IntegrationReq
    },
    data: () => ({
        debag: false,
        comp: undefined,
        methods: [
            {"name": "номенклатура",
             "comp": "goods"},
            {"name": "продажи возвраты",
             "comp": "sale"},
            {"name": "поступления",
             "comp": "incoming"}]
    }),

    computed: {
        ...mapState(['back_domen']),
    },

    method() {

  },

    watch: {

    },

    created: function() {
    }


}

</script>
