<template>
  <v-main class="pt-0">
      <v-card
        class="mx-auto"
        elevation="0"
        max-width="600"
        >
        <div v-if="!user.notfound && user.name && career.statusHierarchy">
          <v-timeline dense clipped>
            <v-timeline-item 
              v-for="(el, idx) in career.statusHierarchy.slice(1)"
              :key="el"
              large
              icon-color="orange"
              color="orange" 
              class="mt-3 mr-3"
              >
              <template v-slot:icon>
                <v-avatar size=80>
                  <img :src="reference[el].scr">
                </v-avatar>
              </template>

              <v-card 
                class="my-3 pb-6" 
                v-if="career.userID && career.education[el]"
                min-width="258"
                :class="career.statusHierarchy[idx] == career.status ?
                        'elevation-6' : 'elevation-1'"
                >
                <v-card-title
                  class="headline mb-5">
                    <v-icon v-if="career.status == reference[el].name.toLowerCase()"
                      color='grey' class="mr-1">
                      mdi-account-school
                    </v-icon>
                    <v-icon v-if="career.statusHierarchy[idx] == career.status"
                      color='grey' class="mr-1">
                      directions_run
                    </v-icon>
                    {{reference[el].name.toLowerCase()}}
                </v-card-title>

                <v-card-subtitle v-if="career.education[el].length"
                  class="subtitle-1"> обучение: </v-card-subtitle>
                <v-list-item
                  dense class="my-n4"
                  v-for="ed in [...career.education[el]]"            
                  :key = "ed.name"
                  >
                  <v-icon class="mr-1" :color="ed.complete ? 'green': 'white'">
                    done
                  </v-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      :class="ed.complete ? 'orangeDark--text': 'text--secondary'"
                      class="font-weight-regular"
                      v-text="ed.name"
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-card-subtitle v-if="career.todo[el].length" class="subtitle-1 mt-4"> условия: </v-card-subtitle>
                <v-list-item class="my-n4"
                  v-for="ed in [...career.todo[el]]"
                  :key = "ed.name"
                  dense
                  >
                  <v-icon class="mr-1" :color="ed.complete ? 'green': 'white'">
                    done
                  </v-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="font-weight-regular"
                      :class="ed.complete ? 'orangeDark--text': 'text--secondary'"
                      v-text="ed.name"
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
        <div v-else-if="!loading">
          <v-card class="pa-5 ma-md-6 mb-8 mt-10"
                hide-details 
                :style="{...style.neobtn, 'border' : '1px solid rgba(255,212,128,0.3)'}">
          <v-card-text>
            <p class="text-18 blueMain--text text-md-center">Данных по карьере не найдено</p>
          </v-card-text>  
        </v-card>
        </div>
      </v-card>
    </v-main>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import axios from 'axios'
export default {
name: 'Career',
data: () => ({
  reference: {
    'стажер': {
    color: 'orange',
    name: 'Стажер',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/2.jpg"
    },
    'юниор': {
    color: 'orange',
    name: 'Юниор',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/3.jpg"
    },
    'мастер': {
    color: 'orange',
    name: 'Мастер',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/6.jpg"
    },
    'эксперт': {
    color: 'orange',
    name: 'Эксперт',
    scr: "https://251317.selcdn.ru/oboi.ru/BlogSrc/7.jpg"
    }
  },
}),

computed: {
  ...mapState([
    'user', 'shop', 'access', 'back_domen', 'style', 'loading']),

  career: function () {
    if (Object.keys(this.user).length != 0) {
    if (this.user.career) {
      console.log(this.user.career);
      return this.user.career;}
    }
    return {};
  },     
},

methods: {
  ...mapMutations(['change_user']),

  async datauser () {
    return axios.post(this.back_domen + '/service/career', {
      'id': this.user.id,
      'shopID': 'all',
    }).then(res => res.data)
    .then(json => this.change_user(Object.assign({}, this.user,
                                   {career: json['response']})))
    .catch(err => console.warn(err))
  }  
},

watch: {
  user () {
    console.log('????', this.user);
    if (this.user.id && !this.user.career)
      this.datauser();
  }
  
},

created: function() {
  if (this.user.id)
    this.datauser();
}

}
</script>
