<template>
  <v-main class="pt-10 pb-0">
    <v-chip-group
      column
      v-model="activeTag"
      color="blueGrey"
      active-class="orangeDark white--text"
    >
      <v-chip
        small
        :style="cardinfo"
        class="mx-1 elevation-1"
        v-for="tag in tags"
        :key="tag"
        :value="tag"
      >
        {{ tag }}
      </v-chip>
    </v-chip-group>
    <v-row class="mt-1">
      <v-col v-for="post in showPost" :key="post.key" class="mb-n4">
        <v-card
          class="mx-2 Card"
          elevation="0"
          v-if="post.name"
          @click="
            open = true;
            active_article = post;
          "
        >
          <v-img
            :src="post.cover.s600x400 || post.cover.main || not_image"
            height="250px"
            max-weight="mx-auto"
          >
            <div
              class="text-18 px-3 pb-1 grey3--text white"
              style="text-align: center; display: flex; justify-content: center; align-items: center"
            >
              <div>
                <p>
                  {{ post.name }}
                </p>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="open" fullscreen>
      <v-card id="dialog">
        <v-btn
          style="position: fixed; bottom: 0px; left: 0px"
          elevation="0"
          large
          width="100%"
          @click="open = false"
          class="rounded-0 transparent grey2 orangeMain--text"
        >
          <v-icon large class="mx-3">
            mdi-keyboard-return
          </v-icon>
          <span class="text-18">
            закрыть
          </span>
        </v-btn>
        <v-card-title class="text-h5">
          <p>
            {{ active_article.name }}
          </p>
        </v-card-title>
        <v-card-text id="article_text" class="pa-2 pb-12">
          <div v-html="active_article.text"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Blog",

  data: () => ({
    posts: [],
    show: true,
    postFlex: 12,
    activeTag: [],
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
    open: false,
    active_article: {},
    cardinfo: { "border-radius": "15px", background: "#F0EFEF", color: "grey" }
  }),

  computed: {
    ...mapState([
      "shop",
      "user",
      "back_domen",
      "access",
      "style",
      "not_image",
      "jwt_domen"
    ]),

    showPost() {
      if (this.posts.length == 0) return [];
      if (this.activeTag.length == 0) return this.posts;
      return this.posts.filter(
        el => el.tags.filter(el1 => ~this.activeTag.indexOf(el1)).length > 0
      );
    },

    tags() {
      return [...new Set(this.posts.map(el => el.tags).flat())].sort();
    }
  },

  methods: {
    async getArticles() {
      return axios
        .post("https://media.service.oboi.ru/news/back/article/list", {
          section: ["sup"],
          cut: ["text"],
          sup_clients: [this.jwt_domen.split(".")[0]],
          active: true
        })
        .then(res => res.data)
        .then(
          json =>
            (this.posts = json["response"].sort((a, b) => {
              if (a.date_public < b.date_public) return 1;
              if (a.date_public > b.date_public) return -1;
              return 0;
            }))
        )
        .catch(err => console.warn(err));
    },

    async getArticle() {
      return axios
        .post("https://media.service.oboi.ru/news/back/article/list", {
          key: [this.active_article.key]
        })
        .then(res => res.data)
        .then(json => (this.active_article = json["response"][0]))
        .catch(err => console.warn(err));
    },

    check_text() {
      setTimeout(() => {
        if (this.active_article.text) {
          let elem = document.getElementById("article_text");
          let width = document.getElementById("dialog").clientWidth;
          this.active_article.text = this.active_article.text
            .split('<iframe class="ql-video"')
            .join(
              `<div style="position: relative;width:${width -
                40}px;height: ${(width - 40) /
                2}px;"><iframe <class="ql-video" style="position: absolute;width: 100%;height: 100%;"`
            )
            .split("</iframe>")
            .join("</iframe></div>");
          if (elem) {
            let img = elem.getElementsByTagName("img");
            for (let el of img)
              if (el.width > width - 40) el.width = width - 40;
          }
        }
      }, 100);
    }
  },

  watch: {
    open() {
      this.getArticle().then(() => this.check_text());
    }
  },

  created: function() {
    this.getArticles();
  }
};
</script>

<style scoped>
.Card {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  color: white;
}
p {
  text-transform: lowercase;
}

p::first-letter {
  text-transform: uppercase;
}
</style>
