<template>
<v-main
  :style="style.background"
  class="pt-0 pb-0">
  <v-card
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-3 pb-12 px-6"
    width="1600"
    height="100%">
    <!--  <v-img
            src="https://251317.selcdn.ru/api2/victoriastenova/888908/300x300/frontal.jpg?updtime=1584734634.101425"
            height="30"
            width="50"
            position="center center"
            ></v-img> -->
    <div class="d-sm-none">
      <v-alert outlined prominent
               type="warning"
               >
        экран слишком маленький для отображения<br><br>воспользуйтесь компьютером или разверните телефон горизонтально
      </v-alert>
    </div>
    <v-row class="d-none d-sm-flex">
      <v-col cols="10" class="pt-0">
        <v-row>
          <v-col>
            
            <span class="font-weight-thin">торговая марка</span>
            <v-chip-group multiple column
                          v-model="f_vendor_model">
              <v-chip label outlined
                      active-class="orangeMain"
                      class="blueMain--text"
                      v-for="item in f_vendor_items"
                      :value="item"
                      :key="item">
                {{ item }}
              </v-chip>
            </v-chip-group>
            
            <span class="font-weight-thin">категория товара</span>
            <v-chip-group multiple column
                          v-model="f_goods_model">
              <v-chip label outlined
                      active-class="orangeMain"
                      class="blueMain--text"
                      v-for="item in f_goods_items"
                      :value="item"
                      :key="item">
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              color="orange"
              v-model="search"
              append-icon="search"
              label="поиск"
              single-line
              hide-details>
            </v-text-field>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-switch
              :disabled="Object.keys(sup_data).length > 0 ? false : true"
              class="white--text"
              color="orangeDark"
              v-model="group_collection"
              label="группировать по коллекциям">
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          
          <v-card flat class="mt-n2" color="grey6">
            <v-card-text>

             <!--  <v-progress-linear indeterminate
                                 v-if="Object.keys(sup_data).length == 0"
                                 color="orangeMain"
                                 ></v-progress-linear> -->
              
              <v-data-table
                multi-sort
                loading="false"
                class="grey6"
                :search="search"
                :loading-text="loading_text"
                :headers="Object.keys(topics)
                          .map(el =>{return {'text': topics[el], 'value': el}})"
                :items="data"
                :items-per-page="100"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500, 1000]}">
                <template v-slot:item.pic="{item}">
                  <a>
                  <v-img
                    @click="item.imgFrontal ? change_pic_art(item) : ''"
                    :src="item.imgFrontal.s300x300 || not_image"
                    height="30"
                    width="50"
                    :style="style.neocard"
                    position="center center"
                    ></v-img>
                  </a>
                </template>
                <template v-slot:item.email="{ item }">
                  {{item.email ? item.email.split('@')[0] : ''}}</template>


                <template
                  v-slot:item.balance="{ item }">
                  <span v-if="!isNaN(item.balance)"
                        class="blue--text text--darken-4">{{item.balance | money}}</span></template>
                <template
                  v-slot:item.price="{ item }">
                  <div v-if="!isNaN(item.price)"
                       class="grey--text text--darken-3 d-flex flex-rows">
                    <div>{{item.price | money}}</div>
                    <v-icon color="grey lighten-2" size="12">fas fa-tag</v-icon></div></template>
                <template
                  v-slot:item.profit_balance_m="{ item }">
                  <span v-if="!isNaN(item.profit_balance_m)"
                        class="orange--text text--darken-4">{{item.profit_balance_m | money}}</span></template>
                <template
                  v-slot:item.current_balance_m="{ item }">
                  <span v-if="!isNaN(item.current_balance_m)"
                        class="blue--text text--darken-4">{{item.current_balance_m | money}}</span></template>
                <template
                  v-slot:item.proceeds="{ item }">
                  <span v-if="!isNaN(item.proceeds)"
                        >{{item.proceeds | money}}</span></template>
                <template
                  v-slot:item.turnover_ratio="{ item }">
                  <span v-if="!isNaN(item.turnover_ratio)"
                        class="blueGrey--text">{{item.turnover_ratio}}</span></template>
                <template
                  v-slot:item.turnover_ratio_day="{ item }">
                  <span v-if="!isNaN(item.turnover_ratio_day)"
                        class="blueGrey--text">{{item.turnover_ratio_day}}</span></template>

                <template v-slot:item.trend="{item}">
                  <div class="d-flex flex-rows">
                    <div>
                      <v-icon v-if="item.trend >= 20"
                              class="orange--text text--lighten-2">far fa-grin-hearts</v-icon>
                      <v-icon v-else-if="item.trend >= 0.5"
                              class="orange--text text--lighten-2">far fa-laugh-beam</v-icon>
                      <v-icon v-else-if="item.trend > 0"
                              class="orange--text text--lighten-3">far fa-smile</v-icon>
                      <v-icon v-else-if="item.trend === 0 && !item.sale"
                              class="blueLight--text text--lighten-3">far fa-dizzy</v-icon>
                      <v-icon v-else-if="item.trend === 0"
                              class="blueGrey--text text--lighten-2">far fa-meh</v-icon>
                      <v-icon v-else-if="item.trend >= -0.5"
                              class="redLight--text text--lighten-2">far fa-frown</v-icon>
                      <v-icon v-else-if="item.trend >= -20"
                              class="redLight--text text--lighten-2">far fa-angry</v-icon>
                      <v-icon  v-else-if="item.trend < -20"
                               class="redLight--text text--lighten-2">fas fa-poop</v-icon>
                    </div>
                    <div class="my-auto blueLight--text pl-2">{{item.trend}}</div>
                  </div>
                </template>
              </v-data-table>
              
            </v-card-text>
          </v-card>

        </v-row>
      </v-col>
      <v-col cols="2" class="d-flex flex-column">
        <div class="ml-auto grey4--text mb-n2">продажи</div>
        <div class="ml-auto orangeDark--text text-h5 mb-n3">{{sale | money}}</div>
        <div class="ml-auto grey--text text-h5 mb-n4">{{balance | money}}</div>
        <div class="ml-auto grey4--text">остатки</div>
        <v-switch
              :disabled="Object.keys(sup_data).length > 0 ? false : true"
              class="white--text ml-auto"
              color="grey6"
              v-model="items_state"
              label="шт">
        </v-switch>
        <v-card flat height="110" color="grey6"></v-card>


        <!-- статистика по областям -->
        <template v-if="Object.keys(state_email).length > 0">
          <div
            v-for="el in regions.filter(el => region_shop[el.key])"
              :key="el.key"
            class="d-flex flex-rows">
            <span class="grey4--text text-h2">{{el.key}}</span>
            <div class="ml-auto">
              <span class="grey4--text text-h5">{{el.name}}</span>
              <div class="text-right orangeDark--text text-h5 mt-n1">
                {{region_shop[el.key].map(el => state_email[el].proceeds)
                .reduce((acc, el) => el ? acc + el : acc) | money}}
              </div>
            </div>
            <v-card flat height="100"></v-card>
          </div>
        </template>
      </v-col>
    </v-row>
    
  </v-card>


</v-main>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import axios from 'axios'

export default {
name: 'Stenova',
data: () => ({
  regions: [
       {name: 'владимирская', 'key': 33},
       {name: 'ивановская', 'key': 37},
       {name: 'нижегородская', 'key': 52},
       {name: 'рязанская', 'key': 62},
       {name: 'самарская', 'key': 63},
       {name: 'смоленская', 'key': 67},
       {name: 'ярославская', 'key': 76}
  ],
  items_state: false,
  fltr: {'item.costprice': {'filter': 'money'},
         'item.price': {'filter': 'money'}, 
         'item.profit_balance_m': {'filter': 'money'}, 
         'item.current_balance_m': {'filter': 'money'},
         'item.average_balance_m': {'filter': 'money'}, 
         'item.proceeds': {'filter': 'money'}},
  quantity_model: '',
  quality_model: '',
  quality: ['profit_balance_m', 'proceeds', 'marjin'],
  quantity: ['shelf', 'sale'],
  search: '',
  topics: {
    "pic": "",
    "category": "тип",
    "vendor": "производитель",
    "collection": "коллекция",
    "article": "артикул",
    "trend": 'тренд',
    "balance": "остаток шт",
    "sale": "продано шт",
    "current_balance_m": "остаток ₽",
    "proceeds": "выручка ₽",
    "price": "цена ₽",
    "turnover_ratio": 'об-ть коэф',
    "turnover_ratio_day": 'об-ть дни',
  },
  loading_text: "Нет данных для рассчета",
  group_collection: false,
  group_shop: false,
  filter: [],
  f_goods_model: [],
  f_goods_items: [],
  f_vendor_model: [],
  f_vendor_items: ['Stenova Home', 'Victoria Stenova'],
  f_shop_model: [],
  f_shop_items: [],
  shop_info: [],
  seasons: {
    '4 недели': 4,
    '3 недели': 3,
    '2 недели': 2,
    '1 неделя': 1,
    'глубина тренда': 0,
    },
  period: [3, 4],
  icons: [
    'hourglass_full',
    'mdi-timer-sand',
    'mdi-timer-sand',
    'mdi-timer-sand',
    'hourglass_empty',
  ],
  open_article: false,
  opened_article: {},
  opened_data: [],
  api_data: [],
  sup_data: {},
  state_email: {}
}),

computed: {
  ...mapState(['year', 'month', 'back_domen', 'access', 'shop', 'not_image', 'style']),

  request_data () { // данные для запроса
    let result = {
      'startdate': new Date(this.year, this.month - 1, 2),
      'finishdate': new Date(this.year, this.month, 2),
      'vendor': this.f_vendor_items,
      'state_email': true,
      'group': [this.group_collection ? 'collection' : '',
                ...["article", "email"]].filter(el => el)
    };
    result = Object.entries(result)
    .reduce((acc, [ k, v ]) => v ? { ...acc, [k]: v } : acc, {});
    return result;
  },

  all_data () { // данные с примесью данных суп
    let result = [];
    if (this.group_collection) {
      result = this.api_data.filter(el => el.collection2 &&
                                          el.collection2 != '-' &&
                                          el.category == 'Обои')
        .reduce((acc, el) => {
        return {...acc, [el.collection]: {...el, article: ''}}
      }, {});
      result = Object.values(result);
    } else result = this.api_data;
    if (Object.keys(this.sup_data).length > 0){
      result = result.map(el => {
        return {...this.sup_data[!this.group_collection ?
                                 el.article : el.collection2] ?? {},
                ...el}});
    }
    return result;
  },

  data () { // отфильтрованные данные
    let result = this.all_data.filter(el => 
      (this.f_vendor_model.length == 0 ||
       ~this.f_vendor_model.indexOf(el.vendor)) &&
      (this.f_goods_model.length == 0 ||
      ~this.f_goods_model.indexOf(el.category)))
    // console.log('data', result);
    return result;
  },

  sale () { // сумма продаж
    if (Object.keys(this.sup_data).length == 0) return 0;
    // console.log(this.data.length);
    return this.data.reduce((acc, el) =>
      el.proceeds ? acc + (this.items_state ? el.sale : el.proceeds) : acc, 0);
  },

  balance () { // общий остаток
    return this.data.reduce((acc, el) => 
      el.current_balance_m ? acc + (this.items_state ? el.balance : el.current_balance_m) : acc, 0);
  },

  table_head () { // заголовки для таблицы
    if (this.data.length > 0) 
      return Object.keys(this.data[0])
      .filter(el => !~['operations', 'period', 'GUID'].indexOf(el));
    return [];
  },

  region_shop() { 
    let regions = [...new Set(Object.keys(this.state_email)
                   .map(el => el.slice(0,2)))]
    .reduce((acc, el) => {return {...acc, [el]: Object.keys(this.state_email)
                          .filter(el1 => el1.slice(0,2) == el)}}, {});
    return regions;
  }



},

methods: {

  ...mapMutations(['change_pic_art']),

  season (val) {
    return this.icons[val]
  },

  async add_api_data(filter){ //получаем даннные из апи
    axios.post('https://api.oboi.ru/application/product',
        {"filter": filter})
    .then(res => res.data)
    .then(json => {
        this.api_data = json['response'].filter(el => !el.deleted)
        .sort((a, b) => {
          if (a.collection < b.collection) return -1
          if (a.collection > b.collection) return 1
          return 0
        });
        this.f_goods_items = [...new Set(this.api_data.map(
          el => el.category))].sort();
    });
  },

  async get_data(data) { // данные из супа
    // console.log(data || this.request_data);
    this.sup_data = {};
    return axios.post(this.back_domen + '/service/abc', data || this.request_data)
    .then(res => res.data)
    .then(json => {
      this.sup_data = json['response'].goods.reduce((acc, el) => {
        return {...acc, [el.article || el.collection]: el}}, {});
      this.state_email = json['response'].state;
      // console.log(this.sup_data);
    });
  }

},

watch: {

  f_goods_model() {
    this.f_vendor_items =  [...new Set(this.api_data
      .filter(el => this.f_goods_model.length == 0 ||
                   ~this.f_goods_model.indexOf(el.category))
      .map(el => el.vendor))].sort();
  },

  f_vendor_model() {
    this.f_goods_items =  [...new Set(this.api_data
      .filter(el => this.f_vendor_model.length == 0 ||
                   ~this.f_vendor_model.indexOf(el.vendor))
      .map(el => el.category))].sort();
  },

  group_collection () {
    this.get_data();
    if (this.group_collection) this.f_goods_model = ['Обои'];
  },

  month () {
    this.get_data();
  },

  year () {
    this.get_data();
  },

  sup_data () {
    if (Object.keys(this.sup_data).length > 0 && this.api_data.length > 0
        && !this.group_collection && !this.api_data[0].collection2) {
      this.api_data = this.api_data.map(el => 
        this.sup_data[el.article] ?
          {...el, collection2: this.sup_data[el.article].collection} : el);
      // let check = this.api_data.map(el => el.article);
      // let undef_art = Object.keys(this.sup_data)
      // .filter(el => !~check.indexOf(el))
      // .map(el => [el, this.sup_data[el].collection]);
      // console.log(undef_art);
    }
  },

},

created: function() {
  this.add_api_data({vendor: this.f_vendor_items});
  this.get_data();
}

}
</script>
