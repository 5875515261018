<template>
  <v-main class="pt-0 pb-0" :style="style.background">
    <v-card
      color="grey6"
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      height="100%"
      max-width="1000"
      class="mx-auto pt-md-3  px-md-0 px-5 pb-16"
    >
      <!--     страница с заказами -->
      <v-row class="justify-center" v-show="addOrder">
        <v-col md="10">
          <v-card-actions class="justify-space-around justify-md-space-between">
            <!--      переключатель комметариев -->
            <v-switch
              label="раскрыть"
              v-model="turnSwitch"
              hide-details
              color="blueLight"
            >
            </v-switch>
            <v-btn
              color="orange lighten-2"
              dark
              large
              v-show="addOrder && check_date() && shop && all_shop_info[shop]"
              class="ml-2"
              @click="addOrder = false"
            >
              <v-icon>shopping_cart</v-icon>
              добавить заказ</v-btn
            >
          </v-card-actions>
          
          <!--  поиск среди заказов -->
          <v-text-field
            outlined
            hide-details
            class="py-0 mb-4 mt-6"
            v-bind:style="{
              ...style.neocard,
              'border-radius': '10px',
              background: focus_b > 0 ? 'white !important' : ''
            }"
            v-model="look"
            dense
            label=""
            color="orangeMain"
            append-icon="search"
            @focus="focus_b = true"
            @blur="focus_b = false"
          >
            <v-icon
              v-show="look"
              v-if="look"
              color="orangeMain"
              slot="append"
              @click="
                look = '';
                count = '';
              "
              >fas fa-backspace</v-icon
            >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center" v-show="addOrder">
        <v-col md="10">
          <!--    список заказов -->
          <v-expansion-panels multiple flat v-model="panel">
            <v-expansion-panel
              expand
              focusable
              v-for="(order, i) in orders"
              :key="i"
              class="my-3"
              :style="style.ordneo"
            >
              <v-expansion-panel-header class="grey--text">
                <v-row>
                  <v-col md="3" sm="3" xs="3">
                    <div>
                      дата
                      <div
                        class="text-18"
                        :class="
                          order.state && order.state.toLowerCase() == 'закрыт'
                            ? 'grey--text'
                            : 'blueMain--text'
                        "
                      >
                        {{ order.date | date2 }}
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      номер
                      <div
                        class="text-18"
                        :class="
                          order.state && order.state.toLowerCase() == 'закрыт'
                            ? 'grey--text'
                            : 'blueMain--text'
                        "
                      >
                        {{ order.number }}
                      </div>
                    </div>
                  </v-col>
                  <v-col md="5">
                    <div>
                      статус
                      <div
                        :class="
                          order.state &&
                          order.state.toLowerCase() == 'на согласовании'
                            ? 'orange--text text--lighten-2'
                            : order.state &&
                              (order.state.toLowerCase() == 'согласован' ||
                                order.state.toLowerCase() == 'подтвержден')
                            ? 'grey--text'
                            : 'red--text text--lighten-2'
                        "
                        class="text-18"
                      >
                        {{ order.state ? order.state.toLowerCase() : "" }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  dense
                  :style="{
                    background: 'linear-gradient(to top left, #FFFFFF, #EAEAEA'
                  }"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left body-2">
                          Артикул
                        </th>
                        <th class="text-left body-2">
                          Производитель
                        </th>
                        <th class="text-left body-2">
                          Количество,шт
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="el in order.goods"
                        :key="el.number"
                        class="blueMain--text"
                      >
                        <td>{{ el.article }}</td>
                        <td>{{ el.vendor }}</td>
                        <td>{{ el.count }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-row class="mx-1 mt-3 grey--text subtitle-2 mx-1">
                  <!--   </v-expansion-panel>
            <v-expansion-panel-content class="grey--text"> -->
                  <!-- <v-icon color="grey">insert_comment</v-icon> -->
                  <pre :style="{'font-family':'Arial'}">{{ order.comment }}</pre>
                </v-row>
                <div class="text-right text-14 grey--text mt-4">
                  менеджер: {{ order.manager }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!--     страница с добавлением заказа -->
      <div v-show="!addOrder">
        <v-row class="justify-center">
          <v-col md="10">
            <v-card-actions class="justify-end ml-4">
              <v-btn icon @click="addOrder = !addOrder">
                <v-icon size="30">close</v-icon>
              </v-btn>
            </v-card-actions>
            <!--    поиск артикула -->
            <v-text-field
              outlined
              hide-details
              id="search"
              class="mb-4 mb-md-6 mt-6"
              label="артикул"
              v-bind:style="{
                ...style.neocard,
                'border-radius': '10px',
                background: focus_a > 0 ? 'white !important' : ''
              }"
              maxlength="20"
              v-model.trim="search"
              color="orangeMain"
              @input="active_product = {}"
              @focus="focus_a = true"
              @blur="focus_a = false"
            >
              <v-icon
                v-show="search"
                v-if="search"
                color="orangeMain"
                slot="append"
                @click="
                  search = '';
                  count = '';
                "
                >fas fa-backspace</v-icon
              >
            </v-text-field>

            <!--      добавление количества -->
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      hide-details
                      color="orangeMain"
                     
                      v-bind:style="{
                        ...style.neocard,
                        'border-radius': '10px',
                        background: focus_col > 0 ? 'white !important' : ''
                      }"
                      label="количество"
                      maxlength="3"
                      v-model="count"
                      @keydown="check_number($event)"
                      @focus="focus_col = true"
                      @blur="focus_col = false"
                    >
                      <v-icon
                        v-show="count"
                        v-if="count"
                        color="orangeMain"
                        slot="append"
                        @click="count = ''"
                        >fas fa-backspace</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" sm="6">
                    <v-btn
                      @click="count > 1 ? count-- : (count = '')"
                      class="mx-3 mx-md-0"
                      height="40"
                      :style="{ ...style.neobtn, ...style.redborder }"
                      >-</v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="pt-2 text-right">
                    <v-btn
                      @click="count++"
                      class="ml-n2 ml-md-n0 mx-3 mx-md-0"
                      height="40"
                      :style="{ ...style.neobtn, ...style.greenborder }"
                      >+</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="text-md-right text-center">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="i in 6"
                    :key="'row1' + i"
                  >
                    <v-btn
                      class="mb-md-3 mb-sm-3" 
                      height="40"
                      @click="count = i"
                      :style="style.neobtn"
                      >{{ i }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!--   кнопуля добавления в заказ + картинка -->
            <v-row
              class="mt-md-3"
              v-if="Object.keys(active_product).length > 0"
            >
              <v-col md="4">
                <div v-if="count && search">
                  <v-btn
                    text
                    @click="save()"
                    color="blueMain"
                    :style="{ ...style.neobtn, 'border-radius': '10px' }"
                  >
                    <v-icon color="green lighten-2"> check</v-icon>
                    добавить в заказ
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="4" md="3">
                <div v-if="count && search">
                  <v-btn
                    text
                    color="blueMain"
                    :style="{ ...style.neobtn, 'border-radius': '10px' }"
                  >
                    <v-icon color="red lighten-2"> clear</v-icon>
                    отмена
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="5" v-if="search">
                <v-img
                  height="150"
                  :src="
                    api_data[active_product.Артикул] &&
                    api_data[active_product.Артикул].imgFrontal.s600x400
                      ? api_data[active_product.Артикул].imgFrontal.s600x400
                      : active_product.pic600x400 ||
                        active_product.pic100x100 ||
                        not_image
                  "
                  :style="{ 'border-radius': '10px' }"
                ></v-img>
                <v-row>
                  <v-col class="grey4--text text-14" cols="4">
                    <div>{{ active_product.Артикул }}</div>
                    <div class="mt-n2">{{ active_product.Коллекция }}</div>
                  </v-col>
                  <v-col class="text-end grey4--text text-14" cols="8">
                    <div>у поставщика:{{active_product.ОстатокНаСкладе || 0}}</div>
                    <div class="mt-n2">свободно у поставщика:{{active_product.СвободныйОстаток || 0}}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!--   таблица с заказом -->
        <v-row class="justify-center">
          <v-col cols="12" md="10">
            <v-data-table
              :headers="headers"
              :items="new_order"
              :items-per-page="1000"
              hide-default-footer
              :style="{ background: '#F2F2F2' }"
              disable-sort
              align="end"
              loading="false"
              loading-text="в заказ еще ничего не добавлено"
              mobile-breakpoint="0"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  @click="
                    new_order = new_order.filter(
                      el => el.Артикул != item.Артикул
                    )
                  "
                  >delete_forever</v-icon
                >
              </template>
            </v-data-table>
            <v-row>
              <v-textarea
                outlined
                v-model="comment"
                class="mx-3 mt-6"
                color="orangeMain"
                label="Комментарий к заказу"
                dense
                height="100"
                hide-details
                v-bind:style="{
                  ...style.neocard,
                  'border-radius': '10px',
                  background: focus_com > 0 ? 'white !important' : ''
                }"
                @focus="focus_com = true"
                @blur="focus_com = false"
              ></v-textarea>
            </v-row>
            <v-card-actions>
              <v-row class="justify-center my-6">
                <v-col md="8">
                  <v-btn
                    dark
                    block
                    color="orange lighten-2"
                    v-if="new_order.length > 0"
                    @click="dialog = true"
                  >
                    отправить в 1С
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>

        <!--  диалог -->
        <v-row>
          <v-dialog v-model="dialog" height="300" width="300">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-18">отправить заказ в 1С?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blueGrey" text @click="dialog = false">
                  нет
                </v-btn>
                 <v-btn
                  color="blueGrey"
                  text
                  @click="add_order1C().then(() => (dialog = false))"
                >
                  да
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </v-card>

    <!-- добавление продажи уведомление-->
    <v-snackbar
      top
      right
      :timeout="1500"
      v-model="addresult"
      :color="~message.indexOf('ошибка') ? 'error' : 'success'"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="addresult = false">
          <v-icon small>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    orders: [], // список заказов из 1С
    panel: [], // список развернутых заказов
    headers: [
      // заголовки для таблицы с заказом
      { text: "артикул", value: "Артикул", align: "center" },
      { text: "коллекция", value: "Коллекция", align: "center" },
      { text: "количество", value: "count", align: "center" },
      { text: "цена", value: "Цена", align: "center" },
      { text: "", value: "action", align: "center" }
    ],
    search: "", // строка поиска артикула в заказе
    look: "", // строка поиска в списке заказов
    addOrder: true, // отображение компонента с формированием заказа
    turnSwitch: false, // раскрытие списка с заказами
    dialog: false, // подтвердение отправки в 1С
    addresult: false, // всплывалка с результатом отправки
    message: "", // результат отправки в 1С
    new_order: [], // товары для нового заказа
    count: "", // ячейка с количеством товара
    comment: "", // комментарий к заказу
    products: [], // список товаров для поиска
    active_product: {}, // найденный артикул
    full_products: [], // список расширенных товаров для поиска
    copy_products: [], // расширенные товары предыдущего поиска
    focus_b: false,
    focus_a: false,
    focus_col: false,
    focus_com: false
  }),

  computed: {
    ...mapState([
      "month",
      "year",
      "not_image",
      "style",
      "shop",
      "access",
      "back_domen",
      "loading",
      "api_data",
      "user",
      "all_shop_info",
      "fullname",
      "username"
    ]),

    search_products() {
      // найденые по списку соответствий продукты
      if (!this.search) return [];
      let check = ["Артикул", "ТорговаяМарка", "Коллекция"];
      let result = this.products.filter(
        el =>
          ~check
            .map(el1 => el[el1])
            .join(" ")
            .toLowerCase()
            .indexOf(this.search.toLowerCase())
      );
      if (result.length < 13) return result;
      return [];
    }
  },

  methods: {
    ...mapActions(["add_api_data"]),

    async orders1C() {
      // получение заказов
      if (!this.all_shop_info[this.shop]) return;
      return axios
        .post(this.back_domen + "/service/orders1C", {
          year: this.year,
          month: this.month,
          email: this.all_shop_info[this.shop].email
        })
        .then(res => res.data)
        .then(
          json =>
            (this.orders = json["response"]
              .filter(el => {
                let date = new Date(el.date);
                return (
                  date.getFullYear() == this.year &&
                  date.getMonth() + 1 == this.month
                );
              })
              .sort((a, b) => new Date(b.date) - new Date(a.date)))
        );
    },

    async add_order1C() {
      // отправляем заказ
      if (!this.all_shop_info[this.shop] ||
          !this.all_shop_info[this.shop].email) return;
      let order = {
        email: this.all_shop_info[this.shop].email,
        comment: this.comment,
        manager: this.access == "shop" ? this.user.name : this.fullname,
        manager_phone: this.access == "shop" ? this.user.phone : this.username,
        goods: this.new_order.map(el => {
          return {
            vendor: el.ТорговаяМарка,
            article: el.Артикул,
            count: el.count
          };
        })
      };
      return axios
        .post(this.back_domen + "/service/add_order1C", order)
        .then(res => res.data)
        .then(json => {
          if (json.response.number) {
            this.new_order = [];
            this.comment = "";
            this.orders.unshift(json.response);
            this.addOrder = true;
          }
          this.message = json.response.state;
          this.addresult = true;
        });
    },

    check_date() {
      // проверяем возможность правки по дате
      let date = new Date();
      let date2 = new Date(this.year, this.month - 1, date.getDate() + 1);
      if (date2 >= date) return true;
      return false;
    },

    check_number(event) {
      // вводим только цифры
      if (event.keyCode != 13 && event.keyCode != 8 && event.keyCode != 9) {
        if (isNaN(event.key) || +event.key == 0) event.preventDefault();
      }
    },

    save() {
      // добавляем позицию в заказ
      let product = this.new_order.filter(
        el => el.Артикул == this.active_product.Артикул
      );
      if (product.length == 1) {
        product[0].count += this.count;
      } else this.new_order.push({ ...this.active_product, count: this.count });
      this.count = 0;
      this.search = "";
      // console.log(this.new_order);
      // console.log(this.new_order.length);
    },

    async get_products() {
      // получаем список продуктов
      if (!this.shop) return;
      return axios
        .post(this.back_domen + "/service/productlist", {api: true})
        .then(res => res.data)
        .then(js => (this.products = js["response"]));
    },

    async get_full_products() {
      // получаем расширенные продукты
      return axios
        .post(this.back_domen + "/service/product", {
          products: this.search_products,
          api: true
        })
        .then(res => res.data)
        .then(json => (this.full_products = json["response"]));
    },

    find_product_full: function (el) {
      for (let prod of this.full_products){
        let result = Object.values(prod).filter(
          val => val && ~Object.values(el).indexOf(val));
        // console.log(el, prod, result);
        if (result.length == Object.values(el).filter(el => el).length)
          return prod;
      }
      return {};
    },

    focus(id, focus) {
      // снимаем фокус с элемента
      let el = document.getElementById(id);
      focus ? el.click() : el.blur();
    }
  },

  watch: {
    all_shop_info() {
      // перезапрос заказов по получению инфы по магазам
      if (!this.orders.length) this.orders1C();
    },

    turnSwitch() {
      // скрытие и раксрытие заказов по переключателю
      if (this.turnSwitch)
        this.panel = [...Array(this.orders.length)].map((k, i) => i);
      else this.panel = [];
    },

    orders() {
      // скрытие и раксрытие заказов при изменении списка заказов
      if (this.turnSwitch)
        this.panel = [...Array(this.orders.length)].map((k, i) => i);
      else this.panel = [];
    },

    month() {
      // загузка заказов при смене даты
      this.orders1C();
    },

    year() {
      // загузка заказов при смене даты
      this.orders1C();
    },

    search_products() {
      // запрашиваем новые продукты при изменении результатов поиска
      if (this.search_products.length > 0) {
        let set1 = this.search_products.map(el => el.Артикул);
        let set2 = new Set(this.copy_products.map(el => el.Артикул));
        if ([...set1].filter(el => !set2.has(el)).length > 0) {
          this.full_products = [];
          this.get_full_products();
          this.copy_products = [...this.search_products];
        }
      }
      let res = [];
      setTimeout(() => {
        if (this.search_products.length > 1)
          res = this.search_products.filter(
            el => el.Артикул == this.search);
          if (res.length == 1)
            this.active_product = this.find_product_full(res[0]);
      }, 1000);
      // console.log(this.search_products);
      // автоматическое открытие продукта, если найден только один
      if (
        this.search_products.length == 1 &&
        Object.keys(this.active_product).length == 0
      )
        this.active_product = this.find_product_full(this.search_products[0]);

    },

    active_product() {
      // заполняем строку поиска при выборе продукта  и снимаем фокус
      if (Object.keys(this.active_product).length > 0) {
        this.search = Object.values(this.active_product)
          .filter(
            el =>
              typeof el == "string" &&
              ~el.toLowerCase().indexOf(this.search.toLowerCase())
          )[0]
          .toLowerCase();
        this.focus("search", false);
      }
      // запрашиваем данные из апи
      if (this.active_product.Артикул)
        this.add_api_data([this.active_product.Артикул]);
    },

    search() {
      // при удалении поискового запроса чистим результат
      if (!this.search) this.active_product = {};
    },

    full_products() {
      // ищем продукт при изменении списка полных
      if (this.full_products.length > 0 && this.search_products.length == 1)
        this.active_product = this.find_product_full(this.search_products[0]);
    },

    shop() {
      // обновляем заказы и товары при смене магазина
      this.orders1C();
      if (this.shop) {
        this.active_product = {};
        this.get_products().then(() => {
          if (this.search_products.length > 0) {
            let set1 = this.search_products.map(el => el.Артикул);
            let set2 = new Set(this.copy_products.map(el => el.Артикул));
            if ([...set1].filter(el => !set2.has(el)).length > 0) {
              this.full_products = [];
              this.get_full_products();
              this.copy_products = [...this.search_products];
            }
          }
        });
      }
    },

    addresult() {
      // очищаем сообщение при закрытии окна
      if (!this.addresult) this.message = "";
    }
  },

  created: function() {
    this.orders1C();
    this.get_products();
  }
};
</script>
