import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        orangeMain: "#FFAA00",
        orangeDark: "#F39314",
        blueMain: "#383F61",
        blueGrey: "#686F8A",
        blueLight: "#868EAA",
        blueMiddle: "#B4BACE",
        blueDark: "#2D324B",
        redLight: "#FF8282",
        greenLight: "#6FDE00",
        grey1: "#333333",
        grey2: "#4F4F4F",
        grey3: "#828282",
        grey4: "#BDBDBD",
        grey5: "#E0E0E0",
        grey6: "#F2F2F2",
        grey7: "#FBFBFB",
        newstyle8newstyle8newstyle8newstyle: "#FFFFFF",
        first1: "#FFAA00",
        first2: "#F39314",
        second1: "#B4BACE",
        second2: "#868EAA",
        second3: "#383F61",
        second4: "#2D324B",
        error1: "#FF8282",
        error2: "#FF8282",
        success1: "#6FDE00",
        success2: "#6FDE00",
        background1: "#000000",
        background2: "#333333",
        background3: "#4F4F4F",
        background4: "#828282",
        background5: "#BDBDBD",
        background6: "#E0E0E0",
        background7: "#F2F2F2",
        background8: "#FBFBFB",
        background9: "#FFFFFF",
      },
      dark: {
        orangeMain: "#FFAA00",
        orangeDark: "#F39314",
        blueMain: "#383F61",
        blueGrey: "#686F8A",
        blueLight: "#868EAA",
        blueMiddle: "#B4BACE",
        blueDark: "#2D324B",
        redLight: "#FF8282",
        greenLight: "#6FDE00",
        grey7: "#333333",
        grey6: "#4F4F4F",
        grey5: "#828282",
        grey4: "#FFAA00",
        grey3: "#333333",
        grey2: "#F2F2F2",
        grey1: "#FBFBFB",
      },
    },
  },
});
