<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pb-12 pr-1"
      width="1800"
      height="100%"
    >
      <div class="d-sm-none">
        <v-alert outlined prominent type="warning">
          экран слишком маленький для отображения<br /><br />воспользуйтесь
          компьютером или разверните телефон горизонтально
        </v-alert>
      </div>
      <v-card flat class="mx-auto pt-4 px-6 mb-4" width="1800" height="230">
        <v-row>
          <v-col cols="1" class="d-flex px-0">
            <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- фильтр магазины  -->
            <v-card flat width="20" class="mt-12 pt-3">
              <p
                class="text-14 grey--text d-flex flex-nowrap"
                style="transform: rotate(-90deg);"
              >
                магазины
              </p>
            </v-card>
            <div class="ml-1 mt-1">
              <div v-if="!f_shop_items.length">
                <v-skeleton-loader
                  v-for="el in 4"
                  :key="el"
                  class="pl-3 mb-n3"
                  type="table-cell"
                ></v-skeleton-loader>
              </div>
              <v-chip-group multiple column v-model="f_shop_model">
                <v-chip
                  label
                  class="px-1 mt-n2"
                  color="white"
                  active-class="blueMain white--text"
                  v-for="item in f_shop_items"
                  :value="item"
                  :key="item"
                >
                  {{ item.split("@")[0] }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-col>

          <v-col cols="6" class="d-flex px-0">
            <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- фильтр товары  -->
            <v-card flat width="20" class="pt-8">
              <p
                class="text-14 grey--text d-flex flex-nowrap"
                style="transform: rotate(-90deg);"
              >
                товар
              </p>
            </v-card>

            <div class="ml-1 mt-n1">
              <v-card
                v-if="!f_shop_items.length"
                class="mt-6 px-3"
                flat
                width="840"
              >
                <v-skeleton-loader
                  class="mb-3"
                  type="text, paragraph@2"
                ></v-skeleton-loader>
              </v-card>

              <v-chip-group multiple column v-model="f_goods_model">
                <v-chip
                  label
                  class="px-1"
                  color="white"
                  active-class="blueMain white--text"
                  v-for="item in f_goods_items"
                  :value="item"
                  :key="item"
                >
                  {{ item.toLowerCase() }}
                </v-chip>
              </v-chip-group>

              <v-chip-group
                multiple
                column
                class="mt-n2"
                v-model="f_vendor_model"
              >
                <v-chip
                  label
                  small
                  class="px-1 mt-n1"
                  color="white"
                  active-class="grey darken-4 white--text"
                  v-for="item in f_vendor_items"
                  :value="item"
                  :key="item"
                >
                  <span class="deep-orange--text text--accent-3">
                    {{ item.toUpperCase().slice(0, 1) }}
                  </span>
                  {{ item.toLowerCase().slice(1) }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-col>

          <v-col cols="5">
            <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- выбор периода -->
            <v-row>
              <v-col class="px-0 d-flex justify-end" cols="4">
                <v-menu
                  :close-on-content-click="close_menu"
                  :nudge-width="280"
                  bottom
                  left
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="mt-3 pr-1 d-flex ">
                      <div>
                        <div class="text-h3 blueLight--text text-right pt-1">
                          {{
                            Math.round(
                              (finishdate - startdate) / (24 * 3600 * 1000) + 1
                            )
                          }}<span class="text-18 mr-3">дн</span>
                        </div>
                      </div>
                      <div class="blueMain--text">
                        <div cols="5" class="pt-1 pl-n4">
                          <div class="text-right">
                            с {{ ("0" + startdate.getDate()).slice(-2) }}.<span
                              class="orange--text text--darken-3"
                              >{{
                                ("0" + (startdate.getMonth() + 1)).slice(-2)
                              }}</span
                            >.{{ startdate.getFullYear() }}
                          </div>
                          <div class="text-right">
                            по
                            {{ ("0" + finishdate.getDate()).slice(-2) }}.<span
                              class="orange--text text--darken-3"
                              >{{
                                ("0" + (finishdate.getMonth() + 1)).slice(-2)
                              }}</span
                            >.{{ finishdate.getFullYear() }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <v-card class="d-flex">
                    <v-date-picker
                      v-model="dates"
                      @change="dates.sort()"
                      range
                      color="orangeDark"
                      locale="ru"
                      min
                    ></v-date-picker>
                    <div class="pl-5 pt-9 mt-12 d-flex flex-column align-start">
                      <div class="blueLight--text">
                        глубина отбора
                      </div>
                      <v-btn color="blueMain" text @click="change = 1">
                        1 месяц
                      </v-btn>
                      <v-btn color="blueMain" text @click="change = 2">
                        2 месяца
                      </v-btn>
                      <v-btn color="blueMain" text @click="change = 3">
                        3 месяца
                      </v-btn>
                      <v-btn color="blueMain" text @click="change = 4">
                        4 месяца
                      </v-btn>
                      <v-btn color="blueMain" text @click="change = 5">
                        5 месяцев
                      </v-btn>
                      <v-btn color="blueMain" text @click="change = 6">
                        6 месяцев
                      </v-btn>
                    </div>
                  </v-card>
                </v-menu>

                <v-card flat width="20" class="mt-3">
                  <p
                    class="text-14 grey--text d-flex flex-nowrap"
                    style="transform: rotate(90deg);"
                  >
                    период
                  </p>
                </v-card>
              </v-col>
              <v-col class="px-0 d-flex justify-end" cols="8">
                <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- выбор режима -->
                <v-menu :close-on-content-click="close_menu" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="mt-2 pr-1" v-bind="attrs" v-on="on">
                      <div class="text-h3 blueLight--text text-right pt-1">
                        {{preset[preset_model].name}}
                      </div>
                      <div class="text-right blueLight--text mt-n1">
                        {{
                          Object.keys(quality).filter(
                            el => quality[el] == quality_model
                          )[0]
                        }}/{{
                          Object.keys(quantity).filter(
                            el => quantity[el] == quantity_model
                          )[0]
                        }}
                      </div>
                    </div>
                  </template>
                  <v-card class="pa-5 pt-8" width="900">
                    <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- меню режима.1 -->

                    <v-btn color="blueLight" class="text-h3 mt-3" text
                      @click="preset_model='main'">
                       {{preset.main.name}}
                    </v-btn>
                    <v-row>
                      <v-col cols="4" class="pl-9">
                        <v-row>
                          <v-col cols="2" class="d-flex align-center">
                            <div
                              class="text-h4 light-green--text text--lighten-3 text-center"
                            >
                              X
                            </div></v-col
                          >
                          <v-col cols="10">
                            <v-radio-group v-model="quality_model">
                              <v-radio
                                color="green"
                                v-for="(v, k) in quality"
                                :key="k"
                                :label="k"
                                :value="v"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="2" class="d-flex align-center mt-n6">
                            <div
                              class="text-h4 orange--text text--lighten-3 text-center"
                            >
                              Y
                            </div></v-col
                          >
                          <v-col cols="10">
                            <v-radio-group
                              class="mt-n3"
                              v-model="quantity_model"
                            >
                              <v-radio
                                color="orange"
                                v-for="(v, k) in quantity"
                                :key="k"
                                :label="k"
                                :value="v"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="8" class="pt-9">
                        <div class="blueMain--text">
                          <span class="light-green--text text--darken-3"
                            >прибыль</span
                          >/<span class="orangeMain--text text--darken-4"
                            >ассортимент</span
                          >
                          - артикулы по прибыльности
                        </div>
                        <div class="blueMain--text">
                          <span class="light-green--text text--darken-3"
                            >выручка</span
                          >/<span class="orangeMain--text text--darken-4"
                            >ассортимент</span
                          >
                          - артикулы по выручке
                        </div>
                        <div class="blueMain--text mt-6">
                          <span class="light-green--text text--darken-3"
                            >прибыль</span
                          >/<span class="orangeMain--text text--darken-4"
                            >продано шт</span
                          >
                          - самые продаваемые артикулы по прибыльности
                        </div>
                        <div class="blueMain--text">
                          <span class="light-green--text text--darken-3"
                            >выручка</span
                          >/<span class="orangeMain--text text--darken-4"
                            >продано шт</span
                          >
                          - самые продаваемые артикулы по выручке
                        </div>
                      </v-col>
                    </v-row>
                    <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- меню режима.2 -->
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="blueLight" class="text-h4 mt-5" text
                          @click="preset_model='marjin'">
                          {{preset.marjin.name}}
                        </v-btn>

                        <div class="grey--text text--darken-1 pt-2 pl-8">
                          посмотри на картину в целом - эффективность работы
                          вложений (группировака по маржинальности)
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <v-btn color="blueLight" class="text-h4 mt-5" text
                         @click="preset_model='purchase'">
                          {{preset.purchase.name}}
                        </v-btn>

                        <div class="grey--text text--darken-1 pt-2 pl-8">
                          следи за важным ассортиментом - товар приносящий
                          прибыль заканчивается
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <v-btn color="blueLight" class="text-h4 mt-5" text
                          @click="preset_model='high_price'">
                          {{preset.high_price.name}}
                        </v-btn>

                        <div class="grey--text text--darken-1 pt-2 pl-8">
                          повысь привлекательность - товар c плохими продажами и
                          высокой маржинальностью
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <v-btn color="blueLight" class="text-h4 mt-5" text
                        @click="preset_model='low_price'">
                          {{preset.low_price.name}}
                        </v-btn>

                        <div class="grey--text text--darken-1 pt-2 pl-8">
                          заработай больше - товар c хорошими продажами и низкой
                          маржинальностью
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <v-btn color="blueLight" class="text-h4 mt-5" text
                         @click="preset_model='sale'">
                          {{preset.sale.name}}
                        </v-btn>

                        <div class="grey--text text--darken-1 pt-2 pl-8">
                          освободи денги - товар в котором надолго заморожены
                          денежные средства
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>
                <v-card flat width="20" class="mt-3">
                  <p
                    class="text-14 grey--text d-flex flex-nowrap"
                    style="transform: rotate(90deg);"
                  >
                    режим
                  </p>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field
                class="mt-2"
                color="orangeDark"
                v-model="search"
                clearable
                label="поиск в результатах"
                outlined
                hide-details
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- таблица -->
      <v-card flat class="grey6" min-height="1200">
        <div id="particles-js" v-show="!data.length || calculate"></div>
        <v-row>
          <v-col cols="2">
            <v-card
              color="grey6"
              width="290"
              class="ml-6 mt-n8 px-4 d-flex"
              :style="{ 'border-radius': '8px' }"
            >
              <v-switch
                hide-details
                class="mt-0 mb-1"
                color="orangeMain"
                v-model="group_shop"
                :disabled="preset_model == 'marjin' ? true : false"
              ></v-switch>
              <div class="grey--text mt-1">группировать магазины</div>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
              color="grey6"
              width="290"
              class="ml-8 mt-n8 px-4 d-flex"
              :style="{ 'border-radius': '8px' }"
            >
              <v-switch
                hide-details
                class="mt-0 mb-1"
                color="orangeMain"
                v-model="group_collection"
                :disabled="preset_model == 'marjin' ? true : false"
              ></v-switch>
              <div class="grey--text mt-1">группировать коллекции</div>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
              color="grey6"
              width="290"
              class="ml-8 mt-n8 px-4 d-flex"
              :style="{ 'border-radius': '8px' }"
            >
              <v-switch
                hide-details
                class="mt-0 mb-1"
                color="orangeMain"
                v-model="group_vendor"
                :disabled="preset_model == 'marjin' ? true : false"
              ></v-switch>
              <div class="grey--text mt-1">группировать бренды</div>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="calculate"
              color="orangeMain"
              class="white--text ml-n8"
              :style="{ 'border-radius': '8px' }"
              @click="add_data()"
              >рассчитать</v-btn
            >
          </v-col>
          <v-col cols="2"> </v-col>
          <v-col cols="2">
            <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- настройка полей -->
            <v-menu :close-on-content-click="false" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  color="grey6"
                  width="190"
                  class="ml-12 mt-n8 px-4 d-flex"
                  :style="{ 'border-radius': '8px' }"
                >
                  <v-spacer> </v-spacer>
                  <v-icon class="mr-2 grey--text">
                    fas fa-ellipsis-h
                  </v-icon>
                  <div class="grey--text my-1">настроить поля</div>
                </v-card>
              </template>

              <v-card class="d-flex">
                <v-chip-group class="ma-2" column>
                  <v-chip
                    label
                    v-for="el in hide_topics"
                    :key="el"
                    class="blue-grey--text"
                    @click="hide_topics = hide_topics.filter(el1 => el1 != el)"
                    outlined
                    color="orange"
                  >
                    {{ topics_any[el] }}
                  </v-chip>
                </v-chip-group>
                <v-chip-group class="ma-2" column>
                  <v-chip
                    label
                    v-for="el in table_headers.filter(
                      el => !~hide_topics.indexOf(el.value)
                    )"
                    :key="el.value"
                    class="blue-grey--text"
                    @click="hide_topics = [...hide_topics, el.value]"
                    :disabled="~[...(preset[preset_model].required || []),
                                 ...(Object.keys(preset[preset_model].sort || []))]
                                 .indexOf(el.value)
                              ? true : false"
                    outlined
                    color="grey"
                  >
                    {{ topics_any[el.value] }}
                  </v-chip>
                </v-chip-group>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-card-text class="pt-0" min-height="1000">
          <!-- заголовки фильтруем в зависимости от выбранных галок группировки -->

          <!-- <v-skeleton-loader
               class="mt2"
               color="grey"
               type="table"
               boilerplate
               ></v-skeleton-loader> -->

          <v-data-table v-if="!calculate && data.length"
            multi-sort
            loading="false"
            class="grey6"
            :search="search"
            :sort-by="Object.keys(preset[preset_model].sort || {})"
            :sort-desc="Object.values(preset[preset_model].sort || {})"
            :loading-text="
              calculate || !data.length ? loading_text.no : loading_text.yes
            "
            :headers="
              table_headers.filter(el => !~hide_topics.indexOf(el.value))
            "
            :items="calculate ? [] : data"
            :items-per-page="100"
            :footer-props="{
              'items-per-page-options': [10, 50, 100, 500, 1000]
            }"
          >
            <template v-slot:item.new="{ item }">
              <span
                v-if="item.new"
                class="green--text"
                >новинка</span
              ></template
            >
            <template v-slot:item.balance="{ item }">
              <span
                v-if="!isNaN(item.balance)"
                class="blue--text text--darken-4"
                >{{ item.balance | money }}</span
              ></template
            >
            <template v-slot:item.costprice="{ item }">
              <div
                v-if="!isNaN(item.costprice)"
                class="blue--text text--darken-4 d-flex flex-rows"
              >
                <div>{{ item.costprice | money }}</div>
                <v-icon color="blue lighten-4" size="12">fas fa-tag</v-icon>
              </div></template
            >
            <template v-slot:item.price="{ item }">
              <div
                v-if="!isNaN(item.price)"
                class="grey--text text--darken-3 d-flex flex-rows"
              >
                <div>{{ item.price | money }}</div>
                <v-icon color="grey lighten-2" size="12">fas fa-tag</v-icon>
              </div></template
            >
            <template v-slot:item.current_balance_m="{ item }">
              <span
                v-if="!isNaN(item.current_balance_m)"
                class="blue--text text--darken-4"
                >{{ item.current_balance_m | money }}</span
              ></template
            >
            <template v-slot:item.marjin_gr="{ item }">
              <span class="orange--text text--darken-2"
                >{{ item.marjin_gr }}
              </span></template
            >
            <template v-slot:item.marjin="{ item }">
              <span
                v-if="!isNaN(item.marjin)"
                class="orange--text text--darken-4"
                >{{ item.marjin }}</span
              ></template
            >
            <template v-slot:item.profit_balance_m="{ item }">
              <span
                v-if="!isNaN(item.profit_balance_m)"
                class="orange--text text--darken-4"
                >{{ item.profit_balance_m | money }}</span
              ></template
            >
            <template v-slot:item.abc="{ item }">
              <span v-if="item.abc == 'A'" class="green--text text--darken-2">{{
                item.abc
              }}</span>
              <span v-if="item.abc == 'B'" class="grey--text">{{
                item.abc
              }}</span>
              <span v-if="item.abc == 'C'" class="red--text text--darken-3">{{
                item.abc
              }}</span></template
            >
            <template v-slot:item.proceeds="{ item }">
              <span v-if="!isNaN(item.proceeds)">{{
                item.proceeds | money
              }}</span></template
            >
            <template v-slot:item.turnover_ratio="{ item }">
              <span v-if="!isNaN(item.turnover_ratio)" class="blueGrey--text">
                {{ item.turnover_ratio }}
              </span></template
            >
            <template v-slot:item.turnover_ratio_day="{ item }">
              <span
                v-if="!isNaN(item.turnover_ratio_day)"
                class="blueGrey--text"
                >{{ item.turnover_ratio_day }}</span
              ></template
            >

            <template v-slot:item.email="{ item }">{{
              item.email ? item.email.split("@")[0] : ""
            }}</template>
            <template v-slot:item.article="{ item }">
              <a
                class="blueDark--text"
                @click="
                  open_article = true;
                  add_article(item);
                "
                >{{ item.article }}</a
              >
            </template>

            <template v-slot:item.trend="{ item }">
              <div class="d-flex flex-rows">
                <div>
                  <v-icon
                    v-if="item.trend >= 20"
                    class="orange--text text--lighten-2"
                    >far fa-grin-hearts</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend >= 0.5"
                    class="orange--text text--lighten-2"
                    >far fa-laugh-beam</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend > 0"
                    class="orange--text text--lighten-3"
                    >far fa-smile</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend === 0 && !item.sale"
                    class="blueLight--text text--lighten-3"
                    >far fa-dizzy</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend === 0"
                    class="blueGrey--text text--lighten-2"
                    >far fa-meh</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend >= -0.5"
                    class="redLight--text text--lighten-2"
                    >far fa-frown</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend >= -20"
                    class="redLight--text text--lighten-2"
                    >far fa-angry</v-icon
                  >
                  <v-icon
                    v-else-if="item.trend < -20"
                    class="redLight--text text--lighten-2"
                    >fas fa-poop</v-icon
                  >
                </div>
                <div class="my-auto blueLight--text pl-2">{{ item.trend }}</div>
              </div>
            </template>

            <template v-slot:item.pic="{ item }">
              <a>
                <v-img
                  :style="style.neocard"
                  @click="
                    api_data[item.article]
                      ? change_pic_art(api_data[item.article])
                      : ''
                  "
                  :src="
                    api_data[item.article]
                      ? api_data[item.article].imgFrontal.s300x300
                      : not_image
                  "
                  height="30"
                  width="50"
                  position="center center"
                ></v-img
              ></a>
            </template>
          </v-data-table>
          <v-card :style="style.neocard" class="mt-8 px-4" width="800">
            <v-row v-if="!calculate && data.length">
              <v-col cols="12" md="5">
                <div class="d-flex text-18 blueGrey--text mb-1">
                  <span class="mr-auto">занято полок</span
                  ><span>{{ analytics.shelf || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> A</span
                  ><span>{{ analytics.shelfA || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> B</span
                  ><span>{{ analytics.shelfB || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> C</span
                  ><span>{{ analytics.shelfC || 0 | money }}</span>
                </div>
              </v-col>
              <v-col cols="0" md="1"> </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex text-18 blueGrey--text mb-1">
                  <span class="mr-auto">вложено денег</span
                  ><span>{{ analytics.money || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> A</span
                  ><span>{{ analytics.moneyA || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> B</span
                  ><span>{{ analytics.moneyB || 0 | money }}</span>
                </div>
                <div class="d-flex">
                  <span class="mr-auto"
                    ><span class="blueLight--text">категория</span> C</span
                  ><span>{{ analytics.moneyC || 0 | money }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>

    <!-- окно при нажатии на артикул -->
    <v-menu
      z-index="100"
      v-model="open_article"
      :close-on-content-click="false"
    >
      <v-card class="px-6 pb-6 pt-3">
        <div class="text-right mb-n12" style="">
          <v-btn
            icon
            large
            class="mb-n8 mr-n3"
            color="orangeDark"
            @click="open_article = false"
            :style="{ 'z-index': '100' }"
          >
            <v-icon>fas fa-arrow-down</v-icon>
          </v-btn>
        </div>
        <abc
          class="mt-n4"
          :data="opened_data"
          :article="opened_article"
          :shop="f_shop_model.length ? f_shop_model : f_shop_items"
          :shop_info="Object.values(all_shop_info)"
        ></abc>
      </v-card>
    </v-menu>
  </v-main>
</template>

<script src="particles.js"></script>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import axios from "axios";
import ABC_Collection from "./collection";
import ParticlesJS from "particles.js";

export default {
  name: "ABC",
  components: {
    abc: ABC_Collection
  },
  data: () => ({
    data: [],
    dates: [],
    quantity_model: localStorage.getItem("quantity_model") || "shelf",
    quality_model: localStorage.getItem("quality_model") || "profit_balance_m",
    quality: { прибыль: "profit_balance_m", выручка: "proceeds" },
    quantity: { ассортимент: "shelf", "продано шт": "sale" },
    search: "",
    preset: {'main': {'name': 'основной'},
             'marjin': {'name': 'маржа',
                        'quantity_model': 'shelf',
                        'quality_model': 'profit_balance_m',
                        'fields': ['trend', 'abc', 'balance',
                                   'sale', 'current_balance_m',
                                   'proceeds', 'costprice', 'marjin_gr',
                                   'profit_balance_m'],
                        'group_marjin': true,
                        'group_shop': true,
                        'required': ['marjin_gr'],
                        'sort': {'profit_balance_m': true}},
             'purchase': {'name': 'закупка',
                          'sort': {'abc': false,
                                   'balance': false}},
             'high_price': {'name': 'высокая цена',
                            'sort': {'marjin_gr': true,
                                     'sale': false,
                                     'balance': true}},
             'low_price': {'name': 'низкая цена',
                           'sort': {'marjin_gr': true,
                                    'balance': true,
                                    'sale': false}},
             'sale': {'name': 'распродажа',
                      'sort': {'marjin_gr': true,
                               'balance': true,
                               'sale': false}}},
    preset_model: 'main',
    sum_topics: [
      "balance",
      "sale",
      "current_balance_m",
      "proceeds",
      "profit_balance_m"
    ],
    topics_any: {
      email: "магазин",
      pic: "pic",
      category: "тип",
      vendor: "производитель",
      collection: "коллекция",
      article: "артикул",
      "new": "новинка в магазине",
      health: "здоровье",
      trend: "тренд",
      abc: "abc",
      balance: "остаток шт",
      sale: "продано шт",
      current_balance_m: "остаток ₽",
      proceeds: "выручка ₽",
      costprice: "себeс-ть ₽",
      price: "цена ₽",
      marjin_gr: "груп.марж",
      marjin: "маржа %",
      profit_balance_m: "прибыль ₽",
      turnover_ratio: "обор-ть коэф",
      turnover_ratio_day: "обор-ть дни"
    },
    topics_mini: [
      "email",
      "pic",
      "category",
      "collection",
      "article",
      "trend",
      "balance",
      "sale",
      "proceeds",
      "price",
      "turnover_ratio",
      "turnover_ratio_day"
    ],
    loading_text: {
      no: "нет данных для расчета",
      yes: "рассчитываю информацию... Подождите немного"
    },
    group_collection: false,
    group_shop: false,
    group_marjin: false,
    group_vendor: false,
    calculate: false,
    close_menu: false,
    filter: [],
    f_goods_model: [],
    f_goods_items: [],
    f_vendor_model: [],
    f_vendor_items: [],
    f_shop_model: [],
    f_shop_items: [],
    shop_info: [],
    change: 0,
    open_article: false,
    opened_article: {},
    opened_data: [],
    hide_topics: localStorage.getItem("hide_topics")
      ? localStorage.getItem("hide_topics").split(",")
      : []
  }),

  computed: {
    ...mapState([
      "year",
      "month",
      "back_domen",
      "access",
      "shop",
      "api_data",
      "not_image",
      "style",
      "all_shop_info",
      "loading"
    ]),

    topics() {
      let result;
      if (~["admin", "producer"].indexOf(this.access.any))
        result = this.topics_any;
      else
        result = this.topics_mini.reduce(
        (acc, el) =>
          this.topics_any[el] ? { ...acc, [el]: this.topics_any[el] } : acc,
        {}
      );
      return this.preset[this.preset_model].fields 
        ? Object.keys(result).reduce(
          (acc, el) =>
           ~this.preset[this.preset_model].fields.indexOf(el)
            ? { ...acc, [el]: this.topics_any[el] } : acc, 
          {})
        : result;
    },

    table_headers() {
      return Object.keys(this.topics)
        .filter(
          el =>
            !~(this.group_marjin
              ? ["article", "category", "vendor", "collection", "pic", "health"]
              : this.group_vendor
              ? [
                  "article",
                  "pic",
                  "collection",
                  "health",
                  this.group_shop ? "email" : ""
                ]
              : this.group_collection
              ? [
                  "article",
                  "pic",
                  this.group_vendor ? "vendor" : "",
                  this.group_shop ? "health" : "",
                  this.group_shop ? "email" : ""
                ]
              : this.group_shop
              ? ["health", "email"]
              : ["health"]
            ).indexOf(el)
        )
        .map(el => {
          return {
            text: this.topics[el] + this.get_sum(this.calculate ? "" : el),
            value: el
          };
        });
    },

    analytics: function() {
      let result = {};
      for (let el of this.data) {
        if (el.abc == "A") {
          result.moneyA = (result.moneyA || 0) + (el.current_balance_m || 0);
          result.shelfA = (result.shelfA || 0) + (el.balance ? 1 : 0);
        }
        if (el.abc == "B") {
          result.moneyB = (result.moneyB || 0) + (el.current_balance_m || 0);
          result.shelfB = (result.shelfB || 0) + (el.balance ? 1 : 0);
        }
        if (el.abc == "C") {
          result.moneyC = (result.moneyC || 0) + (el.current_balance_m || 0);
          result.shelfC = (result.shelfC || 0) + (el.balance ? 1 : 0);
        }
      }
      result.money =
        (result.moneyA || 0) + (result.moneyB || 0) + (result.moneyC || 0);
      result.shelf =
        (result.shelfA || 0) + (result.shelfB || 0) + (result.shelfC || 0);
      return result;
    },

    finishdate: function() {
      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      date =
        this.dates.length > 1
          ? new Date(this.dates[1])
          : this.dates.length
          ? new Date(
              this.startdate.getFullYear(),
              this.startdate.getMonth() + 1,
              this.startdate.getDate()
            )
          : date;
      return date;
    },

    startdate: function() {
      let date = this.dates.length
        ? new Date(this.dates[0])
        : new Date(
            this.finishdate.getFullYear(),
            this.finishdate.getMonth() - 1,
            this.finishdate.getDate() + 1
          )
      // console.log(this.dates);
      return date;
    },

    request_data: function() {
      let result = {
        startdate: new Date(
          this.startdate.getFullYear(),
          this.startdate.getMonth(),
          this.startdate.getDate() + 1
        ),
        finishdate: new Date(
          this.finishdate.getFullYear(),
          this.finishdate.getMonth(),
          this.finishdate.getDate() + 2
        ),
        // producer: Object.entries(this.access).map(
        //   ([k, v]) => v == 'producer' ? k : undefined).filter(el => el),
        category: this.f_goods_model.length > 0 ? this.f_goods_model : "",
        vendor:
          this.f_vendor_model.length > 0
            ? this.f_vendor_model
            : this.access.any == "admin"
            ? ""
            : ["Victoria Stenova", "Stenova Home", "Victoria Stenova клей"],
        email: this.f_shop_model.length > 0 ? this.f_shop_model : "",
        group: [
          this.group_vendor ? "vendor" : "",
          this.group_collection ? "collection" : "",
          this.group_marjin ? "marjin_gr" : "",
          ...(this.group_shop ? ["article", "email"] : "")
        ].filter(el => el),
        factors:
          this.quantity_model != "shelf" && this.filter.length > 0
            ? this.quantity_model
            : "",
        effects: this.filter.length > 0 ? this.quality_model : "",
        health: this.group_collection ? true : false
      };
      result = Object.entries(result).reduce(
        (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
        {}
      );
      return result;
    },

    opened_request: function() {
      let result = {
        startdate: this.startdate,
        finishdate: this.finishdate,
        email: this.f_shop_model.length > 0 ? this.f_shop_model : "",
        category: this.opened_article.category,
        vendor: this.opened_article.vendor,
        collection: this.opened_article.collection,
        factors: this.quantity_model != "shelf" ? this.factors : "",
        effects: this.quality_model,
        sale: true
      };
      result = Object.entries(result).reduce(
        (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
        {}
      );
      return result;
    }
  },

  methods: {
    ...mapMutations(["change_pic_art"]),

    ...mapActions(["add_api_data"]),


    get_sum(attr) {
      let result =
        ~this.sum_topics.indexOf(attr) && this.data.length > 0
          ? this.filter_data(this.data).reduce((acc, el1) => acc + el1[attr], 0)
          : "";
      let format = new Intl.NumberFormat("ru");
      if (!result) return "";
      return " " + format.format(Number(result).toFixed());
    },

    filter_data(data) {
      // фильтр данныx по search
      if (!this.search || data.length == 0) return data;
      return this.data.filter(
        el =>
          Object.keys(el).filter(
            el1 =>
              ~Object.keys(this.topics).indexOf(el1) &&
              ~String(el[el1])
                .toLowerCase()
                .indexOf(this.search.toLowerCase())
          ).length
      );
    },

    async get_data(data) {
      // console.log(data || this.request_data);
      return axios
        .post(this.back_domen + "/service/abc", data || this.request_data)
        .then(res => res.data)
        .then(json => {
          // console.log(json['response']);
          return json["response"].goods;
        });
    },


    add_data() {
      this.data = [];
      this.calculate = false;
      this.get_data().then(data => {
        this.data = data;
        let articles = data.map(el => el.article);
        this.add_api_data(articles);
      });
    },

    add_article(article) {
      this.opened_article = article;
      this.opened_data = [];
      this.get_data(this.opened_request)
        .then(data => (this.opened_data = data))
        .then(() => {
          let articles = this.opened_data
            .map(el => el.article)
            .filter(el => !this.api_data[el]);
          if (articles.length > 0) {
            this.add_api_data(articles);
          }
        });
    },

    set_filters(data) {
      let filter = data.map(el => `${el.category}#####${el.vendor}`);
      filter = [...new Set(filter)].map(el => el.split("#####"));
      // console.log(filter);
      this.filter = filter;
      this.f_goods_items = [...new Set(filter.map(el => el[0]))].sort();
      this.f_vendor_items = [...new Set(filter.map(el => el[1]))].sort();
      this.f_shop_items = [...new Set(data.map(el => el.email))].sort();
    }
  },

  watch: {

    preset_model () {
      this.close_menu = true;
      setTimeout(() => (this.close_menu = false), 50);
      if (this.preset_model == 'main') {
        this.hide_topics = localStorage.getItem("hide_topics")
            ? localStorage.getItem("hide_topics").split(",") : [];
      } else {
        this.hide_topics = this.hide_topics.filter(el =>
          !~[...(this.preset[this.preset_model].required || []),
             ...(Object.keys(this.preset[this.preset_model].sort || []))].indexOf(el));
      }
      this.quantity_model = this.preset[this.preset_model].quantity_model
            || this.quantity_model;
      this.quality_model = this.preset[this.preset_model].quality_model
          || this.quality_model;
      this.group_marjin = this.preset[this.preset_model].group_marjin || false;
      this.group_shop= this.preset[this.preset_model].group_shop || false;
      this.calculate = true;
    },

    hide_topics() {
      if (this.preset_model == 'main')
        localStorage.setItem("hide_topics", this.hide_topics);
    },

    quantity_model() {
      this.calculate = true;
      if (this.preset_model == 'main')
        localStorage.setItem("quantity_model", this.quantity_model);
    },

    quality_model() {
      this.calculate = true;
      if (this.preset_model = 'main')
        localStorage.setItem("quality_model", this.quality_model);
    },

    startdate() {
      this.calculate = true;
    },

    finishdate() {
      this.calculate = true;
    },

    group_shop() {
      this.calculate = true;
    },

    group_vendor() {
      this.calculate = true;
      if (this.group_vendor) this.group_collection = false;
      if (this.group_vendor) this.group_marjin = false;
    },

    filter() {
      if (this.filter.length && !this.data.length) this.calculate = true;
    },

    data() {
      if (this.data.length) this.calculate = false;
    },

    f_shop_model() {
      this.calculate = true;
    },

    f_goods_model() {
      this.f_vendor_items = [
        ...new Set(
          this.filter
            .filter(
              el =>
                this.f_goods_model.length == 0 ||
                ~this.f_goods_model.indexOf(el[0])
            )
            .map(el => el[1])
        )
      ].sort();

      this.calculate = true;
    },

    f_vendor_model() {
      this.f_goods_items = [
        ...new Set(
          this.filter
            .filter(
              el =>
                this.f_vendor_model.length == 0 ||
                ~this.f_vendor_model.indexOf(el[1])
            )
            .map(el => el[0])
        )
      ].sort();

      this.calculate = true;
    },

    group_collection() {
      if (this.group_collection) this.group_marjin = false;
      if (this.group_collection) this.group_vendor = false;
      this.calculate = true;
    },

    group_marjin() {
      if (this.group_marjin) this.group_collection = false;
    },

    change() {
      this.close_menu = true;
      setTimeout(() => (this.close_menu = false), 50);
      let change = this.change;
      let date2 = new Date();
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      let date = new Date(
        date2.getFullYear(),
        date2.getMonth() - change,
        date2.getDate() + 1
      );
      // console.log(`${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      //     "01" +
      //     (date.getDate())
      //   ).slice(-2)}`);
      this.dates = [
        `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
          "0" +
          (date.getDate())
        ).slice(-2)}`,
        `${date2.getFullYear()}-${("0" + (date2.getMonth() + 1)).slice(-2)}-${(
          "0" + date2.getDate()
        ).slice(-2)}`
      ];
    }
  },

  mounted() {
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 700
          }
        },
        color: {
          value: "#686F8A"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#686F8A"
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 0.1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: true,
            speed: 15,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#686F8A",
          opacity: 1,
          width: 1
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab"
          },
          onclick: {
            enable: true,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 20,
            duration: 2,
            opacity: 0.2,
            speed: 20
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    });
  },

  created: function() {
    this.get_data({
      ...this.request_data,
      dictionary: true
    }).then(data => this.set_filters(data));
    // this.data_shop();
    this.dates[0] = `${this.startdate.getFullYear()}-${(
      "0" +
      (this.startdate.getMonth() + 1)
    ).slice(-2)}-${("0" + this.startdate.getDate()).slice(-2)}`;
    let date = new Date(
      this.finishdate.getFullYear(),
      this.finishdate.getMonth(),
      this.finishdate.getDate()
    );
    this.dates[1] = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${("0" + (date.getDate())).slice(-2)}`;
    // console.log(this.dates)
  }
};
</script>

<style lang="scss" scoped>
canvas {
  display: block;
  vertical-align: bottom;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
}
</style>