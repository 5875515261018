<template>
  <div>
    <v-simple-table
      class="mt-2">
      <template>
        <thead>
          <tr>
            <th class="text-left blueLight white--text"
                v-for="title in titletable"
                :key="title">
                  {{title}}
                </th>
          </tr>
        </thead>
        <tbody class="text-left">
          <slot name="line" />
        </tbody>
      </template>
    </v-simple-table>
    <br>
  </div>
</template>

<script>

export default {
name: 'GlueRating',

data: () => ({
  titletable: ['Место','Магазин','Результат'],
}),
}
</script>
