<template>
<v-main
  :style="style.background"
  class="pt-0 pb-0">
  <v-card
    :style="{...style.neobtn, 'border-radius': '0px'}"
    class="mx-auto pt-8 pb-12 px-md-8"
    width="1460"
    height="100%">
    <v-row>
      <v-col cols="12">
        <v-card :style="style.neocard" class="px-md-8 px-6">
          <v-card-title>
            <span class="hidden-screen-only">
              {{shop}} график работы на {{months[(local_month || month) - 1]}}
              {{local_year || year}}</span>
            <span class="d-print-none mr-16">
              {{shop}}
            </span>
            <!-- <div -->
            <!--   v-if="access.any == 'admin' || -->
            <!--         access[shop] == 'admin'"> -->
              <!--   <v-btn -->
              <!--     :key="key" -->
              <!--     v-for="(key, action) in actions" -->
              <!--     @click="active_button=(key!=active_button) ? key : ''" -->
              <!--     :style="style.neobtn" -->
              <!--     class="d-print-none"> -->
                <!-- {{action}} -->
                <!--   </v-btn> -->
              <!-- </div> -->


            <!-- выбор режимов -->
            <v-chip-group column
                          v-if="access.any == 'admin' ||
                                access[shop] == 'admin'"
                          active-class="white--text"
                          class="d-print-none mx-8 mt-n2">
              <div class="pl-5 pr-12 py-3">
                <v-chip
                  class="mr-5"
                  active-class="white--text"
                  :style="{...style.neobtn, 'border-radius': '10px'}"
                  @click="active_button=(key!=active_button) ? key : ''"
                  :color="key==active_button ? 'orangeMain' : ''"
                  v-for="(key, action) in actions"
                  :key="key">
                  {{action}}
                </v-chip>
              </div>
            </v-chip-group>

          </v-card-title>


          <v-row v-for="(position, idx) in Object.keys(position_actions).filter(
            pos => shiftdata.filter(el => el.position == pos).length)" :key="position" :class="idx ? 'mt-n10' : 'mt-n5'">
            <v-col cols="2" md="1" class="pa-0 ma-0">

            <!-- таблица со сменами -->
            <v-card flat
                    class="grey6"
                    >
              <v-simple-table dense
                class="d-print-table grey6">
                <template>
                  <thead>
                    <tr>
                      <th>
                        <div class="grey6--text">.</div>
                        <div class="grey6--text">.</div>
                        <div class="grey4--text ml-n4">{{positions[position]}}</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody class="text-left">
                    <tr v-for="el in shiftdata.filter(el => el.position == position)" :key="el.id">
                      <td class="pl-0">
                        <v-card flat
                                height="40"
                                class="pa-0 ma-0 mb-1 mt-2 grey6"
                                :class="user && user.id == el.id ? '' : ''">
                          <div
                              class="text-12 d-flex">
                            <div class="d-flex flex-column">
                              <span class="mr-auto" 
                                v-if="~el.name.indexOf(' ')">{{el.name.split(' ')[1]}}</span>
                              <span class="mr-auto">{{el.name.split(' ')[0]}}</span>
                            </div>
                            <div class="ml-auto">
                              <v-btn x-small icon
                                     v-if="Object.values(el.shift).filter(el =>
                                           el.status != 'pass' && el.status != 'virtual').length > 0"
                                     class="ml-auto orangeDark white--text"
                                     >
                                {{Object.values(el.shift).filter(el =>
                                el.status != 'pass' && el.status != 'virtual').length}}
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            </v-col>
            <v-col cols="10" md="11"
                   class="pa-0 ma-0">
            <v-card flat
                    width="1390"
                    class="grey6">
              <v-simple-table dense
                class="d-print-table grey6 pb-12">
                <template>
                  <thead>
                    <tr>
                      <th
                        v-for="day in daystable"
                        class="pa-0 text-center"
                        :key="day[0] + Math.random()"
                        :class="
                                calendar[day[0]] &&
                                calendar[day[0]].work=='non-working' ?
                                'orangeDark--text': ''"
                        @click="access_list.includes('admin') ? open_day_correct(day) : ''">
                        <v-icon
                          color="red lighten-3" size="6" class="mb-n6 ms-5">
                          {{Object.keys(working_days).includes(day[0]) ? 'fa-circle': ''}}
                        </v-icon>
                        <div :class="idx ? 'd-xl-none' : '' ">{{day[0]}} </div>
                        <div :class="idx ? 'd-xl-none' : '' ">{{day[1]}} </div>
                        <div v-if="idx" class="d-none d-xl-flex grey6--text">.</div>
                        <div v-if="idx" class="d-none d-xl-flex grey6--text">.</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody class="text-left">
                    <tr v-for="el in shiftdata.filter(el => el.position == position)" :key="el.id">
                      <td v-for="day in daystable" :key="day[0] + Math.random()"
                          @click="position_actions[position].includes(active_button) ? click_shift(day[0], el,
                                              el.shift[day[0]],
                                              el.vocation[day[0]]) : ''"
                          class="pa-0 ma-0">
                        <v-card
                          elevation="0"
                          :style="{
                                  'border-radius': '6px',
                                  }"
                          class="pa-0 ma-0 mb-2 mt-1 grey6 ml-1 ml-md-0"
                          height="40"
                          width="39">
                          <v-chip label
                                  class="pb-6 pt-5"
                                  v-if="el.shift[day[0]] &&
                                  el.shift[day[0]].status != 'virtual'"
                                  :class="el.shift[day[0]].responsible ? 'marker' :
                                  el.shift[day[0]].othercity ? 'marker_travel' : ''"
                                  :style="{'box-shadow': `${el.vocation[day[0]] ? 'inset 0 0 8px #FF6B6C' : 'none'}`,
                                           'background-color': `${get_color(el.shift[day[0]]) == 'blueLight' ? '#868EAA' : '#B4BACE'}`}">
                            <v-icon left v-if="el.shift[day[0]].hours" color="white">
                              {{get_icon(el.shift[day[0]].hours)}}
                            </v-icon>
                            <v-icon left v-else-if="el.shift[day[0]].responsible" color="white">
                              star
                            </v-icon>
                            <v-icon left v-else-if="el.shift[day[0]].othercity" color="white">
                              flight
                            </v-icon>
                            <v-icon
                              left
                              v-else-if="!el.shift[day[0]].sellingMoney
                              && el.shift[day[0]].status == 'open' && position == 'seller'"
                              color="white">
                              far fa-lemon
                            </v-icon>
                            <v-icon left v-else-if="!el.shift[day[0]].hours">
                              t</v-icon>
                          </v-chip>
                          <v-chip label
                                  class="pb-6 pt-5"
                                  v-else-if="el.vocation[day[0]]"
                                  :color="get_color(el.vocation[day[0]])">
                            <v-icon left>t</v-icon>
                          </v-chip>
                          <v-chip label color="grey5"
                                  class="pb-6 pt-5"
                                  :outlined="!el.hours && (access.any == 'admin'
                                  || access[shop] == 'admin') && check_date(day[0])
                                  ? true : false"
                                  v-else>
                            <v-icon left>t</v-icon>
                          </v-chip>
                        </v-card>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            </v-col>
            </v-row>

          <!-- <\!-- таблица со сменами -\-> -->
          <!-- <v-simple-table class="d-print-table grey6 mt-16"> -->
          <!--   <template> -->
          <!--     <thead> -->
          <!--       <tr> -->
          <!--         <th></th> -->
          <!--         <th></th> -->
          <!--         <th v-for="day in daystable" :key="day[0] + Math.random()" -->
          <!--             :class="calendar[day[0]] && -->
          <!--                     calendar[day[0]].work=='non-working' ? -->
          <!--                     'orange--text': ''"> -->
          <!--           {{day[0] + ' ' + day[1]}} -->
          <!--         </th> -->
          <!--       </tr> -->
          <!--     </thead> -->

          <!--     <tbody class="text-left"> -->
          <!--       <tr v-for="el in shiftdata" :key="el.id" -->
          <!--           :class="user && user.id == el.id ? -->
          <!--                   'grey5' : ''"> -->
          <!--         <td>{{el.name}}</td> -->
          <!--         <td>{{Object.values(el.shift).filter(el => -->
          <!--           el.status != 'pass' && el.status != 'virtual').length}}</td> -->
          <!--         <td v-for="day in daystable" :key="day[0] + Math.random()" -->
          <!--             @click="click_shift(day[0], el, el.shift[day[0]])" -->
          <!--             class="pa-0" -->
          <!--             > -->
          <!--           <v-chip -->
          <!--             v-if="el.shift[day[0]] && -->
          <!--             el.shift[day[0]].status != 'virtual'" -->
          <!--             :color="get_color(el.shift[day[0]])" -->
          <!--             label -->
          <!--             :class="el.shift[day[0]].responsible ? 'marker' : -->
          <!--             el.shift[day[0]].othercity ? 'marker_travel' : ''"> -->
          <!--             <v-icon left v-if="el.shift[day[0]].hours" color="white"> -->
          <!--               {{get_icon(el.shift[day[0]].hours)}} -->
          <!--             </v-icon> -->
          <!--             <v-icon left v-else-if="el.shift[day[0]].responsible" color="white"> -->
          <!--               star -->
          <!--             </v-icon> -->
          <!--             <v-icon left v-else-if="el.shift[day[0]].othercity" color="white"> -->
          <!--               flight -->
          <!--             </v-icon> -->
          <!--             <v-icon -->
          <!--               left -->
          <!--               v-else-if="!el.shift[day[0]].sellingMoney -->
          <!--               && el.shift[day[0]].status == 'open'" -->
          <!--               color="orange lighten-2"> -->
          <!--               far fa-lemon -->
          <!--             </v-icon> -->
          <!--             <v-icon left v-else-if="!el.shift[day[0]].hours"> -->
          <!--               t</v-icon> -->
          <!--           </v-chip> -->
          <!--           <v-chip v-else label outlined -->
          <!--                   color="grey4"> -->
          <!--             <v-icon left>t</v-icon> -->
          <!--           </v-chip> -->
          <!--         </td> -->
          <!--       </tr> -->
          <!--     </tbody> -->
          <!--   </template> -->
          <!-- </v-simple-table> -->

        </v-card>

      </v-col>
    </v-row>
    <div class="row pt-2 d-print-none px-2 px-md-0">
      <div class="col-12">

        <!-- планы на день -->
        <span class="blue-grey--text">Планы на день</span>
        <v-chip-group
          class="my-2"
          column
          >
          <v-chip label
                  v-for="key in [...days.slice(1),
                  days[0]]" :key="key"
                  :outlined="key+(+(local_month || month))+(local_year || year)==
                  days[today.getDay()]+(today.getMonth()+1)+
                  today.getFullYear() ? true : false"
                  :color="key+(+(local_month || month))+(local_year || year)==
                  days[today.getDay()]+(today.getMonth()+1)+
                  today.getFullYear() ?  'orangeDark' : ''"
                  :class="key+(+(local_month || month))+(local_year || year)==
                  days[today.getDay()]+(today.getMonth()+1)+
                  today.getFullYear() ?  'black--text' : ''">
            <v-avatar
              left
              class="white--text"
              color="orangeDark">
              {{key}}
            </v-avatar>
            {{shift_plan_week[key] | money2}}
          </v-chip>
        </v-chip-group>

        <!-- подсказка -->
        <span class="blue-grey--text">Как выставляются планы </span>
        <v-icon @click="sheet=true"
                class="pb-3"
                color="orangeDark"
                >far fa-question-circle</v-icon>


      </div>
    </div>

    <!-- таблица для росписи -->
    <div class="hidden-screen-only">
      <div class="col-12">

        <table>
          <tbody>
            <tr v-for="el in shiftdata" height="55" :key="el.day + String(el.id)">
              <td>{{el.name}}</td>
              <td width="200" style="border-bottom: solid 1px black;"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </v-card>

  <!-- диалог для выбора часов -->
  <v-dialog
    width=460
    v-model="dialog">
    <v-card>
      <v-card-title>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-chip-group column>
            <v-chip label
                    v-for="key in active_shift.maxHours" :key="key"
                    class="white--text"
                    :color="(active_shift.hours || active_shift.maxHours)==key ?
                    'orangeDark' : 'blueLight'"
                    @click="active_shift.hours_old =
                    active_shift.hours_old || active_shift.hours || active_shift.maxHours;
                    active_shift.hours=key">
              <v-icon>access_time</v-icon>
              {{ ('0' + key).slice(-2) }} &nbsp;&nbsp;&nbsp;₽
              {{(active_shift.money / (active_shift.hours_old || active_shift.hours || active_shift.maxHours) * key).toFixed()
              | money2}}
            </v-chip>
          </v-chip-group>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text
                @click="dialog=false">
          Закрыть
        </v-btn>
        <v-btn text
                @click="click_shift(active_shift.day,
                shiftdata.filter(el =>
          el.id == active_shift.id)[0],
          active_shift);">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- диалог настройки рабочего дня -->
  <v-dialog
    width=460
    v-model="day_dialog">
    <v-card>
      <v-card-title class="grey--text">
        {{`${('0' + active_day.day).slice(-2)}.${('0'+(local_month || month)).slice(-2)}.${local_year || year}`}}
      </v-card-title>

      <v-card-text>
        <v-text-field
                    outlined hide-details
                    ref="form"
                    :rules="[v => !isNaN(v) || !v || 'Допустимы только цифры']"
                    @input='validateCorrect'
                    :style="{...style.neocard, 'border-radius':'10px'}"
                    label="добавить продажи к дню"
                    v-model.trim="active_day.sales"
                    dense
        ></v-text-field>
        <v-switch hide-details
                  class="mt-5"
                  :disabled="active_day.plan_block ? true : false"
                  v-model="active_day.plan"
                  label="учитывать смену в разбивке планов"
                  color="orange"
        ></v-switch>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text
                @click="day_dialog=false">
          Закрыть
        </v-btn>
        <v-btn text
          :disabled="!checkNumbers"
          @click="add_settings_workday(); day_dialog=false">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- окно с подсказкой -->
  <v-bottom-sheet v-model="sheet">
    <v-sheet max-height="500px">
      <v-btn
        class="mt-6"
        text
        color="orangeDark"
        @click="sheet=false"
        >скрыть</v-btn>
      <div class="pa-3">
        <p class="font-weight-light" v-for="el in text" :key=el>
          {{el}}
        </p>
      </div>
    </v-sheet>
  </v-bottom-sheet>

  <!-- предупреждение - смена существует -->
  <v-snackbar top right
              :timeout="1500"
              v-model="smenaexist"
              color="error">
    есть смена в {{message}}
    <template v-slot:action="{ attrs }">
      <v-btn icon
             v-bind="attrs"
             @click="snackbar = false">
        <v-icon small>far fa-trash-alt</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

</v-main>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
export default {
name: 'WDay',
props: ['local_month', 'local_year'],
data: () => ({
  smenaexist: false,
  shiftdata: [],
  actions: {
    "расстановка смен": 'editing_shift',
    "отпуск": 'vocation',
    "выбор ответственного": 'responsible',
    "редактирование времени": 'editing_time',
    // "день инвентаризации": 'inventory',
    "смена в другом городе": 'othercity'},
  active_button: "",
  dialog: false,
  day_dialog: false,
  checkNumbers: true,
  active_shift: {},
  active_day: {},
  calendar: [],
  working_days: {},
  shift_plan: {},
  today: new Date,
  text: [
    "Мы взяли продажи за последние 3 месяца и нашли отличия в объеме продаж. Из этих отличий и месячного плана на магазин мы рассчитали план на каждый день недели",
    "Если выходной день считается рабочим (из-за переноса праздников), то план в этот день уменьшается на 25 процентов",
    "Если рабочий день считается выходным (из-за переноса праздников), то план в этот день увеличивается на 50 процентов",
    "Уменьшения или увеличения планов из-за праздников равномерно распределяется между всеми днями в месяце"
       ],
  sheet: false,
  editing: true,
  message: '',
  names:['ФИО', 'всего часов','переработка (сколько часов)','переработка (с какого числа)',],
  start: '2020-11-30',
  startMenu: false,
  next_years: ['2020','2021','2022','2023','2024'],
  position_actions: {
    'seller': ['editing_shift', 'vocation', 'editing_time', 'othercity', 'responsible'],
    'storekeeper': ['editing_shift', 'vocation', 'editing_time'],
    'cashier': ['editing_shift', 'vocation', 'editing_time']}
}),

computed: {

  ...mapState(['shop', 'days', 'month','months', 'year',
               'user', 'access', 'back_domen', 'style', 'all_shop_info',
               'positions']),

  access_list() {
      return [
        ...new Set(
          Object.entries(this.access)
            .filter(el => el[0] != "root")
            .map(el => el[1])
        )
      ];
  },

  daystable: function (){
    let result = [];
    let count = 1;
    let check = true;
    while (check){
      let date = new Date(this.local_year || this.year,
                          (this.local_month || this.month) - 1, count);
      if (date.getMonth() == (this.local_month || this.month) - 1){
        result.push([('0' + date.getDate()).slice(-2),
                     this.days[date.getDay()]]);
        count ++;
      } else check = false;
    }
    return result;
  },

  shift_plan_week: function (){
    if (Object.keys(this.calendar).length == 0 ||
        Object.keys(this.shift_plan).length == 0)
      return {};
    let check = this.shiftdata.reduce(
      (acc, el) => el.shift ? {...acc, ...el.shift} : acc, {});
    // console.log(check, this.daystable);
    let days = [...new Set([...this.daystable.map(el => el[1])])];
    days = days.reduce((acc, k) => {
      return {...acc,
        [k]: Math.min.apply(null, this.daystable.filter(
          el => el[1] == k && (!check[el[0]] || !check[el[0]].special))
                                  .map(el => +this.shift_plan[el[0]])
                                  .filter(el => el))};
    }, {});
    return days;
  }
},

methods: {

  validateCorrect() {
    this.checkNumbers = this.$refs.form.validate();
  },

  open_day_correct(day) {
    this.day_dialog=true;
    this.active_day={day:day[0]}
  },

  async get_calendar() {
    if (!this.shop) return;
    this.calendar = {};
    return axios.post(this.back_domen + '/service/calendar', {
      'year': this.local_year || this.year,
      'month': this.local_month || this.month,
    }).then(res => res.data)
      .then(json => this.calendar = json['response']);
  },

  async get_shift() {
    if (!this.shop) return;
    return axios.post(this.back_domen + '/service/shift', {
      'year': this.local_year || this.year,
      'month': this.local_month || this.month,
      'shopID': this.shop,
    }).then(res => res.data)
      .then(json => {
        this.shiftdata = json['response']['shift'];
        this.shift_plan = json['response']['shiftPlan'];
    });

  },

  async post_shift(data) {
    return axios.post(this.back_domen + '/service/shiftupd', data)
    .then(res => res.data)
    .then(json => json['response']);

  },

  get_color(shift) {
    let today = new Date();
    let date = new Date(+shift.year, +shift.month - 1, +shift.day);
    if (shift.status == 'vocation') return '#FCBFB7';
    if (shift.extraHours && this.access.any == 'admin' ||
        this.access[this.shop] == 'admin') return 'redLight';
    if (date > today) return 'blueMiddle';
    if (shift.status == 'pass') return 'grey';
    return 'blueLight';
  },

  check_date(day) {
    let date = new Date();
    if (+day > date.getDate() &&
        (this.local_month || this.month) == date.getMonth() + 1 &&
        (this.local_year || this.year) == date.getFullYear()) return true;
    return false;
  },

  get_icon(hours) {
    return hours < 10 ? 'filter_' + hours : 'filter_9_plus';
  },

  click_shift(day, element, shift, vocation) {
    if (!this.editing) return undefined;
    this.active_shift = shift ? Object.assign({}, shift) : {};
    let today = new Date;
    let date = new Date(+(this.local_year || this.year), 
                          +(this.local_month || this.month) - 1, +day);

    if (this.active_button == 'editing_shift') {
      if (date > new Date(today.getFullYear(),
                          today.getMonth(),
                          today.getDate() - 1) ||
          this.active_shift.status == 'pass' || this.access.root) {
        if (shift) this.active_shift['delete'] = true;
        else {
          this.active_shift = {'day': day,
                               'month': this.local_month || this.month,
                               'year': this.local_year || this.year,
                               'name': element.name,
                               'id': element.id,
                               'shopID': this.shop,
                               'create': true};
          if (date <= new Date(today.getFullYear(),
                               today.getMonth(),
                               today.getDate() - 1))
            this.active_shift.status = "open";
        }
        this.post_shift(this.active_shift).then(json => {
          if (json && this.active_shift['delete']) delete element.shift[day];
          else if (json && !json.error) element.shift[day] = json;
          else if (json && json.error) {
            this.message = String(json.error).split('@')[0];
            this.smenaexist = true;
          }
          this.shiftdata = [...this.shiftdata];
        });
      }

    } else if (this.active_button == 'vocation') {

        if (vocation) vocation['delete'] = true;
        else {
          vocation = {'day': day,
                      'month': this.local_month || this.month,
                      'year': this.local_year || this.year,
                      'name': element.name,
                      'id': element.id,
                      'status': 'vocation',
                      'create': true}
        }
        this.post_shift(vocation).then(json => {
          if (json && vocation['delete']) delete element.vocation[day];
          else if (json && !json.error) element.vocation[day] = json;
          else if (json && json.error) {
            this.message = String(json.error).split('@')[0];
            this.smenaexist = true;
          }
          this.shiftdata = [...this.shiftdata];
        });

    } else if (this.active_button == 'responsible') {
       if (date > new Date(today.getFullYear(),
                           today.getMonth(),
                           today.getDate() - 1)) {
        if (shift) {
          this.active_shift.responsible = (+shift.responsible) ? false : true;
          this.post_shift(this.active_shift).then(json => {
            if (json) {
              element.shift[day] = json;
              if (json.responsible) {
                for (let el of this.shiftdata){
                  if (el == element) continue;
                  if (el.shift[day] &&
                      el.shift[day].responsible) {
                    el.shift[day].responsible = false;
                    this.post_shift(el.shift[day]);
                  }
                }
              }
            }
            this.shiftdata = [...this.shiftdata];
          });
        }
      }

    } else if (this.active_button == 'othercity') {
       if (date > new Date(today.getFullYear(),
                           today.getMonth(),
                           today.getDate() - 1) || this.access.root){
        if (shift) {
          this.active_shift.othercity = (+shift.othercity) ? false : true;
          this.post_shift(this.active_shift).then(json => {
            if (json) element.shift[day] = json;
            this.shiftdata = [...this.shiftdata];
          });
        }
      }

    } else if (this.active_button == 'editing_time') {
        if (shift && !this.dialog) this.dialog = true;
        else if (shift && this.dialog &&
                 this.active_shift.hours &&
                 this.active_shift.hours != this.active_shift.hours_old) {
          this.active_shift.money = (this.active_shift.money /
            this.active_shift.hours_old * this.active_shift.hours);
          this.post_shift(this.active_shift).then(json => {
            this.dialog = false;
            if (json) element.shift[day] = json;
            this.shiftdata = [...this.shiftdata];
          });
        }
      } 
    },

  async add_settings_workday() {
    let save = {};
    // console.log(this.active_day);
    if (!this.active_day.first) save = {...this.active_day};
    else {
      for (let k in this.active_day){
        if (k == 'first') continue;
        if (this.active_day[k] == this.active_day.first[k])
          continue;
        if (!this.active_day[k] && !this.active_day.first[k])
          continue;
        save[k] = this.active_day[k];
      }
    }
    if (!Object.keys(save).length) return;
    save.date = new Date(Date.UTC(+(this.local_year || this.year),
                                  +(this.local_month || this.month)-1,
                                  +this.active_day.day));
    save.email = this.all_shop_info[this.shop].email;
    console.log(save);
    return axios.post(this.back_domen + '/service/workdays_upd', save)
      .then(res => res.data)
      .then(json => {
        if (json.response.complete)
          this.get_shift();
          this.load_working_days();
      });
  },

  async load_working_days() {
    return axios.post(this.back_domen + '/service/workdays', {
      email: this.all_shop_info[this.shop].email,
      date_start : new Date(Date.UTC(+(this.local_year || this.year),
                                     +(this.local_month || this.month)-1, 1)),
      date_end: new Date(Date.UTC(+(this.local_year || this.year),
                                  +(this.local_month || this.month), 1)),
    })
      .then(res => res.data)
      .then(json => this.working_days = json.response.reduce(
        (acc, el) => !el.date ? acc : {...acc, [el.date.split('-')[2].slice(0, 2)]: el}, {}));
  },
},

watch: {

  active_day() {
    if (this.active_day.day) {
      if (this.shift_plan[this.active_day.day]){
        this.active_day.plan_money = this.shift_plan[this.active_day.day];
        this.active_day.plan = true;
        for (let el of this.shiftdata) {
          if (el.shift[this.active_day.day]
              && el.shift[this.active_day.day].planmoney) {
            this.active_day.plan_block = true;
          break;
          }

        }
      }
      else {
        this.active_day.plan_money = 0;
        this.active_day.plan = false;
      }
      if (this.working_days[this.active_day.day]) {
        this.active_day.plan = this.active_day.plan || this.working_days[this.active_day.day].plan;
        this.active_day.sales = this.working_days[this.active_day.day].sales;
      }
      if (!this.active_day.sales) this.active_day.sales = '';
      if (!this.active_day.first)
        this.active_day.first = {...this.active_day};
    }
  },

  month () {
    this.get_shift();
    this.get_calendar();
    if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
  },

  year () {
    this.get_shift();
    this.get_calendar();
    if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
  },

  shop() {
    this.get_shift();
    if (this.calendar.length == 0) this.get_calendar();
    if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
  },

  local_month () {
    this.get_shift();
    this.get_calendar();
    if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
  },

  local_year () {
    this.get_shift();
    this.get_calendar(); 
    if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
  },

  smenaexist () {
    if (!this.smenaexist) this.message = '';
  }
},

created: function() {
  this.get_shift();
  this.get_calendar();
  if (this.access_list.includes("admin")) {
      this.load_working_days();
    }
}

}
</script>
